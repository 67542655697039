import { createGlobalStyle } from "styled-components";

import colours from "../utils/colours";

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  :root {
    font-size: 62.5%;
  }
  body {
    background: ${colours.shell};
    color:  ${colours.charcoal};
    font-family: 'HK Grotesk', sans-serif;
    font-size: 1.6rem;
  }
  input, textarea, select, pre { 
    font-family: inherit;
  }
`;
