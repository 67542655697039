import { useQuery } from "@tanstack/react-query";
import ArticleClient from "@/client/ArticleClient";

const articleClient = new ArticleClient();

export const getLatestArticles = (limit?: number) =>
  useQuery({
    queryKey: ["getLatestArticles"],
    queryFn: () => articleClient.getArticles(limit),
  });
