/**
 * Locales we support:
 * en-gb, en-us, en-au
 *
 *
 *  Resources:
 *  - https://nextjs.org/docs/advanced-features/i18n-routing
 */
import React, { FunctionComponent } from "react";

export interface LocaleContextModel {
  locale: string;
  handleSetLocale: (locale: string) => void;
}

type ProviderProps = {
  children: React.ReactNode;
};

const Context = React.createContext<LocaleContextModel | null>(null);

const Provider: FunctionComponent<ProviderProps> = (props) => {
  const [locale, setLocale] = React.useState<string | undefined>();

  const handleSetLocale = (locale: string) => {
    setLocale(locale);
  };

  return (
    <Context.Provider value={{ locale, handleSetLocale }}>
      {props.children}
    </Context.Provider>
  );
};

export { Context, Provider };
