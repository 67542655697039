import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";

import * as UserContext from "../../UserContext";
import * as u from "../../../utils";
import NavLinks from "@/components/navigation/NavLinks";
import RxPromoStrip from "@/components/ui/display/RxPromoStrip";
import {
  NAV_LINKS_ANONYMOUS,
  NAV_LINKS_AUTHENTICATED,
  pagesWithTransparentNav,
  platformPages,
} from "@/utils/navigationItems";

const RxNavMain: React.FunctionComponent = (): JSX.Element => {
  const router = useRouter();
  const userInfo = useContext(UserContext.Context);
  const transparentNav = pagesWithTransparentNav.includes(router.pathname);
  const isLoggedIn = !!userInfo.id;
  const isPlatform =
    isLoggedIn && platformPages.includes(router.pathname.split("/")[1]);
  const navLinks = isPlatform ? NAV_LINKS_AUTHENTICATED : NAV_LINKS_ANONYMOUS;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [promoStripHeight, setPromoStripHeight] = useState(0);

  return (
    <>
      <NavLinks
        isOpen={isDropdownOpen}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        userInfo={userInfo}
        links={navLinks}
        isPlatform={isPlatform}
      />
      <RxPromoStrip getHeight={(height) => setPromoStripHeight(height)} />
      {!transparentNav && <S.NavPadding promoStripHeight={promoStripHeight} />}
    </>
  );
};

type StyleProps = {
  promoStripHeight?: number;
  isOpen?: boolean;
  signedIn?: boolean;
};

const S = () => {};

S.NavPadding = styled.div<StyleProps>`
  height: calc(60px + ${(p) => p.promoStripHeight}px);

  @media ${u.device.tablet} {
    height: calc(84px + ${(p) => p.promoStripHeight}px);
  }
`;

export default RxNavMain;
