import React from "react";
import styled from "styled-components";
import Image from "next/image";

import * as I from "./Icons";
import * as u from "../utils";

function ImageInitials(props) {
  const [isError, setIsError] = React.useState(false);
  const maybeSrc = getMaybeSrc();
  const maybeInit = getMaybeInit();
  const style = getSize();

  if (maybeSrc && !isError) {
    return (
      <S.Container onClick={props.onClick} $isHover={props.isHover}>
        <S.Image onError={onError} style={style} center={props.center}>
          <Image
            src={maybeSrc}
            layout="fill"
            objectFit="cover"
            alt={`profile picture for ${props.name}`}
          />
        </S.Image>
        {props.isAmbassador && <Ambassador size={props.size} />}
      </S.Container>
    );
  }

  return (
    <S.Container onClick={props.onClick} $isHover={props.isHover}>
      <S.Initials onClick={props.onClick} style={style} center={props.center}>
        {maybeInit}
      </S.Initials>
      {props.isAmbassador && <Ambassador size={props.size} />}
    </S.Container>
  );

  function getMaybeSrc() {
    return props.imageSrc;
  }

  function getMaybeInit() {
    if (props.icon) return props.icon;
    if (props.displayName) return props.displayName.split(" ").map((a) => a[0]);
    if (props.name) return props.name.split(" ").map((a) => a[0]);
    if (!props.firstName && !props.lastName) return "";
    if (!props.firstName) return props.lastName?.charAt(0);
    if (!props.lastName) return props.firstName?.charAt(0);

    return props.firstName[0] + props.lastName[0];
  }

  function onError() {
    setIsError(true);
  }

  function getSize() {
    const getDimensions = (size, fontSize) => ({
      width: size,
      minWidth: size,
      height: size,
      fontSize,
    });

    switch (props.size) {
      case "sml":
        return getDimensions(25, 20);
      case "mid":
        return getDimensions(40, 16);
      case "big":
        return getDimensions(50, 20);
      case "xl":
        return getDimensions(55, 20);
      case "xxl":
        return getDimensions(75, 30);
      case "sxxl":
        return getDimensions(100, 30);
      case "xxxl":
        return getDimensions(137, 40);
      default:
        return getDimensions(props.width ?? 32, 14);
    }
  }
}

function Ambassador(props) {
  return (
    <S.AmbassadorRing size={props.size}>
      <S.AmbassadorIconContainer size={props.size}>
        <I.Ambassador size={getAmbassadorIconSize()} />
      </S.AmbassadorIconContainer>
    </S.AmbassadorRing>
  );

  function getAmbassadorIconSize() {
    switch (props.size) {
      case "big":
        return 21;
      case "xxl":
        return 33;
      case "sxxl":
        return 33;
      case "xxxl":
        return 33;
    }
  }
}

const S = () => {};

S.Container = styled.div<{ $isHover?: boolean }>`
  position: relative;
  ${(props) =>
    props.$isHover
      ? `
      cursor: pointer; 
      &:hover { opacity: .5; }
    `
      : ``}
`;

S.Initials = styled.div<{ center?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${u.colours.shellOverlay};
  width: 32px;
  height: 32px;
  border-radius: 100%;
  font-size: 14px;
  line-height: 14px;
  color: ${u.colours.charcoal};
  ${(p) => p.center && "margin: 0 auto;"}
`;

S.Image = styled.div<{ center?: boolean }>`
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  ${(p) => p.center && "margin: 0 auto;"}
`;

S.AmbassadorRing = styled.div<{ size?: string }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  border: solid ${u.colours.turquoise};
  border-radius: 100%;

  ${(props) => {
    switch (props.size) {
      case "big":
        return `border-width: 3px;`;
      case "xxl":
        return `border-width: 3px;`;
      case "sxxl":
        return `border-width: 3px;`;
      case "xxxl":
        return `border-width: 3px;`;
      default:
        return `border-width: 2px;`;
    }
  }}
`;

S.AmbassadorIconContainer = styled.div<{ size?: string }>`
  position: absolute;

  ${(props) => {
    switch (props.size) {
      case "big":
        return `top: 28px; right: -8px; `;
      case "xxl":
        return `top: 96px; right: 0px;`;
      case "sxxl":
        return `top: 96px; right: 0px;`;
      case "xxxl":
        return `top: 96px; right: 0px;`;
      default:
        return `display: none;`;
    }
  }}
`;
export default ImageInitials;
