"use strict";

const colours = require("./colours").default;
const device = require("./device").default;
const _ = require("lodash");

exports.colours = colours;
exports.device = device;

exports.localStorage = (() => {
  if (typeof window !== "undefined") return localStorage;

  return {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
    clear: () => {},
  };
})();

exports.sessionStorage = (() => {
  if (typeof window !== "undefined") return sessionStorage;

  return {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
    clear: () => {},
  };
})();

exports.pause = (time, message) => {
  return new Promise((ok) => {
    if (message) console.log(message);
    setTimeout(ok, time);
  });
};

exports.clean = (obj) => {
  const store = { ...obj };

  for (var propName in store) {
    if (store[propName] === null || store[propName] === undefined) {
      delete store[propName];
    }
  }

  return store;
};

exports.exec = (regex, text) => {
  const store = [];
  let match;

  while ((match = regex.exec(text))) {
    const start = match.index;
    const end = start + match[0].length;
    store.push({ match: match[0], int: start, end: end });
  }

  return store;
};

exports.trimStr = (str, maxLength) => {
  if (!str) return;
  if (str.length >= maxLength) return str.substring(0, maxLength) + "...";
  return str;
};

exports.uuid = () => {
  var s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

exports.secToNearestMin = (sec) => {
  const min = `${Math.ceil(sec / 60)}`;
  return Math.round(min);
};

exports.secToMin = (sec) => {
  const min = `${Math.floor(sec / 60)}`.padStart(2, "0");
  sec = `${sec % 60}`.padStart(2, "0");
  return `${min}:${sec}`;
};

exports.secToTimeLeft = (sec, isTomorrow) => {
  if (isTomorrow) return `tomorrow`;
  if (sec > 1209600) return `in a few weeks`; // 14 days
  if (sec > 604800) return `in about a week`; // 7 days
  if (sec > 86400) return `in a few days`; // 1 day

  return `in ${getCountdown()}`;

  function getCountdown() {
    const hr = `${Math.floor(sec / 3600)}`.padStart(2, "0");
    const min = `${Math.floor((sec % 3600) / 60)}`.padStart(2, "0");
    const s = `${sec % 60}`.padStart(2, "0");

    return `${hr}:${min}:${s}`;
  }
};

exports.filterToArray = (obj, callback) => {
  return Object.keys(obj)
    .filter((key, idx) => {
      return callback(key, obj[key], idx);
    })
    .map((key) => ({ key, ...obj[key] }));
};

exports.getRandomStr = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result;
};

exports.ellipsis = (string, length = 40) => {
  return string
    ?.slice(0, length)
    .trim()
    .concat(string.length > length ? "..." : "");
};

exports.ukToDate = (string) => {
  const [day, monthIndex, year] = string.split("/");
  const dob = new Date(year, Number(monthIndex) - 1, day).getTime();
  return dob;
};

exports.sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

exports.generateMetaTags = (info) => {
  const resizeImg =
    !!info.imgW && !!info.imgH
      ? `${info.imgUrl}?w=${info.imgW}&h=${info.imgH}`
      : info.imgUrl;
  const trimTitle = info.title.substring(0, 60);
  const trimDesc =
    info.description.length > 155
      ? info.description.substring(0, 155) + "..."
      : info.description;

  return [
    { itemProp: "name", content: trimTitle },
    { itemProp: "description", content: trimDesc },
    { itemProp: "image", content: resizeImg },
    { property: "og:title", content: trimTitle },
    { property: "og:description", content: trimDesc },
    { property: "og:image", content: resizeImg },
    { property: "og:image:alt", content: info.imgAlt },
    { property: "og:image:width", content: info.imgW || 800 },
    { property: "og:image:height", content: info.imgH || 800 },
    { property: "og:url", content: info.url },
    { name: "description", content: trimDesc },
    { name: "twitter:title", content: trimTitle },
    { name: "twitter:description", content: trimDesc },
    { name: "twitter:image", content: resizeImg },
  ];
};

exports.generateMetaType = (type, info = {}) => {
  const typeProps = Object.keys(info);
  const getTypeInfo = (prop) => {
    return { property: `${type}:${prop}`, content: info[prop] };
  };
  const typeInfo = typeProps.map(getTypeInfo);

  return [{ property: "og:type", content: type }, ...typeInfo];
};

exports.stringifyQueryParams = (params) =>
  "?" +
  _.toPairs(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
