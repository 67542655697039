import React from "react";
import styled from "styled-components";
import Link from "next/link";

import * as u from "@/utils";
import * as T from "@/components/Typography";
import * as Icons from "@/components/Icons";
import * as sc from "stream-chat";
import config from "@/config.client";

const MessageIconCount: React.FunctionComponent = () => {
  const chatClient = sc.StreamChat.getInstance(config.STREAM_PUBLIC);
  const [count, setCount] = React.useState<number>(0);

  const countMessages = () => {
    chatClient.on((event) => {
      if (Number.isFinite(event.total_unread_count)) {
        setCount(event.total_unread_count);
      } else if (event.type === "health.check" && event.me) {
        // set initial value, { me } only included in first healthcheck
        setCount(event.me.total_unread_count);
      }
    });
  };

  React.useEffect(countMessages, []);

  return (
    <Link href="/messages">
      <S.Icon data-cy="nav-messages-button">
        <Icons.Plane grow=".7" />
        {!!count && (
          <S.MessageUnread hasMany={count > 9} data-cy="nav-messages-count">
            <T.CS colour={"white"}>{count > 9 ? "9+" : count}</T.CS>
          </S.MessageUnread>
        )}
      </S.Icon>
    </Link>
  );
};

type StyleProps = {
  hasMany?: boolean;
};

const S = () => {};

S.Icon = styled.div`
  align-items: center;
  background: ${u.colours.shellOverlay};
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;

  svg {
    margin-right: 2px;
  }

  &:hover {
    background: rgb(130, 120, 110);
    cursor: pointer;
    svg {
      fill: white;
    }
  }
`;

S.MessageUnread = styled.div<StyleProps>`
  align-items: center;
  background: ${u.colours.errorRed};
  border-radius: 10px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: auto;
  position: absolute;
  right: -${(p) => (p.hasMany ? 15 : 10)}px;
  top: -8px;
  width: ${(p) => (p.hasMany ? 30 : 20)}px;
  ${(p) => p.hasMany && "padding-bottom: 1px;"}
`;

export default MessageIconCount;
