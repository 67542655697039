import React from "react";

import * as c from "../constants";
import * as u from "../utils";
import f from "../firebase.client";
import * as UserContext from "./UserContext";

const Context = React.createContext({});

function Provider(props) {
  const userInfo = React.useContext(UserContext.Context);
  const [connIds, setConnIdsState] = React.useState({});
  const value = { connIds, setConnIds, refresh: onInit };
  React.useEffect(() => {
    onInit();
  }, [userInfo.id]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;

  async function onInit() {
    if (!userInfo.id) return;
    const ref = f.firestore().collection("connections");
    const receiver = (
      await ref.where("receiverId", "==", userInfo.id).get()
    ).docs
      .map((d) => d.data())
      .filter((conn) => conn.state !== "ARCHIVED");
    const requester = (
      await ref.where("requesterId", "==", userInfo.id).get()
    ).docs
      .map((d) => d.data())
      .filter((conn) => conn.state !== "ARCHIVED");
    const currConnIds = [...receiver, ...requester].reduce(toFastFormat, {});
    setConnIdsState(currConnIds);
  }

  function toFastFormat(acc, elm) {
    const { requesterId, receiverId } = elm;
    const otherId = requesterId === userInfo.id ? receiverId : requesterId;
    const isMyRequest = requesterId === userInfo.id;
    acc[otherId] = { state: elm.state, id: elm.id, isMyRequest };
    return acc;
  }

  function setConnIds(nextIds) {
    setConnIdsState(nextIds);
    const nextIdsStr = JSON.stringify(nextIds);
    u.localStorage.setItem(c.CACHE_CONN_IDS, nextIdsStr);
  }
}

export { Context, Provider };
