import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import config from "./config.client";

if (!firebase.apps.length) {
  firebase.initializeApp(config.FIREBASE_CLIENT);
}

export default firebase;
