import AllBrightErrorType from "./AllBrightErrorType";
import { uuidv4 } from "@mswjs/interceptors/lib/utils/uuid";

export interface BaseError {
  id: string;
  name: string;
  errorType: AllBrightErrorType;
  message: string;
}

class AllBrightError extends Error implements BaseError {
  id: string;
  errorType: AllBrightErrorType;
  forceLog = false;

  constructor(message: string, name: string, errorType: AllBrightErrorType) {
    super(message);

    this.id = uuidv4();
    this.message = message;
    this.name = name;
    this.errorType = errorType;
  }

  public logError() {
    this.forceLog = true;
  }

  public serialize(): BaseError {
    return {
      id: this.id,
      name: this.name,
      message: this.message,
      errorType: this.errorType,
    };
  }
}

export default AllBrightError;
