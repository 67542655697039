import React from "react";
import * as Sentry from "@sentry/browser";
import type { StreamClient } from "getstream";

import type { notifications } from "@/lib/Domain/Types/Notification";
import * as s from "../utils/stream";
import * as UserContext from "./UserContext";

type ContextValue = {
  client?: StreamClient;
  notifications?: notifications & { unread: number };
  markRead: (ids: []) => Promise<void>;
};

const Context = React.createContext<ContextValue>({} as ContextValue);

function Provider(props) {
  const userInfo = React.useContext(UserContext.Context);
  const [client, setClient] = React.useState(null);
  const [notifications, setNotifications] = React.useState();
  const value = { markRead, client, notifications };
  React.useEffect(() => {
    onInit();
  }, [userInfo.id]);
  React.useEffect(() => {
    onConnect();
  }, [client]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;

  async function onInit() {
    if (!userInfo.id) return;

    const result = await s.connect();
    setClient(result);
  }

  async function onConnect() {
    try {
      const notification = client?.feed("notification", userInfo.id);
      notification?.subscribe(onUpdateStore);
      onUpdateStore();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  async function onUpdateStore() {
    if (!userInfo.id || !client) return;
    const currOpts = { limit: 30, reactions: { recent: true, counts: true } };
    const currRes = await client
      .feed("notification", userInfo.id)
      .get(currOpts);
    if (!currRes?.results?.length) return;
    return setNotifications(currRes);
  }

  async function markRead(notifIds: []) {
    const opts = notifIds.length
      ? { mark_read: notifIds }
      : { mark_read: true };
    await client?.feed("notification", userInfo.id).get(opts);
    onUpdateStore();
  }
}

export { Context, Provider };
