import React from "react";
import * as Router from "next/router";
import Head from "next/head";

import config from "../config.client";
import * as u from "../utils";
import paths from "../utils/paths";
import * as c from "../constants";
import * as UserContext from "./UserContext";

// TODO implement using isPrivate flag from routes.config
const protectedRoutes = [
  "/home",
  "/account",
  "/connect",
  "/events/join",
  "/events/success",
  "/member/",
  "/messages",
  "/onboarding/",
  "/groups",
];

/**
 *
 * Returns meta tags in head for static pages from utils/paths
 *
 */
export default function RouteManager() {
  const userInfo = React.useContext(UserContext.Context);
  const router = Router.useRouter();
  const [basePath] = router.asPath.split("?");

  React.useEffect(() => {
    authoriseRoutes();
  }, [router.asPath]);

  return getHead();

  async function authoriseRoutes() {
    const isCurrentPath = (route) => router.asPath.startsWith(route);
    const isProtectedRoute = protectedRoutes.some(isCurrentPath);
    const isProtectedAndNotOnboarding =
      isProtectedRoute && !router.pathname.includes("onboarding");
    const isLoggedIn = !!userInfo.id;

    if (isProtectedRoute && !isLoggedIn) {
      u.sessionStorage.setItem(c.RETURN_TO, router.asPath);
      return router.push("/auth/login");
    }

    // If someone has not accepted the allbright pledge then redirect them to onboarding/welcome
    if (isLoggedIn && isProtectedAndNotOnboarding && !userInfo.agreedToPledge) {
      return router.push("/onboarding/welcome");
    }
  }

  function getHead() {
    const info = {
      title:
        paths[basePath]?.title ||
        "AllBright | Redefining ambition, transforming businesses",
      description:
        paths[basePath]?.description ||
        "As the world's largest collective of women in business, we enable ambition in all its forms, supporting women at every stage of their development.",
      imgW: paths[basePath]?.imgW || 800,
      imgH: paths[basePath]?.imgH || 800,
      imgUrl:
        paths[basePath]?.imgUrl ||
        "https://www.allbrightcollective.com/images/marketing/brand-yellow-teal.jpg",
      imgAlt: paths[basePath]?.imgAlt || "AllBright Collective",
      url: paths[basePath]?.url || config.ALLBRIGHT_URL + router.asPath,
    };

    const metaTags = u.generateMetaTags(info);

    return (
      <Head>
        <title>{info.title}</title>
        {metaTags.map((elm) => (
          <meta {...elm} key={elm.name || elm.itemProp || elm.property} />
        ))}
      </Head>
    );
  }
}
