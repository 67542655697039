import React from "react";
import styled from "styled-components";
import * as Router from "next/router";

import * as u from "../utils";
import * as Icons from "./Icons";
import * as NotificationContext from "./Context.Notifications";

export default function NavBell() {
  const router = Router.useRouter();
  const { notifications } = React.useContext(NotificationContext.Context);
  const onClick = () => router.push("/notifications");

  return (
    <S.Container onClick={onClick} data-cy="nav-bell">
      <S.IconContainer>
        <Icons.Bell />
      </S.IconContainer>
      {!!notifications?.unread && (
        <S.Unread count={notifications?.unread}>
          {notifications?.unread > 9 ? "9+" : notifications?.unread}
        </S.Unread>
      )}
    </S.Container>
  );
}

const S = () => {};

S.Container = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${u.colours.shellOverlay};
  border-radius: 100%;
  display: flex;
`;

S.IconContainer = styled.div`
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${u.colours.shellOverlay};
  border-radius: 100%;
  display: flex;

  &:hover {
    cursor: pointer;
    background: rgb(130, 120, 110);
    svg {
      color: white;
    }
  }
`;

S.Unread = styled.div<{ count?: number }>`
  position: absolute;
  top: -10px;
  right: -10px;
  width: ${(props) => (props.count > 9 ? 30 : 20)}px;
  height: 20px;
  border-radius: 10px;
  background: rgb(240, 40, 73);
  margin-left: auto;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  ${(props) => props.count > 9 && "padding-bottom: 1px;"}
`;
