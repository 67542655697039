import React from "react";
import styled from "styled-components";
import * as I from "./Icons";
import * as u from "../utils";

export default function TickInput(props) {
  return (
    <S.Container data-cy={`${props.name}-checkbox`}>
      <S.Box
        role="checkbox"
        aria-checked={props.isTrue}
        tabIndex="0"
        aria-labelledby={`${props.name}--label`}
        onClick={props.onChange}
        onKeyDown={handleKeyDown}
      >
        {props.isTrue && <I.Tick color={u.colours.teal} />}
      </S.Box>
      <S.Label
        id={`${props.name}--label`}
        onClick={props.onChange}
        onKeyDown={handleKeyDown}
      >
        {props.children}
      </S.Label>
    </S.Container>
  );

  function handleKeyDown(event) {
    if (event.keyCode === 32) props.onChange(event);
  }
}

const S = {};

S.Container = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0;
  :hover {
    cursor: pointer;
  }
`;

S.Box = styled.div`
  align-items: center;
  background: white;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 2px;
  display: flex;
  height: 22px;
  justify-content: center;
  margin-right: 10px;
  width: 22px;
`;

S.Label = styled.label`
  flex: 1;
  font-size: 1.4rem;
  :hover {
    cursor: pointer;
  }
`;
