export enum Persona {
  EMERGING_EXPLORER = "EMERGING_EXPLORER",
  AMBITIOUS_MOVE_MAKER = "AMBITIOUS_MOVE_MAKER",
  SKILLED_SOUL_SEARCHER = "SKILLED_SOUL_SEARCHER",
  MASTERFUL_MENTOR = "MASTERFUL_MENTOR",
}

export enum GoalType {
  PUBLIC = "PUBLIC",
  ALLIANCE = "ALLIANCE",
}

export type CareerGoalType = (typeof allCareerGoals)[number];

const allCareerGoals = [
  {
    id: "931cf47e-1135-4d4b-b4ee-178b31dedd81",
    title: "Strategise to stay competitive",
    personas: [Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "7cf45525-f1aa-4af6-916a-7ad5d3043e4f",
    title: "Meet other high-achieving women",
    personas: [Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "49e5ad6a-8b97-4cb7-b7f1-7f881b7671f8",
    title: "Stay on top of current issues",
    personas: [Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "96addbd9-ccc0-4cbe-987c-fad6cb127384",
    title: "Get insights from experts",
    personas: [Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "bd79a5c9-38ca-4313-9a0f-3e143574b5b7",
    title: "Maintain and capitalise on successes",
    personas: [Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "7435be98-b120-4926-9639-9ba05175fb53",
    title: "Access a wide range of CEOs",
    personas: [Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "1a4ac83d-fdb3-4f74-b81c-621db78454b9",
    title: "Get a promotion",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "7dfa285e-5457-4c06-a62f-64610beaf18d",
    title: "Build a strong network",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "0e6bbbda-17d6-43dd-b456-392c31d34b36",
    title: "Maintain a strong network",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "1e6e0571-8249-4da0-927a-d056028c27a4",
    title: "Learn to negotiate",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "4bcc59a2-875b-4e57-b921-97e1eb1d531e",
    title: "Improve negotiation skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "b3b2328f-9f76-481f-a46e-e606646759b0",
    title: "Meet new people",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "7a555948-7788-42db-8f65-f49342906569",
    title: "Understand what employers are looking for",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "a4c4791e-1f5a-4ad6-b144-e9519aafcfc8",
    title: "Build self confidence",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "a22a7687-12ad-4ec0-8a47-0b16b8d06c74",
    title: "Recover my self confidence",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "1e480582-6c4a-4ddc-9de5-ea13262d7c0d",
    title: "Prepare for an interview",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "5670028e-6760-4432-93f7-61210ec19f5d",
    title: "Optimise my interviewing skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "5683c434-9997-4e91-bb02-559f974a2dcb",
    title: "Negotiate a good salary",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "f1a99c00-2451-48b7-9aa3-15bfe8409f9c",
    title: "Get the salary I deserve",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "68afadd6-17f5-4008-84a4-aaff5aa2cab5",
    title: "Ask for a payrise",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "65f4e59a-cc5e-4552-b6f4-9822141d6190",
    title: "Be more assertive",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "b8205b4f-40b8-4f3a-812d-eeb5e60f54f3",
    title: "Start a business",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "0489cdbb-5058-44fb-a4b1-f5ac7e8d0e89",
    title: "Find a coach or career advisor",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "79001e5c-cea3-4012-8e31-de931f69b1b5",
    title: "Develop my coaching skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "ce07281a-0197-4259-8bed-5db210f2e255",
    title: "Work on my passion project",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "f8a72c71-e214-4904-a154-0ee078d5de4d",
    title: "Develop my public speaking skills",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "89d1e220-1a94-4814-96ca-34c373ed83f1",
    title: "Upgrade my public speaking skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "cb2ac7f3-0563-4ed7-babb-fff1a7b733bb",
    title: "Develop my communication skills",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "83bebad3-35ee-4f0d-9449-43f7e832e3f9",
    title: "Advance my communication skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "fdddd784-ce93-4ef1-9069-b7024c91e662",
    title: "Go to networking events",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "414ef34b-cf67-4e4f-a800-9eb83579c61f",
    title: "Organise networking events",
    personas: [Persona.AMBITIOUS_MOVE_MAKER, Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "7f104591-5317-452c-b348-fdcb64b49238",
    title: "Learn to pitch successfully",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "5bb99d0e-18f9-4fca-89dc-8c271471242d",
    title: "Find a mentor",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "9c05cc1f-2407-4b8b-9ebc-4085c9068935",
    title: "Supercharge my mentorship",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "5b1f8fc0-6664-416c-8d31-002ab9f6318e",
    title: "Connect internationally",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "80ea41cd-3dc8-41de-812f-b8d1c347964b",
    title: "Grow internationally",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "6688aa55-b382-48d8-8633-e59cf3fcd415",
    title: "Find someone to mentor",
    personas: [Persona.AMBITIOUS_MOVE_MAKER, Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "85358cab-9018-402f-9d01-5c552765c38a",
    title: "Pivot my career",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "40e6f525-2c00-4522-8d99-126d9e793c3d",
    title: "Change my career path but stay in work",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "938b7aa4-d586-4152-a618-f8dde7bcda30",
    title: "Understand how disruptors might impact my business",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "628021cc-916e-483a-aef4-328418ac06c2",
    title: "Step into C-suite",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "1f2d70c8-ac0a-4927-9c5f-3a841022ca22",
    title: "Tap into new talent markets",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "c5517712-e0b6-4c7f-94b2-d3e9be71a980",
    title: "Become a board member",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "5f5cd7c6-ebd4-4b08-9d28-f10166dfd15a",
    title: "Become an inclusive manager",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "c21a94b3-e1a4-4d68-97e5-9c165337266b",
    title: "Improve my inclusive management skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "931c7d7f-c4d1-4d5b-8586-c55e07f56700",
    title: "Become a strong leader",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "2ac9ee18-5778-4728-83bc-2f791efcc70a",
    title: "Reimagine my leadership",
    personas: [Persona.AMBITIOUS_MOVE_MAKER, Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "af48f346-9326-4eef-8cf5-509aa0890102",
    title: "Find a better job",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "3b2f0b2f-53e0-46b0-8bc2-9b7e70cb493a",
    title: "Become a mindful leader",
    personas: [Persona.EMERGING_EXPLORER],
    type: GoalType.PUBLIC,
  },
  {
    id: "1fab92d2-1821-49b9-bc87-e53176f3a25b",
    title: "Develop my mindful leadership skills",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "d3b935e8-f7b2-4f54-ae6b-575d23ae8bb8",
    title: "Upskill myself",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "015eca28-be16-41ce-9a82-d4a2b0dfd778",
    title: "Upskill and retain my staff",
    personas: [Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "e9320125-b6ba-4553-b329-7fb35c4c6d9c",
    title: "Make better investments",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "5903c848-0d47-484b-a2a0-927afd1fe90d",
    title: "Return to work after time away",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "889e508f-5571-4685-a722-c4916ac6c911",
    title: "Better manage my work-life balance",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "d8f959ce-c9f8-4579-a333-553b06158b5c",
    title: "Increase my personal wealth",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "de92b062-d99f-4e04-a2d4-302a2ffc4929",
    title: "Avoid burnout",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "ea9911e1-b871-416d-8956-722e675d505c",
    title: "Manage stress",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "c0fbcbe3-0284-499e-893a-10e111324acc",
    title: "Plan a family",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "40a4c0f1-b65a-4f1b-b74e-fec14d0c8cac",
    title: "Manage career success and family",
    personas: [Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "91a1bc27-3417-414f-a520-f350efb7dd44",
    title: "Navigate the menopause at work",
    personas: [Persona.AMBITIOUS_MOVE_MAKER, Persona.MASTERFUL_MENTOR],
    type: GoalType.PUBLIC,
  },
  {
    id: "484458c6-fd63-44c7-a7a5-1b072f3a892e",
    title: "Become a menopause ally",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "80cbf17b-e0f4-4483-95e1-7f2408a885d8",
    title: "Prepare for the menopause",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "683029f3-c91b-49f8-91f1-15161b57141f",
    title: "Learn about emerging tech",
    personas: [Persona.EMERGING_EXPLORER, Persona.SKILLED_SOUL_SEARCHER],
    type: GoalType.PUBLIC,
  },
  {
    id: "1579e0a9-026d-4013-8b04-d41429aca40f",
    title: "Develop my AI skills",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "93b18035-e0a9-4d16-8c15-3afe02392e7f",
    title: "Strategise for digital disruptors",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "41980ebe-af05-4e45-80ce-dcafdd28a456",
    title: "Use tech to enhance productivity",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
  {
    id: "fca80241-540a-4910-b575-087063a147f7",
    title: "Utilise blockchain technology",
    personas: [Persona.EMERGING_EXPLORER, Persona.AMBITIOUS_MOVE_MAKER],
    type: GoalType.PUBLIC,
  },
] as const;

export default allCareerGoals;
