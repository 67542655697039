import * as stream from "getstream";
import config from "../config.client";
import * as c from "../constants";
import * as u from "./index";

export const connect = async () => {
  const accessToken = u.localStorage.getItem(c.FEED_TOKEN);
  return accessToken
    ? stream.connect(config.STREAM_PUBLIC, accessToken, config.STREAM_APP_ID)
    : null;
};
