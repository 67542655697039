import React from "react";
import styled, { keyframes } from "styled-components";
import * as Router from "next/router";
import Switch from "react-switch";

import * as u from "../utils";
import Avo from "../utils/Avo";
import rudderstackDestinationInterface from "../utils/RudderStackInterface";
import * as T from "./Typography";
import * as U from "./Utils";
import * as config from "../config.client";
import * as gtag from "../utils/gtag";
import Button from "./Button";
import Link from "./Link";
import BlockScroll from "./BlockScroll";

export default function CookieConsent(props) {
  const router = Router.useRouter();
  const [showBanner, setShowBanner] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [personalisationCookies, setPersonalisationCookies] =
    React.useState(true);

  React.useEffect(checkStorage, []);

  if (!showBanner || router.asPath === "/policies") {
    return null;
  }

  if (showOptions) {
    return (
      <BlockScroll>
        <S.Page>
          <S.Container data-cy="banner__choose">
            <T.H4 textAlign={"center"}>Choose your cookies</T.H4>
            <U.Space height={20} />
            <T.CM>
              Cookies get a bad rep, but they’re actually really useful and save
              you a lot of time.
            </T.CM>
            <S.Line />
            <T.H5>Strictly Necessary Cookies</T.H5>
            <U.Space height={10} />
            <T.CM>
              These are the ones that can't be switched off, as they underpin
              all the important parts of our site - they power our functionality
              and protect your security.
            </T.CM>
            <S.Line />
            <T.H5>Personalisation Cookies</T.H5>
            <U.Space height={10} />
            <S.Wrap>
              <T.CM>
                At AllBright, we only want to be adding to the conversation, not
                the noise. Personalised cookies enable us to treat you like the
                individual you are, by showing information specific to your
                interests.
              </T.CM>
              <S.SwitchWrapper>
                <Switch
                  onChange={() =>
                    setPersonalisationCookies(!personalisationCookies)
                  }
                  checked={personalisationCookies}
                  className="cypress__test__toggle"
                />
              </S.SwitchWrapper>
            </S.Wrap>
            <Button
              text="Done"
              size="mid"
              onClick={onChoose}
              data-cy="button__choose"
            />
          </S.Container>
        </S.Page>
      </BlockScroll>
    );
  }

  return (
    <BlockScroll>
      <S.Page>
        <S.Container data-cy="banner__accept">
          <T.H3 textAlign={"center"}>Your privacy matters to us</T.H3>
          <U.Space height={20} />
          <T.CM textAlign={"center"}>
            We use essential cookies to ensure you're always getting the best,
            most relevant content from AllBright. Plus they allow us to
            constantly improve how our platform serves you, our community. To
            find out more about cookies, please read our
            <Link href="/privacy" isTextAnimation>
              {" "}
              Privacy Policy
            </Link>
            .
          </T.CM>
          <S.Flex>
            <Button
              onClick={onAccept}
              text="Accept cookies"
              size="mid"
              data-cy="button__accept"
            />
            <S.Space />
            <Button
              onClick={() => setShowOptions(true)}
              text="Choose cookies"
              size="mid"
              data-cy="button__choose"
              colour="tranparent"
              fontColour={u.colours.charcoal}
            />
          </S.Flex>
        </S.Container>
      </S.Page>
    </BlockScroll>
  );

  function checkStorage() {
    const canTrack = u.localStorage.getItem("@CAN_TRACK");
    if (canTrack === null) return setShowBanner(true);
    if (canTrack === "true" && config.IS_PROD) initAnalytics();
  }

  function onChoose() {
    if (personalisationCookies) return onAccept();
    u.localStorage.setItem("@CAN_TRACK", "false");
    setShowBanner(false);
  }

  function onAccept() {
    u.localStorage.setItem("@CAN_TRACK", "true");
    if (config.IS_PROD) initAnalytics();
    setShowBanner(false);
    props.onAccept();
  }

  function initAnalytics() {
    Avo.initAvo({ env: "prod" }, {}, {}, rudderstackDestinationInterface);
    window.fbq("init", config.FB_TRACKING_ID);
    window.fbq("track", "PageView");
    window.gtag("js", new Date());
    const onPageView = (url) => gtag.pageview(url);
    onPageView(router.asPath);
    router.events.on("routeChangeComplete", onPageView);

    return () => router.events.off("routeChangeComplete", onPageView);
  }
}

const S = {};

S.Fade = keyframes`
  100% {opacity: 1}
`;

S.Page = styled.div`
  animation: ${S.Fade} 0.2s ease-out forwards;
  animation-delay: 1s;
  background: hsla(0, 0%, 0%, 0.5);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  position: fixed;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.Container = styled.div`
  flex-direction: column;
  background: white;
  min-height: 77px;
  padding: 20px;
  max-width: 500px;
  border-radius: 20px;
  margin: 20px;
  max-height: 95%;
  @media (min-width: 540px) {
    padding: 40px;
  }
`;

S.Line = styled.hr`
  border: none;
  border-bottom: 1px solid #d0d4d5;
  width: 100%;
  margin: 20px 0;
`;

S.Wrap = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

S.SwitchWrapper = styled.div`
  margin-left: 10px;
`;

S.Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 540px) {
    flex-direction: row;
  }
`;

S.Space = styled.div`
  height: 20px;
  width: 20px;
`;
