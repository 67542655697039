import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const userTz = dayjs.tz.guess() || "Europe/London";

export function format(time, hasDot) {
  const dateAndTime = dayjs.unix(time).tz(userTz);
  const timezoneName = dateAndTime.format("zzz");
  const shortTimeZoneName = SHORT_TIMEZONE_NAMES[timezoneName];

  return `${dateAndTime.format(`ddd DD MMM ${hasDot ? "·" : "|"} h:mm A`)} ${shortTimeZoneName || timezoneName}`;
}

const SHORT_TIMEZONE_NAMES = {
  "Alaska Daylight Time": "AKDT",
  "Alaska Standard Time": "AKST",
  "Atlantic Daylight Time": "ADT",
  "Australian Central Daylight Time": "ACDT",
  "Australian Central Standard Time": "ACST",
  "Australian Eastern Daylight Time": "AEDT",
  "Australian Eastern Standard Time": "AEST",
  "Australian Western Daylight Time": "AWDT",
  "Australian Western Standard Time": "AWST",
  "British Summer Time": "BST",
  "Central Daylight Time": "CDT",
  "Central European Summer Time": "CEST",
  "Central European Time": "CET",
  "Central European Standard Time": "CET",
  "Central Standard Time": "CST",
  "China Standard Time": "CST",
  "Eastern Daylight Time": "EDT",
  "Eastern European Summer Time": "EEST",
  "Eastern European Time": "EET",
  "Eastern European Standard Time": "EET",
  "Eastern Standard Time": "EST",
  "Greenwich Mean Time": "GMT",
  "Hong Kong Time": "HKT",
  "India Standard Time": "IST",
  "Irish Standard Time": "IST",
  "Israel Daylight Time": "IDT",
  "Japan Standard Time": "JST",
  "Mountain Daylight Time": "MDT",
  "Mountain Standard Time": "MST",
  "Pacific Daylight Time": "PDT",
  "Pacific Standard Time": "PST",
  "Western European Summer Time": "WEST",
  "Western European Time": "WET",
  "Western European Standard Time": "WET",
};

export function convertMinutesToHours(mins) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;

  return `${h > 0 ? `${h}hr` : ""}${m > 5 ? ` ${m}min` : ""}`;
}
