import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import AllBrightError, { BaseError } from "../../types/errors/AllBrightError";
import AllBrightErrorType from "../../types/errors/AllBrightErrorType";
import * as Sentry from "@sentry/browser";

interface ErrorState {
  messages: BaseError[];
  notifications: BaseError[];
}

const initialState: ErrorState = {
  messages: [],
  notifications: [],
};

const errorSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<BaseError>) => {
      if (action.payload.errorType === AllBrightErrorType.ERROR_MESSAGE) {
        state.messages.push(action.payload);
      }

      if (action.payload.errorType === AllBrightErrorType.ERROR_NOTIFICATION) {
        state.notifications.push(action.payload);
      }
    },
    removeError: (state, action: PayloadAction<BaseError>) => {
      if (action.payload.errorType === AllBrightErrorType.ERROR_MESSAGE) {
        state.messages = state.messages.filter(
          (error) => error.id !== action.payload.id
        );
      }

      if (action.payload.errorType === AllBrightErrorType.ERROR_NOTIFICATION) {
        state.notifications = state.notifications.filter(
          (error) => error.id !== action.payload.id
        );
      }
    },
    removeErrorMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter(
        (error) => error.name.indexOf(action.payload) !== 0
      );
    },
  },
});

export const ErrorStateActions = {
  removeError: (error: AllBrightError) => async (dispatch) => {
    dispatch(errorSlice.actions.removeError(error.serialize()));
  },
  removeErrorMessage: (errorName: string) => async (dispatch) => {
    dispatch(errorSlice.actions.removeErrorMessage(errorName));
  },
  addError: (error: AllBrightError) => async (dispatch) => {
    if (error.forceLog) {
      Sentry.captureException(error);
    }

    dispatch(errorSlice.actions.addError(error.serialize()));
  },
};

export default errorSlice.reducer;
