import React from "react";
import styled from "styled-components";
import Head from "next/head";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "../utils/axiosInterceptors";
import * as u from "../utils";
import * as UserContext from "../components/UserContext";
import * as LocaleContext from "../components/LocaleContext";
import * as ConnsContext from "../components/Context.Connections";
import * as MentorshipsContext from "../components/Context.Mentorships";
import * as NotifsContext from "../components/Context.Notifications";
import config from "../config.client";
import GlobalStyle from "../components/GlobalStyle";
import RxNavMain from "../components/ui/navigation/RxNavMain";
import RouteManager from "../components/RouteManager";
import CookieConsent from "../components/CookieConsent";
import { Provider } from "react-redux";
import { store } from "@/store";
import RxFooter from "@/components/ui/display/RxFooter";
import { storeQueryParams } from "@/handlers/router/queryHandler";
const queryClient = new QueryClient();

export default function App(props) {
  const { Component, pageProps, router } = props;
  const isChat = router.pathname === "/messages";

  const [allowTracking, setAllowTracking] = React.useState(
    u.localStorage.getItem("@CAN_TRACK") === "true"
  );

  storeQueryParams(router);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <GTMProvider state={{ id: config.GTM_ID, injectScript: allowTracking }}>
          <UserContext.Provider>
            <LocaleContext.Provider>
              <ConnsContext.Provider>
                <MentorshipsContext.Provider>
                  <NotifsContext.Provider>
                    <GlobalStyle />
                    {getGlobalHead()}
                    <RouteManager />
                    <RxNavMain />
                    <S.MinHeight>
                      <Component {...pageProps} />
                    </S.MinHeight>
                    {!isChat && <RxFooter />}
                    <CookieConsent onAccept={() => setAllowTracking(true)} />
                  </NotifsContext.Provider>
                </MentorshipsContext.Provider>
              </ConnsContext.Provider>
            </LocaleContext.Provider>
          </UserContext.Provider>
        </GTMProvider>
      </Provider>
    </QueryClientProvider>
  );

  function getGlobalHead() {
    return (
      <Head>
        <meta property="og:title" content="AllBright" />
        <meta
          property="og:description"
          content="As the world's largest collective of women in business, we enable ambition in all its forms, supporting women at every stage of their development."
        />
        <meta
          property="og:image"
          content="https://www.allbrightcollective.com/images/marketing/brand-yellow-teal.jpg"
        />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:locale" content="en_GB" key="og:locale" />
        <meta property="og:site_name" content="AllBright" key="og:site_name" />
        <meta property="fb:app_id" content="484227242163860" key="fb:app_id" />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta
          name="twitter:handle"
          content="@weareallbright"
          key="twitter:handle"
        />
        <meta
          name="twitter:site"
          content="@weareallbright"
          key="twitter:site"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
    );
  }
}

const S = {};

S.MinHeight = styled.div`
  min-height: calc(100vh - 60px); /* account for nav bar */
  @media ${u.device.tablet} {
    min-height: calc(100vh - 84px);
  }
`;
