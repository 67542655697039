import { NextRouter } from "next/router";
import dayjs from "dayjs";
import * as u from "@/utils";

const utmExpiryParam = "utmStorageExpiryUnique";
const utmParamStore = "@UTM_STORE";

export const storeQueryParams = (router: NextRouter): void => {
  const params = getQueryParamsAsObject(router);

  if (!params || Object.keys(params).length < 1) return;

  params[utmExpiryParam] = dayjs().add(30, "minutes").unix();

  u.localStorage.setItem(utmParamStore, JSON.stringify(params));
};

export const getQueryParams = (router: NextRouter): object => {
  let params = getQueryParamsAsObject(router);

  if (!params || Object.keys(params).length < 1) {
    params = JSON.parse(u.localStorage.getItem(utmParamStore) || "{}");

    if (!params || Object.keys(params).length < 1) return {};

    const paramExpiry = params[utmExpiryParam] || 0;

    if (dayjs().unix() > paramExpiry) return {};

    delete params[utmExpiryParam]; // remove expiry from object
  }

  return params || {};
};

const getQueryParamsAsPath = (router: NextRouter): string => {
  if (!router?.asPath) return "";

  const index = router.asPath.indexOf("?");

  if (index === -1) return "";

  return router.asPath.slice(router.asPath.indexOf("?"));
};

const getQueryParamsAsObject = (router: NextRouter): object => {
  // not using router.query as it only works if page is loaded via SSR
  let path = getQueryParamsAsPath(router);

  if (!path || path.length < 4) return {};

  path = path.substring(1); // remove '?' from start

  try {
    return (
      JSON.parse(
        '{"' + path.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      ) || {}
    );
  } catch (e) {
    return {};
  }
};
