import axios from "axios";
import firebase from "../firebase.client";
import { EventModel } from "@/lib/Domain/Event/EventModel";
import config from "@/config";
import configClient from "@/config.client";

class EventClient {
  private endpoint: string;

  constructor() {
    this.endpoint = config.ALLBRIGHT_URL || configClient.ALLBRIGHT_URL;
  }

  public async getAllEvents(): Promise<EventModel[]> {
    try {
      const { data } = await axios.get(`/api/event/get-all`);
      return data;
    } catch (error) {
      throw new Error("Error fetching all events: " + error.message);
    }
  }

  public async getEventById(eventId: string): Promise<EventModel> {
    if (!eventId) throw new Error("Invalid Event Id");

    try {
      const response = await axios.post("/api/event/get-by-id", {
        id: eventId,
      });
      return response.data.event;
    } catch (error) {
      throw new Error("Error fetching event by ID: " + error.message);
    }
  }

  public async getUpcomingEvents(limit?: number): Promise<EventModel[]> {
    try {
      const now = Math.floor(Date.now() / 1000);
      const eventsRef = firebase.firestore().collection("events");
      let query = eventsRef.where("startTime", ">=", now);

      if (limit) {
        query = query.limit(limit);
      }

      const snapshot = await query.get();

      const events = [];
      snapshot.forEach((doc) => {
        events.push({ id: doc.id, ...doc.data() } as EventModel);
      });

      return events;
    } catch (error) {
      throw new Error("Error fetching latest events: " + error.message);
    }
  }

  public async getUserSavedEventIds(userId: string): Promise<string[]> {
    if (!userId) throw new Error("Invalid User Id");

    try {
      const userDoc = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get();

      if (!userDoc.exists) {
        throw new Error("User document does not exist");
      }

      const userData = userDoc.data();
      if (!userData || !Array.isArray(userData.events)) {
        return [];
      }

      return userData.events;
    } catch (error) {
      throw new Error("Error fetching user saved event Ids: " + error.message);
    }
  }

  public async getUserSavedEvents(userId: string): Promise<EventModel[]> {
    if (!userId) throw new Error("Invalid User Id");

    try {
      const eventList = await this.getUserSavedEventIds(userId);

      return await Promise.all(
        eventList.map((eventId) => this.getEventById(eventId))
      );
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export default EventClient;
