export default [
  "Connections",
  "Upskilling",
  "Mentorship",
  "Mentoring others",
  "Event buddy",
  "New/returning roles",
  "Employment",
  "Freelance work",
  "Team members",
  "Collaborators",
] as const;
