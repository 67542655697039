import React from "react";
import axios from "axios";

import * as UserContext from "./UserContext";
import { useRouter } from "next/router";

const Context = React.createContext({});

function Provider(props) {
  const router = useRouter();
  const userInfo = React.useContext(UserContext.Context);
  const [mentorships, setMentorships] = React.useState({});
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const value = {
    ...mentorships,
    updateMentorships,
    refreshRelated: refreshRelated,
    hasLoaded,
  };
  React.useEffect(() => {
    if (router.pathname.includes("mentor") && !hasLoaded) {
      onInit();
    }
  }, [userInfo.id, router.pathname]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;

  async function onInit() {
    if (!userInfo.id) return;
    setHasLoaded(false);
    const res = await axios.get("/api/mentorship.list");
    setMentorships(res.data);
    setHasLoaded(true);
  }

  async function refreshRelated() {
    if (!userInfo.id) return;
    setHasLoaded(false);
    const res = await axios.get("/api/mentorship.related-list");
    setMentorships(res.data);
    setHasLoaded(true);
  }

  function updateMentorships(updates) {
    setMentorships({ ...mentorships, ...updates });
  }
}

export { Context, Provider };
