import React, { useContext } from "react";
import styled from "styled-components";
import * as Router from "next/router";

import { colours } from "@/utils";
import { Device } from "@/utils/device";
import MessageIconCount from "@/components/navigation/MessageIconCount";
import NavBell from "@/components/NavBell";
import ImageInitials from "@/components/ImageInitials";
import * as UserContext from "../UserContext";

type NavCircleButtonsProps = {
  dropdownClick: () => void;
  isOpen: boolean;
  isPlatform: boolean;
};

const NavCircleButtons: React.FunctionComponent<NavCircleButtonsProps> = (
  props
) => {
  const router = Router.useRouter();
  const userInfo = useContext(UserContext.Context);
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoggedIn(!!userInfo?.id);
  }, [userInfo?.id]);

  return (
    <S.Container>
      {props.isPlatform && loggedIn && (
        <>
          <S.PressArea>
            <MessageIconCount />
          </S.PressArea>
          <S.PressArea>
            <NavBell />
          </S.PressArea>
        </>
      )}
      {loggedIn && (
        <S.Initials>
          <ImageInitials
            onClick={() => router.push("/account")}
            isHover="true"
            size={"big"}
            {...userInfo}
          />
        </S.Initials>
      )}
      <S.BurgerButton
        isOpen={props.isOpen}
        onClick={props.dropdownClick}
        tabIndex={0}
        aria-label={`Toggle navigation dropdown menu`}
      >
        <S.Line isOpen={props.isOpen} left />
        <S.Line isOpen={props.isOpen} middle />
        <S.Line isOpen={props.isOpen} right />
      </S.BurgerButton>
    </S.Container>
  );
};

type StyleProps = {
  isOpen?: boolean;
  left?: boolean;
  middle?: boolean;
  right?: boolean;
};

const S = () => {};

S.Container = styled.nav`
  display: flex;
  align-items: center;
`;

S.PressArea = styled.div`
  margin-left: 8px;
  cursor: pointer;
  padding: 6px;
`;

S.Initials = styled.div`
  margin-left: 14px;
  display: none;

  @media (min-width: ${Device.desktop}) {
    display: flex;
  }
`;

S.BurgerButton = styled.button<StyleProps>`
  position: relative;
  margin-left: 14px;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 100%;
  background: ${colours.shellOverlay};
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s ease-out;

  &:hover {
    background: rgb(130, 120, 110);
    > * {
      background: white;
    }
  }

  @media (min-width: ${Device.desktop}) {
    display: none;
  }
`;

S.Line = styled.div<StyleProps>`
  position: absolute;
  border-radius: 1em;
  height: 0.2em;
  width: 60%;
  background: ${colours.charcoal};
  transition: all 0.2s ease-out;

  ${(p) => p.left && "margin: 9% 0 0 0;"}
  ${(p) => p.middle && "margin: 26.5% 0 0 0;"}
  ${(p) => p.right && "margin: 43% 0  0;"}

  ${(p) =>
    p.isOpen &&
    `
    margin: 25% 0 0 0;
    transform: rotate(${p.left ? "45" : "-45"}deg);
    ${p.middle && "opacity: 0;"}
  `}
`;

export default NavCircleButtons;
