import styled from "styled-components";
import * as u from "../utils";

type StyleProps = {
  height?: number;
  width?: number;
  borderSize?: number;
  borderColour?: string;
};

export const Space = styled.div<StyleProps>`
  ${(p) => (p.height ? `height: ${p.height}px` : "")}
  ${(p) => (p.width ? `width: ${p.width}px` : "")}
`;

export const Divider = styled.hr<StyleProps>`
  ${(p) =>
    `border: ${p.borderSize ?? "1"}px solid ${p.borderColour ?? u.colours.teal};`}
  ${(p) => (p.width ? `width: ${p.width}px` : "width: 100%")}
`;

export const VerticalDivider = styled.div`
  width: 2px;
  height: 40px;
  margin: 0 14px;
  background-color: ${u.colours.shellOverlay};
`;
