import React, { FunctionComponent, useEffect, useRef } from "react";
import styled from "styled-components";
import * as Router from "next/router";
import * as gtag from "@/utils/gtag";
import * as T from "@/components/Typography";
import * as u from "@/utils";
import colours from "@/utils/colours";
import { ArrowRight } from "@/components/Icons";
import { getPromoStripQuery } from "@/components/hooks/query/useContentQuery";
import PromoStripContent from "@/types/marketing/PromoStripContent";
import { platformPages } from "@/utils/navigationItems";

interface RxPromoStripProps {
  getHeight?: (height: number) => void;
}

const RxPromoStrip: FunctionComponent<RxPromoStripProps> = (props) => {
  const router = Router.useRouter();
  const promoStripQuery = getPromoStripQuery();
  const promoStripRef = useRef(null);
  let strip: PromoStripContent | null = null;

  useEffect(() => {
    const handleResize = () => {
      if (promoStripRef.current) {
        const height =
          promoStripRef.current.getBoundingClientRect().height || 0;
        if (props.getHeight) props.getHeight(height);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [promoStripQuery.data, promoStripRef.current]);

  (promoStripQuery.data || []).some((item) => {
    const length =
      router.asPath.indexOf("?") > 0
        ? router.asPath.indexOf("?") - 1
        : router.asPath.length;
    const fullPath = router.asPath.slice(0, length);
    const path = fullPath.split("/")[1];

    if (router.pathname === "/groups/[id]") return true;

    // Checks if the exact path matches one of the active pages
    if (item.activePages.includes(fullPath)) {
      strip = item;
      return true;
    }

    // Runs the wild card (e.g /edit* matches everything in /edit)
    item.activePages.some((page) => {
      if (page.indexOf("*") > 0) {
        const matchAll = page.slice(0, page.indexOf("*"));

        if (fullPath.startsWith(matchAll)) {
          strip = item;
          return true;
        }
      }
    });

    if (strip !== null) return true;

    const isPlatform = platformPages.includes(path);
    if (item.activePages.includes("/marketing") && !isPlatform) {
      strip = item;
    }

    if (item.activePages.includes("/platform") && isPlatform) {
      strip = item;
    }
  });

  if (!strip) {
    if (props.getHeight) props.getHeight(0);
    if (promoStripRef.current) {
      promoStripRef.current.value = "";
    }
    return null;
  }

  const onClick = (url) => {
    gtag.event({
      name: gtag.GA_EVENT.PLATFORM_ENGAGEMENT,
      category: "Engagement",
      label: `Click Promo Strip`,
      value: router.route,
    });

    router.push(url);
  };

  return (
    <S.StripContainer
      data-cy="promo-strip"
      bgColor={strip.backgroundColour || colours.marketingYellow}
      textColour={strip.textColour || colours.charcoal}
      ref={promoStripRef}
    >
      <S.FlexRow>
        <T.CL weight="700" roxborough>
          {strip.title}
        </T.CL>
        {strip.buttonUrl && (
          <S.Button onClick={() => onClick(strip.buttonUrl)}>
            <ArrowRight colour={colours.charcoal} height="15" width="15" />
          </S.Button>
        )}
      </S.FlexRow>
      <S.DescriptionContainer>
        <T.CS>
          {strip.copy}
          &nbsp;
          {strip.linkUrl && strip.linkText && (
            <T.A href={strip.linkUrl} fontSize="12px">
              {strip.linkText}
            </T.A>
          )}
        </T.CS>
      </S.DescriptionContainer>
    </S.StripContainer>
  );
};

type StyleProps = {
  bgColor?: string;
  textColour?: string;
};

const S = () => {};

S.StripContainer = styled.div<StyleProps>`
  width: 100%;
  position: fixed;
  background-color: ${(p) => p.bgColor || colours.marketingYellow};
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99;
  margin: 60px 0 0 0;
  padding: 10px 10% 10px 10%;
  color: ${(p) => p.textColour || colours.charcoal};

  @media ${u.device.tablet} {
    margin: 84px 0 0 0;
  }
`;

S.FlexRow = styled.div`
  display: flex;
  gap: 5px;
`;

S.DescriptionContainer = styled.div`
  display: none;

  @media ${u.device.tablet} {
    display: block;
  }
`;

S.Button = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colours.shellOverlay};
  color: inherit;
  border: none;
  border-radius: 50%;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: ${u.colours.charcoal};
  aspect-ratio: 1;

  @supports not (aspect-ratio: 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

export default RxPromoStrip;
