import React from "react";
import styled, { keyframes } from "styled-components";
import colours from "../utils/colours";

export default function LoadingSpinner(props) {
  if (props.finished) return null;

  return (
    <S.SpinnerWrapper data-cy="loading-spinner">
      <S.Spinner size={props.size} width={props.width} height={props.height} />
    </S.SpinnerWrapper>
  );
}

const S = {};

S.SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

S.Spinner = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  width: ${({ width }) => (width ? width : "30px")};
  height: ${({ height }) => (height ? height : "30px")};
  border: 4px solid ${colours.turquoise};
  border-radius: 50%;
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${colours.turquoise} transparent transparent transparent;
  ${({ size }) => {
    if (size === "small") {
      return `
        width: 22px;
        height: 22px;
      `;
    }
  }}
`;
