import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Link from "next/link";
import Image from "next/image";

import { Device } from "@/utils/device";
import { CM, CS } from "../Typography";
import { colours } from "@/utils";

type FeaturedNavContentProps = {
  featuredItems: {
    copy: string;
    href: string;
    image: {
      alt: string;
      src: string;
    };
  }[];
  type?: string;
};

const FeaturedNavContent: FunctionComponent<FeaturedNavContentProps> = (
  props
) => {
  if (props.featuredItems.length === 0) return null;
  const hasBackgroundImage =
    props.type === "events" || props.type === "articles";

  return (
    <S.Container>
      <CM weight="700">Featured</CM>
      <S.LinksContainer>
        {props.featuredItems.map((item) => (
          <Link href={item.href} key={item.href}>
            <S.FeatureBlock tabIndex={0}>
              <S.ImageContainer>
                {hasBackgroundImage && (
                  <S.BackgroundImage
                    loader={() => item.image.src}
                    src={item.image.src}
                    layout="fill"
                    objectFit="cover"
                    unoptimized
                  />
                )}
                <Image
                  src={item.image.src}
                  alt={item.image.alt}
                  layout="fill"
                  objectFit={hasBackgroundImage ? "contain" : "cover"}
                />
              </S.ImageContainer>
              <S.Description>{item.copy}</S.Description>
            </S.FeatureBlock>
          </Link>
        ))}
      </S.LinksContainer>
    </S.Container>
  );
};

type StyleProps = {
  dropdownOpen?: boolean;
};

const S = () => {};

S.Container = styled.div<StyleProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

S.LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (min-width: ${Device.desktop}) {
    flex-direction: row;
  }
`;

S.FeatureBlock = styled.div`
  width: 100%;
  max-width: 20em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.02);
    filter: brightness(1.1);
  }

  &:focus-visible {
    outline: solid blue 2px;
    filter: brightness(1.1);
  }
`;

S.ImageContainer = styled.div`
  background: ${colours.charcoalFaded};
  position: relative;
  box-shadow: 0px 0px 5px #c3afa1;
  border-radius: 0.1em;
  width: 100%;
  flex: none;
  flex-shrink: 0;
  overflow: hidden;
  aspect-ratio: 2.2/1;

  @supports not (aspect-ratio: 2.2/1) {
    &::before {
      float: left;
      padding-top: 45%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

S.Description = styled(CS)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
`;

S.BackgroundImage = styled(Image)`
  filter: brightness(1.2) blur(8px);
`;

export default FeaturedNavContent;
