import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CareerGoalType } from "../../types/user/careerGoals";
import { WritableDraft } from "immer/src/types/types-external";
import CareerGoalClient from "../../client/user/CareerGoalClient";
import { AppDispatch } from "../index";
import { ErrorStateActions } from "../global/errorSlice";
import UnknownError from "../../types/errors/UnknownError";

interface CareerGoalsState {
  all: CareerGoalType[] | null;
}

const initialState: CareerGoalsState = {
  all: null,
};

const careerGoalSlice = createSlice({
  name: "careerGoal",
  initialState,
  reducers: {
    initiate: (
      state,
      action: PayloadAction<{ careerGoals: WritableDraft<CareerGoalType[]> }>
    ) => {
      state.all = action.payload.careerGoals;
    },
  },
});

export const CareerGoalsStateActions = {
  retrieveAllGoals: () => async (dispatch: AppDispatch) => {
    try {
      const goals =
        (await new CareerGoalClient().getAllGoals()) as WritableDraft<
          CareerGoalType[]
        >;
      dispatch(careerGoalSlice.actions.initiate({ careerGoals: goals }));
    } catch (e) {
      dispatch(
        ErrorStateActions.addError(
          new UnknownError(
            "Something went wrong, we could not save your information",
            "careerGoals.api.error"
          )
        )
      );
    }
  },
};

export default careerGoalSlice.reducer;
