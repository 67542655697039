export const cities = [
  {
    city: "‘Ajlūn",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "‘Ajmān",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "‘Alīābād-e Katūl",
    country: "Iran",
    code: "IR",
  },
  {
    city: "‘Aqrah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "‘Ibrī",
    country: "Oman",
    code: "OM",
  },
  {
    city: "‘Izbat al Burj",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "‘Unayzah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "’s-Hertogenbosch",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "A Coruña",
    country: "Spain",
    code: "ES",
  },
  {
    city: "A Yun Pa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Aachen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Aalborg",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Aalen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Aaley",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Aalst",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Aarhus",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Aarsâl",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Aba",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Abaeté",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Abaetetuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Abaji",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Abakan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Abancay",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Abbotsford",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Abbottabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Abdul Hakim",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Abéché",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Abengourou",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Abeokuta",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Aberdeen",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Abertawe",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Abhā",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Abhar",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Abī al Khaşīb",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Abidjan",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Abiko",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Abilene",
    country: "United States",
    code: "US",
  },
  {
    city: "Abington",
    country: "United States",
    code: "US",
  },
  {
    city: "Abnūb",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Abohar",
    country: "India",
    code: "IN",
  },
  {
    city: "Aboisso",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Abomey",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Abomey-Calavi",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Abreu e Lima",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Abu Dhabi",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "Abū Ghurayb",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Abū Ḩamad",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Abū Ḩulayfah",
    country: "Kuwait",
    code: "KW",
  },
  {
    city: "Abū Qurqāş",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Abuja",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Abuyog",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ābyek",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Acacías",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Açailandia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Acajutla",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Acámbaro",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Acambay",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Acapulco de Juárez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Acará",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Acaraú",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Acarigua",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Acatzingo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Acayucan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Accra",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Acerra",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Acharnés",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Achinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Acireale",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Acıpayam",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Acopiara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Acton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Açu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Acul du Nord",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Ad Dabbah",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ad Dakhla",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Ad Dammām",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Ad Darb",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Ad Dīwānīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Ad Diwem",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ad Dujayl",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Adachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Adana",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Addis Ababa",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Adelaide",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Aden",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Ādīgala",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Ādīgrat",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Adilābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Adıyaman",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Adjaouèrè",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Adjarra",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Adjohon",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Ado-Ekiti",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ādoni",
    country: "India",
    code: "IN",
  },
  {
    city: "Adrar",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Adzopé",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Afak",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Afgooye",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Aflao",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Afmadow",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Afragola",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Afşin",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Afula",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Afyonkarahisar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Agadez",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Agadir",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Agartala",
    country: "India",
    code: "IN",
  },
  {
    city: "Agbangnizoun",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Agboville",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Ağcabədi",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Agege",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ageoshimo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Agía Paraskeví",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Ágios Dimítrios",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Agoo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Āgra",
    country: "India",
    code: "IN",
  },
  {
    city: "Agrigento",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Ağrı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Agua Prieta",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Aguacatán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Aguachica",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Aguadilla",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Aguadulce",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Aguascalientes",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Agwār",
    country: "India",
    code: "IN",
  },
  {
    city: "Ahar",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ahenkro",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ahlen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ahmadnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Ahmadpur East",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Ahmedabad",
    country: "India",
    code: "IN",
  },
  {
    city: "Ahuachapán",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Ahvāz",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Aigáleo",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Aihua",
    country: "China",
    code: "CN",
  },
  {
    city: "Ailan Mubage",
    country: "China",
    code: "CN",
  },
  {
    city: "Aïn Beïda",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Aïn Harrouda",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Aïn M’Lila",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Aïn Oussera",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Aïn Sefra",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Aïn Temouchent",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Aira",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Airdrie",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Aisai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ait Ali",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Ait Melloul",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Aix-en-Provence",
    country: "France",
    code: "FR",
  },
  {
    city: "Āīzawl",
    country: "India",
    code: "IN",
  },
  {
    city: "Aizuwakamatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ajaccio",
    country: "France",
    code: "FR",
  },
  {
    city: "Ajax",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Ajdābiyā",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Ajmer",
    country: "India",
    code: "IN",
  },
  {
    city: "Ajodhya",
    country: "India",
    code: "IN",
  },
  {
    city: "Ajuy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Akbou",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Akçaabat",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Akçakale",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Aketi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Akhisar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Akhmīm",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Akiruno",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Akishima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Akita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Akola",
    country: "India",
    code: "IN",
  },
  {
    city: "Akot",
    country: "India",
    code: "IN",
  },
  {
    city: "Akron",
    country: "United States",
    code: "US",
  },
  {
    city: "Aksaray",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Akşehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Aksu",
    country: "China",
    code: "CN",
  },
  {
    city: "Āksum",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Akurana",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Akure",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Akyazı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Al ‘Ajamī",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al ‘Amārah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al ‘Āmirāt",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Al ‘Aqabah",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Al ‘Arīsh",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al ‘Āshir min Ramaḑān",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al ‘Ayn",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "Al Aḩad al Masāriḩah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Aḩmadī",
    country: "Kuwait",
    code: "KW",
  },
  {
    city: "Al Badrashayn",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Başrah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Bayḑā’",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Al Buraymī",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Al Fallūjah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Fāw",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Fayyūm",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Fqih Ben Çalah",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Al Fujayrah",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "Al Ghardaqah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Ḩajar al Aswad",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Al Ḩamzah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Ḩasakah",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Al Ḩawīyah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Ḩayy",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Ḩillah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Ḩillah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Hindīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Hoceïma",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Al Ḩudaydah",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Al Hufūf",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Jahrā’",
    country: "Kuwait",
    code: "KW",
  },
  {
    city: "Al Jammālīyah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Jīzah",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Al Jubayl",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Jumayl",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Al Khānkah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Kharj",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Khawr",
    country: "Qatar",
    code: "QA",
  },
  {
    city: "Al Khmissat",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Al Khubar",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Khums",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Al Kūfah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Kūt",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Līth",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Mafraq",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Al Maḩallah al Kubrá",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Majāridah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Manāqil",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Al Manşūrah",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Al Manşūrah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Marj",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Al Maţarīyah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Mijlad",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Al Minshāh",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Minyā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Miqdādīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Mubarraz",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Muḑaybī",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Al Muḩarraq",
    country: "Bahrain",
    code: "BH",
  },
  {
    city: "Al Mukallā",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Al Musayyib",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Qā’im",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Qāmishlī",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Al Qaţīf",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Qunfudhah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Qurayn",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Qurayyā",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Al Qurayyāt",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Qurnah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Al Qūşīyah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Al Wajh",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Al Wakrah",
    country: "Qatar",
    code: "QA",
  },
  {
    city: "Alabang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alabel",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alafaya",
    country: "United States",
    code: "US",
  },
  {
    city: "Alagoinhas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Alamada",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alameda",
    country: "United States",
    code: "US",
  },
  {
    city: "Alaminos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alaminos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alangalang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alanya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Alasandigutta",
    country: "India",
    code: "IN",
  },
  {
    city: "Alaşehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Alba Iulia",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Albacete",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Albany",
    country: "United States",
    code: "US",
  },
  {
    city: "Albany",
    country: "United States",
    code: "US",
  },
  {
    city: "Albany",
    country: "United States",
    code: "US",
  },
  {
    city: "Alberton",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Ālbū Kamāl",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Albuquerque",
    country: "United States",
    code: "US",
  },
  {
    city: "Alcalá de Guadaira",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Alcalá de Henares",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Alchevsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Alcobaça",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Alcobendas",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Alcorcón",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Alcoy",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Aldershot",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Alegrete",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Aleksandrov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Aleksin",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Alenquer",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Aleppo",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Alessandria",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Ålesund",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Alexandria",
    country: "United States",
    code: "US",
  },
  {
    city: "Alexandria",
    country: "United States",
    code: "US",
  },
  {
    city: "Alexandria",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Alexandroúpoli",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Alfenas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Alfonso",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Algeciras",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Algiers",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Alhambra",
    country: "United States",
    code: "US",
  },
  {
    city: "Aliaga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Aliağa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Alicante",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Alicia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Alīgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Alīgūdarz",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Alīpur Duār",
    country: "India",
    code: "IN",
  },
  {
    city: "Aliso Viejo",
    country: "United States",
    code: "US",
  },
  {
    city: "Alkmaar",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Allada",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Allanmyo",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Allen",
    country: "United States",
    code: "US",
  },
  {
    city: "Allentown",
    country: "United States",
    code: "US",
  },
  {
    city: "Alleppey",
    country: "India",
    code: "IN",
  },
  {
    city: "Allinagaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Almada",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Almaty",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Almelo",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Almere",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Almería",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Almetyevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Almirante Tamandaré",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Aloha",
    country: "United States",
    code: "US",
  },
  {
    city: "Alor Setar",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Alpharetta",
    country: "United States",
    code: "US",
  },
  {
    city: "Alphen aan den Rijn",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Alta Floresta",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Altamira",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Altamira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Altamura",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Altay",
    country: "China",
    code: "CN",
  },
  {
    city: "Alto Hospicio",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Alton",
    country: "United States",
    code: "US",
  },
  {
    city: "Altoona",
    country: "United States",
    code: "US",
  },
  {
    city: "Altrincham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Alvand",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Alvarado",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Alvorada",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Alwāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Alwar",
    country: "India",
    code: "IN",
  },
  {
    city: "Alytus",
    country: "Lithuania",
    code: "LT",
  },
  {
    city: "Ama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Amadora",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Amagasaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Amakusa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Amalāpuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Amalner",
    country: "India",
    code: "IN",
  },
  {
    city: "Amarante",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Amarāvati",
    country: "India",
    code: "IN",
  },
  {
    city: "Amarillo",
    country: "United States",
    code: "US",
  },
  {
    city: "Amasya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Amatitlán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Ambahikily",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Ambājogāi",
    country: "India",
    code: "IN",
  },
  {
    city: "Ambarawa",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Ambato",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Ambattūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Ambilobe",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Ambohibary",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Ambohimangakely",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Ambon",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Ambovombe",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Amealco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ameca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Americana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Amersfoort",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ames",
    country: "United States",
    code: "US",
  },
  {
    city: "Amherst",
    country: "United States",
    code: "US",
  },
  {
    city: "Amiens",
    country: "France",
    code: "FR",
  },
  {
    city: "Amman",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Āmol",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ampanihy",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Amparo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ampitatafika",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Amrāvati",
    country: "India",
    code: "IN",
  },
  {
    city: "Amritsar",
    country: "India",
    code: "IN",
  },
  {
    city: "Amroha",
    country: "India",
    code: "IN",
  },
  {
    city: "Amstelveen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Amsterdam",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Amulung",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "An",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "An Nabk",
    country: "Syria",
    code: "SY",
  },
  {
    city: "An Najaf",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "An Nāşirīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "An Nhơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "An Nimāş",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "An Nu‘mānīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "An Nuhūd",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Anaco",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Anaheim",
    country: "United States",
    code: "US",
  },
  {
    city: "Ānaiyūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Anakāpalle",
    country: "India",
    code: "IN",
  },
  {
    city: "Analavory",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Anamur",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Anan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ānand",
    country: "India",
    code: "IN",
  },
  {
    city: "Ananindeua",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Anantapur",
    country: "India",
    code: "IN",
  },
  {
    city: "Anapa",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Anápolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Anchorage",
    country: "United States",
    code: "US",
  },
  {
    city: "Ancona",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Anda",
    country: "China",
    code: "CN",
  },
  {
    city: "Andahuaylas",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Anderlecht",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Anderson",
    country: "United States",
    code: "US",
  },
  {
    city: "Anderson",
    country: "United States",
    code: "US",
  },
  {
    city: "Andijon",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Andīmeshk",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Andoharanofotsy",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Andong",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Andover",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Andradina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Andria",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Androtsy",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Angarsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Angat",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Angeles City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Angers",
    country: "France",
    code: "FR",
  },
  {
    city: "Angono",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Angra dos Reis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Angren",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Anguo",
    country: "China",
    code: "CN",
  },
  {
    city: "Anjangaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Anjōmachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Anju",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Ankadinondry-Sakay",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Ankang",
    country: "China",
    code: "CN",
  },
  {
    city: "Ankara",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ankazoabokely",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Ankeny",
    country: "United States",
    code: "US",
  },
  {
    city: "Anlu",
    country: "China",
    code: "CN",
  },
  {
    city: "Ann Arbor",
    country: "United States",
    code: "US",
  },
  {
    city: "Annaba",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Annaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Annecy",
    country: "France",
    code: "FR",
  },
  {
    city: "Anniston",
    country: "United States",
    code: "US",
  },
  {
    city: "Anqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Anqiu",
    country: "China",
    code: "CN",
  },
  {
    city: "Ansan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Anse à Galets",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Anseong",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Anshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Anshun",
    country: "China",
    code: "CN",
  },
  {
    city: "Antalaha",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antalya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Antananarivo",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antanifotsy",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antehiroka",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antibes",
    country: "France",
    code: "FR",
  },
  {
    city: "Antigua Guatemala",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Antioch",
    country: "United States",
    code: "US",
  },
  {
    city: "Antipolo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Antofagasta",
    country: "Chile",
    code: "CL",
  },
  {
    city: "António Enes",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Antony",
    country: "France",
    code: "FR",
  },
  {
    city: "Antratsyt",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Antsalova",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antsinanantsena",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antsirabe",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antsiranana",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Antwerp",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Anuradhapura",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Anyang",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Anzhero-Sudzhensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Anzio",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Aomori",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ấp Đa Lợi",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Apac",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Apalit",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Apapa",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Aparri",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Apartadó",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Apaseo el Alto",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Apatity",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Apatzingan de la Constitucion",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Apeldoorn",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Apex",
    country: "United States",
    code: "US",
  },
  {
    city: "Apizaco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Aplahoué",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Apopa",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Apopka",
    country: "United States",
    code: "US",
  },
  {
    city: "Apple Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Apple Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Appleton",
    country: "United States",
    code: "US",
  },
  {
    city: "Aprilia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Apucarana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Āqchah",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Aqsū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Aqtaū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Aqtöbe",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Aquin",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Aquiraz",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ar Ramādī",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Ar Ramthā",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Ar Raqqah",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Ar Rass",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Ar Rastan",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Ar Rayyān",
    country: "Qatar",
    code: "QA",
  },
  {
    city: "Ar Riqqah",
    country: "Kuwait",
    code: "KW",
  },
  {
    city: "Ar Ruşayfah",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Ar Ruseris",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ar Rustāq",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Aracaju",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Aracati",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araçatuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araci",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Aracruz",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arad",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Arafat",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Araguaína",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araguari",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arāk",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Arakawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Aral",
    country: "China",
    code: "CN",
  },
  {
    city: "Arāmbāgh",
    country: "India",
    code: "IN",
  },
  {
    city: "Ārān Bīdgol",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Aranjuez",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Arankhola",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Arao",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Arapiraca",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arapongas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araranguá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araraquara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araras",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arāria",
    country: "India",
    code: "IN",
  },
  {
    city: "Araripina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araruama",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arauca",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Araucária",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Araure",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Araxá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arayat",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ārba Minch’",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Arcadia",
    country: "United States",
    code: "US",
  },
  {
    city: "Arcot",
    country: "India",
    code: "IN",
  },
  {
    city: "Arcoverde",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ardabīl",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ardakān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Arden-Arcade",
    country: "United States",
    code: "US",
  },
  {
    city: "Arecibo",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Areguá",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Arequipa",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Arezzo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Argao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Argenteuil",
    country: "France",
    code: "FR",
  },
  {
    city: "Ariana",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Arica",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Arifwala",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Arima",
    country: "Trinidad and Tobago",
    code: "TT",
  },
  {
    city: "Aringay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ariquemes",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arjona",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Arkhangelsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Arkonam",
    country: "India",
    code: "IN",
  },
  {
    city: "Arles",
    country: "France",
    code: "FR",
  },
  {
    city: "Arlington",
    country: "United States",
    code: "US",
  },
  {
    city: "Arlington",
    country: "United States",
    code: "US",
  },
  {
    city: "Arlington Heights",
    country: "United States",
    code: "US",
  },
  {
    city: "Arlit",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Armant",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Armavir",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Armenia",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Armūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Arnavutköy",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Arnhem",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Arni",
    country: "India",
    code: "IN",
  },
  {
    city: "Arnsberg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Arona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Aroroy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Arrah",
    country: "India",
    code: "IN",
  },
  {
    city: "Arrecife",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Arroyo Naranjo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Arsenyev",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Arsikere",
    country: "India",
    code: "IN",
  },
  {
    city: "Arsuz",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Arta",
    country: "Djibouti",
    code: "DJ",
  },
  {
    city: "Artëm",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Artemisa",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Artur Nogueira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Arua",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Arujá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Aruppukkottai",
    country: "India",
    code: "IN",
  },
  {
    city: "Arusha",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Arvada",
    country: "United States",
    code: "US",
  },
  {
    city: "Arwal",
    country: "India",
    code: "IN",
  },
  {
    city: "Arzamas",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Aş Şaff",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "As Safīrah",
    country: "Syria",
    code: "SY",
  },
  {
    city: "As Salamīyah",
    country: "Syria",
    code: "SY",
  },
  {
    city: "As Salţ",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "As Samāwah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "As Sīb",
    country: "Oman",
    code: "OM",
  },
  {
    city: "As Sulaymānīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "As Suwaydā’",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Aş Şuwayḩirah as Sāḩil",
    country: "Oman",
    code: "OM",
  },
  {
    city: "As Suwayq",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Aş Şuwayrah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Asadābād",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Aşağıçinik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Asahi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Asahikawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Asaka",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Asaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Asan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Asan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Āsansol",
    country: "India",
    code: "IN",
  },
  {
    city: "Asbest",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Aschaffenburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Āsela",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Asenovgrad",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Ash Shāmīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Ash Shaţrah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Ash Shaykh ‘Uthmān",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Ash Shaykh Zuwayd",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Ash Shīḩānīyah",
    country: "Qatar",
    code: "QA",
  },
  {
    city: "Ash Shiḩr",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Ash Shuhadā’",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Ashaiman",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ashdod",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Asheville",
    country: "United States",
    code: "US",
  },
  {
    city: "Ashford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ashgabat",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Ashikaga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ashiya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ashoknagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Ashqelon",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Ashta",
    country: "India",
    code: "IN",
  },
  {
    city: "Asingan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Asker",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Asmara",
    country: "Eritrea",
    code: "ER",
  },
  {
    city: "Aspen Hill",
    country: "United States",
    code: "US",
  },
  {
    city: "Assab",
    country: "Eritrea",
    code: "ER",
  },
  {
    city: "Assen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Assis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Asti",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Astrakhan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Asturias",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Asunción",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Asunción Mita",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Aswān",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Asyūţ",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Aţ Ţā’if",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "At Tājī",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "At Tall",
    country: "Syria",
    code: "SY",
  },
  {
    city: "At Tawāhī",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Atakpamé",
    country: "Togo",
    code: "TG",
  },
  {
    city: "Atambua",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Atascocita",
    country: "United States",
    code: "US",
  },
  {
    city: "Atbara",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Atchampeta",
    country: "India",
    code: "IN",
  },
  {
    city: "Atebubu",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ath Thawrah",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Athens",
    country: "United States",
    code: "US",
  },
  {
    city: "Athens",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Athi River",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Athiémé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Athni",
    country: "India",
    code: "IN",
  },
  {
    city: "Athurugiriya",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Atibaia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Atimonan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Atlanta",
    country: "United States",
    code: "US",
  },
  {
    city: "Atlantic City",
    country: "United States",
    code: "US",
  },
  {
    city: "Atotonilco el Alto",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Atsugichō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Attiecoubé",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Attock Khurd",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Atushi",
    country: "China",
    code: "CN",
  },
  {
    city: "Atyraū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Aubervilliers",
    country: "France",
    code: "FR",
  },
  {
    city: "Auburn",
    country: "United States",
    code: "US",
  },
  {
    city: "Auburn",
    country: "United States",
    code: "US",
  },
  {
    city: "Auchi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Auckland",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Augsburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Augusta",
    country: "United States",
    code: "US",
  },
  {
    city: "Aulnay-sous-Bois",
    country: "France",
    code: "FR",
  },
  {
    city: "Aurangābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Aurangābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Aurora",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Aurora",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Aurora",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Aurora",
    country: "United States",
    code: "US",
  },
  {
    city: "Aurora",
    country: "United States",
    code: "US",
  },
  {
    city: "Austin",
    country: "United States",
    code: "US",
  },
  {
    city: "Autlán de Navarro",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Āvadi",
    country: "India",
    code: "IN",
  },
  {
    city: "Avaniyāpuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Avaré",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Avcılar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Aveiro",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Avellino",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Aversa",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Aves",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Avignon",
    country: "France",
    code: "FR",
  },
  {
    city: "Ávila",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Avilés",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Avondale",
    country: "United States",
    code: "US",
  },
  {
    city: "Avrankou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Aw Dheegle",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Āwarē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Āwasa",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Awka",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ayacucho",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Ayase",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Aydın",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Aylesbury",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ayolas",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Ayvalık",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Az Zaqāzīq",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Az Zarqā’",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Az Zāwīyah",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Az Zubaydīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Az Zubayr",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Az Zulfī",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Azare",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Azov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Azrou",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Azua",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Azul",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Azumino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Azzaba",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Ba Đồn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Bà Rịa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Ba‘qūbah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Baao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baardheere",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Babahoyo",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Babīlā",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Babīlē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Bābol",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bābolsar",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Babruysk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Babylon",
    country: "United States",
    code: "US",
  },
  {
    city: "Bắc Giang",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Bạc Liêu",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Bắc Ninh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Bacaadweyn",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Bacabal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bacacay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bacău",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Bacolod",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bacoor",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bad Homburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bad Kreuznach",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bad Salzuflen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bada Barabīl",
    country: "India",
    code: "IN",
  },
  {
    city: "Badajoz",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Badalona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Bade",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Baden-Baden",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Badhan",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Badr Ḩunayn",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Badūria",
    country: "India",
    code: "IN",
  },
  {
    city: "Badvel",
    country: "India",
    code: "IN",
  },
  {
    city: "Bafang",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bafia",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bafoussam",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bafra",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bafut",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bagaha",
    country: "India",
    code: "IN",
  },
  {
    city: "Bāgalkot",
    country: "India",
    code: "IN",
  },
  {
    city: "Bagam",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bagan Si Api-api",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Baganga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bağcılar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bagé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Baggao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bāgh-e Malek",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Baghdad",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Bagheria",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Baghlān",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Bago",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bago",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Bagong Silangan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bagu Na Mohra",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Báguanos",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Baguio City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bagumbayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baham",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bahār",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Baharampur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bahārestān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bahawalnagar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Bahawalpur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Bahía Blanca",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Bahir Dar",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Bahlā’",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Bahraigh",
    country: "India",
    code: "IN",
  },
  {
    city: "Bahrain",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Baía Farta",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Baia Mare",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Baicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Baicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Baidoa",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Baidyabāti",
    country: "India",
    code: "IN",
  },
  {
    city: "Baigou",
    country: "China",
    code: "CN",
  },
  {
    city: "Bail-Hongal",
    country: "India",
    code: "IN",
  },
  {
    city: "Bainet",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Baiquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Bais",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baisha",
    country: "China",
    code: "CN",
  },
  {
    city: "Baishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Baiyin",
    country: "China",
    code: "CN",
  },
  {
    city: "Baj Baj",
    country: "India",
    code: "IN",
  },
  {
    city: "Bajos de Haina",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Bakersfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Bakhmut",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Bakıxanov",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Baku",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Bakwa-Kalonji",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Balad",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Balagtas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Balakovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Balamban",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Balancán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Balandougou",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Balanga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Balāngīr",
    country: "India",
    code: "IN",
  },
  {
    city: "Balashikha",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Balashov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Balayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Balboa Heights",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Baldwin Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Baleraja",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bāli",
    country: "India",
    code: "IN",
  },
  {
    city: "Balikpapan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Balingasag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baliuag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Balıkesir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Balkanabat",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Balkh",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Ballarat",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Ballia",
    country: "India",
    code: "IN",
  },
  {
    city: "Balneário de Camboriú",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Balombo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Balqash",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Balsas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bălţi",
    country: "Moldova",
    code: "MD",
  },
  {
    city: "Baltimore",
    country: "United States",
    code: "US",
  },
  {
    city: "Bālurghāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Bam",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bama",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Bamako",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Bamban",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bambang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bamberg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bamei",
    country: "China",
    code: "CN",
  },
  {
    city: "Bamenda",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bamiantong",
    country: "China",
    code: "CN",
  },
  {
    city: "Bāmyān",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Ban Bang Kaeo",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Bang Pu Mai",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Laem Chabang",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Lam Sam Kaeo",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Mangkon",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Om Noi",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Rangsit",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Suan",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Talat Rangsit",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ban Tha Khlong",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Banane",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Bānchpār",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Banco Filipino International Village",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Banda Aceh",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Banda del Río Salí",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Bandar ‘Abbās",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandar Emām",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandar Lampung",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bandar Seri Begawan",
    country: "Brunei",
    code: "BN",
  },
  {
    city: "Bandar-e Anzalī",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandar-e Būshehr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandar-e Genāveh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandar-e Māhshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandar-e Torkaman",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bandırma",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bandō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Bandundu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bandung",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bāneh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Banfield",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Banfora",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Bang Bua Thong",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Banga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bangalore",
    country: "India",
    code: "IN",
  },
  {
    city: "Bangaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Bangkok",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Bangor",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bangued",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bangui",
    country: "Central African Republic",
    code: "CF",
  },
  {
    city: "Banhā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Bani",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baní",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Banī Mazār",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Banī Suwayf",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Banī Walīd",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Banikoara",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Banja Luka",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Banjar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Banjarbaru",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Banjarmasin",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Banjul",
    country: "The Gambia",
    code: "GM",
  },
  {
    city: "Bankra",
    country: "India",
    code: "IN",
  },
  {
    city: "Bānkura",
    country: "India",
    code: "IN",
  },
  {
    city: "Bannu",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Bansalan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bānsbāria",
    country: "India",
    code: "IN",
  },
  {
    city: "Banská Bystrica",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Bantacan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bantayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Banté",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Banyuwangi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bảo Lộc",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Baoding",
    country: "China",
    code: "CN",
  },
  {
    city: "Baojishi",
    country: "China",
    code: "CN",
  },
  {
    city: "Baoshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Baotou",
    country: "China",
    code: "CN",
  },
  {
    city: "Bāpatla",
    country: "India",
    code: "IN",
  },
  {
    city: "Bāqershahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bar Bigha",
    country: "India",
    code: "IN",
  },
  {
    city: "Barabai",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Baracoa",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Barahona",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Baraka",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bārākpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bāramūla",
    country: "India",
    code: "IN",
  },
  {
    city: "Baranavichy",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Baranoa",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Baras",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bārāsat",
    country: "India",
    code: "IN",
  },
  {
    city: "Barbacena",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barbalha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barberena",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Barbosa",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Barcarena Nova",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barcelona",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Barcelona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Barcelos",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Barddhamān",
    country: "India",
    code: "IN",
  },
  {
    city: "Bareilly",
    country: "India",
    code: "IN",
  },
  {
    city: "Bargarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Bargny",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Bari",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bariadi",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Barili",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Barinas",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Barishal",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Barking",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bârlad",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Barletta",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Barnāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Barnaul",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Barneveld",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Barnsley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Barobo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Barotac Nuevo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Barquisimeto",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Barra",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barra do Corda",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barra do Dande",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Barra do Garças",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barra do Piraí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barra Mansa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barranca",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Barrancabermeja",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Barranqueras",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Barranquilla",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Barrechid",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Barreiras",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barreirinhas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barreiro",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Barretos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Barri",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Barrie",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Barrow in Furness",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bartın",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bartlett",
    country: "United States",
    code: "US",
  },
  {
    city: "Bartolomé Masó",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Barueri",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bāruipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Barura",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Baruta",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Barwaaqo",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Barwāni",
    country: "India",
    code: "IN",
  },
  {
    city: "Bas Limbé",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Basankusu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Basavakalyān",
    country: "India",
    code: "IN",
  },
  {
    city: "Basel",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Basey",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Basildon",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Basingstoke",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Basīrhat",
    country: "India",
    code: "IN",
  },
  {
    city: "Başkale",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bāsoda",
    country: "India",
    code: "IN",
  },
  {
    city: "Bassila",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Basuo",
    country: "China",
    code: "CN",
  },
  {
    city: "Bat Yam",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Bata",
    country: "Equatorial Guinea",
    code: "GQ",
  },
  {
    city: "Batac",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Batāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Batang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Batangas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Batarasa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Batatais",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bataysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bath",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Batley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Batman",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Batna",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Bato",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baton Rouge",
    country: "United States",
    code: "US",
  },
  {
    city: "Battalgazi",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Battambang",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Batticaloa",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Battipaglia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Battle Creek",
    country: "United States",
    code: "US",
  },
  {
    city: "Batu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Batu Gajah",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Batu Pahat",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Batumi",
    country: "Georgia",
    code: "GE",
  },
  {
    city: "Bauan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bauang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baubau",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bauchi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Bauru",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bávaro",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Bawku",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Bawshar",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Bay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bay City",
    country: "United States",
    code: "US",
  },
  {
    city: "Bayamo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Bayamón",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Bayan Hot",
    country: "China",
    code: "CN",
  },
  {
    city: "Bayawan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Baybay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bayburt",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bayeux",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bayjī",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Bayombong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bayonne",
    country: "France",
    code: "FR",
  },
  {
    city: "Bayonne",
    country: "United States",
    code: "US",
  },
  {
    city: "Bayramaly",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Bayreuth",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bayt Lāhyā",
    country: "Gaza Strip",
    code: "XG",
  },
  {
    city: "Baytown",
    country: "United States",
    code: "US",
  },
  {
    city: "Bayugan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bāzār-e Yakāwlang",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Bazhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Bazhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Beaumont",
    country: "United States",
    code: "US",
  },
  {
    city: "Beaumont",
    country: "United States",
    code: "US",
  },
  {
    city: "Beauvais",
    country: "France",
    code: "FR",
  },
  {
    city: "Beaverton",
    country: "United States",
    code: "US",
  },
  {
    city: "Beāwar",
    country: "India",
    code: "IN",
  },
  {
    city: "Bebedouro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bebington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Beccar",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Béchar",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Bedford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Będzin",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Beersheba",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Begusarai",
    country: "India",
    code: "IN",
  },
  {
    city: "Behbahān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Behshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Beian",
    country: "China",
    code: "CN",
  },
  {
    city: "Beichengqu",
    country: "China",
    code: "CN",
  },
  {
    city: "Beidao",
    country: "China",
    code: "CN",
  },
  {
    city: "Beihai",
    country: "China",
    code: "CN",
  },
  {
    city: "Beijing",
    country: "China",
    code: "CN",
  },
  {
    city: "Beining",
    country: "China",
    code: "CN",
  },
  {
    city: "Beipiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Beira",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Beirut",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Béja",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Bejaïa",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Beji",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bekasi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Békéscsaba",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Bekobod",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Bel Air South",
    country: "United States",
    code: "US",
  },
  {
    city: "Belampalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Belas",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Bełchatów",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Belebey",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Beledweyne",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Belek",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Belém",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Belén de Escobar",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Belfast",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Belgaum",
    country: "India",
    code: "IN",
  },
  {
    city: "Belgorod",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Belgrade",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Belize City",
    country: "Belize",
    code: "BZ",
  },
  {
    city: "Bella Vista",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Belladère",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Bellary",
    country: "India",
    code: "IN",
  },
  {
    city: "Belle-Anse",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Belleville",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Bellevue",
    country: "France",
    code: "FR",
  },
  {
    city: "Bellevue",
    country: "United States",
    code: "US",
  },
  {
    city: "Bellevue",
    country: "United States",
    code: "US",
  },
  {
    city: "Bellflower",
    country: "United States",
    code: "US",
  },
  {
    city: "Bellingham",
    country: "United States",
    code: "US",
  },
  {
    city: "Bello",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Belo Horizonte",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Belo Jardim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Belo Tsiribihina",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Beloeil",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Belogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Belorechensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Beloretsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Belovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ben Arous",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Ben Gardane",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Ben Guerir",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Bến Tre",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Benalmádena",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Bend",
    country: "United States",
    code: "US",
  },
  {
    city: "Bender",
    country: "Moldova",
    code: "MD",
  },
  {
    city: "Bendigo",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Bené Beraq",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Benevento",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Benevides",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bengbu",
    country: "China",
    code: "CN",
  },
  {
    city: "Benghazi",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Bengkulu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Benguela",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Beni",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Beni Enzar",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Béni Mellal",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Benidorm",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Benin City",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Benoni",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Bensalem",
    country: "United States",
    code: "US",
  },
  {
    city: "Benslimane",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Bento Gonçalves",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bentonville",
    country: "United States",
    code: "US",
  },
  {
    city: "Benxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Beppu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Berazategui",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Berbera",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Berbérati",
    country: "Central African Republic",
    code: "CF",
  },
  {
    city: "Berdiansk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Berdsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Berdychiv",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Berekum",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Berezniki",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Berëzovskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bergama",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bergamo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bergen",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Bergen op Zoom",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Bergheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bergisch Gladbach",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Berisso",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Berkane",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Berkeley",
    country: "United States",
    code: "US",
  },
  {
    city: "Berlin",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bern",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Bernal",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Berriozábal",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Bertioga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bertoua",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Beruniy",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Berwyn",
    country: "United States",
    code: "US",
  },
  {
    city: "Besançon",
    country: "France",
    code: "FR",
  },
  {
    city: "Bet Shemesh",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Bethal",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Bethesda",
    country: "United States",
    code: "US",
  },
  {
    city: "Bethlehem",
    country: "United States",
    code: "US",
  },
  {
    city: "Betigeri",
    country: "India",
    code: "IN",
  },
  {
    city: "Betim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Betsiboka",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Bettiah",
    country: "India",
    code: "IN",
  },
  {
    city: "Betūl Bazār",
    country: "India",
    code: "IN",
  },
  {
    city: "Beykoz",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Beylikdüzü",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Beypore",
    country: "India",
    code: "IN",
  },
  {
    city: "Beyşehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bezerros",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Béziers",
    country: "France",
    code: "FR",
  },
  {
    city: "Bhabhua",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhadrāchalam",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhadrakh",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhadrāvati",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhadreswar",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhāgalpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhairāhawā",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Bhakkar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Bhaktapur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Bhālswa Jahangirpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhalwal",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Bhāndāria",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Bhāngar",
    country: "India",
    code: "IN",
  },
  {
    city: "Bharatpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bharatpur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Bharūch",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhatinda",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhātpāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhavāni",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhāvnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhawana",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Bhawānipatna",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhayandar",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhilai",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhilai",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhīlwāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhīmavaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhīmunipatnam",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhind",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhisho",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Bhiwandi",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhiwāni",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhola",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Bhongīr",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhopāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhubaneshwar",
    country: "India",
    code: "IN",
  },
  {
    city: "Bhusāval",
    country: "India",
    code: "IN",
  },
  {
    city: "Bi’r al ‘Abd",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Biak",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Biała Podlaska",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Białystok",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Bianyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Biaora",
    country: "India",
    code: "IN",
  },
  {
    city: "Bibā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Bibémi",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bida",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Bīdar",
    country: "India",
    code: "IN",
  },
  {
    city: "Biel/Bienne",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Bielefeld",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bielsko-Biała",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Biên Hòa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Biga",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Biguaçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bihać",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Bihār",
    country: "India",
    code: "IN",
  },
  {
    city: "Bijāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bījār",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bijeljina",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Bijiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Bijie",
    country: "China",
    code: "CN",
  },
  {
    city: "Bīkaner",
    country: "India",
    code: "IN",
  },
  {
    city: "Bikramganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Bila Tserkva",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Bilāspur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bilbao",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Bilbays",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Bilecik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bilhorod-Dnistrovskyi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Bilis Qooqaani",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Billings",
    country: "United States",
    code: "US",
  },
  {
    city: "Bilwi",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Bỉm Sơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Bima",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bimbo",
    country: "Central African Republic",
    code: "CF",
  },
  {
    city: "Bimo",
    country: "Central African Republic",
    code: "CF",
  },
  {
    city: "Binalbagan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Binalonan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Biñan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Binangonan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Binga",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bingerville",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Binghamton",
    country: "United States",
    code: "US",
  },
  {
    city: "Bingöl",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bình Hòa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Bình Long",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Binjai",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Binmaley",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Binnāguri",
    country: "India",
    code: "IN",
  },
  {
    city: "Bintulu",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Binxian",
    country: "China",
    code: "CN",
  },
  {
    city: "Binzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Bīr",
    country: "India",
    code: "IN",
  },
  {
    city: "Bir Ali Ben Khalifa",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Birāṭnagar",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Birecik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Birgañj",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Birigui",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bīrjand",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Birkenhead",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Birmingham",
    country: "United States",
    code: "US",
  },
  {
    city: "Birmingham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Birnin Kebbi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Birnin Konni",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Birobidzhan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bisceglie",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bishkek",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "Bishnupur",
    country: "India",
    code: "IN",
  },
  {
    city: "Biskra",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Bislig",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bismarck",
    country: "United States",
    code: "US",
  },
  {
    city: "Bissau",
    country: "Guinea-Bissau",
    code: "GW",
  },
  {
    city: "Bistriţa",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Bitlis",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bitola",
    country: "Macedonia",
    code: "MK",
  },
  {
    city: "Bitonto",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bitung",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Biu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Biysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bizerte",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Blackburn",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Blackpool",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Blacksburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Blagoevgrad",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Blagoveshchensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Blaine",
    country: "United States",
    code: "US",
  },
  {
    city: "Blainville",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Blantyre",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Blida",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Blitar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bloemfontein",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Bloomfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Bloomington",
    country: "United States",
    code: "US",
  },
  {
    city: "Bloomington",
    country: "United States",
    code: "US",
  },
  {
    city: "Bloomington",
    country: "United States",
    code: "US",
  },
  {
    city: "Blue Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Blumenau",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bo",
    country: "Sierra Leone",
    code: "SL",
  },
  {
    city: "Boa Viagem",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Boa Vista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Boac",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Boaco",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Boadilla del Monte",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Boankra",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Bobbili",
    country: "India",
    code: "IN",
  },
  {
    city: "Bobigny",
    country: "France",
    code: "FR",
  },
  {
    city: "Böblingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bobo-Dioulasso",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Boca Chica",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Boca del Rio",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Boca Raton",
    country: "United States",
    code: "US",
  },
  {
    city: "Bocaranga",
    country: "Central African Republic",
    code: "CF",
  },
  {
    city: "Bocaue",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bocholt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bochum",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bocoio",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Bodhan",
    country: "India",
    code: "IN",
  },
  {
    city: "Bodināyakkanūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Bodītī",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Bodø",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Bodrum",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bognor Regis",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bogo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bogo",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Bogor",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bogotá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Bogra",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Bohicon",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Boise",
    country: "United States",
    code: "US",
  },
  {
    city: "Boituva",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bojnūrd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Boké",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Bokhtar",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Boksburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Bole",
    country: "China",
    code: "CN",
  },
  {
    city: "Bolgatanga",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Boli",
    country: "China",
    code: "CN",
  },
  {
    city: "Bolinao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bolingbrook",
    country: "United States",
    code: "US",
  },
  {
    city: "Bologna",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bolpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bolton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bolu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bolzano",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bom Jesus da Lapa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Boma",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bombo",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Bonāb",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bonao",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Bondoukou",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Bondy",
    country: "France",
    code: "FR",
  },
  {
    city: "Bongabon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bongabong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bongaigaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Bongao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bongouanou",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Bonita Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Bonn",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bontang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Boosaaso",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Bootle",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bopa",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Bor",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bor",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bor",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Borås",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Borāzjān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bordeaux",
    country: "France",
    code: "FR",
  },
  {
    city: "Bordj Bou Arreridj",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Bordj Menaïel",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Borisoglebsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Borj el Qoblé",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Borj Hammoud",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Borongan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Borovichi",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Borūjen",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Borūjerd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Boryeong",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Boryspil",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Bossier City",
    country: "United States",
    code: "US",
  },
  {
    city: "Boston",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Boston",
    country: "United States",
    code: "US",
  },
  {
    city: "Botolan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Botoşani",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Botou",
    country: "China",
    code: "CN",
  },
  {
    city: "Bottrop",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Botucatu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bou Saâda",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Bouaflé",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Bouaké",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Boudouaou",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Boufarik",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Bougouni",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Bouira",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Boukoumbé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Boulder",
    country: "United States",
    code: "US",
  },
  {
    city: "Boulogne-Billancourt",
    country: "France",
    code: "FR",
  },
  {
    city: "Bouna",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Boundiali",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Bourges",
    country: "France",
    code: "FR",
  },
  {
    city: "Bournemouth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bouskoura",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Bowie",
    country: "United States",
    code: "US",
  },
  {
    city: "Bowling Green",
    country: "United States",
    code: "US",
  },
  {
    city: "Bowringpet",
    country: "India",
    code: "IN",
  },
  {
    city: "Boynton Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Bozeman",
    country: "United States",
    code: "US",
  },
  {
    city: "Bozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Bozüyük",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bracknell",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bradenton",
    country: "United States",
    code: "US",
  },
  {
    city: "Bradford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Braga",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Bragança",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bragança Paulista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Brāhmanbāria",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Brahmanpara",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Brahmapur",
    country: "India",
    code: "IN",
  },
  {
    city: "Brăila",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Braintree",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Brājarājnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Brakpan",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Brampton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Brandenburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Brandon",
    country: "United States",
    code: "US",
  },
  {
    city: "Brantford",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Brasília",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Braşov",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Bratislava",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Bratsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Braunschweig",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Brazzaville",
    country: "Congo (Brazzaville)",
    code: "CG",
  },
  {
    city: "Breda",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Bremen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bremerhaven",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Bremerton",
    country: "United States",
    code: "US",
  },
  {
    city: "Brentwood",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Brentwood",
    country: "United States",
    code: "US",
  },
  {
    city: "Brentwood",
    country: "United States",
    code: "US",
  },
  {
    city: "Brescia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Brest",
    country: "France",
    code: "FR",
  },
  {
    city: "Brest",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Breves",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Brick",
    country: "United States",
    code: "US",
  },
  {
    city: "Bridgeport",
    country: "United States",
    code: "US",
  },
  {
    city: "Bridgetown",
    country: "Barbados",
    code: "BB",
  },
  {
    city: "Brighton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Brikama",
    country: "The Gambia",
    code: "GM",
  },
  {
    city: "Brindisi",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Brisbane",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Bristol",
    country: "United States",
    code: "US",
  },
  {
    city: "Bristol",
    country: "United States",
    code: "US",
  },
  {
    city: "Bristol",
    country: "United States",
    code: "US",
  },
  {
    city: "Bristol",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Brits",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Brixton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Brno",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Brockton",
    country: "United States",
    code: "US",
  },
  {
    city: "Broken Arrow",
    country: "United States",
    code: "US",
  },
  {
    city: "Bromley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Bronx",
    country: "United States",
    code: "US",
  },
  {
    city: "Brookes Point",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Brookhaven",
    country: "United States",
    code: "US",
  },
  {
    city: "Brookhaven",
    country: "United States",
    code: "US",
  },
  {
    city: "Brookline",
    country: "United States",
    code: "US",
  },
  {
    city: "Brooklyn",
    country: "United States",
    code: "US",
  },
  {
    city: "Brooklyn Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Broomfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Brossard",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Brovary",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Brownsville",
    country: "United States",
    code: "US",
  },
  {
    city: "Bruges",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Brumado",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Brusque",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Brussels",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Bryan",
    country: "United States",
    code: "US",
  },
  {
    city: "Bryansk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bucak",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bucaramanga",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Bucharest",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Bucheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Buckeye",
    country: "United States",
    code: "US",
  },
  {
    city: "Budapest",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Budaun",
    country: "India",
    code: "IN",
  },
  {
    city: "Budënnovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Buea",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Buena Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Buenaventura",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Buenavista",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Buenavista",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Buenos Aires",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Buffalo",
    country: "United States",
    code: "US",
  },
  {
    city: "Bugallon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bugulma",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Buhi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Buin",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Buíque",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Bujumbura",
    country: "Burundi",
    code: "BI",
  },
  {
    city: "Būkān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bukavu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bukhara",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Bukit Mertajam",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Bukittinggi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Bukoba",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Bula",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bulacan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bulan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bulancak",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bulandshahr",
    country: "India",
    code: "IN",
  },
  {
    city: "Bulanık",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Bulawayo",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Buluan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Bulungu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Būmahen",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Bumba",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bunbury",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Bundaberg",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Būndi",
    country: "India",
    code: "IN",
  },
  {
    city: "Bungoma",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Bunia",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Bunkyō-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Buôn Hồ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Buôn Ma Thuột",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Buqda Caqable",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Būr Fu’ād",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Bura",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Burauen",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Buraydah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Burbank",
    country: "United States",
    code: "US",
  },
  {
    city: "Burco",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Burdur",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Burgas",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Burgos",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Burhaniye",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Burhānpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Burien",
    country: "United States",
    code: "US",
  },
  {
    city: "Burla",
    country: "India",
    code: "IN",
  },
  {
    city: "Burlington",
    country: "United States",
    code: "US",
  },
  {
    city: "Burlington",
    country: "United States",
    code: "US",
  },
  {
    city: "Burlington",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Burnaby",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Burnley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Burnsville",
    country: "United States",
    code: "US",
  },
  {
    city: "Bursa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Burton upon Trent",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Burutu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Bury",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Burzaco",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Busan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Busia",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Busto Arsizio",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Bustos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Buta",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Butare",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Butembo",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Buthidaung",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Butterworth",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Butuan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Butwāl",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Buuhoodle",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Buurhakaba",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Buxar",
    country: "India",
    code: "IN",
  },
  {
    city: "Buynaksk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Büyükçekmece",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Buzău",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Buzuluk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Bwana Mkubwa",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Byatarayanpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Bydgoszcz",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Bytom",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Cà Mau",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Caacupé",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Cabadbaran",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cabagan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cabanatuan City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cabatuan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cabedelo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cabiao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cabimas",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Cabinda",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cabo Frio",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cabo San Lucas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cabudare",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Cabuyao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Caçador",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Čačak",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Caçapava",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cáceres",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cáceres",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Cachoeira do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cachoeiras de Macacu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cachoeirinha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cachoeiro de Itapemirim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cacoal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cacuaco",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cadaado",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Cadereyta Jiménez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cadiz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cádiz",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Caen",
    country: "France",
    code: "FR",
  },
  {
    city: "Cardiff (Caerdydd)",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Caetité",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cagayan de Oro",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cagliari",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Cagnes-sur-Mer",
    country: "France",
    code: "FR",
  },
  {
    city: "Cagua",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Caguas",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Cahama",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cai Lậy",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Caicó",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caieiras",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caimbambo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cainta",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cairns",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Cairo",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Cajamar",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cajamarca",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Cajazeiras",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cajicá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Calabanga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calabar",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Calabayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calabozo",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Calaca",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calais",
    country: "France",
    code: "FR",
  },
  {
    city: "Calama",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Calandala",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Calapan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Călăraşi",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Calarcá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Calasiao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calatagan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calatrava",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calauag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calauan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Calbayog City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Caldas",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Caldas da Rainha",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Caldas Novas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Çaldıran",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Caldwell",
    country: "United States",
    code: "US",
  },
  {
    city: "Caledon",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Caleta Olivia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Calgary",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Cali",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Calicut",
    country: "India",
    code: "IN",
  },
  {
    city: "Calinog",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Callao",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Caloocan City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Caltanissetta",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Calulo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Calumpit",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Caluquembe",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Calviá",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Cẩm Phả",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Cam Ranh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Cam Ranh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Camabatela",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Camaçari",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Camacupa",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Camagüey",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Camajuaní",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Camalig",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Camaquã",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Camarajibe",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Camarillo",
    country: "United States",
    code: "US",
  },
  {
    city: "Cambambe",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cambanugoy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cambé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Camboriú",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cambridge",
    country: "United States",
    code: "US",
  },
  {
    city: "Cambridge",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Cambridge",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Cambundi Catembo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Camden",
    country: "United States",
    code: "US",
  },
  {
    city: "Cametá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Camiling",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Camocim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Camotán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Campana",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Campeche",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Campina Grande",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campinas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Alegre",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Belo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Bom",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Formoso",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Grande",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Largo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Limpo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campo Mourão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Campos do Jordão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cần Thơ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Canagatan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Çanakkale",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Canberra",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Cancún",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Candaba",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Candeias",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Candelaria",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Candelaria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Candon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cangandala",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cangola",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Canguçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cangzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Canindé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Çankaya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Çankırı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Canlaon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cannanore",
    country: "India",
    code: "IN",
  },
  {
    city: "Cannes",
    country: "France",
    code: "FR",
  },
  {
    city: "Cannock",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Canoas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Canoinhas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cantaura",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Canterbury",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Canton",
    country: "United States",
    code: "US",
  },
  {
    city: "Cantonment",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Cao Bằng",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Cao Lãnh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Caotun",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Cap-Haïtien",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Capanema",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Capas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cape Breton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Cape Coast",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Cape Coral",
    country: "United States",
    code: "US",
  },
  {
    city: "Cape Town",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Capelle aan den IJssel",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Capenda Camulemba",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Capiatá",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Capitão Poço",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Capivari",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caracas",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Caracase",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Caraguatatuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caramoan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Carapicuíba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caratinga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caràzinho",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Carbondale",
    country: "United States",
    code: "US",
  },
  {
    city: "Carcar",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cárdenas",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Cardona",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cariacica",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Carigara",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Caripito",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Carles",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Carlisle",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Carlos Manuel de Céspedes",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Carlsbad",
    country: "United States",
    code: "US",
  },
  {
    city: "Carmel",
    country: "United States",
    code: "US",
  },
  {
    city: "Carmen",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Carmen",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Carmen",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Carmichael",
    country: "United States",
    code: "US",
  },
  {
    city: "Carmona",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Carolina",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Carora",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Carpi",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Carpina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Carrara",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Carrefour",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Carrollton",
    country: "United States",
    code: "US",
  },
  {
    city: "Çarşamba",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Carson",
    country: "United States",
    code: "US",
  },
  {
    city: "Carson City",
    country: "United States",
    code: "US",
  },
  {
    city: "Cartagena",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Cartagena",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Cartago",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Caruaru",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Carúpano",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Cary",
    country: "United States",
    code: "US",
  },
  {
    city: "Casa Grande",
    country: "United States",
    code: "US",
  },
  {
    city: "Casa Nova",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Casablanca",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Casas Adobes",
    country: "United States",
    code: "US",
  },
  {
    city: "Cascais",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Cascavel",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caseros",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Caserta",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Casoria",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Casper",
    country: "United States",
    code: "US",
  },
  {
    city: "Cassongue",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Castanhal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Castelar",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Castellammare di Stabia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Castelldefels",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Castellón de la Plana",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Castelo Branco",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Castilla",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Castillejos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Castle Rock",
    country: "United States",
    code: "US",
  },
  {
    city: "Castries",
    country: "Saint Lucia",
    code: "LC",
  },
  {
    city: "Castro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Castro Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Castrop-Rauxel",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Catabola",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Catacamas",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Catacaos",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Cataguases",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cataingan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Catalão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Çatalca",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Catalina Foothills",
    country: "United States",
    code: "US",
  },
  {
    city: "Catamarca",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Catanauan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Catanduva",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Catania",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Catanzaro",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Catape",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Catarman",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Catbalogan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Catchiungo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cathedral City",
    country: "United States",
    code: "US",
  },
  {
    city: "Catia La Mar",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Catu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Catumbela",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Caucaia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caucasia",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Cava de’ Tirreni",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Cavite City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cawayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cawayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cawnpore",
    country: "India",
    code: "IN",
  },
  {
    city: "Caxias",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caxias do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Caxito",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Çaycuma",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cayenne",
    country: "French Guiana",
    code: "GF",
  },
  {
    city: "Çayırova",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cazanga",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ceará-Mirim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cebu City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cedar Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Cedar Rapids",
    country: "United States",
    code: "US",
  },
  {
    city: "Cedeño",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Ceel Baraf",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Ceel Dheere",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Çekme",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Celaya",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Celle",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Cencheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Centennial",
    country: "United States",
    code: "US",
  },
  {
    city: "Central Coast",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Central District",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Centreville",
    country: "United States",
    code: "US",
  },
  {
    city: "Centro Habana",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Centurion",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Cerca la Source",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Cereté",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Cergy",
    country: "France",
    code: "FR",
  },
  {
    city: "Cerignola",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Çerkezköy",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Çermik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cerro de Pasco",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Cesena",
    country: "Italy",
    code: "IT",
  },
  {
    city: "České Budějovice",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Ceyhan",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ceylanpınar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ch’ŏnan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Ch’ŏngjin",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Ch’ungmu",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Chābahār",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Chaedŏk",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Chaguanas",
    country: "Trinidad and Tobago",
    code: "TT",
  },
  {
    city: "Chaigoubu",
    country: "China",
    code: "CN",
  },
  {
    city: "Chajul",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chākdaha",
    country: "India",
    code: "IN",
  },
  {
    city: "Chakradharpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Chakwal",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chalándri",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Chalchuapa",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Chalco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chalkída",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Challakere",
    country: "India",
    code: "IN",
  },
  {
    city: "Chaman",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chambéry",
    country: "France",
    code: "FR",
  },
  {
    city: "Champaign",
    country: "United States",
    code: "US",
  },
  {
    city: "Champdani",
    country: "India",
    code: "IN",
  },
  {
    city: "Champigny-sur-Marne",
    country: "France",
    code: "FR",
  },
  {
    city: "Chāmrājnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Chānda",
    country: "India",
    code: "IN",
  },
  {
    city: "Chandannagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Chandīgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Chandler",
    country: "United States",
    code: "US",
  },
  {
    city: "Chandralapādu",
    country: "India",
    code: "IN",
  },
  {
    city: "Chang’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Changbang",
    country: "China",
    code: "CN",
  },
  {
    city: "Changchun",
    country: "China",
    code: "CN",
  },
  {
    city: "Changde",
    country: "China",
    code: "CN",
  },
  {
    city: "Changhua",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Changling",
    country: "China",
    code: "CN",
  },
  {
    city: "Changping",
    country: "China",
    code: "CN",
  },
  {
    city: "Changsha",
    country: "China",
    code: "CN",
  },
  {
    city: "Changsha",
    country: "China",
    code: "CN",
  },
  {
    city: "Changshu",
    country: "China",
    code: "CN",
  },
  {
    city: "Changting",
    country: "China",
    code: "CN",
  },
  {
    city: "Changwon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Changzhi",
    country: "China",
    code: "CN",
  },
  {
    city: "Changzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Chaniá",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Channapatna",
    country: "India",
    code: "IN",
  },
  {
    city: "Chanwari",
    country: "India",
    code: "IN",
  },
  {
    city: "Chaohucun",
    country: "China",
    code: "CN",
  },
  {
    city: "Chaoshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Chaoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Chaozhou",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Chaozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Chapadinha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Chapayevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chapecó",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Chapel Hill",
    country: "United States",
    code: "US",
  },
  {
    city: "Chāpra",
    country: "India",
    code: "IN",
  },
  {
    city: "Charallave",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Chārīkār",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Charleroi",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Charleston",
    country: "United States",
    code: "US",
  },
  {
    city: "Charleston",
    country: "United States",
    code: "US",
  },
  {
    city: "Charlotte",
    country: "United States",
    code: "US",
  },
  {
    city: "Charlottesville",
    country: "United States",
    code: "US",
  },
  {
    city: "Charsadda",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chās",
    country: "India",
    code: "IN",
  },
  {
    city: "Châteauguay",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Chatham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Chatham",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Chattanooga",
    country: "United States",
    code: "US",
  },
  {
    city: "Chattogram",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Châu Đốc",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Chauddagram",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Chauk",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Chauk Azam",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chaykovskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chbar Mon",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Cheboksary",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chech’ŏn",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Chèddra",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Cheektowaga",
    country: "United States",
    code: "US",
  },
  {
    city: "Chegutu",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Chekhov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chelghoum el Aïd",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Chelles",
    country: "France",
    code: "FR",
  },
  {
    city: "Chełm",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Chelmsford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Cheltenham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Chelyabinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chemnitz",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Chenab Nagar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chenārān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Chengbin",
    country: "China",
    code: "CN",
  },
  {
    city: "Chengdu",
    country: "China",
    code: "CN",
  },
  {
    city: "Chengguan",
    country: "China",
    code: "CN",
  },
  {
    city: "Chengjiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Chengjiao Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Chengtangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Chengxiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Chennai",
    country: "India",
    code: "IN",
  },
  {
    city: "Chenzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Cheongju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Cherbourg",
    country: "France",
    code: "FR",
  },
  {
    city: "Cheremkhovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Cherepovets",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Cherkasy",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Cherkessk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chernihiv",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Chernivtsi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Chernogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Cherry Hill",
    country: "United States",
    code: "US",
  },
  {
    city: "Chervonohrad",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Chesapeake",
    country: "United States",
    code: "US",
  },
  {
    city: "Chester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Chetumal",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cheyenne",
    country: "United States",
    code: "US",
  },
  {
    city: "Chhatarpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Chhindwāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Chí Linh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Chía",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Chiang Mai",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Chiang Rai",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Chiantla",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chiapa de Corzo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chiayi",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Chiba",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chibia",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Chibok",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Chibuto",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Chicacao",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chicacole",
    country: "India",
    code: "IN",
  },
  {
    city: "Chicago",
    country: "United States",
    code: "US",
  },
  {
    city: "Chichibu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chichicastenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chiclana de la Frontera",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Chiclayo",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Chico",
    country: "United States",
    code: "US",
  },
  {
    city: "Chicoloapan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chicomba",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Chicopee",
    country: "United States",
    code: "US",
  },
  {
    city: "Chidambaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Chieti",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Chifeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Chigasaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chigorodó",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Chiguayante",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Chihuahua",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chik Ballāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Chikmagalūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Chikuma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chikusei",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chikushino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chilakalūrupet",
    country: "India",
    code: "IN",
  },
  {
    city: "Chilapa de Álvarez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chilas",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chilibre",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Chililabombwe",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Chillán",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Chilliwack",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Chilpancingo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chimalhuacán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Chimaltenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chimbas",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Chimbote",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Chimoio",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Chinandega",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Chinautla",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chincha Alta",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Chinchvad",
    country: "India",
    code: "IN",
  },
  {
    city: "Chingford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Chingleput",
    country: "India",
    code: "IN",
  },
  {
    city: "Chingola",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Chinguar",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Chinhoyi",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Chini",
    country: "China",
    code: "CN",
  },
  {
    city: "Chiniot",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chinju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Chinnachauku",
    country: "India",
    code: "IN",
  },
  {
    city: "Chinnamanūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Chino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chino",
    country: "United States",
    code: "US",
  },
  {
    city: "Chino Hills",
    country: "United States",
    code: "US",
  },
  {
    city: "Chintāmani",
    country: "India",
    code: "IN",
  },
  {
    city: "Chinú",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Chipata",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Chipindo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Chiquimula",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chiquimulilla",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chiquinquirá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Chīrāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Chirchiq",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Chirundu",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Chiryū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chisec",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Chishtian",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chishui",
    country: "China",
    code: "CN",
  },
  {
    city: "Chisinau",
    country: "Moldova",
    code: "MD",
  },
  {
    city: "Chistopol",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chita",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Chitaldrug",
    country: "India",
    code: "IN",
  },
  {
    city: "Chitembo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Chitose",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chittandikavundanūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Chittaurgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Chittoor",
    country: "India",
    code: "IN",
  },
  {
    city: "Chitungwiza",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Chivilcoy",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Chiyoda-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Chlef",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Chōfugaoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chokwé",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Cholet",
    country: "France",
    code: "FR",
  },
  {
    city: "Choloma",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Cholula de Rivadabia",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Choma",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Chơn Thành",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Chone",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Chŏngju",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Chongoroi",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Chongqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Chongshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Chongzuo",
    country: "China",
    code: "CN",
  },
  {
    city: "Chonthrhu",
    country: "India",
    code: "IN",
  },
  {
    city: "Chornomorsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Chorzów",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Choshi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chosica",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Chota",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Christchurch",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Christchurch",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Chuādānga",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Chula Vista",
    country: "United States",
    code: "US",
  },
  {
    city: "Chulucanas",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Chuncheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Chungju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Chunian",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Chūō-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Chust",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Chuxiong",
    country: "China",
    code: "CN",
  },
  {
    city: "Chuzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Chystiakove",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Ciamis",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cianjur",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cianorte",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cibinong",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cicero",
    country: "United States",
    code: "US",
  },
  {
    city: "Cidade de Nacala",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Ciego de Ávila",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Ciénaga",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Ciénaga de Oro",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Cienfuegos",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Cihanbeyli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cikupa",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cilacap",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cilegon",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cimahi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cimanggis",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cincinnati",
    country: "United States",
    code: "US",
  },
  {
    city: "Cinere",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cinisello Balsamo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Cipolletti",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Ciputat",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cirebon",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Cisauk",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Citrus Heights",
    country: "United States",
    code: "US",
  },
  {
    city: "City of Calamba",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "City of Isabela",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "City of Parañaque",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ciudad Acuña",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Apodaca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Arce",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Ciudad Benito Juárez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Bolívar",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Ciudad Choluteca",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Ciudad Cuauhtémoc",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad de Atlixco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad de Ceuta",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Ciudad de la Costa",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Ciudad de Melilla",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Ciudad del Carmen",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad del Este",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Ciudad General Belgrano",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Ciudad General Escobedo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Guayana",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Ciudad Guzmán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Hidalgo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Lázaro Cárdenas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Lerdo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad López Mateos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Madero",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Mante",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Nezahualcóyotl",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Obregón",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Ojeda",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Ciudad Real",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Ciudad Río Bravo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Sandino",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Ciudad Santa Catarina",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Valles",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudad Victoria",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ciudadela",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Civitavecchia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Çivril",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cizre",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Çınar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Clacton-on-Sea",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Clamart",
    country: "France",
    code: "FR",
  },
  {
    city: "Clarington",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Clarkstown",
    country: "United States",
    code: "US",
  },
  {
    city: "Clarksville",
    country: "United States",
    code: "US",
  },
  {
    city: "Claveria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Clay",
    country: "United States",
    code: "US",
  },
  {
    city: "Clearwater",
    country: "United States",
    code: "US",
  },
  {
    city: "Clermont-Ferrand",
    country: "France",
    code: "FR",
  },
  {
    city: "Cleveland",
    country: "United States",
    code: "US",
  },
  {
    city: "Cleveland",
    country: "United States",
    code: "US",
  },
  {
    city: "Clichy",
    country: "France",
    code: "FR",
  },
  {
    city: "Clifton",
    country: "United States",
    code: "US",
  },
  {
    city: "Clorinda",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Closepet",
    country: "India",
    code: "IN",
  },
  {
    city: "Clovis",
    country: "United States",
    code: "US",
  },
  {
    city: "Cluj-Napoca",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Coacalco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Coari",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Coatepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Coatepeque",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Coatzacoalcos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cobán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Cochabamba",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Cocody",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Coconut Creek",
    country: "United States",
    code: "US",
  },
  {
    city: "Codó",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Coeur d'Alene",
    country: "United States",
    code: "US",
  },
  {
    city: "Cogan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Coimbatore",
    country: "India",
    code: "IN",
  },
  {
    city: "Coimbra",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Cojutepeque",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Colatina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Colchester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Colima",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Colina",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Collado-Villalba",
    country: "Spain",
    code: "ES",
  },
  {
    city: "College Station",
    country: "United States",
    code: "US",
  },
  {
    city: "Collierville",
    country: "United States",
    code: "US",
  },
  {
    city: "Colmar",
    country: "France",
    code: "FR",
  },
  {
    city: "Colmenar Viejo",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Cologne",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Colomba",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Colombes",
    country: "France",
    code: "FR",
  },
  {
    city: "Colombo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Colombo",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Colón",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Colón",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Colón",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Colonie",
    country: "United States",
    code: "US",
  },
  {
    city: "Colorado Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Colton",
    country: "United States",
    code: "US",
  },
  {
    city: "Columbia",
    country: "United States",
    code: "US",
  },
  {
    city: "Columbia",
    country: "United States",
    code: "US",
  },
  {
    city: "Columbia",
    country: "United States",
    code: "US",
  },
  {
    city: "Columbus",
    country: "United States",
    code: "US",
  },
  {
    city: "Columbus",
    country: "United States",
    code: "US",
  },
  {
    city: "Columbus",
    country: "United States",
    code: "US",
  },
  {
    city: "Comalcalco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Comayagua",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Comayagüela",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Comilla",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Comitán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Comitancillo",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Commerce City",
    country: "United States",
    code: "US",
  },
  {
    city: "Como",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Comodoro Rivadavia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Companiganj",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Compostela",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Compton",
    country: "United States",
    code: "US",
  },
  {
    city: "Conakry",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Conceição do Coité",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Concepcion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Concepción",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Concepción del Uruguay",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Concepción Tutuapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Conchalí",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Concord",
    country: "United States",
    code: "US",
  },
  {
    city: "Concord",
    country: "United States",
    code: "US",
  },
  {
    city: "Concordia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Concórdia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Conda",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Conjeeveram",
    country: "India",
    code: "IN",
  },
  {
    city: "Conroe",
    country: "United States",
    code: "US",
  },
  {
    city: "Conselheiro Lafaiete",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Consolacion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Consolación del Sur",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Constanţa",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Constantine",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Constanza",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Consuelito",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Contagem",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Contai",
    country: "India",
    code: "IN",
  },
  {
    city: "Contramaestre",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Conway",
    country: "United States",
    code: "US",
  },
  {
    city: "Coon Rapids",
    country: "United States",
    code: "US",
  },
  {
    city: "Coonoor",
    country: "India",
    code: "IN",
  },
  {
    city: "Copacabana",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Copenhagen",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Copiapó",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Coquimbo",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Coquitlam",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Coral Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Corbeil-Essonnes",
    country: "France",
    code: "FR",
  },
  {
    city: "Corby",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Córdoba",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Córdoba",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Córdoba",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Cork",
    country: "Ireland",
    code: "IE",
  },
  {
    city: "Çorlu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cornillon",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Coro",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Coroatá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Coron",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Corona",
    country: "United States",
    code: "US",
  },
  {
    city: "Coronel",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Coronel Fabriciano",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Coronel Oviedo",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Corozal",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Corpus Christi",
    country: "United States",
    code: "US",
  },
  {
    city: "Corrientes",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Cortazar",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Çorum",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Corumbá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Coruripe",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Corvallis",
    country: "United States",
    code: "US",
  },
  {
    city: "Cosamaloapan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cosenza",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Cosmópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cosquín",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Costa Mesa",
    country: "United States",
    code: "US",
  },
  {
    city: "Cotabato",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cotia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cotonou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Cottbus",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Cotuí",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Council Bluffs",
    country: "United States",
    code: "US",
  },
  {
    city: "Country Club",
    country: "United States",
    code: "US",
  },
  {
    city: "Courbevoic",
    country: "France",
    code: "FR",
  },
  {
    city: "Coventry",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Covilhã",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Covina",
    country: "United States",
    code: "US",
  },
  {
    city: "Cox’s Bāzār",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Coyhaique",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Cozumel",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Craiova",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Cranbourne",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Cranston",
    country: "United States",
    code: "US",
  },
  {
    city: "Crateús",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Crato",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Crato",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Crawley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Cremona",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Créteil",
    country: "France",
    code: "FR",
  },
  {
    city: "Crewe",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Criciúma",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Croix-des-Bouquets",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Crosby",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Crotone",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Croydon",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Cruz Alta",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cruz das Almas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cruz del Eje",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Cruzeiro do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cúa",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Cửa Lô",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Cuamba",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Cuango",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cuauhtémoc",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cuautitlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cuautitlán Izcalli",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cuautla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cubal",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cubatão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cubulco",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Cúcuta",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Cuddalore",
    country: "India",
    code: "IN",
  },
  {
    city: "Cuddapah",
    country: "India",
    code: "IN",
  },
  {
    city: "Cuemba",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cuenca",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Cuenca",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Cuernavaca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cui’erzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Cuiabá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cuilco",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Cuito",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cukai",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Culiacán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cumaná",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Cumanayagua",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Cumbernauld",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Çumra",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Cunduacán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Cuneo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Cunhinga",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cupang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cupertino",
    country: "United States",
    code: "US",
  },
  {
    city: "Curepipe",
    country: "Mauritius",
    code: "MU",
  },
  {
    city: "Curicó",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Curitiba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Curug",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Curvelo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Cuscatancingo",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Cusco",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Cutervo",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Cuttack",
    country: "India",
    code: "IN",
  },
  {
    city: "Cuvelai",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Cuyahoga Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Cuyapo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Cuyotenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Cypress",
    country: "United States",
    code: "US",
  },
  {
    city: "Częstochowa",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Da’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Daanbantayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dabou",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Dabra",
    country: "India",
    code: "IN",
  },
  {
    city: "Dąbrowa Górnicza",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Dadu",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Dadukou",
    country: "China",
    code: "CN",
  },
  {
    city: "Daegu",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Daejeon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Daet",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dagana",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Dagenham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Dagupan City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dahuaishu",
    country: "China",
    code: "CN",
  },
  {
    city: "Dahūk",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Daisen",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Daitōchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Daiwanishi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Dakar",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Dalaguete",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dale City",
    country: "United States",
    code: "US",
  },
  {
    city: "Dali",
    country: "China",
    code: "CN",
  },
  {
    city: "Dali",
    country: "China",
    code: "CN",
  },
  {
    city: "Dalian",
    country: "China",
    code: "CN",
  },
  {
    city: "Daljā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Dallas",
    country: "United States",
    code: "US",
  },
  {
    city: "Daloa",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Dalton",
    country: "United States",
    code: "US",
  },
  {
    city: "Daltonganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Daly City",
    country: "United States",
    code: "US",
  },
  {
    city: "Dam Dam",
    country: "India",
    code: "IN",
  },
  {
    city: "Damanhūr",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Damascus",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Damaturu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Damboa",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Dambulla",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Dāmghān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Damietta",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Damoh",
    country: "India",
    code: "IN",
  },
  {
    city: "Danao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Danbury",
    country: "United States",
    code: "US",
  },
  {
    city: "Dandarah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Dandeli",
    country: "India",
    code: "IN",
  },
  {
    city: "Dandong",
    country: "China",
    code: "CN",
  },
  {
    city: "Dangbo",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Dangila",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Dangyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Danjiangkou",
    country: "China",
    code: "CN",
  },
  {
    city: "Danlí",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Danyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Danzao",
    country: "China",
    code: "CN",
  },
  {
    city: "Daoukro",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Dapaong",
    country: "Togo",
    code: "TG",
  },
  {
    city: "Dapitan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Daqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Dar el Beïda",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Dar es Salaam",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Dar Naim",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Dar‘ā",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Daraga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dārayyā",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Darbhanga",
    country: "India",
    code: "IN",
  },
  {
    city: "Darhan",
    country: "Mongolia",
    code: "MN",
  },
  {
    city: "Darhan",
    country: "Mongolia",
    code: "MN",
  },
  {
    city: "Darjeeling",
    country: "India",
    code: "IN",
  },
  {
    city: "Darlington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Darmstadt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Darnah",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Dartford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Darwin",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Dāsarhalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Dashiqiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Dasmariñas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Daşoguz",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Dassa-Zoumé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Datang",
    country: "China",
    code: "CN",
  },
  {
    city: "Date",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Datia",
    country: "India",
    code: "IN",
  },
  {
    city: "Daudnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Daugavpils",
    country: "Latvia",
    code: "LV",
  },
  {
    city: "Dauis",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Daule",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Davangere",
    country: "India",
    code: "IN",
  },
  {
    city: "Davao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Davenport",
    country: "United States",
    code: "US",
  },
  {
    city: "David",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Davie",
    country: "United States",
    code: "US",
  },
  {
    city: "Davis",
    country: "United States",
    code: "US",
  },
  {
    city: "Dawei",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Daxincun",
    country: "China",
    code: "CN",
  },
  {
    city: "Dayan",
    country: "China",
    code: "CN",
  },
  {
    city: "Daye",
    country: "China",
    code: "CN",
  },
  {
    city: "Dayin",
    country: "China",
    code: "CN",
  },
  {
    city: "Daying",
    country: "China",
    code: "CN",
  },
  {
    city: "Dayong",
    country: "China",
    code: "CN",
  },
  {
    city: "Dayr al Balaḩ",
    country: "Gaza Strip",
    code: "XG",
  },
  {
    city: "Dayr az Zawr",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Dayton",
    country: "United States",
    code: "US",
  },
  {
    city: "Daytona Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Dazaifu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Dazhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Dearborn",
    country: "United States",
    code: "US",
  },
  {
    city: "Dearborn Heights",
    country: "United States",
    code: "US",
  },
  {
    city: "Debre Birhan",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Debre Mark’os",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Debre Tabor",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Debre Zeyit",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Debrecen",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Decatur",
    country: "United States",
    code: "US",
  },
  {
    city: "Decatur",
    country: "United States",
    code: "US",
  },
  {
    city: "Dédougou",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Deerfield Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Dehdasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Dehra Dūn",
    country: "India",
    code: "IN",
  },
  {
    city: "Dehri",
    country: "India",
    code: "IN",
  },
  {
    city: "Dehui",
    country: "China",
    code: "CN",
  },
  {
    city: "DeKalb",
    country: "United States",
    code: "US",
  },
  {
    city: "Delano",
    country: "United States",
    code: "US",
  },
  {
    city: "Delft",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Delgado",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Delhi",
    country: "India",
    code: "IN",
  },
  {
    city: "Delhi Cantonment",
    country: "India",
    code: "IN",
  },
  {
    city: "Delicias",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Delmas",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Delmenhorst",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Delmiro Gouveia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Delray Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Delta",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Deltona",
    country: "United States",
    code: "US",
  },
  {
    city: "Den Helder",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Denan",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Dengtacun",
    country: "China",
    code: "CN",
  },
  {
    city: "Dengtalu",
    country: "China",
    code: "CN",
  },
  {
    city: "Denizli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Denov",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Denpasar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Denton",
    country: "United States",
    code: "US",
  },
  {
    city: "Denver",
    country: "United States",
    code: "US",
  },
  {
    city: "Deo",
    country: "India",
    code: "IN",
  },
  {
    city: "Deoghar",
    country: "India",
    code: "IN",
  },
  {
    city: "Depok",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Dera Ghazi Khan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Dera Ismail Khan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Derbent",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Derby",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Déressia",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Derik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Derry",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Des Moines",
    country: "United States",
    code: "US",
  },
  {
    city: "Des Plaines",
    country: "United States",
    code: "US",
  },
  {
    city: "Desē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "DeSoto",
    country: "United States",
    code: "US",
  },
  {
    city: "Dessalines",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Dessau-Roßlau",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Detmold",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Detroit",
    country: "United States",
    code: "US",
  },
  {
    city: "Deurne",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Deva",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Devakottai",
    country: "India",
    code: "IN",
  },
  {
    city: "Develi",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Deventer",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Dewās",
    country: "India",
    code: "IN",
  },
  {
    city: "Dewsbury",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Dexing",
    country: "China",
    code: "CN",
  },
  {
    city: "Deyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Dezfūl",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Dezhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Dhaka",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Dhamār",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Dhamtari",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhanbād",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhangaḍhi̇̄",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Dhār",
    country: "India",
    code: "IN",
  },
  {
    city: "Dharān",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Dhārāpuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Dharmapuri",
    country: "India",
    code: "IN",
  },
  {
    city: "Dharmavaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhenkānāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Dholka",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhone",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhorāji",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhuburi",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhūlia",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhuliān",
    country: "India",
    code: "IN",
  },
  {
    city: "Dhūri",
    country: "India",
    code: "IN",
  },
  {
    city: "Dĩ An",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Diadema",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Diamond Bar",
    country: "United States",
    code: "US",
  },
  {
    city: "Diaobingshancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Dias d’Ávila",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Dibrugarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Điện Biên Phủ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Dieppe",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Diffun",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Diglipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Digos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dijon",
    country: "France",
    code: "FR",
  },
  {
    city: "Dikirnis",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Dikwella South",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Dīla",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Dili",
    country: "Timor-Leste",
    code: "TL",
  },
  {
    city: "Dimāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Dimbokro",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Dimitrovgrad",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dinājpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Dinalupihan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dinapore",
    country: "India",
    code: "IN",
  },
  {
    city: "Dindigul",
    country: "India",
    code: "IN",
  },
  {
    city: "Dingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Dingxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Dingzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Dinslaken",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Diourbel",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Dipalpur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Diphu",
    country: "India",
    code: "IN",
  },
  {
    city: "Dipolog",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dire Dawa",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Diriamba",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Disūq",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Diu",
    country: "India",
    code: "IN",
  },
  {
    city: "Divinópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Divo",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Diyarbakır",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Djakotomé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Djelfa",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Djemmal",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Djibo",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Djibouti",
    country: "Djibouti",
    code: "DJ",
  },
  {
    city: "Djidja",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Djougou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Dmitrov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dnipro",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Do Gonbadān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Dobni Para",
    country: "India",
    code: "IN",
  },
  {
    city: "Doboj",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Dobrich",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Dod Ballāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Dodoma",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Doetinchem",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Doğubayazıt",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Doha",
    country: "Qatar",
    code: "QA",
  },
  {
    city: "Dolgoprudnyy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dolisie",
    country: "Congo (Brazzaville)",
    code: "CG",
  },
  {
    city: "Dolores Hidalgo Cuna de la Independencia Nacional",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Domaa-Ahenkro",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Dome",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Domodedovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dompu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Don Bosco",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Don Carlos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Don Torcuato",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Doncaster",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Dondo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Dondo",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Donetsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Đông Hà",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Đông Hòa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Đồng Hới",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Đồng Xoài",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Dongchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongducheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Dongguan",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongguan",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongguazhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Donghua",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongning",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongshan",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Dongsheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongsheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongtai",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Dongyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Donostia",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Donskoy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Donsol",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Doral",
    country: "United States",
    code: "US",
  },
  {
    city: "Dordrecht",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Dormagen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Dorsten",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Dortmund",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Dorūd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Dos Hermanas",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Dosquebradas",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Dosso",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Dothan",
    country: "United States",
    code: "US",
  },
  {
    city: "Douala",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Douliu",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Dourados",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Dover",
    country: "United States",
    code: "US",
  },
  {
    city: "Dover",
    country: "United States",
    code: "US",
  },
  {
    city: "Dovzhansk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Downers Grove",
    country: "United States",
    code: "US",
  },
  {
    city: "Downey",
    country: "United States",
    code: "US",
  },
  {
    city: "Drabar",
    country: "India",
    code: "IN",
  },
  {
    city: "Drammen",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Drancy",
    country: "France",
    code: "FR",
  },
  {
    city: "Dresden",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Drobeta-Turnu Severin",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Drohobych",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Drug",
    country: "India",
    code: "IN",
  },
  {
    city: "Drummondville",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Druzhkivka",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Dschang",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Dubai",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "Dublin",
    country: "United States",
    code: "US",
  },
  {
    city: "Dublin",
    country: "Ireland",
    code: "IE",
  },
  {
    city: "Dubna",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dubuque",
    country: "United States",
    code: "US",
  },
  {
    city: "Đức Phổ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Ducheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Dudley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Duekoué",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Duisburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Duitama",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Duluth",
    country: "United States",
    code: "US",
  },
  {
    city: "Dūmā",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Dumaguete City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dumai",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Dumangas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dumanjog",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Dumraon",
    country: "India",
    code: "IN",
  },
  {
    city: "Dundalk",
    country: "United States",
    code: "US",
  },
  {
    city: "Dundee",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Dundo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Dunedin",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Dunfermline",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Dungu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Dunhua",
    country: "China",
    code: "CN",
  },
  {
    city: "Dunhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Dunkerque",
    country: "France",
    code: "FR",
  },
  {
    city: "Dunwoody",
    country: "United States",
    code: "US",
  },
  {
    city: "Durango",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Durban",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Düren",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Durgāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Durham",
    country: "United States",
    code: "US",
  },
  {
    city: "Durrës",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Dushanbe",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Düsseldorf",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Dutse",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Duyun",
    country: "China",
    code: "CN",
  },
  {
    city: "Düzce",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Dzerzhinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dzerzhinskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Dzolokpuita",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "E’erguna",
    country: "China",
    code: "CN",
  },
  {
    city: "Eagan",
    country: "United States",
    code: "US",
  },
  {
    city: "Ealing",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "East Ham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "East Hartford",
    country: "United States",
    code: "US",
  },
  {
    city: "East Honolulu",
    country: "United States",
    code: "US",
  },
  {
    city: "East Kilbride",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "East London",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "East Los Angeles",
    country: "United States",
    code: "US",
  },
  {
    city: "East Orange",
    country: "United States",
    code: "US",
  },
  {
    city: "Eastbourne",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Eastleigh",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Eastvale",
    country: "United States",
    code: "US",
  },
  {
    city: "Eau Claire",
    country: "United States",
    code: "US",
  },
  {
    city: "Ebetsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ebina",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ebo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ébolowa",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Ecatepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Echague",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Echizen",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ed Daein",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ed Damazin",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ed Damer",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ede",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ede",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Edéa",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Eden Prairie",
    country: "United States",
    code: "US",
  },
  {
    city: "Edgware",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Edina",
    country: "United States",
    code: "US",
  },
  {
    city: "Edinburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Edinburgh",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Edirne",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Edison",
    country: "United States",
    code: "US",
  },
  {
    city: "Edmond",
    country: "United States",
    code: "US",
  },
  {
    city: "Edmonton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Edmonton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Edogawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Edremit",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Eger",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Eilat",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Eindhoven",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ejido",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Ejmiatsin",
    country: "Armenia",
    code: "AM",
  },
  {
    city: "Ejura",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ekibastuz",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "El Alto",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "El Bagre",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "El Banco",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "El Bayadh",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "El Bosque",
    country: "Chile",
    code: "CL",
  },
  {
    city: "El Cajon",
    country: "United States",
    code: "US",
  },
  {
    city: "El Carmen de Bolívar",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "El Centro",
    country: "United States",
    code: "US",
  },
  {
    city: "El Cerrito",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "El Ejido",
    country: "Spain",
    code: "ES",
  },
  {
    city: "El Estor",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "El Eulma",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "El Fasher",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "El Geneina",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "El Ghâzîyé",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "El Hamma",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "El Jadid",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "El Jem",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "El Kef",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "El Kelaa des Srarhna",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "El Limón",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "El Milia",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "El Minié",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "El Monte",
    country: "United States",
    code: "US",
  },
  {
    city: "El Nido",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "El Obeid",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "El Oued",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "El Palomar",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "El Paso",
    country: "United States",
    code: "US",
  },
  {
    city: "El Paso de Robles",
    country: "United States",
    code: "US",
  },
  {
    city: "El Prat de Llobregat",
    country: "Spain",
    code: "ES",
  },
  {
    city: "El Progreso",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "El Puerto de Santa María",
    country: "Spain",
    code: "ES",
  },
  {
    city: "El Rama",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "El Salvador",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "El Seibo",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "El Talar de Pacheco",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "El Tigre",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "El Tumbador",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "El Viejo",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "El Wak",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Elazığ",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Elbasan",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Elbistan",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Elbląg",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Elche",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Elda",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Eldersburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Eldorado",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Eldoret",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Elektrostal",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Elenga",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Elgin",
    country: "United States",
    code: "US",
  },
  {
    city: "Elista",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Elizabeth",
    country: "United States",
    code: "US",
  },
  {
    city: "Elizabethtown",
    country: "United States",
    code: "US",
  },
  {
    city: "Ełk",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Elk Grove",
    country: "United States",
    code: "US",
  },
  {
    city: "Elkhart",
    country: "United States",
    code: "US",
  },
  {
    city: "Ellesmere Port",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ellicott City",
    country: "United States",
    code: "US",
  },
  {
    city: "Ellore",
    country: "India",
    code: "IN",
  },
  {
    city: "Elmira",
    country: "United States",
    code: "US",
  },
  {
    city: "Elmshorn",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Eloy Alfaro",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Elyria",
    country: "United States",
    code: "US",
  },
  {
    city: "Embu-Guaçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Emmen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Emmiganūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Encarnación",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Encarnación de Díaz",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Encheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Encinitas",
    country: "United States",
    code: "US",
  },
  {
    city: "Ende",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Enerhodar",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Enfield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Engels",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Enid",
    country: "United States",
    code: "US",
  },
  {
    city: "Eniwa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Enschede",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ensenada",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Ensenada",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Entebbe",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Enterprise",
    country: "United States",
    code: "US",
  },
  {
    city: "Enugu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Envigado",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Epe",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Épinay-sur-Seine",
    country: "France",
    code: "FR",
  },
  {
    city: "Eqbālīyeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Erbaa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Erbil",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Erciş",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ercolano",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Érd",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Erdenet",
    country: "Mongolia",
    code: "MN",
  },
  {
    city: "Erechim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ereğli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ereğli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ērer Sātā",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Erfurt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ergani",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Erie",
    country: "United States",
    code: "US",
  },
  {
    city: "Erlangen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Erode",
    country: "India",
    code: "IN",
  },
  {
    city: "Errachidia",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Erzincan",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Erzurum",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Es Senia",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Esbjerg",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Escada",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Escalante",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Eschweiler",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Escondido",
    country: "United States",
    code: "US",
  },
  {
    city: "Escuintla",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Eséka",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Esenler",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Esenyurt",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Eşfahān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Esfarāyen",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Esher",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Eskilstuna",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Eskişehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Eslāmābād-e Gharb",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Eslāmshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Esmeraldas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Esmeraldas",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Espejo",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Esperanza",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Esperanza",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Esperanza",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Espinal",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Espoo",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Esquipulas",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Essaouira",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Essen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Esslingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Estancia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Estância",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Esteban Echeverría",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Esteio",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Estelí",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Estepona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Etah",
    country: "India",
    code: "IN",
  },
  {
    city: "Etāwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Etāwah",
    country: "India",
    code: "IN",
  },
  {
    city: "Euclides da Cunha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Eugene",
    country: "United States",
    code: "US",
  },
  {
    city: "Euless",
    country: "United States",
    code: "US",
  },
  {
    city: "Euriápolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Euskirchen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Evanston",
    country: "United States",
    code: "US",
  },
  {
    city: "Evansville",
    country: "United States",
    code: "US",
  },
  {
    city: "Everett",
    country: "United States",
    code: "US",
  },
  {
    city: "Évora",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Évosmos",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Évry",
    country: "France",
    code: "FR",
  },
  {
    city: "Exeter",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ezhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Ezpeleta",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Facatativá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Fada Ngourma",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Faenza",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Fafe",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Fairbanks",
    country: "United States",
    code: "US",
  },
  {
    city: "Fairfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Fairfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Faisalabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Faīẕābād",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Fajardo",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Fall River",
    country: "United States",
    code: "US",
  },
  {
    city: "Famalicão",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Fancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Fangchenggang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fangting",
    country: "China",
    code: "CN",
  },
  {
    city: "Fano",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Fanyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fanzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fāqūs",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Farāh",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Faranah",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Fardīs",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Farg‘ona",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Fargo",
    country: "United States",
    code: "US",
  },
  {
    city: "Farīdābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Farīdkot",
    country: "India",
    code: "IN",
  },
  {
    city: "Farīdpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Farmington Hills",
    country: "United States",
    code: "US",
  },
  {
    city: "Farnborough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Faro",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Farroupilha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Farrukhābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Farshūţ",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Fasā",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Fatehābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Fatehpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Fatsa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Fatwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Fayetteville",
    country: "United States",
    code: "US",
  },
  {
    city: "Fayetteville",
    country: "United States",
    code: "US",
  },
  {
    city: "Fāzilka",
    country: "India",
    code: "IN",
  },
  {
    city: "Federal Way",
    country: "United States",
    code: "US",
  },
  {
    city: "Fedosiia",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Feicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Feira de Santana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Felgueiras",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Felipe Carrillo Puerto",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Feltham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Fengcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Fengcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Fenggang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fenglu",
    country: "China",
    code: "CN",
  },
  {
    city: "Fengning",
    country: "China",
    code: "CN",
  },
  {
    city: "Fengyicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Feni",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Fenyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Ferfer",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Ferizaj",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Ferkessédougou",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Fernando de la Mora",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Fernandópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ferozepore",
    country: "India",
    code: "IN",
  },
  {
    city: "Ferrara",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Ferraz de Vasconcelos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ferrol",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Fès",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Fethiye",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Fianarantsoa",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Fier",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Figueira da Foz",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Finchley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Finglas",
    country: "Ireland",
    code: "IE",
  },
  {
    city: "Finote Selam",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Fishers",
    country: "United States",
    code: "US",
  },
  {
    city: "Fiumicino",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Flagstaff",
    country: "United States",
    code: "US",
  },
  {
    city: "Flensburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Flint",
    country: "United States",
    code: "US",
  },
  {
    city: "Florence",
    country: "United States",
    code: "US",
  },
  {
    city: "Florence",
    country: "United States",
    code: "US",
  },
  {
    city: "Florence",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Florence-Graham",
    country: "United States",
    code: "US",
  },
  {
    city: "Florencia",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Florencio Varela",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Florencio Varela",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Flores",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Floriano",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Florianópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Florida",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Florida",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Florida",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Floridablanca",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Floridablanca",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Florin",
    country: "United States",
    code: "US",
  },
  {
    city: "Florissant",
    country: "United States",
    code: "US",
  },
  {
    city: "Flower Mound",
    country: "United States",
    code: "US",
  },
  {
    city: "Fnidq",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Focşani",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Foggia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Foligno",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Folkestone",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Folsom",
    country: "United States",
    code: "US",
  },
  {
    city: "Fontana",
    country: "United States",
    code: "US",
  },
  {
    city: "Fontenay-sous-Bois",
    country: "France",
    code: "FR",
  },
  {
    city: "Forbesganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Forest",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Forlì",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Formiga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Formosa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Formosa",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Fort Collins",
    country: "United States",
    code: "US",
  },
  {
    city: "Fort Lauderdale",
    country: "United States",
    code: "US",
  },
  {
    city: "Fort Myers",
    country: "United States",
    code: "US",
  },
  {
    city: "Fort Portal",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Fort Smith",
    country: "United States",
    code: "US",
  },
  {
    city: "Fort Wayne",
    country: "United States",
    code: "US",
  },
  {
    city: "Fort Worth",
    country: "United States",
    code: "US",
  },
  {
    city: "Fort-de-France",
    country: "Martinique",
    code: "MQ",
  },
  {
    city: "Fortaleza",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Foshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Fouchana",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Foumban",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Foumbot",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Fountain Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Fountainebleau",
    country: "United States",
    code: "US",
  },
  {
    city: "Foz do Iguaçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Fraijanes",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Framingham",
    country: "United States",
    code: "US",
  },
  {
    city: "Franca",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Franceville",
    country: "Gabon",
    code: "GA",
  },
  {
    city: "Francisco Beltrão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Francisco I. Madero",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Francisco Morato",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Francistown",
    country: "Botswana",
    code: "BW",
  },
  {
    city: "Franco da Rocha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Frankfurt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Frankfurt (Oder)",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Franklin",
    country: "United States",
    code: "US",
  },
  {
    city: "Franklin",
    country: "United States",
    code: "US",
  },
  {
    city: "Fray Bartolomé de Las Casas",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Frechen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Frederick",
    country: "United States",
    code: "US",
  },
  {
    city: "Fredericksburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Fredericton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Fredrikstad",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Freetown",
    country: "Sierra Leone",
    code: "SL",
  },
  {
    city: "Freiburg im Breisgau",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Fréjus",
    country: "France",
    code: "FR",
  },
  {
    city: "Fremont",
    country: "United States",
    code: "US",
  },
  {
    city: "Fresnillo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Fresno",
    country: "United States",
    code: "US",
  },
  {
    city: "Friedrichshafen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Frisco",
    country: "United States",
    code: "US",
  },
  {
    city: "Frontera",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Frutal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Fryazino",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Frýdek-Místek",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Fu’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuchū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fuchū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fuding",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuefuki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fuengirola",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Fuenlabrada",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Fugangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Fugu",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuji",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fujiidera",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fujimi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fujimino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fujin",
    country: "China",
    code: "CN",
  },
  {
    city: "Fujioka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fujisawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fujita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fukayachō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukuchiyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukui",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukuoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukuroi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukushima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukutsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fukuyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fulda",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Fulgāzi",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Fulham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Fullerton",
    country: "United States",
    code: "US",
  },
  {
    city: "Funato",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Funchal",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Fundación",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Funing",
    country: "China",
    code: "CN",
  },
  {
    city: "Funtua",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Funza",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Fuqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Fürth",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Fusagasugá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Fushun",
    country: "China",
    code: "CN",
  },
  {
    city: "Fussa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Fuxin",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Fuzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Fyzābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Gaalkacyo",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Gabela",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Gabès",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Gaborone",
    country: "Botswana",
    code: "BW",
  },
  {
    city: "Gabrovo",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Gadag",
    country: "India",
    code: "IN",
  },
  {
    city: "Gādarwāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Gadda Madiral",
    country: "India",
    code: "IN",
  },
  {
    city: "Gadwāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Gafsa",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Gagnoa",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Gaibandha",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Gainesville",
    country: "United States",
    code: "US",
  },
  {
    city: "Gainesville",
    country: "United States",
    code: "US",
  },
  {
    city: "Gaithersburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Gaizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Galaţi",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Galátsi",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Galgamuwa",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Gallarate",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Galle",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Galveston",
    country: "United States",
    code: "US",
  },
  {
    city: "Galway",
    country: "Ireland",
    code: "IE",
  },
  {
    city: "Gamagōri",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Gampaha",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Ganda",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Gandajika",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Gāndhīdhām",
    country: "India",
    code: "IN",
  },
  {
    city: "Gandía",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Gangammapeta",
    country: "India",
    code: "IN",
  },
  {
    city: "Gangānagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Gangārāmpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Gangāwati",
    country: "India",
    code: "IN",
  },
  {
    city: "Gangneung",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gangtok",
    country: "India",
    code: "IN",
  },
  {
    city: "Gannan",
    country: "China",
    code: "CN",
  },
  {
    city: "Ganthier",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Ganzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Gaobeidian",
    country: "China",
    code: "CN",
  },
  {
    city: "Gaomi",
    country: "China",
    code: "CN",
  },
  {
    city: "Gaoping",
    country: "China",
    code: "CN",
  },
  {
    city: "Gaoping",
    country: "China",
    code: "CN",
  },
  {
    city: "Gaoyou",
    country: "China",
    code: "CN",
  },
  {
    city: "Gaozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Gapan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Garanhuns",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Garbsen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "García",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Garden Grove",
    country: "United States",
    code: "US",
  },
  {
    city: "Gardena",
    country: "United States",
    code: "US",
  },
  {
    city: "Gardēz",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Garín",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Garissa",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Garland",
    country: "United States",
    code: "US",
  },
  {
    city: "Garoowe",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Garoua",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Garulia",
    country: "India",
    code: "IN",
  },
  {
    city: "Garut",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Gary",
    country: "United States",
    code: "US",
  },
  {
    city: "Garzón",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Gashua",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Gaspar",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Gastonia",
    country: "United States",
    code: "US",
  },
  {
    city: "Gatchina",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gateshead",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Gatineau",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Gattaran",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Gaura",
    country: "India",
    code: "IN",
  },
  {
    city: "Gävle",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Gaya",
    country: "India",
    code: "IN",
  },
  {
    city: "Gaza",
    country: "Gaza Strip",
    code: "XG",
  },
  {
    city: "Gaziantep",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Gāzipura",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Gbadolite",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Gbawe",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Gdańsk",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Gdynia",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Gebze",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Gedaref",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Geelong",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Geita",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Gela",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Gelendzhik",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gelsenkirchen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gemena",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "General Mariano Alvarez",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "General Pico",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "General Roca",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "General Rodríguez",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "General Santos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "General Tinio",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "General Trias",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Geneva",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Gengzhuangqiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Genhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Genk",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Genoa",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Gent",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "George",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "George Town",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Georgetown",
    country: "United States",
    code: "US",
  },
  {
    city: "Georgetown",
    country: "Guyana",
    code: "GY",
  },
  {
    city: "Georgiyevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gera",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gerli",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Germantown",
    country: "United States",
    code: "US",
  },
  {
    city: "Germiston",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Gerona",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Getafe",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Gexianzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Gəncə",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Ghāndīnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Gharbara",
    country: "India",
    code: "IN",
  },
  {
    city: "Ghardaïa",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Ghardimaou",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Gharyān",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Ghātāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Ghāziābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Ghazīpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Ghaznī",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Ghorāhī",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Ghōriyān",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Ghŭlakandoz",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Ghulja",
    country: "China",
    code: "CN",
  },
  {
    city: "Gia Nghĩa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Giá Rai",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Gibara",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Gibraltar",
    country: "Gibraltar",
    code: "GI",
  },
  {
    city: "Gießen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gifu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Gijón",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Gilbert",
    country: "United States",
    code: "US",
  },
  {
    city: "Gillingham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Gilroy",
    country: "United States",
    code: "US",
  },
  {
    city: "Gimpo",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gingoog",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ginowan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Girardot",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Girardota",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Giresun",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Girón",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Girona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Gisenyi",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Gitarama",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Gitega",
    country: "Burundi",
    code: "BI",
  },
  {
    city: "Giugliano in Campania",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Giurgiu",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Givatayim",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Giyon",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Giza",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Gjakovë",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Gjilan",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Gladbeck",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Glan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Glasgow",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Glazoué",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Glazov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Glen Burnie",
    country: "United States",
    code: "US",
  },
  {
    city: "Glendale",
    country: "United States",
    code: "US",
  },
  {
    city: "Glendale",
    country: "United States",
    code: "US",
  },
  {
    city: "Glendora",
    country: "United States",
    code: "US",
  },
  {
    city: "Glens Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Glew",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Gliwice",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Gllogovc",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Głogów",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Gloria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Gloucester",
    country: "United States",
    code: "US",
  },
  {
    city: "Gloucester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Glyfáda",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Gniezno",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Gò Công",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Goa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Goālpāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Goalundo Ghāt",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Gobernador Gálvez",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Gobindgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Godalming",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Godāwari̇̄",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Godē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Godhra",
    country: "India",
    code: "IN",
  },
  {
    city: "Godinlabe",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Godomè",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Godoy Cruz",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Gogounou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Gogrial",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Gohadi",
    country: "India",
    code: "IN",
  },
  {
    city: "Gohna",
    country: "India",
    code: "IN",
  },
  {
    city: "Goiana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Goianésia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Goiânia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Gojra",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Gokāk",
    country: "India",
    code: "IN",
  },
  {
    city: "Gölcük",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Gold Coast",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Golmeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Golmud",
    country: "China",
    code: "CN",
  },
  {
    city: "Goma",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Gombe",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Gombong",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Gómez Palacio",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Gonaïves",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Gonbad-e Kāvūs",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Gonder",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Gondiā",
    country: "India",
    code: "IN",
  },
  {
    city: "Gondomar",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Gongzhuling",
    country: "China",
    code: "CN",
  },
  {
    city: "González Catán",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Goodyear",
    country: "United States",
    code: "US",
  },
  {
    city: "Gopālganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Gopālpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Gopichettipālaiyam",
    country: "India",
    code: "IN",
  },
  {
    city: "Göppingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gorakhpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Gorgān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Görlitz",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gorno-Altaysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gorontalo",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Gorzów Wielkopolski",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Goshogawara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Goslar",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gosport",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Gotenba",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Goth Tando Sumro",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Gothenburg",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Göttingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gouda",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Governador Valadares",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Goya",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Goyang",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Goz-Beida",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Graaff-Reinet",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Gracias",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Grahamstown",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Grajaú",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Granada",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Granada",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Granadilla de Abona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Granby",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Grand Bourg",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Grand Forks",
    country: "United States",
    code: "US",
  },
  {
    city: "Grand Island",
    country: "United States",
    code: "US",
  },
  {
    city: "Grand Junction",
    country: "United States",
    code: "US",
  },
  {
    city: "Grand Prairie",
    country: "United States",
    code: "US",
  },
  {
    city: "Grand Rapids",
    country: "United States",
    code: "US",
  },
  {
    city: "Grand-Bassam",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Grand-Popo",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Grande Prairie",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Granja",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Granollers",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Grapevine",
    country: "United States",
    code: "US",
  },
  {
    city: "Gravatá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Gravataí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Gravesend",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Grays",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Graz",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Great Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Greece",
    country: "United States",
    code: "US",
  },
  {
    city: "Greeley",
    country: "United States",
    code: "US",
  },
  {
    city: "Green Bay",
    country: "United States",
    code: "US",
  },
  {
    city: "Greenburgh",
    country: "United States",
    code: "US",
  },
  {
    city: "Greensboro",
    country: "United States",
    code: "US",
  },
  {
    city: "Greenville",
    country: "United States",
    code: "US",
  },
  {
    city: "Greenville",
    country: "United States",
    code: "US",
  },
  {
    city: "Greenwich",
    country: "United States",
    code: "US",
  },
  {
    city: "Greenwood",
    country: "United States",
    code: "US",
  },
  {
    city: "Greifswald",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Grenoble",
    country: "France",
    code: "FR",
  },
  {
    city: "Gresham",
    country: "United States",
    code: "US",
  },
  {
    city: "Grevenbroich",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Grimsby",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Groningen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Grosseto",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Groznyy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Grudziądz",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Guacara",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Guadalajara",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Guadalajara",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guadalajara de Buga",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Guadalupe",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guadalupe",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guagua",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Guaíba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guáimaro",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Gualeguaychú",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Guamúchil",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guanabacoa",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Guanajuato",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guanambi",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guanare",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Guang’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Guangming",
    country: "China",
    code: "CN",
  },
  {
    city: "Guangping",
    country: "China",
    code: "CN",
  },
  {
    city: "Guangshui",
    country: "China",
    code: "CN",
  },
  {
    city: "Guangyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Guangzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Guankou",
    country: "China",
    code: "CN",
  },
  {
    city: "Guankou",
    country: "China",
    code: "CN",
  },
  {
    city: "Guantánamo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Guapimirim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guarabira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guarapari",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guarapuava",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guaratinguetá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guarenas",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Guarne",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Guarujá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guarulhos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Guasave",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guasavito",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guasdualito",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Guatemala City",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Guatire",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Guayama",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Guayaquil",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Guaymas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Guaynabo",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Gubat",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Gubeng",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Gubkin",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gūdalur",
    country: "India",
    code: "IN",
  },
  {
    city: "Gudermes",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gudivāda",
    country: "India",
    code: "IN",
  },
  {
    city: "Gudiyāttam",
    country: "India",
    code: "IN",
  },
  {
    city: "Gūdūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Guéckédou",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Guédiawaye",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Guelma",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Guelmim",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Guelph",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Guercif",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Guernica",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Guerra",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Guider",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Guigang",
    country: "China",
    code: "CN",
  },
  {
    city: "Guiglo",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Guiguinto",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Guihulñgan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Guildford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Guilin",
    country: "China",
    code: "CN",
  },
  {
    city: "Guimarães",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Guimba",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Güines",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Guinobatan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Guiping",
    country: "China",
    code: "CN",
  },
  {
    city: "Guisa",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Guiuan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Guixi",
    country: "China",
    code: "CN",
  },
  {
    city: "Guiyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Gujiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Gujranwala",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Gujrat",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Gukovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gulariyā",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Gulbarga",
    country: "India",
    code: "IN",
  },
  {
    city: "Gulfport",
    country: "United States",
    code: "US",
  },
  {
    city: "Guli",
    country: "China",
    code: "CN",
  },
  {
    city: "Guliston",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Gulou",
    country: "China",
    code: "CN",
  },
  {
    city: "Gulu",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Gumaca",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Gumlā",
    country: "India",
    code: "IN",
  },
  {
    city: "Gummersbach",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Gümüşhane",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Guna",
    country: "India",
    code: "IN",
  },
  {
    city: "Güngören",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Guntakal",
    country: "India",
    code: "IN",
  },
  {
    city: "Guntūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Gunungsitoli",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Gunupur",
    country: "India",
    code: "IN",
  },
  {
    city: "Gurabo al Medio",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Gurdāspur",
    country: "India",
    code: "IN",
  },
  {
    city: "Gurgaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Guri",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gurupi",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Gus’-Khrustal’nyy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Gusau",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Gutao",
    country: "China",
    code: "CN",
  },
  {
    city: "Gütersloh",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Guwāhāti",
    country: "India",
    code: "IN",
  },
  {
    city: "Guyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Guzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Gwacheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gwadar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Gwalior",
    country: "India",
    code: "IN",
  },
  {
    city: "Gwangju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gwangju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gwangmyeongni",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gwangyang",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gwelej",
    country: "Eritrea",
    code: "ER",
  },
  {
    city: "Gweru",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Gwoza",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Gyeongsan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Gyōda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Győr",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Gyumri",
    country: "Armenia",
    code: "AM",
  },
  {
    city: "Hà Giang",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hạ Long",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hà Tĩnh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Ḩā’il",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Haarlem",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Habaswein",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Habiganj",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Habikino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hābra",
    country: "India",
    code: "IN",
  },
  {
    city: "Hachinohe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hachiōji",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hacienda Heights",
    country: "United States",
    code: "US",
  },
  {
    city: "Hadano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hadera",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Haeju",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Hafizabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Ḩafr al Bāţin",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Hagen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hāgere Hiywet",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Hagerstown",
    country: "United States",
    code: "US",
  },
  {
    city: "Hagonoy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Hagonoy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Hải Dương",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hai’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Haicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Haifa",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Haikou",
    country: "China",
    code: "CN",
  },
  {
    city: "Hailin",
    country: "China",
    code: "CN",
  },
  {
    city: "Hailun",
    country: "China",
    code: "CN",
  },
  {
    city: "Haiphong",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hājīpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Hakkari",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Hakodate",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ḩalabjah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Haldia",
    country: "India",
    code: "IN",
  },
  {
    city: "Halesowen",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Half Way Tree",
    country: "Jamaica",
    code: "JM",
  },
  {
    city: "Halifax",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Halifax",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Hālīsahar",
    country: "India",
    code: "IN",
  },
  {
    city: "Halle",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Halmstad",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Halton Hills",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Ḩalwān",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Hamada",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hamadān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ḩamāh",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Hamamatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hamburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Hamburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hamden",
    country: "United States",
    code: "US",
  },
  {
    city: "Hämeenlinna",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Hameln",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hamhŭng",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Hamilton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hamilton",
    country: "United States",
    code: "US",
  },
  {
    city: "Hamilton",
    country: "United States",
    code: "US",
  },
  {
    city: "Hamilton",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Hamilton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Hamm",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hammamet",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Hammersmith",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hammond",
    country: "United States",
    code: "US",
  },
  {
    city: "Hammond",
    country: "United States",
    code: "US",
  },
  {
    city: "Hampton",
    country: "United States",
    code: "US",
  },
  {
    city: "Hamtic",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Hamura",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hanam",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Hanamaki Onsen",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hanau",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Hanchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Handa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Handan",
    country: "China",
    code: "CN",
  },
  {
    city: "Handeni",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Handwāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Hanford",
    country: "United States",
    code: "US",
  },
  {
    city: "Hangu",
    country: "China",
    code: "CN",
  },
  {
    city: "Hangzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Hannō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hannover",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hanoi",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hanover",
    country: "United States",
    code: "US",
  },
  {
    city: "Hānsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Hanumāngarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Hanyū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hanzhong",
    country: "China",
    code: "CN",
  },
  {
    city: "Hāora",
    country: "India",
    code: "IN",
  },
  {
    city: "Hāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Ḩaraḑ",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Harar",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Harare",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Harbin",
    country: "China",
    code: "CN",
  },
  {
    city: "Harda Khās",
    country: "India",
    code: "IN",
  },
  {
    city: "Hardenberg",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Hargeysa",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Haridwār",
    country: "India",
    code: "IN",
  },
  {
    city: "Harihar",
    country: "India",
    code: "IN",
  },
  {
    city: "Harlingen",
    country: "United States",
    code: "US",
  },
  {
    city: "Harlow",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Harpanahalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Harrisburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Harrisonburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Harrogate",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Harrow",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hartford",
    country: "United States",
    code: "US",
  },
  {
    city: "Hartlepool",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Harunabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Hashima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hashimoto",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hashtgerd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Hashtpar",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Hasilpur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Haskah Mēnah",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Haskovo",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Hassa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Hassan",
    country: "India",
    code: "IN",
  },
  {
    city: "Hassan Abdal",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Hasselt",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Hastings",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hasuda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hat Yai",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Hatay",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Hato Mayor",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Hatogaya-honchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hatsukaichi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hattiesburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Hattingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Havana",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Haverford",
    country: "United States",
    code: "US",
  },
  {
    city: "Haverhill",
    country: "United States",
    code: "US",
  },
  {
    city: "Hāveri",
    country: "India",
    code: "IN",
  },
  {
    city: "Havířov",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Hawthorne",
    country: "United States",
    code: "US",
  },
  {
    city: "Hayes",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hayward",
    country: "United States",
    code: "US",
  },
  {
    city: "Hazāribāgh",
    country: "India",
    code: "IN",
  },
  {
    city: "Hebi",
    country: "China",
    code: "CN",
  },
  {
    city: "Hebron",
    country: "West Bank",
    code: "XW",
  },
  {
    city: "Hechi",
    country: "China",
    code: "CN",
  },
  {
    city: "Heerhugowaard",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Heerlen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Hefei",
    country: "China",
    code: "CN",
  },
  {
    city: "Hegang",
    country: "China",
    code: "CN",
  },
  {
    city: "Heidelberg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Heihe",
    country: "China",
    code: "CN",
  },
  {
    city: "Heilbronn",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hejian",
    country: "China",
    code: "CN",
  },
  {
    city: "Hejin",
    country: "China",
    code: "CN",
  },
  {
    city: "Hekinan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Helixi",
    country: "China",
    code: "CN",
  },
  {
    city: "Helmond",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Helong",
    country: "China",
    code: "CN",
  },
  {
    city: "Helsingborg",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Helsinki",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Hemei",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Hemel Hempstead",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hemet",
    country: "United States",
    code: "US",
  },
  {
    city: "Hempstead",
    country: "United States",
    code: "US",
  },
  {
    city: "Henderson",
    country: "United States",
    code: "US",
  },
  {
    city: "Hendersonville",
    country: "United States",
    code: "US",
  },
  {
    city: "Hengbei",
    country: "China",
    code: "CN",
  },
  {
    city: "Hengelo",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Hengkou",
    country: "China",
    code: "CN",
  },
  {
    city: "Hengnan",
    country: "China",
    code: "CN",
  },
  {
    city: "Hengshui",
    country: "China",
    code: "CN",
  },
  {
    city: "Hengyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Hengzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Herāt",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Hereford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Herford",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hermosa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Hermosillo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Herne",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Herning",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Heroica Caborca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Heroica Guaymas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Heroica Matamoros",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Heroica Nogales",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Herriman",
    country: "United States",
    code: "US",
  },
  {
    city: "Herten",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hervey Bay",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Herẕliyya",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Heshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Heshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Hesperia",
    country: "United States",
    code: "US",
  },
  {
    city: "Heṭauḍā",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Heyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Heyunkeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Heze",
    country: "China",
    code: "CN",
  },
  {
    city: "Hezhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Hezuo",
    country: "China",
    code: "CN",
  },
  {
    city: "Hialeah",
    country: "United States",
    code: "US",
  },
  {
    city: "Hickory",
    country: "United States",
    code: "US",
  },
  {
    city: "Hidaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Higashi-Hiroshima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Higashi-Matsuyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Higashi-ōsaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Higashimurayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Higashiōmi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Higashiyamato",
    country: "Japan",
    code: "JP",
  },
  {
    city: "High Point",
    country: "United States",
    code: "US",
  },
  {
    city: "High Wycombe",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Highland",
    country: "United States",
    code: "US",
  },
  {
    city: "Highlands Ranch",
    country: "United States",
    code: "US",
  },
  {
    city: "Higüey",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Hihyā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Hikkaduwa",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Hikone",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hilden",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hildesheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Hillsboro",
    country: "United States",
    code: "US",
  },
  {
    city: "Hilongos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Hilton Head Island",
    country: "United States",
    code: "US",
  },
  {
    city: "Hilversum",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Himamaylan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Himatnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Himeji",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hinche",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Hindupur",
    country: "India",
    code: "IN",
  },
  {
    city: "Hinigaran",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Hino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hinthada",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Hirakata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hiratsuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hiriyūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Hirosaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hiroshima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hisar",
    country: "India",
    code: "IN",
  },
  {
    city: "Hīt",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Hita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hitachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hitachi-Naka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ho",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ho Chi Minh City",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hòa Bình",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hòa Thành",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hobart",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Hoboken",
    country: "United States",
    code: "US",
  },
  {
    city: "Hobyo",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Hod HaSharon",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Hoeryŏng",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Hoffman Estates",
    country: "United States",
    code: "US",
  },
  {
    city: "Hōfu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hohhot",
    country: "China",
    code: "CN",
  },
  {
    city: "Hohoe",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Hội An",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hoima",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Holguín",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Holland",
    country: "United States",
    code: "US",
  },
  {
    city: "Hollywood",
    country: "United States",
    code: "US",
  },
  {
    city: "Holon",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Homestead",
    country: "United States",
    code: "US",
  },
  {
    city: "Homosassa Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Homs",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Homyel’",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Honchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Honchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Hong Kong",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Hồng Ngự",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hong’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Hongjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Hongzhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Honiara",
    country: "Solomon Islands",
    code: "SB",
  },
  {
    city: "Honjō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Honmachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Honolulu",
    country: "United States",
    code: "US",
  },
  {
    city: "Hoofddorp",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Hoogeveen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Hoorn",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Hoover",
    country: "United States",
    code: "US",
  },
  {
    city: "Horad Barysaw",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Horad Zhodzina",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Horishni Plavni",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Horizon West",
    country: "United States",
    code: "US",
  },
  {
    city: "Horizonte",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Horlivka",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Hornchurch",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Horqueta",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Horsens",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Horsham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hortolândia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Hosa’ina",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Hosan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Hoshangābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Hospet",
    country: "India",
    code: "IN",
  },
  {
    city: "Hosūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Hotan",
    country: "China",
    code: "CN",
  },
  {
    city: "Houma",
    country: "United States",
    code: "US",
  },
  {
    city: "Houmt Souk",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Houndé",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Hounslow",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Houston",
    country: "United States",
    code: "US",
  },
  {
    city: "Houten",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Houzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Hove",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Hövsan",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Howell",
    country: "United States",
    code: "US",
  },
  {
    city: "Hpa-An",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Hradec Králové",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Hrazdan",
    country: "Armenia",
    code: "AM",
  },
  {
    city: "Hrodna",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Hsinchu",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Hua Hin",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Huacho",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Huadian",
    country: "China",
    code: "CN",
  },
  {
    city: "Huai’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaibei",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaihua",
    country: "China",
    code: "CN",
  },
  {
    city: "Huainan",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaishu",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaixiangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaiyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaiyin",
    country: "China",
    code: "CN",
  },
  {
    city: "Huajiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Hualien",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Huamantla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huambo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Huancayo",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Huanggang",
    country: "China",
    code: "CN",
  },
  {
    city: "Huanghua",
    country: "China",
    code: "CN",
  },
  {
    city: "Huanghuajie",
    country: "China",
    code: "CN",
  },
  {
    city: "Huanglongsi",
    country: "China",
    code: "CN",
  },
  {
    city: "Huangshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Huangshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Huangyan",
    country: "China",
    code: "CN",
  },
  {
    city: "Huaniu",
    country: "China",
    code: "CN",
  },
  {
    city: "Huanren",
    country: "China",
    code: "CN",
  },
  {
    city: "Huánuco",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Huaral",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Huaraz",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Huatusco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huauchinango",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huaycan",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Huayin",
    country: "China",
    code: "CN",
  },
  {
    city: "Huazangsi",
    country: "China",
    code: "CN",
  },
  {
    city: "Huazhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Huazhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Huddersfield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Huế",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Huebampo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huehuetenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Huehuetoca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huejutla de Reyes",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huelva",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Huesca",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Hugli",
    country: "India",
    code: "IN",
  },
  {
    city: "Hŭich’ŏn",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Huichang",
    country: "China",
    code: "CN",
  },
  {
    city: "Huili Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Huilong",
    country: "China",
    code: "CN",
  },
  {
    city: "Huishi",
    country: "China",
    code: "CN",
  },
  {
    city: "Huixquilucan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huixtla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Huizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Hujra Shah Muqim",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Hukou",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Hulin",
    country: "China",
    code: "CN",
  },
  {
    city: "Huludao",
    country: "China",
    code: "CN",
  },
  {
    city: "Humaitá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Humpata",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Hunchun",
    country: "China",
    code: "CN",
  },
  {
    city: "Hunedoara",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Hưng Yên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hunsūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Huntersville",
    country: "United States",
    code: "US",
  },
  {
    city: "Huntington",
    country: "United States",
    code: "US",
  },
  {
    city: "Huntington",
    country: "United States",
    code: "US",
  },
  {
    city: "Huntington Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Huntington Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Huntsville",
    country: "United States",
    code: "US",
  },
  {
    city: "Huolu",
    country: "China",
    code: "CN",
  },
  {
    city: "Hương Thủy",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Hương Trà",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Huozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Huquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Hurlingham",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Hürth",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Huwei",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Huzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Hwasu-dong",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Hyderābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Hyderabad City",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Hyères",
    country: "France",
    code: "FR",
  },
  {
    city: "Hyesan",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Hyosha",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Hyūga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iaşi",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Iba",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ibaan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ibadan",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ibagué",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Ibajay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ibaraki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ibarra",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Ibb",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Ibb",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Ibbenbüren",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ibirité",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ibitinga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ibiúna",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ibiza",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Ica",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Içara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ich’ŏn",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Ichalkaranji",
    country: "India",
    code: "IN",
  },
  {
    city: "Ichihara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ichikawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ichinomiya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ichinoseki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Icó",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Idah",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Idaho Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Idappādi",
    country: "India",
    code: "IN",
  },
  {
    city: "Idfū",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "İdil",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Idiofa",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Idkū",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Idlib",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Ifakara",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Ifanhim",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Iga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iganga",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Igarapé-Miri",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Igarassu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Igbanke",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Igboho",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Iğdır",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Iguaçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Iguala de la Independencia",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Iguatu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ihosy",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Iida",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iizuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ijebu-Ode",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Iju",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ijuí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ikare",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ikeda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ikeja",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ikoma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ikot Okoro",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Iksan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Ilagan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ile-Ife",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ilebo",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Ilesa",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ilford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ilhéus",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ilidža",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Iligan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ílion",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Ilioúpoli",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Ilkal",
    country: "India",
    code: "IN",
  },
  {
    city: "Ilo",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Ilobasco",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Ilobu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ilog",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Iloilo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ilorin",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Imabari",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Imam Qasim",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Imarichō-kō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Imerintsiatosika",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Imizuchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Imola",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Impasugong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Imperatriz",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Imphāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Imus",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ina",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Inagi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Inazawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Incheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "İncirliova",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Indaial",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Indaiatuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Indanan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Indang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Independence",
    country: "United States",
    code: "US",
  },
  {
    city: "Indianapolis",
    country: "United States",
    code: "US",
  },
  {
    city: "Indio",
    country: "United States",
    code: "US",
  },
  {
    city: "Indore",
    country: "India",
    code: "IN",
  },
  {
    city: "Indramayu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "İnegöl",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Inezgane",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Infanta",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Inglewood",
    country: "United States",
    code: "US",
  },
  {
    city: "Ingolstadt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ingrāj Bāzār",
    country: "India",
    code: "IN",
  },
  {
    city: "Inhambane",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Innsbruck",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Inowrocław",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Intibucá",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Inuyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Inzai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ioánnina",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Iowa City",
    country: "United States",
    code: "US",
  },
  {
    city: "Ipatinga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ipele",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ipetumodu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ipiales",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Ipil",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ipirá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ipoh",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Ipojuca",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ipswich",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Ipswich",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Iquique",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Iquitos",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Irákleio",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Īrānshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Irapuato",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Irati",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Irbid",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Irecê",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Iriga City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Iringa",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Irinjālakuda",
    country: "India",
    code: "IN",
  },
  {
    city: "Irkutsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Irondequoit",
    country: "United States",
    code: "US",
  },
  {
    city: "Irosin",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Irpin",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Iruma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Irún",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Irvine",
    country: "United States",
    code: "US",
  },
  {
    city: "Irving",
    country: "United States",
    code: "US",
  },
  {
    city: "Irvington",
    country: "United States",
    code: "US",
  },
  {
    city: "Isabela",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Isahaya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Isanlu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ise",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Isehara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iserlohn",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Isesaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iseyin",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Isfara",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Ishikari",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ishim",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ishimbay",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ishioka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ishizaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Isidro Casanova",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Isiro",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Iskandar",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "İskenderun",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Iskitim",
    country: "Russia",
    code: "RU",
  },
  {
    city: "İslahiye",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Islamabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Islāmābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Islāmpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Islington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Islip",
    country: "United States",
    code: "US",
  },
  {
    city: "Ismailia",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Isnā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Isparta",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Issy-les-Moulineaux",
    country: "France",
    code: "FR",
  },
  {
    city: "Istanbul",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Istaravshan",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Isulan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Itá",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Itabaiana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itabashi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Itaberaba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itabira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itaboraí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itabuna",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itacoatiara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itagüí",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Īṭahari̇̄",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Itaituba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itajaí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itajubá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itamaraju",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itami",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Itānagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Itanhaém",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapecerica da Serra",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapecuru Mirim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itaperuna",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapetinga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapetininga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapeva",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapevi",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapipoca",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itapira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itaquaquecetuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itararé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itārsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Itatiba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itauguá",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Itaúna",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itele",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Itō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Itogon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Itoman",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Itoshima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Itu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ituiutaba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itumbiara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itupeva",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Itupiranga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ituzaingó",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Ivano-Frankivsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Ivanovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ivanteyevka",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ivry-sur-Seine",
    country: "France",
    code: "FR",
  },
  {
    city: "Iwaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iwakuni",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iwamizawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iwata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Iwo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ixhuatlán de Madero",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ixmiquilpan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ixtaczoquitlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ixtahuacán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Ixtapaluca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ixtlahuaca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ixtlahuacán de los Membrillos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Izalco",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Izberbash",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Izhevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Izmail",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "İzmir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Iztapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Izumi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Izumiōtsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Izumisano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Izumo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Jabalpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Jabālyā",
    country: "Gaza Strip",
    code: "XG",
  },
  {
    city: "Jablah",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Jaboatão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jabuticabal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jacareí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jackson",
    country: "United States",
    code: "US",
  },
  {
    city: "Jackson",
    country: "United States",
    code: "US",
  },
  {
    city: "Jackson",
    country: "United States",
    code: "US",
  },
  {
    city: "Jackson",
    country: "United States",
    code: "US",
  },
  {
    city: "Jacksonville",
    country: "United States",
    code: "US",
  },
  {
    city: "Jacksonville",
    country: "United States",
    code: "US",
  },
  {
    city: "Jacmel",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Jacobabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jacobina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jacundá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jaen",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Jaén",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Jaén",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Jaffna",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Jaggayyapeta",
    country: "India",
    code: "IN",
  },
  {
    city: "Jagraon",
    country: "India",
    code: "IN",
  },
  {
    city: "Jaguaquara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jaguariúna",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jagüey Grande",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Jahrom",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Jaipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Jaisalmer",
    country: "India",
    code: "IN",
  },
  {
    city: "Jakarta",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Jalal-Abad",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "Jalālābād",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Jalalpur Jattan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jalandhar",
    country: "India",
    code: "IN",
  },
  {
    city: "Jalapa",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Jalapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Jalgaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Jalingo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Jālna",
    country: "India",
    code: "IN",
  },
  {
    city: "Jalor",
    country: "India",
    code: "IN",
  },
  {
    city: "Jalpāiguri",
    country: "India",
    code: "IN",
  },
  {
    city: "Jamaame",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Jamālpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Jamālpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Jambi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Jamjamāl",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Jammu",
    country: "India",
    code: "IN",
  },
  {
    city: "Jāmnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Jamshedpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Jamshoro",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jamūī",
    country: "India",
    code: "IN",
  },
  {
    city: "Jamundí",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Janakpur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Janaúba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jandira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Janesville",
    country: "United States",
    code: "US",
  },
  {
    city: "Jangaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Jangipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Janiuay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Januária",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Janzūr",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Jaorā",
    country: "India",
    code: "IN",
  },
  {
    city: "Japeri",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jarabacoa",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Jaraguá do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jaramānā",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Jaranwala",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jarash",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Jaru",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jasaan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Jaspur",
    country: "India",
    code: "IN",
  },
  {
    city: "Jastrzębie-Zdrój",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Jataí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jatani",
    country: "India",
    code: "IN",
  },
  {
    city: "Jaú",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jauharabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jaunpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Javānrūd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Jawhar",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Jaworzno",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Jayapura",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Jāzān",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Jean-Rabel",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Jeddah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Jeju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Jelenia Góra",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Jelgava",
    country: "Latvia",
    code: "LV",
  },
  {
    city: "Jember",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Jena",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Jendouba",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Jeonghae",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Jeonju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Jepara",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Jequié",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jerez de García Salinas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Jerez de la Frontera",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Jersey City",
    country: "United States",
    code: "US",
  },
  {
    city: "Jerusalem",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Jessore",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Jesús Menéndez",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Jette",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Jeypore",
    country: "India",
    code: "IN",
  },
  {
    city: "Jhang City",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jhānsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Jhārgrām",
    country: "India",
    code: "IN",
  },
  {
    city: "Jhārsugra",
    country: "India",
    code: "IN",
  },
  {
    city: "Jhelum",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Jhenida",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Ji-Paraná",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ji’an",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Ji’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Ji’an Shi",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiāganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Jiaji",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiamusi",
    country: "China",
    code: "CN",
  },
  {
    city: "Jian’ou",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiangguanchi",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiangjiafan",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiangmen",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiangna",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiangshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Jianguang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiangyin",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiannan",
    country: "China",
    code: "CN",
  },
  {
    city: "Jianshe",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiantang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiaojiangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiaozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiaozuo",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiaxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiayuguan",
    country: "China",
    code: "CN",
  },
  {
    city: "Jicheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Jieshou",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiexiu",
    country: "China",
    code: "CN",
  },
  {
    city: "Jieyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiguaní",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Jihlava",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Jijel",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Jijiga",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Jilib",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Jilin",
    country: "China",
    code: "CN",
  },
  {
    city: "Jilotepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Jīma",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Jimaguayú",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Jin’e",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinan",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinbi",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinchang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinchang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jincheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Jincheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Jīnd",
    country: "India",
    code: "IN",
  },
  {
    city: "Jingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Jingdezhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinggang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinghong",
    country: "China",
    code: "CN",
  },
  {
    city: "Jingling",
    country: "China",
    code: "CN",
  },
  {
    city: "Jingping",
    country: "China",
    code: "CN",
  },
  {
    city: "Jingzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinhua",
    country: "China",
    code: "CN",
  },
  {
    city: "Jining",
    country: "China",
    code: "CN",
  },
  {
    city: "Jining",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinja",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Jinjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinoba-an",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Jinotega",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Jinotepe",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Jinsha",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Jinzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Jirjā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Jīroft",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Jitpur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Jiujiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiutai",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiutepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Jixi",
    country: "China",
    code: "CN",
  },
  {
    city: "Jiyyammavalasa",
    country: "India",
    code: "IN",
  },
  {
    city: "Jizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Jizzax",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "João Monlevade",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "João Pessoa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jocotán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Jocotitlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Jodhpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Joensuu",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Jōetsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Johannesburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Johns Creek",
    country: "United States",
    code: "US",
  },
  {
    city: "Johnson City",
    country: "United States",
    code: "US",
  },
  {
    city: "Johor Bahru",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Joinvile",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Joliet",
    country: "United States",
    code: "US",
  },
  {
    city: "Jolo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Jonesboro",
    country: "United States",
    code: "US",
  },
  {
    city: "Jönköping",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Joplin",
    country: "United States",
    code: "US",
  },
  {
    city: "Jorhāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Jos",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Jose Abad Santos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "José C. Paz",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "José María Ezeiza",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Jose Pañganiban",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Jōsō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Joünié",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Jovellanos",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Joyabaj",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Jōyō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Juana Díaz",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Juanjuí",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Juárez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Juazeiro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Juazeiro do Norte",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Juba",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Juchitán de Zaragoza",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Juigalpa",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Juiz de Fora",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Juliaca",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Julu",
    country: "China",
    code: "CN",
  },
  {
    city: "Jumri Tilaiyā",
    country: "India",
    code: "IN",
  },
  {
    city: "Jūnāgadh",
    country: "India",
    code: "IN",
  },
  {
    city: "Jūnāgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Jundiaí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Junín",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Jupiter",
    country: "United States",
    code: "US",
  },
  {
    city: "Jūrmala",
    country: "Latvia",
    code: "LV",
  },
  {
    city: "Jurong",
    country: "China",
    code: "CN",
  },
  {
    city: "Jurupa Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Juruti",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Jutiapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Juticalpa",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Juventino Rosas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Jyväskylä",
    country: "Finland",
    code: "FI",
  },
  {
    city: "K’ebrī Dehar",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Kabacan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kabale",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kabankalan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kabarore",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Kabinda",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kabirwala",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kabul",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Kabwe",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Kadaiyanallūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Kadi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kadiri",
    country: "India",
    code: "IN",
  },
  {
    city: "Kadirli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kadoma",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Kadoma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kadugli",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Kaduna",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Kaech’ŏn",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Kaesŏng",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Kafr ad Dawwār",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Kafr ash Shaykh",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Kafr az Zayyāt",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Kafue",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Kaga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kâğıthane",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kagoshima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kahemba",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kahnūj",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kahramanmaraş",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kahror Pakka",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kâhta",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kaifeng Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Kaihua",
    country: "China",
    code: "CN",
  },
  {
    city: "Kaikkudi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kailua",
    country: "United States",
    code: "US",
  },
  {
    city: "Kairāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Kairouan",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Kaiserslautern",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kaithal",
    country: "India",
    code: "IN",
  },
  {
    city: "Kaiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Kaiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Kaiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Kaizuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kajo Kaji",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Kakamega",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kakamigahara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kakegawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kākināda",
    country: "India",
    code: "IN",
  },
  {
    city: "Kakogawachō-honmachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kakuma",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kalalé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Kalamansig",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kalamariá",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kalamáta",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kalamazoo",
    country: "United States",
    code: "US",
  },
  {
    city: "Kalamboli",
    country: "India",
    code: "IN",
  },
  {
    city: "Kalār",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Kalawana",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kalemie",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kalibo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kaliningrad",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kalisz",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Kāliyāganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Kālkāji Devi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kallakkurichchi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kallithéa",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kallūru",
    country: "India",
    code: "IN",
  },
  {
    city: "Kalmunai",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kālna",
    country: "India",
    code: "IN",
  },
  {
    city: "Kālpi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kalpitiya",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kaluga",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kalush",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kalyani",
    country: "India",
    code: "IN",
  },
  {
    city: "Kamagaya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kamakurayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kamalia",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kamālshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kāmāreddipet",
    country: "India",
    code: "IN",
  },
  {
    city: "Kāmārhāti",
    country: "India",
    code: "IN",
  },
  {
    city: "Kambam",
    country: "India",
    code: "IN",
  },
  {
    city: "Kambar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kamensk-Shakhtinskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kamensk-Ural’skiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kameoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kamëz",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Kamianets-Podilskyi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kamianske",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kamina",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kamirenjaku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kamisu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kamloops",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Kampala",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kampen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Kampong Cham",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Kampung Tengah",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kamsar",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Kamuli",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kamyshin",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kan’onjichō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kananga",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kananya",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kanasín",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Kanazawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kānchrāpāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Kandahār",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Kandangan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kandhkot",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kandi",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Kāndi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kandori",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kandukūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Kandy",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kangān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kangbao",
    country: "China",
    code: "CN",
  },
  {
    city: "Kanggye",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Kānhangād",
    country: "India",
    code: "IN",
  },
  {
    city: "Kani",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kankakee",
    country: "United States",
    code: "US",
  },
  {
    city: "Kankan",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Kannapolis",
    country: "United States",
    code: "US",
  },
  {
    city: "Kano",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Kanoya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kansas City",
    country: "United States",
    code: "US",
  },
  {
    city: "Kansas City",
    country: "United States",
    code: "US",
  },
  {
    city: "Kansk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kanuma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kaohsiung",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Kaolack",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Kapaklı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kapalong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kapatagan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kapchagay",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Kaposvár",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Kāpra",
    country: "India",
    code: "IN",
  },
  {
    city: "Kapūrthala",
    country: "India",
    code: "IN",
  },
  {
    city: "Kara-Balta",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "Karabük",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Karachi",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kāraikkudi",
    country: "India",
    code: "IN",
  },
  {
    city: "Karaj",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Karakax",
    country: "China",
    code: "CN",
  },
  {
    city: "Karakol",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "Karaköprü",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Karaman",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Karamay",
    country: "China",
    code: "CN",
  },
  {
    city: "Karanganyar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Karatepe",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Karatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Karawang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Karbalā’",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Kardzhali",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Kargilik",
    country: "China",
    code: "CN",
  },
  {
    city: "Karimama",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Karīmganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Karīmnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Kariya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Karlovac",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Karlskrona",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Karlsruhe",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Karlstad",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Karnāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Karonga",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Kars",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kartal",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Karūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Karviná",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Kārwār",
    country: "India",
    code: "IN",
  },
  {
    city: "Kaş",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kasama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kasama",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Kasangati",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kāsaragod",
    country: "India",
    code: "IN",
  },
  {
    city: "Kasese",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kāshān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kashgar",
    country: "China",
    code: "CN",
  },
  {
    city: "Kashiba",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kashikishi",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Kashima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kashiwa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kashiwara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kashiwara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kashiwazaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kāshmar",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kasongo",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kaspiysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kassala",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Kassel",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kasserine",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Kastamonu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kasuga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kasugai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kasukabe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kasulu",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Kasungu",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Kasur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Katano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kateríni",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Katha",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Kathmandu",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Kathri",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kathua",
    country: "India",
    code: "IN",
  },
  {
    city: "Kati",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Katihār",
    country: "India",
    code: "IN",
  },
  {
    city: "Katiola",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Katlehong",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Katori",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Katowice",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Kātoya",
    country: "India",
    code: "IN",
  },
  {
    city: "Katri",
    country: "India",
    code: "IN",
  },
  {
    city: "Katsina",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Katsushika-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kattagan",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Kattaqo’rg’on Shahri",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Katueté",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Katwijk",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Kaukhāli",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Kaunas",
    country: "Lithuania",
    code: "LT",
  },
  {
    city: "Kavála",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kāvali",
    country: "India",
    code: "IN",
  },
  {
    city: "Kawachinagano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kawagoe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kawaguchi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kawartha Lakes",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Kawasaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kawashiri",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kawit",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kawkareik",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Kawm Umbū",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Kawthoung",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Kaya",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Kāyankulam",
    country: "India",
    code: "IN",
  },
  {
    city: "Kayapınar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kayes",
    country: "Congo (Brazzaville)",
    code: "CG",
  },
  {
    city: "Kayes",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Kayseri",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kazan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kazanlak",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Kāzerūn",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kazo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kebili",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Kecskemét",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Kediri",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kędzierzyn-Koźle",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Keelung",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Kefar Sava",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Keffi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Keighley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Kelaa Kebira",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Kélibia",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Kelo",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Kelowna",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Kemalpaşa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kemerovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kempten",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kendale Lakes",
    country: "United States",
    code: "US",
  },
  {
    city: "Kendall",
    country: "United States",
    code: "US",
  },
  {
    city: "Kendari",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kendu Bay",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kenema",
    country: "Sierra Leone",
    code: "SL",
  },
  {
    city: "Kengri",
    country: "India",
    code: "IN",
  },
  {
    city: "Kengtung",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Kenitra",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Kennedy Town",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Kenner",
    country: "United States",
    code: "US",
  },
  {
    city: "Kennewick",
    country: "United States",
    code: "US",
  },
  {
    city: "Kenosha",
    country: "United States",
    code: "US",
  },
  {
    city: "Kensington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Kent",
    country: "United States",
    code: "US",
  },
  {
    city: "Kentaū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Kentwood",
    country: "United States",
    code: "US",
  },
  {
    city: "Keonjhargarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Keratsíni",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kerch",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Keren",
    country: "Eritrea",
    code: "ER",
  },
  {
    city: "Kermān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kermānshāh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kerpen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kesbewa",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kesennuma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Keshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Keshod",
    country: "India",
    code: "IN",
  },
  {
    city: "Kétou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Kettering",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Kettering",
    country: "United States",
    code: "US",
  },
  {
    city: "Khabarovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Khagaria",
    country: "India",
    code: "IN",
  },
  {
    city: "Khagaul",
    country: "India",
    code: "IN",
  },
  {
    city: "Khairpur Mir’s",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Khāk-e ‘Alī",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khambhāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Khamīs Mushayţ",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Khammam",
    country: "India",
    code: "IN",
  },
  {
    city: "Khān Shaykhūn",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Khān Yūnis",
    country: "Gaza Strip",
    code: "XG",
  },
  {
    city: "Khānaqīn",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Khandwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Khanewal",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Khanna",
    country: "India",
    code: "IN",
  },
  {
    city: "Khanpur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Khanty-Mansiysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kharagpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Kharar",
    country: "India",
    code: "IN",
  },
  {
    city: "Khardah",
    country: "India",
    code: "IN",
  },
  {
    city: "Khargone",
    country: "India",
    code: "IN",
  },
  {
    city: "Kharian",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kharkiv",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Khartoum",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Khartoum North",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Khartsyzk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Khasavyurt",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Khāsh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khatīma",
    country: "India",
    code: "IN",
  },
  {
    city: "Khattan",
    country: "India",
    code: "IN",
  },
  {
    city: "Khayelitsha",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Khemis el Khechna",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Khemis Miliana",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Khemis Sahel",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Khenchela",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Khénifra",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Kherson",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Khimki",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Khlong Luang",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Khmelnytskyi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Khomeyn",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khomeynī Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khon Kaen",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Khorramābād",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khorramdarreh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khorramshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khōst",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Khowrāsgān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khowy",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Khrustalnyi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Khŭjand",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Khulayş",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Khulna",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Khurai",
    country: "India",
    code: "IN",
  },
  {
    city: "Khurda",
    country: "India",
    code: "IN",
  },
  {
    city: "Khushab",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Khuzdar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kiamba",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kiambu",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kibaha",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Kichha",
    country: "India",
    code: "IN",
  },
  {
    city: "Kidapawan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kidderminster",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Kiel",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kielce",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Kiffa",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Kigali",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Kigoma",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Kikuyu",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kikwit",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kilifi",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kilinochchi",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kilis",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Killeen",
    country: "United States",
    code: "US",
  },
  {
    city: "Kilosa",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Kimberley",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Kimhae",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Kimilili",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kimitsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kimje",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Kindamba",
    country: "Congo (Brazzaville)",
    code: "CG",
  },
  {
    city: "Kindia",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Kindu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kineshma",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kingsport",
    country: "United States",
    code: "US",
  },
  {
    city: "Kingston",
    country: "Jamaica",
    code: "JM",
  },
  {
    city: "Kingston upon Hull",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Kingswood",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Kinh Môn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Kinshasa",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kintampo",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Kipushi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kirātot",
    country: "India",
    code: "IN",
  },
  {
    city: "Kirdāsah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Kireka",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kirishi",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kirishima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kirkland",
    country: "United States",
    code: "US",
  },
  {
    city: "Kirkuk",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Kirov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kirovo-Chepetsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kirtipur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Kiryū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kisangani",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kisaran",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kisarazu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kiselëvsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kishanganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Kishiwada",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kisi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Kislovodsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kismaayo",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Kissidougou",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Kissimmee",
    country: "United States",
    code: "US",
  },
  {
    city: "Kisumu",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kita-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitahiroshima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitakami",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitakōriyamachō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitakyūshū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitale",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kitamoto",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitanagoya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kitaotao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Kitchener",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Kitenkela",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Kitgum",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kitwe",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Kiyose",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kiyosu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kizugawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kırıkkale",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kırklareli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kırşehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kızılpınar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kızıltepe",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kladno",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Klagenfurt",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Klaipėda",
    country: "Lithuania",
    code: "LT",
  },
  {
    city: "Klang",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Klerksdorp",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Kleve",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Klimovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Klin",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Klintsy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kluang",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Knoxville",
    country: "United States",
    code: "US",
  },
  {
    city: "Knysna",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Ko Samui",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Kōbe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Koblenz",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kobryn",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Kocaeli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Koch Bihār",
    country: "India",
    code: "IN",
  },
  {
    city: "Kochi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kōchi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kodād",
    country: "India",
    code: "IN",
  },
  {
    city: "Kodaira",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kodungallūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Kōenchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Koforidua",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Kōfu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Koga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Koga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Koganei",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kogon Shahri",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Kohat",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kohīma",
    country: "India",
    code: "IN",
  },
  {
    city: "Koidu",
    country: "Sierra Leone",
    code: "SL",
  },
  {
    city: "Koidu-Bulma",
    country: "Sierra Leone",
    code: "SL",
  },
  {
    city: "Koiridih",
    country: "India",
    code: "IN",
  },
  {
    city: "Koja",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kōka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kokawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kokomo",
    country: "United States",
    code: "US",
  },
  {
    city: "Kökshetaū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Koktokay",
    country: "China",
    code: "CN",
  },
  {
    city: "Kokubunji",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kolār",
    country: "India",
    code: "IN",
  },
  {
    city: "Kolda",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Kolding",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Kolhāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Kolkāta",
    country: "India",
    code: "IN",
  },
  {
    city: "Kollegāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Kolomna",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kolomyia",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kolonnawa",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kolpino",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kolwezi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Komae",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Komaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Komatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kombolcha",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Komotiní",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Komsomol’sk-na-Amure",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Konak",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Konan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kōnan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kongjiazhuangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Kongolo",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Kongoussi",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Konibodom",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Konin",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Konongo",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Kōnosu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Konotop",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Konstanz",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kontagora",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Konya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kopargo",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Kopeysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Korāput",
    country: "India",
    code: "IN",
  },
  {
    city: "Koratla",
    country: "India",
    code: "IN",
  },
  {
    city: "Korba",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Korba",
    country: "India",
    code: "IN",
  },
  {
    city: "Korçë",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Körfez",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Korgas",
    country: "China",
    code: "CN",
  },
  {
    city: "Korhogo",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Koriyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Korla",
    country: "China",
    code: "CN",
  },
  {
    city: "Korolëv",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Koronadal",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Korosten",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kortrijk",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Korydallós",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kosai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Koshigaya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kōshizuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Košice",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Kostiantynivka",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kostroma",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Koszalin",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Kot Addu",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kot Kapūra",
    country: "India",
    code: "IN",
  },
  {
    city: "Kota",
    country: "India",
    code: "IN",
  },
  {
    city: "Kota Bharu",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Kota Kinabalu",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Kotamobagu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kotdwāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Kothāpet",
    country: "India",
    code: "IN",
  },
  {
    city: "Kotka",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Kotlas",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kotmale",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Kōtō-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kotri",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Kottagūdem",
    country: "India",
    code: "IN",
  },
  {
    city: "Kottayam",
    country: "India",
    code: "IN",
  },
  {
    city: "Kouandé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Koudougou",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Koumassi",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Kouribga",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Kousséri",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Koutiala",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Kouvola",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Kovel",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kovilpatti",
    country: "India",
    code: "IN",
  },
  {
    city: "Kovrov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kowloon",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Kowloon City",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Koytendag",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Kozan",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kozáni",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Kozluk",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kpalimé",
    country: "Togo",
    code: "TG",
  },
  {
    city: "Kragujevac",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Kraków",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Kraljevo",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Kramatorsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Krasnodar",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Krasnogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Krasnokamensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Krasnokamsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Krasnotur’insk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Krasnoyarsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Krasnyi Luch",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kratie",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Krefeld",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Kremenchuk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kresek",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kribi",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Krishnagiri",
    country: "India",
    code: "IN",
  },
  {
    city: "Krishnanagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Krishnarājpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Kristiansand",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Kronjo",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kroonstad",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Kropotkin",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kropyvnytskyi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Krugersdorp",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Kruševac",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Krymsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kryvyi Rih",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Ksar El Kebir",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Kstovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kuacjok",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Kuaidamao",
    country: "China",
    code: "CN",
  },
  {
    city: "Kuala Kapuas",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kuala Lumpur",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Kuala Terengganu",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Kuantan",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Kuchaiburi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kuching",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Küçükçekmece",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kudamatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kūhdasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kuiju",
    country: "China",
    code: "CN",
  },
  {
    city: "Kuje",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Kūkatpalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Kukawa",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Kukichūō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kŭlob",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Kumagaya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kumamoto",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kumanovo",
    country: "Macedonia",
    code: "MK",
  },
  {
    city: "Kumārapālaiyam",
    country: "India",
    code: "IN",
  },
  {
    city: "Kumasi",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Kumba",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Kumbakonam",
    country: "India",
    code: "IN",
  },
  {
    city: "Kumbo",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Kumertau",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kumi",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Kumul",
    country: "China",
    code: "CN",
  },
  {
    city: "Kunduz",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Kungur",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kuningan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kunitachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kuniyamuttūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Kunming",
    country: "China",
    code: "CN",
  },
  {
    city: "Kunp’o",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Kunsan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Kuopio",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Kupang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Kuqa",
    country: "China",
    code: "CN",
  },
  {
    city: "Kurashiki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kure",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kurgan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kurichchi",
    country: "India",
    code: "IN",
  },
  {
    city: "Kurihara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kurmuk",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Kurnool",
    country: "India",
    code: "IN",
  },
  {
    city: "Kursk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kurume",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kuşadası",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kusatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kushiro",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kushtia",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Kusŏng",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Kūstī",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Kūt-e ‘Abdollāh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Kütahya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Kutaisi",
    country: "Georgia",
    code: "GE",
  },
  {
    city: "Kuvango",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Kuwait City",
    country: "Kuwait",
    code: "KW",
  },
  {
    city: "Kuwana",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kuytun",
    country: "China",
    code: "CN",
  },
  {
    city: "Kuznetsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kwekwe",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Kỳ Anh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Kyaliwajjala",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Kyaukse",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Kyaunggon",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Kyiv",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Kyŏngju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Kyōtanabe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kyōtango",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kyōto",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Kyustendil",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Kyzyl",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Kyzyl-Kyya",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "L’Aquila",
    country: "Italy",
    code: "IT",
  },
  {
    city: "La Asunción",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "La Banda",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "La Barca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "La Calera",
    country: "Chile",
    code: "CL",
  },
  {
    city: "La Carlota",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "La Ceiba",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "La Ceja",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "La Chorrera",
    country: "Panama",
    code: "PA",
  },
  {
    city: "La Crosse",
    country: "United States",
    code: "US",
  },
  {
    city: "La Democracia",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "La Dorada",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "La Estrella",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "La Florida",
    country: "Chile",
    code: "CL",
  },
  {
    city: "La Gi",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "La Gomera",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "La Granja",
    country: "Chile",
    code: "CL",
  },
  {
    city: "La Grita",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "La Guaira",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "La Habra",
    country: "United States",
    code: "US",
  },
  {
    city: "La Laguna",
    country: "Spain",
    code: "ES",
  },
  {
    city: "La Libertad",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "La Lima",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "La Línea de la Concepción",
    country: "Spain",
    code: "ES",
  },
  {
    city: "La Louvière",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "La Marsa",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "La Mesa",
    country: "United States",
    code: "US",
  },
  {
    city: "La Paz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "La Paz",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "La Paz",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "La Piedad",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "La Pintana",
    country: "Chile",
    code: "CL",
  },
  {
    city: "La Plata",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "La Plata",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "La Reina",
    country: "Chile",
    code: "CL",
  },
  {
    city: "La Rioja",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "La Roche-sur-Yon",
    country: "France",
    code: "FR",
  },
  {
    city: "La Rochelle",
    country: "France",
    code: "FR",
  },
  {
    city: "La Romana",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "La Serena",
    country: "Chile",
    code: "CL",
  },
  {
    city: "La Seyne-sur-Mer",
    country: "France",
    code: "FR",
  },
  {
    city: "La Spezia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "La Trinidad",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "La Trinidad",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "La Vega",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "La Victoria",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "La Victoria",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Laâyoune",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Labé",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Labinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Labo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Labuan",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Lac-Brome",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Lacey",
    country: "United States",
    code: "US",
  },
  {
    city: "Lachhmangarh Sīkar",
    country: "India",
    code: "IN",
  },
  {
    city: "Ladysmith",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Lae",
    country: "Papua New Guinea",
    code: "PG",
  },
  {
    city: "Laeken",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Lafayette",
    country: "United States",
    code: "US",
  },
  {
    city: "Lafayette",
    country: "United States",
    code: "US",
  },
  {
    city: "Lafayette",
    country: "United States",
    code: "US",
  },
  {
    city: "Lafey",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Lafia",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Lagarto",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Laghouat",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Lagoa Santa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lagonoy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lagos",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Lagos de Moreno",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Laguna Niguel",
    country: "United States",
    code: "US",
  },
  {
    city: "Lahad Datu",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Lāharpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Lahat",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Lahore",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Lahti",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Laibin",
    country: "China",
    code: "CN",
  },
  {
    city: "Laiwu",
    country: "China",
    code: "CN",
  },
  {
    city: "Laixi",
    country: "China",
    code: "CN",
  },
  {
    city: "Laiyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Laiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Lajeado",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lajes",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lake Charles",
    country: "United States",
    code: "US",
  },
  {
    city: "Lake Elsinore",
    country: "United States",
    code: "US",
  },
  {
    city: "Lake Forest",
    country: "United States",
    code: "US",
  },
  {
    city: "Lake Havasu City",
    country: "United States",
    code: "US",
  },
  {
    city: "Lake Jackson",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakeland",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakeville",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakewood",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakewood",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakewood",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakewood",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakewood",
    country: "United States",
    code: "US",
  },
  {
    city: "Lakhdaria",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Lakhminia",
    country: "India",
    code: "IN",
  },
  {
    city: "Laksar",
    country: "India",
    code: "IN",
  },
  {
    city: "Lala",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lala Musa",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Lalian",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Lalmanirhat",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Lalo",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Lam Tin",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Lambayeque",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Lambunao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lamía",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Lamitan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lampang",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Lancaster",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Lancaster",
    country: "United States",
    code: "US",
  },
  {
    city: "Lancaster",
    country: "United States",
    code: "US",
  },
  {
    city: "Landshut",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Lạng Sơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Langarūd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Langenfeld",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Langenhagen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Langfang",
    country: "China",
    code: "CN",
  },
  {
    city: "Langley",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Langsa",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Langzhong",
    country: "China",
    code: "CN",
  },
  {
    city: "Lansing",
    country: "United States",
    code: "US",
  },
  {
    city: "Lantapan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lanús",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Lanxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Lanxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Lanzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Lào Cai",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Laoag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Laoang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Laohekou",
    country: "China",
    code: "CN",
  },
  {
    city: "Lappeenranta",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Lapu-Lapu City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Larache",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Laredo",
    country: "United States",
    code: "US",
  },
  {
    city: "Largo",
    country: "United States",
    code: "US",
  },
  {
    city: "Lárisa",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Larkana",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Larnaca",
    country: "Cyprus",
    code: "CY",
  },
  {
    city: "Las Condes",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Las Cruces",
    country: "United States",
    code: "US",
  },
  {
    city: "Las Delicias",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Las Heras",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Las Margaritas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Las Palmas",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Las Piedras",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Las Piñas City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Las Rozas de Madrid",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Las Tunas",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Las Vegas",
    country: "United States",
    code: "US",
  },
  {
    city: "Lashio",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Lashkar Gāh",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Latacunga",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Latakia",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Latina",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Lauderhill",
    country: "United States",
    code: "US",
  },
  {
    city: "Launceston",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Lausanne",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Lautoka",
    country: "Fiji",
    code: "FJ",
  },
  {
    city: "Laval",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Lavras",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lawang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Lawrence",
    country: "United States",
    code: "US",
  },
  {
    city: "Lawrence",
    country: "United States",
    code: "US",
  },
  {
    city: "Lawton",
    country: "United States",
    code: "US",
  },
  {
    city: "Layton",
    country: "United States",
    code: "US",
  },
  {
    city: "Le Bardo",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Le Blanc-Mesnil",
    country: "France",
    code: "FR",
  },
  {
    city: "Le Havre",
    country: "France",
    code: "FR",
  },
  {
    city: "Le Kram",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Le Mans",
    country: "France",
    code: "FR",
  },
  {
    city: "League City",
    country: "United States",
    code: "US",
  },
  {
    city: "Leander",
    country: "United States",
    code: "US",
  },
  {
    city: "Lebanon",
    country: "United States",
    code: "US",
  },
  {
    city: "Lecce",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Lechang",
    country: "China",
    code: "CN",
  },
  {
    city: "Lecheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Lee's Summit",
    country: "United States",
    code: "US",
  },
  {
    city: "Leeds",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Leer",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Leesburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Leeuwarden",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Leganés",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Legazpi City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Legionowo",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Legnano",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Legnica",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Lehi",
    country: "United States",
    code: "US",
  },
  {
    city: "Lehigh Acres",
    country: "United States",
    code: "US",
  },
  {
    city: "Leicester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Leiden",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Leipzig",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Leiria",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Leith",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Leiyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Leizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Leling",
    country: "China",
    code: "CN",
  },
  {
    city: "Lelystad",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Lembok",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Leme",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lemery",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lençóis Paulista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lenexa",
    country: "United States",
    code: "US",
  },
  {
    city: "Lengshuijiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Leninogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Leninsk-Kuznetskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Léogâne",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Leominster",
    country: "United States",
    code: "US",
  },
  {
    city: "Leon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "León",
    country: "Spain",
    code: "ES",
  },
  {
    city: "León",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "León de los Aldama",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Leopoldina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Leping",
    country: "China",
    code: "CN",
  },
  {
    city: "Leping",
    country: "China",
    code: "CN",
  },
  {
    city: "Léré",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Lerma",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Les Cayes",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Leshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Leshou",
    country: "China",
    code: "CN",
  },
  {
    city: "Leskovac",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Lesosibirsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Leszno",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Lethbridge",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Letpandan",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Leuven",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Leuwiliang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Levallois-Perret",
    country: "France",
    code: "FR",
  },
  {
    city: "Leverkusen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Lévis",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Levittown",
    country: "United States",
    code: "US",
  },
  {
    city: "Levittown",
    country: "United States",
    code: "US",
  },
  {
    city: "Lewisville",
    country: "United States",
    code: "US",
  },
  {
    city: "Lexington",
    country: "United States",
    code: "US",
  },
  {
    city: "Lənkəran",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Lhasa",
    country: "China",
    code: "CN",
  },
  {
    city: "Lhokseumawe",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Lian",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Liancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Liangshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianran",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Liantangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Lianzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Liaocheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Liaoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Liaoyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Liberec",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Liberia",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "Libertad",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Libmanan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Libon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Libreville",
    country: "Gabon",
    code: "GA",
  },
  {
    city: "Libungan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Licheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Licheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Lichinga",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Lichuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Lida",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Liège",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Liepāja",
    country: "Latvia",
    code: "LV",
  },
  {
    city: "Ligao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Likasi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Lille",
    country: "France",
    code: "FR",
  },
  {
    city: "Lilongwe",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Lima",
    country: "United States",
    code: "US",
  },
  {
    city: "Lima",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Limassol",
    country: "Cyprus",
    code: "CY",
  },
  {
    city: "Limay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Limbe",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Limbé",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Limeira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Limerick",
    country: "Ireland",
    code: "IE",
  },
  {
    city: "Limoeiro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Limoeiro do Norte",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Limoges",
    country: "France",
    code: "FR",
  },
  {
    city: "Limonade",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Limpio",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Lin’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Linares",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Linares",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Linares",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Lincang",
    country: "China",
    code: "CN",
  },
  {
    city: "Lincoln",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Lincoln",
    country: "United States",
    code: "US",
  },
  {
    city: "Lindong",
    country: "China",
    code: "CN",
  },
  {
    city: "Linfen",
    country: "China",
    code: "CN",
  },
  {
    city: "Lingampalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Lingayen",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lingbao Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Lingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Lingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Linghai",
    country: "China",
    code: "CN",
  },
  {
    city: "Lingshou",
    country: "China",
    code: "CN",
  },
  {
    city: "Lingtang",
    country: "China",
    code: "CN",
  },
  {
    city: "Lingyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Linhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Linhares",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Linjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Linköping",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Linkou",
    country: "China",
    code: "CN",
  },
  {
    city: "Linquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Lins",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Linxia Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Linyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Linz",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Lipa City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lipetsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Lipjan",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Lippstadt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Lira",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Lisala",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Lisbon",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Lisburn",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Lishui",
    country: "China",
    code: "CN",
  },
  {
    city: "Liski",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Little Rock",
    country: "United States",
    code: "US",
  },
  {
    city: "Littlehampton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Liuhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Liushi",
    country: "China",
    code: "CN",
  },
  {
    city: "Liuzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Livermore",
    country: "United States",
    code: "US",
  },
  {
    city: "Liverpool",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Livingston",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Lívingston",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Livingstone",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Livonia",
    country: "United States",
    code: "US",
  },
  {
    city: "Livorno",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Ljubljana",
    country: "Slovenia",
    code: "SI",
  },
  {
    city: "Lleida",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Lo Barnechea",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Lobito",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Lobnya",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Lod",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Lodi",
    country: "United States",
    code: "US",
  },
  {
    city: "Lodja",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Łódź",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Logan",
    country: "United States",
    code: "US",
  },
  {
    city: "Logroño",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Lohārdagā",
    country: "India",
    code: "IN",
  },
  {
    city: "Loja",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Lokoja",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Lokossa",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Lomas de Zamora",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Lomas del Mirador",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Lomé",
    country: "Togo",
    code: "TG",
  },
  {
    city: "Łomża",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Lonāvale",
    country: "India",
    code: "IN",
  },
  {
    city: "London",
    country: "Canada",
    code: "CA",
  },
  {
    city: "London",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Londrina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Londuimbali",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Long Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Long Bình",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Long Khánh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Long Mỹ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Long Xuyên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Longba",
    country: "China",
    code: "CN",
  },
  {
    city: "Longchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Longhua",
    country: "China",
    code: "CN",
  },
  {
    city: "Longjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Longjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Longjin",
    country: "China",
    code: "CN",
  },
  {
    city: "Longjing",
    country: "China",
    code: "CN",
  },
  {
    city: "Longkeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Longkoucun",
    country: "China",
    code: "CN",
  },
  {
    city: "Longmen",
    country: "China",
    code: "CN",
  },
  {
    city: "Longmont",
    country: "United States",
    code: "US",
  },
  {
    city: "Longonjo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Longquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Longquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Longquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Longshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Longtian",
    country: "China",
    code: "CN",
  },
  {
    city: "Longueuil",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Longview",
    country: "United States",
    code: "US",
  },
  {
    city: "Longview",
    country: "United States",
    code: "US",
  },
  {
    city: "Longxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Longyan",
    country: "China",
    code: "CN",
  },
  {
    city: "Longzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Loni",
    country: "India",
    code: "IN",
  },
  {
    city: "Lopez",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lorain",
    country: "United States",
    code: "US",
  },
  {
    city: "Lorca",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Lorica",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Lorient",
    country: "France",
    code: "FR",
  },
  {
    city: "Los Alcarrizos",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Los Amates",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Los Andes",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Los Angeles",
    country: "United States",
    code: "US",
  },
  {
    city: "Los Ángeles",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Los Baños",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Los Cerrillos",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Los Guayos",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Los Minas",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Los Mochis",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Los Patios",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Los Polvorines",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Los Reyes de Salgado",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Los Teques",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Louangphabang",
    country: "Laos",
    code: "LA",
  },
  {
    city: "Loudi",
    country: "China",
    code: "CN",
  },
  {
    city: "Louga",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Loughborough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Louisville",
    country: "United States",
    code: "US",
  },
  {
    city: "Loulé",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Loum",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Loures",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Loveland",
    country: "United States",
    code: "US",
  },
  {
    city: "Lowell",
    country: "United States",
    code: "US",
  },
  {
    city: "Lower Hutt",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Lower Merion",
    country: "United States",
    code: "US",
  },
  {
    city: "Lower Paxton",
    country: "United States",
    code: "US",
  },
  {
    city: "Lowestoft",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Loznica",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Lozova",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Lqoliaa",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Lu’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Luancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Luanda",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Luanshya",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Luanzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Luau",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Lubango",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Lubao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lubbock",
    country: "United States",
    code: "US",
  },
  {
    city: "Lübeck",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Lubin",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Lublin",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Lubuklinggau",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Lubumbashi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Lucapa",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Lucas do Rio Verde",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lucban",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lucca",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Lucena",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Lucerne",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Lucheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Luckeesarai",
    country: "India",
    code: "IN",
  },
  {
    city: "Lucknow",
    country: "India",
    code: "IN",
  },
  {
    city: "Lüdenscheid",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ludhiāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Ludwigsburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ludwigshafen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Luena",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Lugang",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Lugano",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Lugazi",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Lugo",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Luhansk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Luján",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Luján de Cuyo",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Lüleburgaz",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Lüneburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Lünen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Lunglei",
    country: "India",
    code: "IN",
  },
  {
    city: "Luocheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Luocheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Luodong",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Luofeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Luohe",
    country: "China",
    code: "CN",
  },
  {
    city: "Luoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Lupon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Luque",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Luquembo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Lusaka",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Lushar",
    country: "China",
    code: "CN",
  },
  {
    city: "Lutayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Luton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Lutsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Luuq",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Luxembourg",
    country: "Luxembourg",
    code: "LU",
  },
  {
    city: "Luxor",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Luzhang",
    country: "China",
    code: "CN",
  },
  {
    city: "Luzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Luziânia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Lviv",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Lynchburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Lynn",
    country: "United States",
    code: "US",
  },
  {
    city: "Lynwood",
    country: "United States",
    code: "US",
  },
  {
    city: "Lyon",
    country: "France",
    code: "FR",
  },
  {
    city: "Lysva",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Lysychansk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Lytkarino",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Lyubertsy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "M.Ə. Rəsulzadə",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "M’diq",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "M’lang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "M’Sila",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Ma‘ān",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Ma‘arrat an Nu‘mān",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Ma’anshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Ma’erkang",
    country: "China",
    code: "CN",
  },
  {
    city: "Maasin",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Maasin",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Maastricht",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Mabacun",
    country: "China",
    code: "CN",
  },
  {
    city: "Mabai",
    country: "China",
    code: "CN",
  },
  {
    city: "Mabalacat",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mabinay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mabini",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Macabebe",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Macaé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Macaíba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Macapá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Macau",
    country: "Macau",
    code: "MO",
  },
  {
    city: "Macaúbas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Macclesfield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Maceió",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Machakos",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Machala",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Macheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Mācherla",
    country: "India",
    code: "IN",
  },
  {
    city: "Machida",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Machilīpatnam",
    country: "India",
    code: "IN",
  },
  {
    city: "Machiques",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Mackay",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Maco",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Macon",
    country: "United States",
    code: "US",
  },
  {
    city: "Macuspana",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Mādabā",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Madan",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Madanapalle",
    country: "India",
    code: "IN",
  },
  {
    city: "Madang",
    country: "Papua New Guinea",
    code: "PG",
  },
  {
    city: "Madera",
    country: "United States",
    code: "US",
  },
  {
    city: "Madgaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Madhavaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Madhipura",
    country: "India",
    code: "IN",
  },
  {
    city: "Madhubani",
    country: "India",
    code: "IN",
  },
  {
    city: "Madhupur",
    country: "India",
    code: "IN",
  },
  {
    city: "Madhyamgram",
    country: "India",
    code: "IN",
  },
  {
    city: "Madīnat as Sādāt",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Madīnat as Sādis min Uktūbar",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Madīnat Ḩamad",
    country: "Bahrain",
    code: "BH",
  },
  {
    city: "Madirovalo",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Madison",
    country: "United States",
    code: "US",
  },
  {
    city: "Madison",
    country: "United States",
    code: "US",
  },
  {
    city: "Madiun",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Madrid",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Madrid",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Madurai",
    country: "India",
    code: "IN",
  },
  {
    city: "Maduraivayal",
    country: "India",
    code: "IN",
  },
  {
    city: "Maebashi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mafeteng",
    country: "Lesotho",
    code: "LS",
  },
  {
    city: "Mafra",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mafra",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Maga",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Magadan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Magalang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Magangué",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Magdeburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Magé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Magelang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Maghāghah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Magnitogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Magong",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Magpet",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Magsaysay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Magway",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Magwe",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Maha Sarakham",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Mahābād",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mahād",
    country: "India",
    code: "IN",
  },
  {
    city: "Mahajanga",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Mahalapye",
    country: "Botswana",
    code: "BW",
  },
  {
    city: "Maharagama",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Mahāsamund",
    country: "India",
    code: "IN",
  },
  {
    city: "Mahbūbnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Māhdāsht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mahdia",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Mahesāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Mahilyow",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Mahlaing",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Maḩmūd-e Rāqī",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Mai’Adua",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Maia",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Maicao",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Maidenhead",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Maidstone",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Maiduguri",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Maijdi",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Maīmanah",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Maimbung",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Maina",
    country: "Guam",
    code: "GU",
  },
  {
    city: "Mainz",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Maipú",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Maipú",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Maiquetía",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Mairiporã",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Maisons-Alfort",
    country: "France",
    code: "FR",
  },
  {
    city: "Maizuru",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Majadahonda",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Majalengka",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Majene",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Makassar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Makati City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Makeni",
    country: "Sierra Leone",
    code: "SL",
  },
  {
    city: "Makhachkala",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Makiivka",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Makilala",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Makrāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Makurdi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Malabo",
    country: "Equatorial Guinea",
    code: "GQ",
  },
  {
    city: "Malabon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malacatán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Maladzyechna",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Málaga",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Malakal",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Malambo",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Malang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Malanje",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Malanville",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Malapatan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malappuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Malārd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Malasiqui",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malate",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malatya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Malaut",
    country: "India",
    code: "IN",
  },
  {
    city: "Malay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malaybalay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malāyer",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Māldah",
    country: "India",
    code: "IN",
  },
  {
    city: "Malden",
    country: "United States",
    code: "US",
  },
  {
    city: "Maldonado",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Male",
    country: "Maldives",
    code: "MV",
  },
  {
    city: "Malema",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Māler Kotla",
    country: "India",
    code: "IN",
  },
  {
    city: "Mali",
    country: "China",
    code: "CN",
  },
  {
    city: "Malindi",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Malita",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malkājgiri",
    country: "India",
    code: "IN",
  },
  {
    city: "Malkāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Malkara",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mallawī",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Malmö",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Malolos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Maluñgun",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Malvar",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mambéré",
    country: "Central African Republic",
    code: "CF",
  },
  {
    city: "Mamou",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Mamuju",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Mamungan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Man",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Manacapuru",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Manado",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Managua",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Manama",
    country: "Bahrain",
    code: "BH",
  },
  {
    city: "Manaoag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Manapla",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Manaure",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Manaus",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Manavgat",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Manbij",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Mancherāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Manchester",
    country: "United States",
    code: "US",
  },
  {
    city: "Manchester",
    country: "United States",
    code: "US",
  },
  {
    city: "Manchester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Mandalay",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mandaluyong City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mandamāri",
    country: "India",
    code: "IN",
  },
  {
    city: "Mandapeta",
    country: "India",
    code: "IN",
  },
  {
    city: "Mandaue City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mandeville",
    country: "United States",
    code: "US",
  },
  {
    city: "Mandi Bahauddin",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mandi Burewala",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mandi Dabwāli",
    country: "India",
    code: "IN",
  },
  {
    city: "Mandiraja Kulon",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Mandlā",
    country: "India",
    code: "IN",
  },
  {
    city: "Mandsaur",
    country: "India",
    code: "IN",
  },
  {
    city: "Māndvi",
    country: "India",
    code: "IN",
  },
  {
    city: "Māndvi",
    country: "India",
    code: "IN",
  },
  {
    city: "Mandya",
    country: "India",
    code: "IN",
  },
  {
    city: "Manfalūţ",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Manfredonia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Mang La",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Mangalagiri",
    country: "India",
    code: "IN",
  },
  {
    city: "Mangaldan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mangalore",
    country: "India",
    code: "IN",
  },
  {
    city: "Mangatarem",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mangghystaū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Manglaur",
    country: "India",
    code: "IN",
  },
  {
    city: "Mangochi",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Māngrol",
    country: "India",
    code: "IN",
  },
  {
    city: "Manhattan",
    country: "United States",
    code: "US",
  },
  {
    city: "Manhattan",
    country: "United States",
    code: "US",
  },
  {
    city: "Manhiça",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Manhuaçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Manicaragua",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Manicoré",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Manila",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Manisa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Manizales",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Manjeri",
    country: "India",
    code: "IN",
  },
  {
    city: "Mankono",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Manmād",
    country: "India",
    code: "IN",
  },
  {
    city: "Mannārgudi",
    country: "India",
    code: "IN",
  },
  {
    city: "Mannheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Manokwari",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Manolo Fortich",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Manouba",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Manpo",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Manresa",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Mansa",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Mānsa",
    country: "India",
    code: "IN",
  },
  {
    city: "Mansāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Mansalay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mansehra",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mansfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Mansfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Mansfield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Manta",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Manteca",
    country: "United States",
    code: "US",
  },
  {
    city: "Manukau City",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Manzanillo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Manzanillo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Manzhouli",
    country: "China",
    code: "CN",
  },
  {
    city: "Manzini",
    country: "Swaziland",
    code: "SZ",
  },
  {
    city: "Mao",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Maoming",
    country: "China",
    code: "CN",
  },
  {
    city: "Maple Grove",
    country: "United States",
    code: "US",
  },
  {
    city: "Maple Ridge",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Maputo",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Maquela do Zombo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Mar del Plata",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Marabá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Maracaibo",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Maracanaú",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Maracay",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Maradi",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Marāgheh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Maramag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Marana",
    country: "United States",
    code: "US",
  },
  {
    city: "Marand",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Maranguape",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Marano di Napoli",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Maratturai",
    country: "India",
    code: "IN",
  },
  {
    city: "Maravatío de Ocampo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Marawi City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Marbella",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Marburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Marco de Canavezes",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Marcory",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Mardan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mardin",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Marechal Deodoro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Marg‘ilon",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Margate",
    country: "United States",
    code: "US",
  },
  {
    city: "Margate",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Mariano Acosta",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Mariano Roque Alonso",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Maribor",
    country: "Slovenia",
    code: "SI",
  },
  {
    city: "Maricá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Maricopa",
    country: "United States",
    code: "US",
  },
  {
    city: "Marietta",
    country: "United States",
    code: "US",
  },
  {
    city: "Marigot",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Marikina City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Marilao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Marília",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Maringá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Marinilla",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Marituba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mariupol",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Marīvān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mariveles",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Marka",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Markala",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Mārkāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Markham",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Marl",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Marmagao",
    country: "India",
    code: "IN",
  },
  {
    city: "Marmaris",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Marondera",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Maroua",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Maroúsi",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Marovoay",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Marpalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Marrakech",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Marsala",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Marseille",
    country: "France",
    code: "FR",
  },
  {
    city: "Martapura",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Martil",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Martin",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Martínez",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Martínez de la Torre",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Marugame",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Marvdasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mary",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Marysville",
    country: "United States",
    code: "US",
  },
  {
    city: "Masaka",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Masallātah",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Masan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Masantol",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Masaurhi Buzurg",
    country: "India",
    code: "IN",
  },
  {
    city: "Masaya",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Masbate",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mascara",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Maseru",
    country: "Lesotho",
    code: "LS",
  },
  {
    city: "Mashhad",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Masindi",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Masinloc",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Masjed Soleymān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Massa",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Massy",
    country: "France",
    code: "FR",
  },
  {
    city: "Masvingo",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Matadi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Matagalpa",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Matalam",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Matamey",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Matamoros",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Matanao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Matanzas",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Matão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Matara",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Mataram",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Mataró",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Matehuala",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Matera",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Matéri",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Mathura",
    country: "India",
    code: "IN",
  },
  {
    city: "Mati",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Matola",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Matosinhos",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Maţraḩ",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Maţrūḩ",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Matsubara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Matsudo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Matsue",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Matsumoto",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Matsuyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Matsuzaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Maturín",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Mau",
    country: "India",
    code: "IN",
  },
  {
    city: "Mauá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mauban",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Maubin",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Maués",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mauldin",
    country: "United States",
    code: "US",
  },
  {
    city: "Mauli",
    country: "India",
    code: "IN",
  },
  {
    city: "Maumere",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Maun",
    country: "Botswana",
    code: "BW",
  },
  {
    city: "Maungdaw",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mawanella",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Mawlamyine",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Maxixe",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "May Pen",
    country: "Jamaica",
    code: "JM",
  },
  {
    city: "Mayagüez",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Mayarí",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Maykop",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Mazabuka",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Mazār-e Sharīf",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Mazara del Vallo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Mazatenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Mazatlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Mazhang",
    country: "China",
    code: "CN",
  },
  {
    city: "Mazyr",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Mbabane",
    country: "Swaziland",
    code: "SZ",
  },
  {
    city: "Mbaké",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Mbale",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Mbalmayo",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Mbandaka",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Mbanza Kongo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Mbanza-Ngungu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Mbarara",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Mbeya",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Mbuji-Mayi",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Mbulungu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "McAllen",
    country: "United States",
    code: "US",
  },
  {
    city: "McKinney",
    country: "United States",
    code: "US",
  },
  {
    city: "Meaux",
    country: "France",
    code: "FR",
  },
  {
    city: "Mecca",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Mechelen",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Mecheria",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Medan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Médéa",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Medellin",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Medellín",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Medenine",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Medford",
    country: "United States",
    code: "US",
  },
  {
    city: "Medford",
    country: "United States",
    code: "US",
  },
  {
    city: "Medicine Hat",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Medina",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Medina Estates",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Medinīpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Mediouna",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Meerbusch",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Meerut",
    country: "India",
    code: "IN",
  },
  {
    city: "Meguro",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mehtar Lām",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Meicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Meïganga",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Meihekou",
    country: "China",
    code: "CN",
  },
  {
    city: "Meihua",
    country: "China",
    code: "CN",
  },
  {
    city: "Meiktila",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Meishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Meishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Meizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Mejicanos",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Mekele",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Meknès",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Melaka",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Melbourne",
    country: "United States",
    code: "US",
  },
  {
    city: "Melbourne",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Melchor Ocampo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Meleuz",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Melipilla",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Melitopol",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Melo",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Melong",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Melton",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Memphis",
    country: "United States",
    code: "US",
  },
  {
    city: "Menderes",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mendi",
    country: "Papua New Guinea",
    code: "PG",
  },
  {
    city: "Mendoza",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Menemen",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mengdingjie",
    country: "China",
    code: "CN",
  },
  {
    city: "Mengla",
    country: "China",
    code: "CN",
  },
  {
    city: "Menglang",
    country: "China",
    code: "CN",
  },
  {
    city: "Menifee",
    country: "United States",
    code: "US",
  },
  {
    city: "Menongue",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Menzel Bourguiba",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Menzel Temime",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Meram",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Merced",
    country: "United States",
    code: "US",
  },
  {
    city: "Mercedes",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mercedes",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Mérida",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Mérida",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Mérida",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Meriden",
    country: "United States",
    code: "US",
  },
  {
    city: "Meridian",
    country: "United States",
    code: "US",
  },
  {
    city: "Mérignac",
    country: "France",
    code: "FR",
  },
  {
    city: "Merlo",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Mersin",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Merthyr Tudful",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Meru",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Merzifon",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mesa",
    country: "United States",
    code: "US",
  },
  {
    city: "Meshgīn Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Meshkīn Dasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mesquite",
    country: "United States",
    code: "US",
  },
  {
    city: "Messaad",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Messina",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Mestre",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Metairie",
    country: "United States",
    code: "US",
  },
  {
    city: "Metapán",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Metema",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Methuen Town",
    country: "United States",
    code: "US",
  },
  {
    city: "Métouia",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Metpalli",
    country: "India",
    code: "IN",
  },
  {
    city: "Metro",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Mettupālaiyam",
    country: "India",
    code: "IN",
  },
  {
    city: "Metz",
    country: "France",
    code: "FR",
  },
  {
    city: "Meulaboh",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Mexicali",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Mexico",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mexico City",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Meybod",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Meycauayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mezhdurechensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Mhow",
    country: "India",
    code: "IN",
  },
  {
    city: "Miagao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Miami",
    country: "United States",
    code: "US",
  },
  {
    city: "Miami Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Miami Gardens",
    country: "United States",
    code: "US",
  },
  {
    city: "Mian Channun",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mīāneh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mianwali",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mianyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Miaoli",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Miass",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Michigan City",
    country: "United States",
    code: "US",
  },
  {
    city: "Michurinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Midalt",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Middelburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Middlesbrough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Middletown",
    country: "United States",
    code: "US",
  },
  {
    city: "Middletown",
    country: "United States",
    code: "US",
  },
  {
    city: "Midland",
    country: "United States",
    code: "US",
  },
  {
    city: "Midoun",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Midsayap",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Midwest City",
    country: "United States",
    code: "US",
  },
  {
    city: "Midyat",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mielec",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Mihara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mijas",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Mikhaylovka",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Mikhaylovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Miki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mikkeli",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Milagro",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Milagros",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Milan",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Milford city",
    country: "United States",
    code: "US",
  },
  {
    city: "Millcreek",
    country: "United States",
    code: "US",
  },
  {
    city: "Millcreek",
    country: "United States",
    code: "US",
  },
  {
    city: "Milpitas",
    country: "United States",
    code: "US",
  },
  {
    city: "Milton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Milton Keynes",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Miluo Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Milwaukee",
    country: "United States",
    code: "US",
  },
  {
    city: "Minalabac",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Minami-Alps",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minami-Sōma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minamisuita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minamiuonuma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minatitlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Minato",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minbya",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mindelo",
    country: "Cabo Verde",
    code: "CV",
  },
  {
    city: "Minden",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Mineiros",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mineral’nyye Vody",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Mineshita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mingaora",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mingəçevir",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Mingguang",
    country: "China",
    code: "CN",
  },
  {
    city: "Minglanilla",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mingxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Minna",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Minneapolis",
    country: "United States",
    code: "US",
  },
  {
    city: "Minnetonka",
    country: "United States",
    code: "US",
  },
  {
    city: "Minō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minokamo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minoo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Minsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Minusinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Minxiong",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Minzhu",
    country: "China",
    code: "CN",
  },
  {
    city: "Mirabel",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Miragoâne",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Miramar",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Miramar",
    country: "United States",
    code: "US",
  },
  {
    city: "Mirassol",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Miri",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Miriālgūda",
    country: "India",
    code: "IN",
  },
  {
    city: "Mirpur Khas",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Miryang",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Mirzāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Misantla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Misato",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Mishawaka",
    country: "United States",
    code: "US",
  },
  {
    city: "Miskolc",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Mişrātah",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Missérété",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Mission",
    country: "United States",
    code: "US",
  },
  {
    city: "Mission Viejo",
    country: "United States",
    code: "US",
  },
  {
    city: "Mississauga",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Missoula",
    country: "United States",
    code: "US",
  },
  {
    city: "Missouri City",
    country: "United States",
    code: "US",
  },
  {
    city: "Mīt Ghamr",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Mitcham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Mitchells Plain",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Mito",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mitoyo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mitrovicë",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Mityana",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Mixco",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Miyako",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Miyakojima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Miyakonojō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Miyazaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Miyoshi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Miyoshi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Mizuho",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mladenovac",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Mmabatho",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Moa",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Moanda",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Mobara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mobārakeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mobile",
    country: "United States",
    code: "US",
  },
  {
    city: "Moca",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Moçâmedes",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Mocoa",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Mococa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mocuba",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Mocuba",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Modāsa",
    country: "India",
    code: "IN",
  },
  {
    city: "Modena",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Modesto",
    country: "United States",
    code: "US",
  },
  {
    city: "Modi‘in Makkabbim Re‘ut",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Modica",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Moers",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Moga",
    country: "India",
    code: "IN",
  },
  {
    city: "Mogadishu",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Mogaung",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mogi das Cruzes",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mogi Guaçu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mogi Mirim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mogoditshane",
    country: "Botswana",
    code: "BW",
  },
  {
    city: "Mogok",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Moḩammad Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Mohammedia",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Moita",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Mojokerto",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Moju",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Mokameh",
    country: "India",
    code: "IN",
  },
  {
    city: "Moknine",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Mokpo",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Molave",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Molepolole",
    country: "Botswana",
    code: "BW",
  },
  {
    city: "Molfetta",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Molina de Segura",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Mollet",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Molo",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Mombasa",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Momostenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Monastir",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Moncada",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Moncalieri",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Mönchengladbach",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Monclova",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Moncton",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Móng Cái",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Möng Tun",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mongaguá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Monghyr",
    country: "India",
    code: "IN",
  },
  {
    city: "Mongu",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Monkayo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Monroe",
    country: "United States",
    code: "US",
  },
  {
    city: "Monrovia",
    country: "Liberia",
    code: "LR",
  },
  {
    city: "Mons",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Montauban",
    country: "France",
    code: "FR",
  },
  {
    city: "Monte Alegre",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Monte Chingolo",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Monte Mor",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Monte Plata",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Monte Santo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Montebello",
    country: "United States",
    code: "US",
  },
  {
    city: "Montego Bay",
    country: "Jamaica",
    code: "JM",
  },
  {
    city: "Montelíbano",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Montenegro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Montepuez",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Monterey Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Montería",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Montero",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Monterrey",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Montes Claros",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Montesilvano",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Montevideo",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Montgomery",
    country: "United States",
    code: "US",
  },
  {
    city: "Montijo",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Montpellier",
    country: "France",
    code: "FR",
  },
  {
    city: "Montréal",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Montreuil",
    country: "France",
    code: "FR",
  },
  {
    city: "Monywa",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Monza",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Mooka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Moore",
    country: "United States",
    code: "US",
  },
  {
    city: "Moortebeek",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Mopti",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Moquegua",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Mora",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "Morada Nova",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Morādābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Morales",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Moramanga",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Moratuwa",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Morbi",
    country: "India",
    code: "IN",
  },
  {
    city: "Morelia",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Morena",
    country: "India",
    code: "IN",
  },
  {
    city: "Moreno",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Moreno Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Morgantown",
    country: "United States",
    code: "US",
  },
  {
    city: "Moriguchi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Morioka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Moriya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Moriyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Morogoro",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Morón",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Morón",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Mörön",
    country: "Mongolia",
    code: "MN",
  },
  {
    city: "Morondava",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Morong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Moroni",
    country: "Comoros",
    code: "KM",
  },
  {
    city: "Moscow",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Moshi",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Mosigkau",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Mositai",
    country: "China",
    code: "CN",
  },
  {
    city: "Moskovskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Mosquera",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Moss",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Mossel Bay",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Mossoró",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Most",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Mostaganem",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Mostar",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Móstoles",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Mosul",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Mota",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Mothīhāri",
    country: "India",
    code: "IN",
  },
  {
    city: "Motril",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Moulay Abdallah",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Moundou",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Mount Lavinia",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Mount Pleasant",
    country: "United States",
    code: "US",
  },
  {
    city: "Mount Prospect",
    country: "United States",
    code: "US",
  },
  {
    city: "Mount Vernon",
    country: "United States",
    code: "US",
  },
  {
    city: "Mount Vernon",
    country: "United States",
    code: "US",
  },
  {
    city: "Mountain View",
    country: "United States",
    code: "US",
  },
  {
    city: "Mouscron",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Moyobamba",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Mpanda",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Mpondwe",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Mporokoso",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Msaken",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Mtwara",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Muar",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Muban Saeng Bua Thong",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Mubende",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Mubi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Mudanjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Mudon",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mufulira",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Muğla",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mukacheve",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Mukeriān",
    country: "India",
    code: "IN",
  },
  {
    city: "Mukōchō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Mukono",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Muktsar",
    country: "India",
    code: "IN",
  },
  {
    city: "Mulanay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Mulbāgal",
    country: "India",
    code: "IN",
  },
  {
    city: "Mülheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Mulhouse",
    country: "France",
    code: "FR",
  },
  {
    city: "Mulongo",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Multai",
    country: "India",
    code: "IN",
  },
  {
    city: "Multan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Mumbai",
    country: "India",
    code: "IN",
  },
  {
    city: "Mumias",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Mun’gyŏng",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Munakata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Munch’ŏn",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Muncie",
    country: "United States",
    code: "US",
  },
  {
    city: "Mūndka",
    country: "India",
    code: "IN",
  },
  {
    city: "Mungeli",
    country: "India",
    code: "IN",
  },
  {
    city: "Mungo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Munich",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Münster",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Muntinlupa City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Munūf",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Murakami",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Murcia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Murcia",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Murfreesboro",
    country: "United States",
    code: "US",
  },
  {
    city: "Muriaé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Muridke",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Murmansk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Murom",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Muroran",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Murray",
    country: "United States",
    code: "US",
  },
  {
    city: "Murrieta",
    country: "United States",
    code: "US",
  },
  {
    city: "Muş",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Musashimurayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Musashino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Muscat",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Muskegon",
    country: "United States",
    code: "US",
  },
  {
    city: "Musoma",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Mut",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Mutare",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Mutsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Muzaffarabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Muzaffargarh",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Muzaffarpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Mwanza",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Mweka",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Mwene-Ditu",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "My Drarga",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Mỹ Tho",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Myaungmya",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Myawadi",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Myaydo",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Myebon",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Myeik",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Myingyan",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Myitkyina",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Mykolaiv",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Mymensingh",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Myrnohrad",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Myrtle Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Mysłowice",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Mytishchi",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Mzuzu",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "N’Djamena",
    country: "Chad",
    code: "TD",
  },
  {
    city: "N’Zérékoré",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Nabari",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nabatîyé",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Naberezhnyye Chelny",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nabeul",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Nābha",
    country: "India",
    code: "IN",
  },
  {
    city: "Nabire",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Nāblus",
    country: "West Bank",
    code: "XW",
  },
  {
    city: "Nabua",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nabunturan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nacaome",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Nada",
    country: "China",
    code: "CN",
  },
  {
    city: "Nadi",
    country: "Fiji",
    code: "FJ",
  },
  {
    city: "Nadiād",
    country: "India",
    code: "IN",
  },
  {
    city: "Nador",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Naga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Naga City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nagahama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagakute",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagaoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagaoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagareyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagari",
    country: "India",
    code: "IN",
  },
  {
    city: "Nagasaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagcarlan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nagda",
    country: "India",
    code: "IN",
  },
  {
    city: "Nāgercoil",
    country: "India",
    code: "IN",
  },
  {
    city: "Nagīna",
    country: "India",
    code: "IN",
  },
  {
    city: "Nago",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nagoya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nāgpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Nagqu",
    country: "China",
    code: "CN",
  },
  {
    city: "Nagua",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Naguilian",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Naha",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nahariyya",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Nahāvand",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Nāḩiyat al Iskandarīyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Nāḩiyat al Karmah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Nāḩiyat Ghammās",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Nāḩiyat Khān Banī Sa‘d",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Nahualá",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Naic",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Naihāti",
    country: "India",
    code: "IN",
  },
  {
    city: "Nairobi",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Najafābād",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Najafgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Najrān",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Naju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Naka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nakagawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nakano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nakatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nakatsugawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nakhodka",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nakhon Pathom",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Nakhon Ratchasima",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Nakhon Sawan",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Nakhon Si Thammarat",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Nakuru",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Nalchik",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nalgonda",
    country: "India",
    code: "IN",
  },
  {
    city: "Nālūt",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Nam Định",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Namacunde",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Nāmakkal",
    country: "India",
    code: "IN",
  },
  {
    city: "Namangan",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Nambuangongo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Namhkam",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Nampa",
    country: "United States",
    code: "US",
  },
  {
    city: "Nampo",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Nampula",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Namsan",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Namur",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Nan Zhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanaimo",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Nanao",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nanchang",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanchong",
    country: "China",
    code: "CN",
  },
  {
    city: "Nancy",
    country: "France",
    code: "FR",
  },
  {
    city: "Nānded",
    country: "India",
    code: "IN",
  },
  {
    city: "Nāndgaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Nandi Hills",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Nāndod",
    country: "India",
    code: "IN",
  },
  {
    city: "Nandyāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Nanfengcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Nangandao",
    country: "China",
    code: "CN",
  },
  {
    city: "Nāngloi Jāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Nangong",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanjangūd",
    country: "India",
    code: "IN",
  },
  {
    city: "Nanjian",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanjing",
    country: "China",
    code: "CN",
  },
  {
    city: "Nankana Sahib",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Nanning",
    country: "China",
    code: "CN",
  },
  {
    city: "Nānpāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Nanping",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanqiaotou",
    country: "China",
    code: "CN",
  },
  {
    city: "Nansana",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Nansang",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Nantang",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanterre",
    country: "France",
    code: "FR",
  },
  {
    city: "Nantes",
    country: "France",
    code: "FR",
  },
  {
    city: "Nantingcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Nantong",
    country: "China",
    code: "CN",
  },
  {
    city: "Nantou",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Nanxicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Nanyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Naogaon",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Napa",
    country: "United States",
    code: "US",
  },
  {
    city: "Naperville",
    country: "United States",
    code: "US",
  },
  {
    city: "Napier",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Naples",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Naqadeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Nara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Narasapur",
    country: "India",
    code: "IN",
  },
  {
    city: "Narasaraopet",
    country: "India",
    code: "IN",
  },
  {
    city: "Narashino",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nārāyanganj",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Narbonne",
    country: "France",
    code: "FR",
  },
  {
    city: "Narela",
    country: "India",
    code: "IN",
  },
  {
    city: "Narita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nārnaul",
    country: "India",
    code: "IN",
  },
  {
    city: "Naro-Fominsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Narra",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Narsingdi",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Narutochō-mitsuishi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Narva",
    country: "Estonia",
    code: "EE",
  },
  {
    city: "Narwāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Nashua",
    country: "United States",
    code: "US",
  },
  {
    city: "Nashville",
    country: "United States",
    code: "US",
  },
  {
    city: "Nāsik",
    country: "India",
    code: "IN",
  },
  {
    city: "Nasīm Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Nasīrabād",
    country: "India",
    code: "IN",
  },
  {
    city: "Nassau",
    country: "The Bahamas",
    code: "BS",
  },
  {
    city: "Nasugbu",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nasushiobara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Natal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Natal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "National City",
    country: "United States",
    code: "US",
  },
  {
    city: "Natitingou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Natogyi",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Natori-shi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Naucalpan de Juárez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Naugaon Sādāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Naujan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nausori",
    country: "Fiji",
    code: "FJ",
  },
  {
    city: "Nautanwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Navadwīp",
    country: "India",
    code: "IN",
  },
  {
    city: "Naval",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Navapolatsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Navarre",
    country: "United States",
    code: "US",
  },
  {
    city: "Navegantes",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Naviraí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Navoiy",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Navojoa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Navotas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nawá",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Nawābganj",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Nawabshah",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Nawāda",
    country: "India",
    code: "IN",
  },
  {
    city: "Nawalgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Nawucun",
    country: "China",
    code: "CN",
  },
  {
    city: "Naxçıvan",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Nay Pyi Taw",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Naz̧arābād",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Nazareth",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Nazarovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nazilli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Nazipur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Nazran",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nazrēt",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Nchelenge",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Ndalatando",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ndali",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Ndjamba",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ndola",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Ndulo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Néa Ionía",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Néa Smýrni",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Neath",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Necochea",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Necoclí",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Nedumangād",
    country: "India",
    code: "IN",
  },
  {
    city: "Neftekamsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nefteyugansk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Negage",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Negapatam",
    country: "India",
    code: "IN",
  },
  {
    city: "Negombo",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Nehe",
    country: "China",
    code: "CN",
  },
  {
    city: "Neibu",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Neijiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Neiva",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Nek’emtē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Nekā",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Nelamangala",
    country: "India",
    code: "IN",
  },
  {
    city: "Nellore",
    country: "India",
    code: "IN",
  },
  {
    city: "Néma",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Ñemby",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Nenjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Nentón",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Nepālgañj",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Nerima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nerkunram",
    country: "India",
    code: "IN",
  },
  {
    city: "Neryungri",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nes Ẕiyyona",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Netanya",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Netrakona",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Neu-Ulm",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Neubrandenburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Neuilly-sur-Seine",
    country: "France",
    code: "FR",
  },
  {
    city: "Neumünster",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Neuquén",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Neuss",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Neustadt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Neuwied",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Nevinnomyssk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nevşehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "New Bedford",
    country: "United States",
    code: "US",
  },
  {
    city: "New Braunfels",
    country: "United States",
    code: "US",
  },
  {
    city: "New Britain",
    country: "United States",
    code: "US",
  },
  {
    city: "New Brunswick",
    country: "United States",
    code: "US",
  },
  {
    city: "New Corella",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "New Delhi",
    country: "India",
    code: "IN",
  },
  {
    city: "New Haven",
    country: "United States",
    code: "US",
  },
  {
    city: "New Kru Town",
    country: "Liberia",
    code: "LR",
  },
  {
    city: "New Mirpur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "New Orleans",
    country: "United States",
    code: "US",
  },
  {
    city: "New Plymouth",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "New Rochelle",
    country: "United States",
    code: "US",
  },
  {
    city: "New Westminster",
    country: "Canada",
    code: "CA",
  },
  {
    city: "New York",
    country: "United States",
    code: "US",
  },
  {
    city: "Newark",
    country: "United States",
    code: "US",
  },
  {
    city: "Newark",
    country: "United States",
    code: "US",
  },
  {
    city: "Newcastle",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Newcastle",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Newcastle",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Newcastle under Lyme",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Newmarket",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Newport",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Newport Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Newport News",
    country: "United States",
    code: "US",
  },
  {
    city: "Newton",
    country: "United States",
    code: "US",
  },
  {
    city: "Neya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Neyshābūr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Neyveli",
    country: "India",
    code: "IN",
  },
  {
    city: "Neyyāttinkara",
    country: "India",
    code: "IN",
  },
  {
    city: "Nezahualcóyotl",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ngã Bảy",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Ngaoundéré",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Nghi Sơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Nghĩa Lộ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Ngong",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Nguru",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Nha Trang",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Niagara Falls",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Niamey",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Nice",
    country: "France",
    code: "FR",
  },
  {
    city: "Nichinan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nicolás Romero",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Nicosia",
    country: "Cyprus",
    code: "CY",
  },
  {
    city: "Nicoya",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "Nieuwegein",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Niğde",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Nihonmatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nihtaur",
    country: "India",
    code: "IN",
  },
  {
    city: "Niigata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Niihama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Niiza",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nijmegen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Níkaia",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Nikki",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Nikkō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nikopol",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Niksar",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Nikšić",
    country: "Montenegro",
    code: "ME",
  },
  {
    city: "Nilanga",
    country: "India",
    code: "IN",
  },
  {
    city: "Nīmbāhera",
    country: "India",
    code: "IN",
  },
  {
    city: "Nîmes",
    country: "France",
    code: "FR",
  },
  {
    city: "Ning’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Ningbo",
    country: "China",
    code: "CN",
  },
  {
    city: "Ningde",
    country: "China",
    code: "CN",
  },
  {
    city: "Ninh Bình",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Ninh Hòa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Niort",
    country: "France",
    code: "FR",
  },
  {
    city: "Nipāni",
    country: "India",
    code: "IN",
  },
  {
    city: "Nirāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Nirmal",
    country: "India",
    code: "IN",
  },
  {
    city: "Niš",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Nishinomiya-hama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nishio",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nishitōkyō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nisshin",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Niterói",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Nitra",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Nizāmābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Nizhnekamsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nizhnevartovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nizhniy Novgorod",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nizhniy Tagil",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nizhyn",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Nizip",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Nizwá",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Njeru",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Nkawkaw",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Nkhotakota",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Nkongsamba",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Nkpor",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Nnewi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Nobeoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Noblesville",
    country: "United States",
    code: "US",
  },
  {
    city: "Noda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nogales",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Nōgata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Noginsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Noginsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Noida",
    country: "India",
    code: "IN",
  },
  {
    city: "Noisy-le-Grand",
    country: "France",
    code: "FR",
  },
  {
    city: "Nokha",
    country: "India",
    code: "IN",
  },
  {
    city: "Noksan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Nong’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Nonoichi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nonthaburi",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Norderstedt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Nordhorn",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Nordre Fåle",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Norfolk",
    country: "United States",
    code: "US",
  },
  {
    city: "Norilsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Normal",
    country: "United States",
    code: "US",
  },
  {
    city: "Norman",
    country: "United States",
    code: "US",
  },
  {
    city: "Norrköping",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "North Bay",
    country: "Canada",
    code: "CA",
  },
  {
    city: "North Bergen",
    country: "United States",
    code: "US",
  },
  {
    city: "North Bethesda",
    country: "United States",
    code: "US",
  },
  {
    city: "North Charleston",
    country: "United States",
    code: "US",
  },
  {
    city: "North Hempstead",
    country: "United States",
    code: "US",
  },
  {
    city: "North Lakhimpur",
    country: "India",
    code: "IN",
  },
  {
    city: "North Las Vegas",
    country: "United States",
    code: "US",
  },
  {
    city: "North Little Rock",
    country: "United States",
    code: "US",
  },
  {
    city: "North Miami",
    country: "United States",
    code: "US",
  },
  {
    city: "North Port",
    country: "United States",
    code: "US",
  },
  {
    city: "North Richland Hills",
    country: "United States",
    code: "US",
  },
  {
    city: "North Vancouver",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Northampton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Northcote",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Northwich",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Norton",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Norwalk",
    country: "United States",
    code: "US",
  },
  {
    city: "Norwalk",
    country: "United States",
    code: "US",
  },
  {
    city: "Norwich",
    country: "United States",
    code: "US",
  },
  {
    city: "Norwich",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Norzagaray",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Nossa Senhora do Socorro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Nottingham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Nouadhibou",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Nouakchott",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Nouméa",
    country: "New Caledonia",
    code: "NC",
  },
  {
    city: "Nova Esperança",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Nova Friburgo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Nova Lima",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Nova Odessa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Nova Serrana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Novara",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Novato",
    country: "United States",
    code: "US",
  },
  {
    city: "Novaya Balakhna",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novi",
    country: "United States",
    code: "US",
  },
  {
    city: "Novi Pazar",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Novi Sad",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Novo Hamburgo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Novoaltaysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novocheboksarsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novocherkassk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novohrad-Volynskyi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Novokuybyshevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novokuznetsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novomoskovsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Novomoskovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novorossiysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novoshakhtinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novosibirsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novotroitsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novouralsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Novovolynsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Novyy Urengoy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nowgong",
    country: "India",
    code: "IN",
  },
  {
    city: "Nowrangapur",
    country: "India",
    code: "IN",
  },
  {
    city: "Nowshera",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Nowy Sącz",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Noyabrsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nsukka",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ntoum",
    country: "Gabon",
    code: "GA",
  },
  {
    city: "Nueva Concepción",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Nueva Guinea",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Nuevo Casas Grandes",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Nuevo Laredo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Nukus",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Numan",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Numazu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Nuneaton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Nur-Sultan",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Nuremberg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Nusaybin",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Nūzvīd",
    country: "India",
    code: "IN",
  },
  {
    city: "Nyagan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Nyala",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Nyanza",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Nyenga",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Nyeri",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Nyíregyháza",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "O'Fallon",
    country: "United States",
    code: "US",
  },
  {
    city: "Oak Lawn",
    country: "United States",
    code: "US",
  },
  {
    city: "Oak Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Ōakashichō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Oakland",
    country: "United States",
    code: "US",
  },
  {
    city: "Oakville",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Oas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Oaxaca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Obando",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Oberá",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Oberhausen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Obihiro",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Obninsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ōbu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Obuase",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ocala",
    country: "United States",
    code: "US",
  },
  {
    city: "Ocampo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ocaña",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Oceanside",
    country: "United States",
    code: "US",
  },
  {
    city: "Ocotlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ocoyoacac",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ocozocoautla de Espinosa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ocumare del Tuy",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Oda",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Ōdate",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Odawara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ödemiş",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Odendaalsrus",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Odense",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Odesa",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Odessa",
    country: "United States",
    code: "US",
  },
  {
    city: "Odienné",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Odintsovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Odivelas",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Oeiras",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Offenburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ōgaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ogbomoso",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ogden",
    country: "United States",
    code: "US",
  },
  {
    city: "Ogōri",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ogōshi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ohrid",
    country: "Macedonia",
    code: "MK",
  },
  {
    city: "Ōita",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ŏjŏk-tong",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Okara",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Okayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Okazaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Oke-Mesi",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Okegawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Okene",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Okinawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Oklahoma City",
    country: "United States",
    code: "US",
  },
  {
    city: "Oktyabr’skiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Olanchito",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Olathe",
    country: "United States",
    code: "US",
  },
  {
    city: "Olavarría",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Olbia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Old Bridge",
    country: "United States",
    code: "US",
  },
  {
    city: "Oldenburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Oldham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Oleksandriia",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Olímpia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Olinda",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Oliveira de Azemeis",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Olmaliq",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Olomouc",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Olongapo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Olsztyn",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Olympia",
    country: "United States",
    code: "US",
  },
  {
    city: "Omaha",
    country: "United States",
    code: "US",
  },
  {
    city: "Omdurman",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Ōme",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Omīdīyeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ōmihachiman",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ōmiyachō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Omsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ōmura",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ōmuta",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ondjiva",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ondo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ongata Rongai",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Ongjang",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Ongole",
    country: "India",
    code: "IN",
  },
  {
    city: "Onitsha",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ōnojō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Onomichi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ontario",
    country: "United States",
    code: "US",
  },
  {
    city: "Oodweyne",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Oosterhout",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ootacamund",
    country: "India",
    code: "IN",
  },
  {
    city: "Opava",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Opol",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Opole",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Oradea",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Orai",
    country: "India",
    code: "IN",
  },
  {
    city: "Oral",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Oran",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Orange",
    country: "United States",
    code: "US",
  },
  {
    city: "Orani",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ordu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Örebro",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Orekhovo-Borisovo Yuzhnoye",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Orekhovo-Zuyevo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Orël",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Orem",
    country: "United States",
    code: "US",
  },
  {
    city: "Orenburg",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Orihuela",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Orion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Orito",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Oriximiná",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Orizaba",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Orland Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Orlando",
    country: "United States",
    code: "US",
  },
  {
    city: "Orléans",
    country: "France",
    code: "FR",
  },
  {
    city: "Ormoc",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Oroquieta",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Orpington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Orsha",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Orsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Orūmīyeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Oruro",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Oruro",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Ōsaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ōsakasayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ōsaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Osan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Osasco",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Osh",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "Oshawa",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Oshkosh",
    country: "United States",
    code: "US",
  },
  {
    city: "Oshnavīyeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ōshū",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Osijek",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Osisioma",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Öskemen",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Oslo",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Osmānābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Osmaniye",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Osnabrück",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Osogbo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Osorno",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Oss",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ostend",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Ostrava",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Ostrołęka",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Ostrów Wielkopolski",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Ostrowiec Świętokrzyski",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Ostuncalco",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Ōta",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ōta-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Otaru",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ōtawara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Oton",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ōtsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ottappālam",
    country: "India",
    code: "IN",
  },
  {
    city: "Ottawa",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Otukpo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ouagadougou",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Ouahigouya",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Ouaké",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Ouargla",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Oudtshoorn",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Oued Lill",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Oued Zem",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Ouezzane",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Ouidah",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Ouinhri",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Oujda-Angad",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Oulad Teïma",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Oulgaret",
    country: "India",
    code: "IN",
  },
  {
    city: "Oulu",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Oum el Bouaghi",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Oumé",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Ourense",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Ouricuri",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ourinhos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ouro Prêto",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ovalle",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Ovar",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Overland Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Oviedo",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Owariasahi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Owendo",
    country: "Gabon",
    code: "GA",
  },
  {
    city: "Owensboro",
    country: "United States",
    code: "US",
  },
  {
    city: "Owerri",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Owo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Oxchuc",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Oxford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Oxnard",
    country: "United States",
    code: "US",
  },
  {
    city: "Oyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Oyem",
    country: "Gabon",
    code: "GA",
  },
  {
    city: "Oyo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Oyster Bay",
    country: "United States",
    code: "US",
  },
  {
    city: "Ozamiz City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ozërsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "P’yŏng-dong",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Paarl",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Pabianice",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pābna",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Pacajus",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pacatuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pāchora",
    country: "India",
    code: "IN",
  },
  {
    city: "Pachuca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Paco",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Paço do Lumiar",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pacora",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Paços de Ferreira",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Padalarang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Padang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Padangpanjang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Padangsidempuan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Paderborn",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Padiāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Padova",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Padre Garcia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Padre Hurtado",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Padre Las Casas",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Pagadian",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pagbilao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pageralam",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Paghmān",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Paignton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Paine",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Paingkyon",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Paisley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Paita",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Paju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Pak Kret",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Pākdasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Pakokku",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Pakpattan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Pakxé",
    country: "Laos",
    code: "LA",
  },
  {
    city: "Palaió Fáliro",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Palangkaraya",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Palangotu Adwār",
    country: "India",
    code: "IN",
  },
  {
    city: "Palatine",
    country: "United States",
    code: "US",
  },
  {
    city: "Palembang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Palencia",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Palencia",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Paleng",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Palenque",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Palermo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Palermo",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Paletwa",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Pālghar",
    country: "India",
    code: "IN",
  },
  {
    city: "Pālghāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Palhoça",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pāli",
    country: "India",
    code: "IN",
  },
  {
    city: "Pāli",
    country: "India",
    code: "IN",
  },
  {
    city: "Palimbang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Palín",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Pālitāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Pallāvaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Pallíni",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Palm Bay",
    country: "United States",
    code: "US",
  },
  {
    city: "Palm Beach Gardens",
    country: "United States",
    code: "US",
  },
  {
    city: "Palm Coast",
    country: "United States",
    code: "US",
  },
  {
    city: "Palm Desert",
    country: "United States",
    code: "US",
  },
  {
    city: "Palm Harbor",
    country: "United States",
    code: "US",
  },
  {
    city: "Palma",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Palma Soriano",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Palmaner",
    country: "India",
    code: "IN",
  },
  {
    city: "Palmares",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Palmas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Palmdale",
    country: "United States",
    code: "US",
  },
  {
    city: "Palmeira dos Índios",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Palmela",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Palmerston North",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Palmira",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Palni",
    country: "India",
    code: "IN",
  },
  {
    city: "Palo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Palo Alto",
    country: "United States",
    code: "US",
  },
  {
    city: "Palo Negro",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Palompon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pāloncha",
    country: "India",
    code: "IN",
  },
  {
    city: "Palopo",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Palpalá",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Palu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Palwal",
    country: "India",
    code: "IN",
  },
  {
    city: "Pamplona",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Pamplona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Pamukkale",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Pan’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Panabo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Panama City",
    country: "United States",
    code: "US",
  },
  {
    city: "Panama City",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Pančevo",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Panchkula",
    country: "India",
    code: "IN",
  },
  {
    city: "Pandacan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pandharpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Pāndhurnā",
    country: "India",
    code: "IN",
  },
  {
    city: "Pandi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Panevėžys",
    country: "Lithuania",
    code: "LT",
  },
  {
    city: "Pangantocan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pangkalpinang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pānihāti",
    country: "India",
    code: "IN",
  },
  {
    city: "Pānīpat",
    country: "India",
    code: "IN",
  },
  {
    city: "Paniqui",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Panjakent",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Panna",
    country: "India",
    code: "IN",
  },
  {
    city: "Panruti",
    country: "India",
    code: "IN",
  },
  {
    city: "Panshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Pānskura",
    country: "India",
    code: "IN",
  },
  {
    city: "Pantin",
    country: "France",
    code: "FR",
  },
  {
    city: "Panvel",
    country: "India",
    code: "IN",
  },
  {
    city: "Panzos",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Paombong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Paoy Paet",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Papantla de Olarte",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Papeete",
    country: "French Polynesia",
    code: "PF",
  },
  {
    city: "Paphos",
    country: "Cyprus",
    code: "CY",
  },
  {
    city: "Pará de Minas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Paracale",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Paracatu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Parachinar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Parādīp Garh",
    country: "India",
    code: "IN",
  },
  {
    city: "Paradise",
    country: "United States",
    code: "US",
  },
  {
    city: "Paragominas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Paraíso",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Parakou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Paramagudi",
    country: "India",
    code: "IN",
  },
  {
    city: "Paramaribo",
    country: "Suriname",
    code: "SR",
  },
  {
    city: "Paramount",
    country: "United States",
    code: "US",
  },
  {
    city: "Paraná",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Paranaguá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Paranavaí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Parang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Parang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Parbhani",
    country: "India",
    code: "IN",
  },
  {
    city: "Pardubice",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Pardwāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Paredes",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Parepare",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pariaman",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Parintins",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Paris",
    country: "France",
    code: "FR",
  },
  {
    city: "Parkent",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Parker",
    country: "United States",
    code: "US",
  },
  {
    city: "Parkersburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Parla",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Parma",
    country: "United States",
    code: "US",
  },
  {
    city: "Parma",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Parnaíba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Parnamirim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Parobé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Parral",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Parsippany-Troy Hills",
    country: "United States",
    code: "US",
  },
  {
    city: "Pārvatipuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Pasacao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pasadena",
    country: "United States",
    code: "US",
  },
  {
    city: "Pasadena",
    country: "United States",
    code: "US",
  },
  {
    city: "Pasaje",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Pasarkemis",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pasay City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pasco",
    country: "United States",
    code: "US",
  },
  {
    city: "Pasig City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pasir Gudang",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Pasrur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Passaic",
    country: "United States",
    code: "US",
  },
  {
    city: "Passau",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Passi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Passo Fundo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Passos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pasto",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Pasuruan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pātan",
    country: "India",
    code: "IN",
  },
  {
    city: "Patancheruvu",
    country: "India",
    code: "IN",
  },
  {
    city: "Paterna",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Pateros",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Paterson",
    country: "United States",
    code: "US",
  },
  {
    city: "Pathānkot",
    country: "India",
    code: "IN",
  },
  {
    city: "Pāthardi",
    country: "India",
    code: "IN",
  },
  {
    city: "Pathein",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Pati",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Patiāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Patikul",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Patna",
    country: "India",
    code: "IN",
  },
  {
    city: "Patnos",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Pato Branco",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Patos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Patos de Minas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pátra",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Patrocínio",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pattoki",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Pattukkottai",
    country: "India",
    code: "IN",
  },
  {
    city: "Patuakhāli",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Pátzcuaro",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Patzún",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Pau",
    country: "France",
    code: "FR",
  },
  {
    city: "Pau d’Alho",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pauktaw",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Paulínia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Paulista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Paulo Afonso",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pavia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pavia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Pavlodar",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Pavlohrad",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Pavlovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pavlovskiy Posad",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pawtucket",
    country: "United States",
    code: "US",
  },
  {
    city: "Payakumbuh",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Payatas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Paysandú",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Pazardzhik",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Peabody",
    country: "United States",
    code: "US",
  },
  {
    city: "Pearland",
    country: "United States",
    code: "US",
  },
  {
    city: "Pebane",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Pécs",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Péda-Houéyogbé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Pedro Brand",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Pedro Juan Caballero",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Pedro Leopoldo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Péhonko",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Peine",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Pekalongan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pekanbaru",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pelabuhanratu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pelileo",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Pelotas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pematangsiantar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pemba",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Pembroke Pines",
    country: "United States",
    code: "US",
  },
  {
    city: "Peñablanca",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Penafiel",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Peñaflor",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Peñalolén",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Penápolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pencheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Pendik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Penedo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pénjamo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pensacola",
    country: "United States",
    code: "US",
  },
  {
    city: "Penza",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Peoria",
    country: "United States",
    code: "US",
  },
  {
    city: "Peoria",
    country: "United States",
    code: "US",
  },
  {
    city: "Peranāmpattu",
    country: "India",
    code: "IN",
  },
  {
    city: "Pereira",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Pèrèrè",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Pergamino",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Perintalmanna",
    country: "India",
    code: "IN",
  },
  {
    city: "Peristéri",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Periya Semūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Perm",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pernik",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Perote",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Perpignan",
    country: "France",
    code: "FR",
  },
  {
    city: "Perris",
    country: "United States",
    code: "US",
  },
  {
    city: "Perth",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Perth Amboy",
    country: "United States",
    code: "US",
  },
  {
    city: "Perugia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Peruíbe",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Peruvancha",
    country: "India",
    code: "IN",
  },
  {
    city: "Pervomaisk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Pervouralsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pesaro",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Pescara",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Peshawar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Pesqueira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pessac",
    country: "France",
    code: "FR",
  },
  {
    city: "Petaẖ Tiqwa",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Petaling Jaya",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Petaluma",
    country: "United States",
    code: "US",
  },
  {
    city: "Petapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Petare",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Petarukan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Peterborough",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Peterborough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Pétion-Ville",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Petite Rivière de l’Artibonite",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Petlād",
    country: "India",
    code: "IN",
  },
  {
    city: "Petrel",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Petrolina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Petropavl",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Petropavlovsk-Kamchatskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Petrópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Petroúpoli",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Petrozavodsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Petržalka",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Pflugerville",
    country: "United States",
    code: "US",
  },
  {
    city: "Pforzheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Phagwāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Phaltan",
    country: "India",
    code: "IN",
  },
  {
    city: "Phan Rang-Tháp Chàm",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phan Thiết",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Pharr",
    country: "United States",
    code: "US",
  },
  {
    city: "Phatthaya",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Philadelphia",
    country: "United States",
    code: "US",
  },
  {
    city: "Phitsanulok",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Phnom Penh",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Phoenix",
    country: "United States",
    code: "US",
  },
  {
    city: "Phra Nakhon Si Ayutthaya",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Phủ Lý",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phú Quốc",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phú Thọ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phủ Từ Sơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phú Yên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phúc Yên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Phuket",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Phusro",
    country: "India",
    code: "IN",
  },
  {
    city: "Piacenza",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Piaseczno",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Piatra Neamţ",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Pickering",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Pico Rivera",
    country: "United States",
    code: "US",
  },
  {
    city: "Picos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pidugurālla",
    country: "India",
    code: "IN",
  },
  {
    city: "Piedecuesta",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Piedras Negras",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Piekary Śląskie",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pietermaritzburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Pigcawayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pijijiapan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pikine",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Pikit",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pila",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Piła",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pilar",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pilar",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Pili",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pililla",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pilkhua",
    country: "India",
    code: "IN",
  },
  {
    city: "Pinagkaisahan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pinamalayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pinamungahan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pinar del Río",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Pindamonhangaba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pine Hills",
    country: "United States",
    code: "US",
  },
  {
    city: "Pinellas Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Ping’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingdingshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingdu",
    country: "China",
    code: "CN",
  },
  {
    city: "Pinghu",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingliang",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingtung",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Pingxiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingyuanjie",
    country: "China",
    code: "CN",
  },
  {
    city: "Pingzhen",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Pinheiro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pinotepa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pinrang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pinsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Pinto",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Piotrków Trybunalski",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pipariā",
    country: "India",
    code: "IN",
  },
  {
    city: "Piracicaba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Piraçununga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Piraeus",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Pirané",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Pīrānshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Pirapora",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Piraquara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Piripiri",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pisa",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Piscataway",
    country: "United States",
    code: "US",
  },
  {
    city: "Pisco",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Pistoia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Pitalito",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Piteşti",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Pithampur",
    country: "India",
    code: "IN",
  },
  {
    city: "Pithāpuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Pithorāgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Pittsburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Pittsburgh",
    country: "United States",
    code: "US",
  },
  {
    city: "Piura",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Pizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Placentia",
    country: "United States",
    code: "US",
  },
  {
    city: "Placer",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Placetas",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Plainfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Plaisance",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Planaltina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Planeta Rica",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Plano",
    country: "United States",
    code: "US",
  },
  {
    city: "Plantation",
    country: "United States",
    code: "US",
  },
  {
    city: "Plaridel",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Plato",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Plauen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Playa del Carmen",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Playas de Rosarito",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pleasanton",
    country: "United States",
    code: "US",
  },
  {
    city: "Pleiku",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Pleven",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Płock",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Ploieşti",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Plovdiv",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Plymouth",
    country: "United States",
    code: "US",
  },
  {
    city: "Plymouth",
    country: "United States",
    code: "US",
  },
  {
    city: "Plymouth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Plzeň",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Poá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pobé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Poblacion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Poblacion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Poblacion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Poblacion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Poblacion",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pocatello",
    country: "United States",
    code: "US",
  },
  {
    city: "Pocheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Pochuta",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Poços de Caldas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Podgorica",
    country: "Montenegro",
    code: "ME",
  },
  {
    city: "Podolsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Podujevë",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Pohang",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Poinciana",
    country: "United States",
    code: "US",
  },
  {
    city: "Pointe-à-Pitre",
    country: "Guadeloupe",
    code: "GP",
  },
  {
    city: "Pointe-Noire",
    country: "Congo (Brazzaville)",
    code: "CG",
  },
  {
    city: "Poitiers",
    country: "France",
    code: "FR",
  },
  {
    city: "Pokhara",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Pokrovsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Pola de Siero",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Polangui",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Polatlı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Polatsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Polevskoy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pollāchi",
    country: "India",
    code: "IN",
  },
  {
    city: "Polokwane",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Polomolok",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Poltava",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Polūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Pombal",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Pomezia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Pomona",
    country: "United States",
    code: "US",
  },
  {
    city: "Pompano Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Ponce",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Pondokaren",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Ponferrada",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Pongnam",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Pongotan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ponnagyun",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Ponnāni",
    country: "India",
    code: "IN",
  },
  {
    city: "Ponnūru",
    country: "India",
    code: "IN",
  },
  {
    city: "Ponta Delgada",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Ponta Grossa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ponta Porã",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pontal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ponte Nova",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pontevedra",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pontevedra",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Pontiac",
    country: "United States",
    code: "US",
  },
  {
    city: "Pontianak",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Poole",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Poonamallee",
    country: "India",
    code: "IN",
  },
  {
    city: "Popayán",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Popeşti-Leordeni",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Popondetta",
    country: "Papua New Guinea",
    code: "PG",
  },
  {
    city: "Poprad",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Poptún",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Porac",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pordenone",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Pori",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Porirua",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Porlamar",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Port Arthur",
    country: "United States",
    code: "US",
  },
  {
    city: "Port Blair",
    country: "India",
    code: "IN",
  },
  {
    city: "Port Charlotte",
    country: "United States",
    code: "US",
  },
  {
    city: "Port Coquitlam",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Port Elizabeth",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Port Harcourt",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Port Huron",
    country: "United States",
    code: "US",
  },
  {
    city: "Port Louis",
    country: "Mauritius",
    code: "MU",
  },
  {
    city: "Port Moresby",
    country: "Papua New Guinea",
    code: "PG",
  },
  {
    city: "Port of Spain",
    country: "Trinidad and Tobago",
    code: "TT",
  },
  {
    city: "Port Orange",
    country: "United States",
    code: "US",
  },
  {
    city: "Port Said",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Port Shepstone",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Port St. Lucie",
    country: "United States",
    code: "US",
  },
  {
    city: "Port Sudan",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Port-au-Prince",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Port-Bouët",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Port-de-Paix",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Port-Gentil",
    country: "Gabon",
    code: "GA",
  },
  {
    city: "Port-Vila",
    country: "Vanuatu",
    code: "VU",
  },
  {
    city: "Portel",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Porterville",
    country: "United States",
    code: "US",
  },
  {
    city: "Portici",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Portimão",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Portland",
    country: "United States",
    code: "US",
  },
  {
    city: "Portland",
    country: "United States",
    code: "US",
  },
  {
    city: "Portmore",
    country: "Jamaica",
    code: "JM",
  },
  {
    city: "Porto",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Porto Alegre",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Porto Alexandre",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Porto Amboim",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Porto Feliz",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pôrto Ferreira",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pôrto Seguro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Porto Velho",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Porto-Novo",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Portoviejo",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Portsmouth",
    country: "United States",
    code: "US",
  },
  {
    city: "Portsmouth",
    country: "United States",
    code: "US",
  },
  {
    city: "Portsmouth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Posadas",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Potchefstroom",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Potenza",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Potiskum",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Potosí",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Pototan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Potsdam",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Pottstown",
    country: "United States",
    code: "US",
  },
  {
    city: "Poughkeepsie",
    country: "United States",
    code: "US",
  },
  {
    city: "Pouso Alegre",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Pouytenga",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Póvoa de Varzim",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Poza Rica de Hidalgo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pozi",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Poznań",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pozorrubio",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pozzuoli",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Prabumulih",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Prachuap Khiri Khan",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Pradera",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Prague",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Praia",
    country: "Cabo Verde",
    code: "CV",
  },
  {
    city: "Praia Grande",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Prato",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Prayagraj",
    country: "India",
    code: "IN",
  },
  {
    city: "Prescott Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Presidencia Roque Sáenz Peña",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "President Roxas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Presidente Franco",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Presidente Prudente",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Prešov",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Preston",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Pretoria",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Prey Veng",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Prijedor",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Prilep",
    country: "Macedonia",
    code: "MK",
  },
  {
    city: "Prince George",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Pristina",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Prizren",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Probolinggo",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Proddatūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Prokhladnyy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Prokopyevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Prosperidad",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Providence",
    country: "United States",
    code: "US",
  },
  {
    city: "Provo",
    country: "United States",
    code: "US",
  },
  {
    city: "Pruszków",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pryluky",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Przemyśl",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Pskov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pucallpa",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Pudong",
    country: "China",
    code: "CN",
  },
  {
    city: "Puducherry",
    country: "India",
    code: "IN",
  },
  {
    city: "Pudukkottai",
    country: "India",
    code: "IN",
  },
  {
    city: "Puebla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pueblo",
    country: "United States",
    code: "US",
  },
  {
    city: "Puente Alto",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Puerto Asís",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Puerto Ayacucho",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Puerto Barrios",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Puerto Boyacá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Puerto Cabello",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Puerto Cortés",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Puerto Iguazú",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Puerto La Cruz",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Puerto Libertador",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Puerto Limón",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "Puerto Madryn",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Puerto Maldonado",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Puerto Montt",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Puerto Padre",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Puerto Peñasco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Puerto Plata",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Puerto Princesa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Puerto Vallarta",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Pul-e Khumrī",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Pula",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Pulheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Puli",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Pulilan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Pulimaddi",
    country: "India",
    code: "IN",
  },
  {
    city: "Pulivendla",
    country: "India",
    code: "IN",
  },
  {
    city: "Puliyankudi",
    country: "India",
    code: "IN",
  },
  {
    city: "Pune",
    country: "India",
    code: "IN",
  },
  {
    city: "Punganūru",
    country: "India",
    code: "IN",
  },
  {
    city: "Puning",
    country: "China",
    code: "CN",
  },
  {
    city: "Puno",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Punta Alta",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Punta Arenas",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Punto Fijo",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Puqi",
    country: "China",
    code: "CN",
  },
  {
    city: "Puqiancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Puri",
    country: "India",
    code: "IN",
  },
  {
    city: "Purísima de Bustos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Purmerend",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Purnea",
    country: "India",
    code: "IN",
  },
  {
    city: "Pursat",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Purulhá",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Puruliya",
    country: "India",
    code: "IN",
  },
  {
    city: "Purwakarta",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Purwokerto",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Pushkino",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Putatan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Putian",
    country: "China",
    code: "CN",
  },
  {
    city: "Putrajaya",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Puttūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Puttūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Puxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Puyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Puyang Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Pyapon",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Pyatigorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Pyay",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Pyeongtaek",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Pyinmana",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Pyongyang",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Qā’em Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Qabb Eliâs",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Qabqa",
    country: "China",
    code: "CN",
  },
  {
    city: "Qabr as Sitt",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Qal‘ah-ye Now",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Qal‘at Bīshah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Qal‘at Sukkar",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Qalqīlyah",
    country: "West Bank",
    code: "XW",
  },
  {
    city: "Qalyūb",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Qamdo",
    country: "China",
    code: "CN",
  },
  {
    city: "Qaraçuxur",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Qaraghandy",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Qarchak",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Qardho",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Qarqan",
    country: "China",
    code: "CN",
  },
  {
    city: "Qarshi",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Qaskeleng",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Qazvīn",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Qian’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Qiantangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Qianwu",
    country: "China",
    code: "CN",
  },
  {
    city: "Qianxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Qianzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Qiaotou",
    country: "China",
    code: "CN",
  },
  {
    city: "Qinā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Qinbaling",
    country: "China",
    code: "CN",
  },
  {
    city: "Qincheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingan",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingdao",
    country: "China",
    code: "CN",
  },
  {
    city: "Qinggang",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingnian",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingping",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Qingzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Qinhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Qinhuangdao",
    country: "China",
    code: "CN",
  },
  {
    city: "Qinzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Qionghu",
    country: "China",
    code: "CN",
  },
  {
    city: "Qiqihar",
    country: "China",
    code: "CN",
  },
  {
    city: "Qiryat Ata",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Qiryat Gat",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Qo‘ng‘irot Shahri",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Qo‘qon",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Qom",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Qorveh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Qoryooley",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Qostanay",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Quảng Hà",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Quảng Ngãi",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Quảng Yên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Quanzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Quartu Sant’Elena",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Quatre Bornes",
    country: "Mauritius",
    code: "MU",
  },
  {
    city: "Qūchān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Quebec City",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Queen Creek",
    country: "United States",
    code: "US",
  },
  {
    city: "Queens",
    country: "United States",
    code: "US",
  },
  {
    city: "Queenstown",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Quelimane",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Queluz",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Querétaro",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Quetta",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Quetzaltenango",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Quevedo",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Quezaltepeque",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Quezon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Quezon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Quezon City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Qufu",
    country: "China",
    code: "CN",
  },
  {
    city: "Quibala",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Quibdó",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Quíbor",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Quilāndi",
    country: "India",
    code: "IN",
  },
  {
    city: "Quilenda",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Quilengues",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Quilicura",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Quillacollo",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Quillota",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Quilmes",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Quilon",
    country: "India",
    code: "IN",
  },
  {
    city: "Quilpué",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Quimbele",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Quimper",
    country: "France",
    code: "FR",
  },
  {
    city: "Quincy",
    country: "United States",
    code: "US",
  },
  {
    city: "Quipungo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Quito",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Quixadá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Quixeramobim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Qujing",
    country: "China",
    code: "CN",
  },
  {
    city: "Qŭnghirot",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Qurayyāt",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Quvasoy",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Quy Nhơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Quzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Qyzylorda",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Ra‘ananna",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Ra’s al Khafjī",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Ra’s al Khaymah",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "Ra’s Ghārib",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Raba",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Rabat",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Rạch Giá",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Racibórz",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Racine",
    country: "United States",
    code: "US",
  },
  {
    city: "Radès",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Radom",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Rāe Bareli",
    country: "India",
    code: "IN",
  },
  {
    city: "Rafael Calzada",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rafael Castillo",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rafaela",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rafaḩ",
    country: "Gaza Strip",
    code: "XG",
  },
  {
    city: "Rafḩā",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Rafsanjān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ragay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ragusa",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Raha",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Raharpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Rahat",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Rahimyar Khan",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Rahovec",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Rāhuri",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāichūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāiganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Raigarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Raipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājahmundry",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājapālaiyam",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājendranagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Rajin",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Rājkot",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājpura",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājsamand",
    country: "India",
    code: "IN",
  },
  {
    city: "Rājshāhi",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Raleigh",
    country: "United States",
    code: "US",
  },
  {
    city: "Rāmanāthapuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Ramapo",
    country: "United States",
    code: "US",
  },
  {
    city: "Ramat Gan",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Ramenskoye",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Rāmgundam",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāmhormoz",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ramla",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Rāmnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāmnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Râmnicu Vâlcea",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Ramon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ramos Arizpe",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Ramos Mejía",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rāmpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāmpur Hat",
    country: "India",
    code: "IN",
  },
  {
    city: "Ramu",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Rānāghāt",
    country: "India",
    code: "IN",
  },
  {
    city: "Rancagua",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Rānchi",
    country: "India",
    code: "IN",
  },
  {
    city: "Rancho Cordova",
    country: "United States",
    code: "US",
  },
  {
    city: "Rancho Cucamonga",
    country: "United States",
    code: "US",
  },
  {
    city: "Ranchuelo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Randburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Randers",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Randfontein",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Rangapukur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Rangewala",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Rangkasbitung",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Rangoon",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Rangpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Rānībennur",
    country: "India",
    code: "IN",
  },
  {
    city: "Rānipet",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāniyah",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Rantau Prapat",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Rāpar",
    country: "India",
    code: "IN",
  },
  {
    city: "Rapid City",
    country: "United States",
    code: "US",
  },
  {
    city: "Ras Tanura",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Rasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Rāsipuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Rass el Djebel",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Rastatt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Rasūlpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Ratangarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāth",
    country: "India",
    code: "IN",
  },
  {
    city: "Ratingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ratlām",
    country: "India",
    code: "IN",
  },
  {
    city: "Ratnāgiri",
    country: "India",
    code: "IN",
  },
  {
    city: "Ratnapura",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Raurkela",
    country: "India",
    code: "IN",
  },
  {
    city: "Ravenna",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Ravensburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Rawalpindi",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Rawānduz",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Rawasari",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Raxaul",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāyachoti",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāyadrug",
    country: "India",
    code: "IN",
  },
  {
    city: "Rāyagada",
    country: "India",
    code: "IN",
  },
  {
    city: "Rayleigh",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rayong",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Rāzampeta",
    country: "India",
    code: "IN",
  },
  {
    city: "Reading",
    country: "United States",
    code: "US",
  },
  {
    city: "Reading",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rechytsa",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Recife",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Recklinghausen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Reconquista",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Red Deer",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Redding",
    country: "United States",
    code: "US",
  },
  {
    city: "Redditch",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Redenção",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Redlands",
    country: "United States",
    code: "US",
  },
  {
    city: "Redmond",
    country: "United States",
    code: "US",
  },
  {
    city: "Redondo Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Redwood City",
    country: "United States",
    code: "US",
  },
  {
    city: "Regensburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Reggio di Calabria",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Reggio Emilia",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Regina",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Registro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Reẖovot",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Reims",
    country: "France",
    code: "FR",
  },
  {
    city: "Relizane",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Remedios de Escalada",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Remscheid",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Renca",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Rengo",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Renk",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Rennes",
    country: "France",
    code: "FR",
  },
  {
    city: "Reno",
    country: "United States",
    code: "US",
  },
  {
    city: "Renqiu",
    country: "China",
    code: "CN",
  },
  {
    city: "Renton",
    country: "United States",
    code: "US",
  },
  {
    city: "Renukūt",
    country: "India",
    code: "IN",
  },
  {
    city: "Repalle",
    country: "India",
    code: "IN",
  },
  {
    city: "Repentigny",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Resende",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Resistencia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Reşiţa",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Reston",
    country: "United States",
    code: "US",
  },
  {
    city: "Retalhuleu",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Reus",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Reutlingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Reutov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Revda",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Revere",
    country: "United States",
    code: "US",
  },
  {
    city: "Rewa",
    country: "India",
    code: "IN",
  },
  {
    city: "Rewāri",
    country: "India",
    code: "IN",
  },
  {
    city: "Reyhanlı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Reykjavík",
    country: "Iceland",
    code: "IS",
  },
  {
    city: "Reynosa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Rheine",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Rho",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Rhondda",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rialto",
    country: "United States",
    code: "US",
  },
  {
    city: "Ribeira do Pombal",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ribeirão das Neves",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ribeirão Pires",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ribeirão Prêto",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Riberalta",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Rîbniţa",
    country: "Moldova",
    code: "MD",
  },
  {
    city: "Richard-Toll",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Richards Bay",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Richardson",
    country: "United States",
    code: "US",
  },
  {
    city: "Richland",
    country: "United States",
    code: "US",
  },
  {
    city: "Richmond",
    country: "United States",
    code: "US",
  },
  {
    city: "Richmond",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Richmond",
    country: "United States",
    code: "US",
  },
  {
    city: "Richmond Hill",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Riga",
    country: "Latvia",
    code: "LV",
  },
  {
    city: "Rijeka",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Rijswijk",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Rimini",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Rincón de la Victoria",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Rincón de Romos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Rio Branco",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rio Claro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Río Cuarto",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rio das Ostras",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rio de Janeiro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rio do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Río Gallegos",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rio Grande",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Río Grande",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rio Largo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rio Negro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rio Rancho",
    country: "United States",
    code: "US",
  },
  {
    city: "Rio Tinto",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Rio Verde",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Riobamba",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Ríohacha",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Rionegro",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Riosucio",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Riosucio",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Rioverde",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Rishīkesh",
    country: "India",
    code: "IN",
  },
  {
    city: "Rishon LeẔiyyon",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Rishra",
    country: "India",
    code: "IN",
  },
  {
    city: "Rittō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Rivadavia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rivadavia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rivera",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Riverside",
    country: "United States",
    code: "US",
  },
  {
    city: "Riverview",
    country: "United States",
    code: "US",
  },
  {
    city: "Rivne",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Riyadh",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Rizal",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Rize",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Rizhao",
    country: "China",
    code: "CN",
  },
  {
    city: "Roanoke",
    country: "United States",
    code: "US",
  },
  {
    city: "Robāţ Karīm",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Robertsonpet",
    country: "India",
    code: "IN",
  },
  {
    city: "Robles",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Rochdale",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rochester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rochester",
    country: "United States",
    code: "US",
  },
  {
    city: "Rochester",
    country: "United States",
    code: "US",
  },
  {
    city: "Rochester Hills",
    country: "United States",
    code: "US",
  },
  {
    city: "Rock Hill",
    country: "United States",
    code: "US",
  },
  {
    city: "Rockford",
    country: "United States",
    code: "US",
  },
  {
    city: "Rocklin",
    country: "United States",
    code: "US",
  },
  {
    city: "Rockville",
    country: "United States",
    code: "US",
  },
  {
    city: "Rocky Mount",
    country: "United States",
    code: "US",
  },
  {
    city: "Ródos",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Rodriguez",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Roermond",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Roeselare",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Rogers",
    country: "United States",
    code: "US",
  },
  {
    city: "Rohri",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Rohtak",
    country: "India",
    code: "IN",
  },
  {
    city: "Rolândia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rolim de Moura",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Roman",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Rome",
    country: "United States",
    code: "US",
  },
  {
    city: "Rome",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Romford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rondonópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rongat",
    country: "India",
    code: "IN",
  },
  {
    city: "Rongcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Rongcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Rongcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Rongjiawan",
    country: "China",
    code: "CN",
  },
  {
    city: "Rongwo",
    country: "China",
    code: "CN",
  },
  {
    city: "Roodepoort",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Roosendaal",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Ropar",
    country: "India",
    code: "IN",
  },
  {
    city: "Roquetas de Mar",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Rosales",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Rosario",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Rosario",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Rosario",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Rosario",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Rosemead",
    country: "United States",
    code: "US",
  },
  {
    city: "Rosenheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Rosetta",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Roseville",
    country: "United States",
    code: "US",
  },
  {
    city: "Rosh Ha‘Ayin",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Roskilde",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Roslavl",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Rosso",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Rossosh",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Rostock",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Rostov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Roswell",
    country: "United States",
    code: "US",
  },
  {
    city: "Rotherham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rotorua",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Rotterdam",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Roubaix",
    country: "France",
    code: "FR",
  },
  {
    city: "Rouen",
    country: "France",
    code: "FR",
  },
  {
    city: "Rouiba",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Round Lake Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Round Rock",
    country: "United States",
    code: "US",
  },
  {
    city: "Rovaniemi",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Rovigo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Rowlett",
    country: "United States",
    code: "US",
  },
  {
    city: "Rowley Regis",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Roxas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Roxas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Roxas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Roxas City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Royal Leamington Spa",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Royal Oak",
    country: "United States",
    code: "US",
  },
  {
    city: "Royal Tunbridge Wells",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rubí",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Rubio",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Rubizhne",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Rubtsovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Rucheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Ruda Śląska",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Rudarpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Rudnyy",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Rueil-Malmaison",
    country: "France",
    code: "FR",
  },
  {
    city: "Rufisque",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Rugby",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ruhengeri",
    country: "Rwanda",
    code: "RW",
  },
  {
    city: "Rui’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Ruiming",
    country: "China",
    code: "CN",
  },
  {
    city: "Ruislip",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Ruma",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Rumia",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Runcorn",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Rundu",
    country: "Namibia",
    code: "NA",
  },
  {
    city: "Ruse",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Russas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Rüsselsheim",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Rustavi",
    country: "Georgia",
    code: "GE",
  },
  {
    city: "Rustenburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Ruteng",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Ryazan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Rybinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Rybnik",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Ryūgasaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Rzeszów",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Rzhev",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sa Đéc",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Sa-ch’on",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Şa‘dah",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Sa’ada",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Saanich",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Saarbrücken",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Sab‘ al Būr",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Sabadell",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Sabae",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sabanalarga",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Sabanalarga",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Sabaneta",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Sabará",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sabara Bangou",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Şabbāshahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Sabhā",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Sabinas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Sablayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Şabrātah",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Şabyā",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Sabzevār",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Sacaba",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Sacapulas",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Sach’on",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sacramento",
    country: "United States",
    code: "US",
  },
  {
    city: "Saddiqabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Sado",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Safi",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Saga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sagaing",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Sagamihara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sāgar",
    country: "India",
    code: "IN",
  },
  {
    city: "Sagay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Saginaw",
    country: "United States",
    code: "US",
  },
  {
    city: "Sagua de Tánamo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Sagua la Grande",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Saguenay",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Sagunto",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Saḩāb",
    country: "Jordan",
    code: "JO",
  },
  {
    city: "Sahagún",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Sahāranpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Saharsa",
    country: "India",
    code: "IN",
  },
  {
    city: "Sahaswān",
    country: "India",
    code: "IN",
  },
  {
    city: "Sāhibganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Sahiwal",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Sahuayo de Morelos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Saïda",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Saidpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Saidpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Saidu Sharif",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Saijō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Saiki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Saint Albans",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Saint Helens",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Saint John",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Saint Petersburg",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Saint-Denis",
    country: "France",
    code: "FR",
  },
  {
    city: "Saint-Denis",
    country: "Reunion",
    code: "RE",
  },
  {
    city: "Saint-Étienne",
    country: "France",
    code: "FR",
  },
  {
    city: "Saint-Hyacinthe",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Saint-Jean-sur-Richelieu",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Saint-Jérôme",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Saint-Louis",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Saint-Louis du Nord",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Saint-Louis du Sud",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Saint-Marc",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Saint-Maur-des-Fossés",
    country: "France",
    code: "FR",
  },
  {
    city: "Saint-Michel de l’Atalaye",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Saint-Nazaire",
    country: "France",
    code: "FR",
  },
  {
    city: "Saint-Ouen",
    country: "France",
    code: "FR",
  },
  {
    city: "Saint-Quentin",
    country: "France",
    code: "FR",
  },
  {
    city: "Saitama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakado",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakaidechō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakākā",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Sakarya",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sakata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakété",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Saki",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Sakiet ed Daier",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Sakiet ez Zit",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Sakon Nakhon",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Saku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakubva",
    country: "Zimbabwe",
    code: "ZW",
  },
  {
    city: "Sakura",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sakurai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Şalālah",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Salamá",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Salaman",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Salamanca",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Salamanca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Salatiga",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Salavat",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Saldanha",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Sale",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Sale",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Salekhard",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Salem",
    country: "United States",
    code: "US",
  },
  {
    city: "Salem",
    country: "India",
    code: "IN",
  },
  {
    city: "Salerno",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Salford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Salgueiro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Salihli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Salihorsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Salima",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Salina Cruz",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Salinas",
    country: "United States",
    code: "US",
  },
  {
    city: "Salisbury",
    country: "United States",
    code: "US",
  },
  {
    city: "Salmān Bāk",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Salmās",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Salo",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Salsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Salt Lake City",
    country: "India",
    code: "IN",
  },
  {
    city: "Salt Lake City",
    country: "United States",
    code: "US",
  },
  {
    city: "Salta",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Saltillo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Salto",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Salto",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Salto de Agua",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Salvador",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Salzburg",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Salzgitter",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Sầm Sơn",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Samā’il",
    country: "Oman",
    code: "OM",
  },
  {
    city: "Samadiāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Samal",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sāmalkot",
    country: "India",
    code: "IN",
  },
  {
    city: "Samālūţ",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Samaná",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Samāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Samandağ",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Samannūd",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Samara",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Samarinda",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Samarkand",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Sāmarrā’",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Samāstipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Sambalpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Sambava",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Sambhal",
    country: "India",
    code: "IN",
  },
  {
    city: "Sambrial",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Samch’ŏk",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sammamish",
    country: "United States",
    code: "US",
  },
  {
    city: "Sampit",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Samraong",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Samsun",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Samundri",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Samut Prakan",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Samut Sakhon",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "San Agustín Acasaguastlán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Andres",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Andrés",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "San Andrés Tuxtla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Andrés Villa Seca",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Angelo",
    country: "United States",
    code: "US",
  },
  {
    city: "San Antonio",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Antonio",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "San Antonio",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Antonio",
    country: "Chile",
    code: "CL",
  },
  {
    city: "San Antonio",
    country: "United States",
    code: "US",
  },
  {
    city: "San Antonio del Táchira",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Antonio Enchisi",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Antonio Suchitepéquez",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Bartolomé",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San Baudilio de Llobregat",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San Bernardino",
    country: "United States",
    code: "US",
  },
  {
    city: "San Bernardo",
    country: "Chile",
    code: "CL",
  },
  {
    city: "San Buenaventura",
    country: "United States",
    code: "US",
  },
  {
    city: "San Carlos",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Carlos",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Carlos City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Carlos de Bariloche",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Carlos del Zulia",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Clemente",
    country: "United States",
    code: "US",
  },
  {
    city: "San Cristóbal",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Cristóbal",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "San Cristóbal",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Cristóbal Verapaz",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Cugat del Vallés",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San Diego",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Diego",
    country: "United States",
    code: "US",
  },
  {
    city: "San Dionisio",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Estanislao",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "San Fabian",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Felipe",
    country: "Chile",
    code: "CL",
  },
  {
    city: "San Felipe",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Felipe del Progreso",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Fernando",
    country: "Chile",
    code: "CL",
  },
  {
    city: "San Fernando",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Fernando",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Fernando",
    country: "Trinidad and Tobago",
    code: "TT",
  },
  {
    city: "San Fernando",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San Fernando",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Fernando",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Fernando",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Francisco",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Francisco",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Francisco",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Francisco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Francisco",
    country: "United States",
    code: "US",
  },
  {
    city: "San Francisco de Macorís",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "San Francisco del Rincón",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Francisco El Alto",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Francisco Solano",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Germán",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "San Gil",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "San Ignacio",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "San Ignacio",
    country: "Peru",
    code: "PE",
  },
  {
    city: "San Ildefonso",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Isidro",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Isidro",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Jacinto",
    country: "United States",
    code: "US",
  },
  {
    city: "San Joaquin",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Joaquín",
    country: "Chile",
    code: "CL",
  },
  {
    city: "San Jose",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Jose",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Jose",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Jose",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Jose",
    country: "United States",
    code: "US",
  },
  {
    city: "San José",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "San José de las Lajas",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "San José del Cabo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San José del Guaviare",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "San Jose del Monte",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San José Pinula",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San José Villa de Allende",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Juan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Juan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Juan",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "San Juan",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Juan",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "San Juan Chamelco",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Juan de los Lagos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Juan de los Morros",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "San Juan del Río",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Juan Opico",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "San Juan Sacatepéquez",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Justo",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Leandro",
    country: "United States",
    code: "US",
  },
  {
    city: "San Leonardo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Lorenzo",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "San Luis",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "San Luis",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Luis",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "San Luis",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Luis",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Luis de la Paz",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Luis Potosí",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Luis Río Colorado",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Manuel",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "San Manuel",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Marcos",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "San Marcos",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Marcos",
    country: "United States",
    code: "US",
  },
  {
    city: "San Marcos",
    country: "United States",
    code: "US",
  },
  {
    city: "San Marcos",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "San Mariano",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Martín",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Martín Jilotepeque",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Martin Texmelucan de Labastida",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Mateo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Mateo",
    country: "United States",
    code: "US",
  },
  {
    city: "San Mateo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Mateo Ixtatán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Miguel",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Miguel",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Miguel",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "San Miguel de Allende",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Miguel de Tucumán",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Miguel Ixtahuacán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Miguelito",
    country: "Panama",
    code: "PA",
  },
  {
    city: "San Narciso",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Nicolás de los Arroyos",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Nicolás de los Garza",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Onofre",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "San Pablo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Pascual",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Pedro",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Pedro",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Pedro",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Pedro Ayampuc",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Pedro Carchá",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Pedro de Macorís",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "San Pedro Garza García",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "San Pedro Pinula",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Pedro Sacatepéquez",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Pedro Sacatepéquez",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "San Pedro Sula",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "San Rafael",
    country: "United States",
    code: "US",
  },
  {
    city: "San Rafael",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Rafael",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Ramon",
    country: "United States",
    code: "US",
  },
  {
    city: "San Ramón",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "San Ramón",
    country: "Chile",
    code: "CL",
  },
  {
    city: "San Ramón de la Nueva Orán",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Remigio",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Remo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "San Salvador",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "San Salvador de Jujuy",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "San Sebastián de los Reyes",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San Severo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "San Simon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "San Tan Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "San Vicente",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "San Vicente de Baracaldo",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San Vicente del Caguán",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "San Vicente del Raspeig",
    country: "Spain",
    code: "ES",
  },
  {
    city: "San-Pédro",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Sanaa",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Sanandaj",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Sancti Spíritus",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Sandachō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sandakan",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Sandefjord",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Sandīla",
    country: "India",
    code: "IN",
  },
  {
    city: "Sandnes",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Sandton",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Sandvika",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Sandy",
    country: "United States",
    code: "US",
  },
  {
    city: "Sandy Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Sanford",
    country: "United States",
    code: "US",
  },
  {
    city: "Sangamner",
    country: "India",
    code: "IN",
  },
  {
    city: "Sangāreddi",
    country: "India",
    code: "IN",
  },
  {
    city: "Sangereng",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sangju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sāngli",
    country: "India",
    code: "IN",
  },
  {
    city: "Sangmélima",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Sangolquí",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Sangrūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Sanhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Sanjō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sankaranayinār Kovil",
    country: "India",
    code: "IN",
  },
  {
    city: "Sankeshwar",
    country: "India",
    code: "IN",
  },
  {
    city: "Sankt Augustin",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Sankt Gallen",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Sankt Pölten",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Şanlıurfa",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sanlúcar de Barrameda",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Sanmenxia",
    country: "China",
    code: "CN",
  },
  {
    city: "Sanming",
    country: "China",
    code: "CN",
  },
  {
    city: "Sannai",
    country: "India",
    code: "IN",
  },
  {
    city: "Sannār",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Sano",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Santa Ana",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Ana",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Ana",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Santa Ana",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Barbara",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Barbara",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Barbara",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Bárbara d’Oeste",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Catalina",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Catarina Ixtahuacán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa Catarina Otzolotepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santa Catarina Pinula",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa Clara",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Clara",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Santa Clarita",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Cruz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Cruz",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "Santa Cruz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Cruz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Cruz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Cruz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Cruz",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Cruz",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Santa Cruz",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Santa Cruz del Quiché",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa Cruz del Sur",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Santa Cruz do Capibaribe",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Cruz do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Cruz Xoxocotlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santa Eulalia",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa Fe",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Fe",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Santa Ignacia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Inês",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Isabel",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Isabel do Pará",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Lucía",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Santa Lucía",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Santa Lucía Cotzumalguapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa Luzia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Maria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Maria",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Maria",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Maria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa María Chiquimula",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa María La Pila",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Santa María Texmelucan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santa Marta",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Santa Monica",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Rita",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Rita",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Santa Rosa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santa Rosa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Rosa",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Santa Rosa",
    country: "United States",
    code: "US",
  },
  {
    city: "Santa Rosa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santa Rosa de Cabal",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Santa Rosa de Copán",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Santa Rosa Jauregui",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santa Tecla",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Santa Teresa del Tuy",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Santamesa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santana de Parnaíba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santana do Livramento",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santander",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Santander de Quilichao",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Santarém",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Santarém",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santee",
    country: "United States",
    code: "US",
  },
  {
    city: "Santiago",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santiago",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santiago",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Santiago",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Santiago de Compostela",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Santiago de Cuba",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Santiago del Estero",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Santiago Tianguistenco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santiago Tuxtla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santo Agostinho",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo Amaro",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo André",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo Ângelo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo Antônio de Jesus",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo Antônio do Descoberto",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo Domingo",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Santo Domingo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santo Domingo",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Santo Domingo de los Colorados",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Santo Domingo Este",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Santo Domingo Tehuantepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Santo Estêvão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Santo Tirso",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Santo Tomas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santo Tomas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Santo Tomé",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Santos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sanwal",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Sanya",
    country: "China",
    code: "CN",
  },
  {
    city: "Sanyō-Onoda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sanza Pombo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Sanzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "São Bento do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Bento do Una",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Bernardo do Campo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Borja",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Caetano do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Carlos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Cristóvão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Félix do Xingu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Francisco",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Francisco do Sul",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Gabriel",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Gonçalo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Gonçalo do Amarante",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São João da Boa Vista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São João del Rei",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Joaquim da Barra",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São José",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São José de Ribamar",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São José do Rio Pardo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São José do Rio Prêto",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São José dos Campos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São José dos Pinhais",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Leopoldo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Lourenço da Mata",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Luís",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Mateus",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Miguel do Guamá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Miguel dos Campos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Paulo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Pedro da Aldeia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Roque",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Sebastião",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Sebastião do Paraíso",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "São Tomé",
    country: "Sao Tome and Principe",
    code: "ST",
  },
  {
    city: "São Vicente",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sapé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sapele",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Sapiranga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sapporo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Saqqez",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Saquarema",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sar-e Pul",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Sara",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Saraburi",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Sarai Alamgir",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Sarajevo",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Sarandí",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Saransk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sarapiquí",
    country: "Costa Rica",
    code: "CR",
  },
  {
    city: "Sarapul",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sarasota",
    country: "United States",
    code: "US",
  },
  {
    city: "Saratoga Springs",
    country: "United States",
    code: "US",
  },
  {
    city: "Saratov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sarāvān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Saravia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sarcelles",
    country: "France",
    code: "FR",
  },
  {
    city: "Sardārshahr",
    country: "India",
    code: "IN",
  },
  {
    city: "Sardasht",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Sardhana",
    country: "India",
    code: "IN",
  },
  {
    city: "Sargodha",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Sarh",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Sariaya",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sarikei",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Sariwŏn",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Sarıyer",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sārni",
    country: "India",
    code: "IN",
  },
  {
    city: "Sarnia",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Sarov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sarpsborg",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Sarqan",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Sartā",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Sartrouville",
    country: "France",
    code: "FR",
  },
  {
    city: "Saruhanlı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sasagawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sasarām",
    country: "India",
    code: "IN",
  },
  {
    city: "Sasebo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Saskatoon",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Sasolburg",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Sassandra",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Sassari",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Sātāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Sätbayev",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Sātkhira",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Satna",
    country: "India",
    code: "IN",
  },
  {
    city: "Şatrovka",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Satsumasendai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sattenapalle",
    country: "India",
    code: "IN",
  },
  {
    city: "Satu Mare",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Satyamangalam",
    country: "India",
    code: "IN",
  },
  {
    city: "Saugor",
    country: "India",
    code: "IN",
  },
  {
    city: "Sault Ste. Marie",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Saumlaki",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Saunda",
    country: "India",
    code: "IN",
  },
  {
    city: "Saundatti",
    country: "India",
    code: "IN",
  },
  {
    city: "Saurimo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Sausar",
    country: "India",
    code: "IN",
  },
  {
    city: "Savannah",
    country: "United States",
    code: "US",
  },
  {
    city: "Savannakhet",
    country: "Laos",
    code: "LA",
  },
  {
    city: "Sāveh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Savona",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Sawahlunto",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sawangan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sawrān",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Say’ūn",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Sayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sayanogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sayaxché",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Sayhāt",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Saymayl",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Sayyid Şādiq",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Scafati",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Scandicci",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Scarborough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Schaumburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Schenectady",
    country: "United States",
    code: "US",
  },
  {
    city: "Scheveningen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Schiedam",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Schwäbisch Gmünd",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Schweinfurt",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Schwerin",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Science City of Muñoz",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Scottsdale",
    country: "United States",
    code: "US",
  },
  {
    city: "Scranton",
    country: "United States",
    code: "US",
  },
  {
    city: "Scunthorpe",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Seaside",
    country: "United States",
    code: "US",
  },
  {
    city: "Seattle",
    country: "United States",
    code: "US",
  },
  {
    city: "Seberang Jaya",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Sebring",
    country: "United States",
    code: "US",
  },
  {
    city: "Sechura",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Secunderābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Sefrou",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Ségbana",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Ségou",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Segovia",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Séguéla",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Sehore",
    country: "India",
    code: "IN",
  },
  {
    city: "Seinäjoki",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Seixal",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Sejong",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sekimachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sekondi",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Selçuklu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Semara",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Semarang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Semera",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Semey",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Semnān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Senador Canedo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Senahú",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Sendai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sendhwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Senhor do Bonfim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sennan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sentani",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Seogwipo",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Seoni",
    country: "India",
    code: "IN",
  },
  {
    city: "Seoni Mālwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Seoul",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sepatan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Seraing",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Serang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Serdar",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Serekunda",
    country: "The Gambia",
    code: "GM",
  },
  {
    city: "Seremban",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Sergiyev Posad",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Serik",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Seropédica",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Serov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Serpukhov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Serra",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Serra Talhada",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sérres",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Serrinha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sertãozinho",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sertolovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sesto San Giovanni",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Sesvete",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Setagaya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sete Lagoas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sétif",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Seto",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Settat",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Settsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Setúbal",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Sevastopol",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Severn",
    country: "United States",
    code: "US",
  },
  {
    city: "Severodvinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Severomorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Seversk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sevilla",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Sevran",
    country: "France",
    code: "FR",
  },
  {
    city: "Seydişehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Seyhan",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Şəki",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Sfântu-Gheorghe",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Sfax",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Sha Tin",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Shache",
    country: "China",
    code: "CN",
  },
  {
    city: "Shacheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Shadrinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Shagamu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Shah Alam",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Shah Latif Town",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Shahdol",
    country: "India",
    code: "IN",
  },
  {
    city: "Shahe",
    country: "China",
    code: "CN",
  },
  {
    city: "Shāhīn Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shāhjānpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shāhpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shahr-e Bābak",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shahr-e Kord",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shahr-e Qods",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shahreẕā",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shahrīār",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shahrisabz",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Shahrixon",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Shāhrūd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shājāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shakargarh",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Shakhty",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Shali",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sham Shui Po",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Shāmli",
    country: "India",
    code: "IN",
  },
  {
    city: "Shancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Shangchuankou",
    country: "China",
    code: "CN",
  },
  {
    city: "Shanghai",
    country: "China",
    code: "CN",
  },
  {
    city: "Shangqiu",
    country: "China",
    code: "CN",
  },
  {
    city: "Shangrao",
    country: "China",
    code: "CN",
  },
  {
    city: "Shangtangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Shangzhi",
    country: "China",
    code: "CN",
  },
  {
    city: "Shangzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Shanhu",
    country: "China",
    code: "CN",
  },
  {
    city: "Shāntipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shantou",
    country: "China",
    code: "CN",
  },
  {
    city: "Shanwei",
    country: "China",
    code: "CN",
  },
  {
    city: "Shaoguan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shaoshanzhan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shaowu",
    country: "China",
    code: "CN",
  },
  {
    city: "Shaoxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Shaoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Shaoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Sharjah",
    country: "United Arab Emirates",
    code: "AE",
  },
  {
    city: "Sharm ash Shaykh",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Sharūrah",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Shashemenē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Shawinigan",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Shawnee",
    country: "United States",
    code: "US",
  },
  {
    city: "Shchëkino",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Shchelkovo",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sheboygan",
    country: "United States",
    code: "US",
  },
  {
    city: "Sheffield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Shegaon",
    country: "India",
    code: "IN",
  },
  {
    city: "Sheikhpura",
    country: "India",
    code: "IN",
  },
  {
    city: "Shekhupura",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Shendi",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Shengli",
    country: "China",
    code: "CN",
  },
  {
    city: "Shenmu",
    country: "China",
    code: "CN",
  },
  {
    city: "Shenyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Shenzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Shenzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Sheopur",
    country: "India",
    code: "IN",
  },
  {
    city: "Sherbrooke",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Sherkot",
    country: "India",
    code: "IN",
  },
  {
    city: "Sherman",
    country: "United States",
    code: "US",
  },
  {
    city: "Sherpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Shibata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shibīn al Kawm",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Shibirghān",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Shibukawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shibuya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shibuya-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Shidong",
    country: "China",
    code: "CN",
  },
  {
    city: "Shihe",
    country: "China",
    code: "CN",
  },
  {
    city: "Shihezi",
    country: "China",
    code: "CN",
  },
  {
    city: "Shiji",
    country: "China",
    code: "CN",
  },
  {
    city: "Shijiazhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Shijōnawate",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shikohābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Shikokuchūō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shiliguri",
    country: "India",
    code: "IN",
  },
  {
    city: "Shillong",
    country: "India",
    code: "IN",
  },
  {
    city: "Shimada",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shimencun",
    country: "China",
    code: "CN",
  },
  {
    city: "Shimla",
    country: "India",
    code: "IN",
  },
  {
    city: "Shimoga",
    country: "India",
    code: "IN",
  },
  {
    city: "Shimotsuke",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shinagawa-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shinjuku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shinkai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shinozaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shinyanga",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Shiogama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shiojiri",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shirakawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shiraoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shirayamamachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shīrāz",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shirē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Shiroi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shīrvān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shishi",
    country: "China",
    code: "CN",
  },
  {
    city: "Shivpuri",
    country: "India",
    code: "IN",
  },
  {
    city: "Shiyan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shiyan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shizhaobi",
    country: "China",
    code: "CN",
  },
  {
    city: "Shizuishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shizuoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shkodër",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Shorāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shoreline",
    country: "United States",
    code: "US",
  },
  {
    city: "Shostka",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Shouguang",
    country: "China",
    code: "CN",
  },
  {
    city: "Shreveport",
    country: "United States",
    code: "US",
  },
  {
    city: "Shrewsbury",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Shrīrāmpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shuangcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Shuanghe",
    country: "China",
    code: "CN",
  },
  {
    city: "Shuangqiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Shuangshuicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Shuangyashan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shubrā al Khaymah",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Shuibian",
    country: "China",
    code: "CN",
  },
  {
    city: "Shuixi",
    country: "China",
    code: "CN",
  },
  {
    city: "Shuizhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Shujaabad",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Shujālpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Shulan",
    country: "China",
    code: "CN",
  },
  {
    city: "Shumen",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Shūnan",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Shuozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Shūsh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shūshtar",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Shuya",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Shuyangzha",
    country: "China",
    code: "CN",
  },
  {
    city: "Shwebo",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Shymkent",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Sialkot City",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Siasi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Siaton",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Šiauliai",
    country: "Lithuania",
    code: "LT",
  },
  {
    city: "Sibalom",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sibay",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sibiu",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Sibolga",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sibonga",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sibsāgar",
    country: "India",
    code: "IN",
  },
  {
    city: "Sibu",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Sibulan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Siddhapur",
    country: "India",
    code: "IN",
  },
  {
    city: "Siddhirganj",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Siddipet",
    country: "India",
    code: "IN",
  },
  {
    city: "Sidhi",
    country: "India",
    code: "IN",
  },
  {
    city: "Sidi Bel Abbès",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Sidi Bennour",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Sidi Bouzid",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Sidi Qacem",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Sidi Slimane",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Sidi Yahya Zaer",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Sidlaghatta",
    country: "India",
    code: "IN",
  },
  {
    city: "Sidon",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Siedlce",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Siegen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Siem Reap",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Siemianowice Śląskie",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Siena",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Sievierodonetsk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Sigaboy",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Siguatepeque",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Siguiri",
    country: "Guinea",
    code: "GN",
  },
  {
    city: "Sihanoukville",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Sihor",
    country: "India",
    code: "IN",
  },
  {
    city: "Sihui",
    country: "China",
    code: "CN",
  },
  {
    city: "Sihŭng",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Siirt",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sikandarābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Sīkar",
    country: "India",
    code: "IN",
  },
  {
    city: "Sikasso",
    country: "Mali",
    code: "ML",
  },
  {
    city: "Silang",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Silao",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Silay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Silchar",
    country: "India",
    code: "IN",
  },
  {
    city: "Siliancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Silifke",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Silivri",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sillod",
    country: "India",
    code: "IN",
  },
  {
    city: "Silopi",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Silvassa",
    country: "India",
    code: "IN",
  },
  {
    city: "Silver Spring",
    country: "United States",
    code: "US",
  },
  {
    city: "Simao",
    country: "China",
    code: "CN",
  },
  {
    city: "Simav",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Simdega",
    country: "India",
    code: "IN",
  },
  {
    city: "Simeulu",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Simferopol",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Simi Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Simojovel de Allende",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Sincelejo",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Sindangan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sindelfingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Sinendé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Singa",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Singaparna",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Singapore",
    country: "Singapore",
    code: "SG",
  },
  {
    city: "Singaraja",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Singida",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Singkawang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Singrauliya",
    country: "India",
    code: "IN",
  },
  {
    city: "Sinjār",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Sinnar",
    country: "India",
    code: "IN",
  },
  {
    city: "Sinnūris",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Sinop",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sinpo",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Sint-Niklaas",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Sintra",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Sinŭiju",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Sioux City",
    country: "United States",
    code: "US",
  },
  {
    city: "Sioux Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Sipalay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Siping",
    country: "China",
    code: "CN",
  },
  {
    city: "Sipocot",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sīra",
    country: "India",
    code: "IN",
  },
  {
    city: "Siracusa",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Sirājganj",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Sīrjān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Sironj",
    country: "India",
    code: "IN",
  },
  {
    city: "Sirsa",
    country: "India",
    code: "IN",
  },
  {
    city: "Sirsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Sirsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Sirsilla",
    country: "India",
    code: "IN",
  },
  {
    city: "Siruguppa",
    country: "India",
    code: "IN",
  },
  {
    city: "Sirūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Şirvan",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Sison",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sisophon",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Sītāmarhi",
    country: "India",
    code: "IN",
  },
  {
    city: "Sitārganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Sittingbourne",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Sittwe",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Siuna",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Siuri",
    country: "India",
    code: "IN",
  },
  {
    city: "Sivakāsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Sivas",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Siverek",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Siwān",
    country: "India",
    code: "IN",
  },
  {
    city: "Sizhan",
    country: "China",
    code: "CN",
  },
  {
    city: "Şırnak",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Skarżysko-Kamienna",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Skellefteå",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Skenderaj",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Skhirate",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Skien",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Skikda",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Skokie",
    country: "United States",
    code: "US",
  },
  {
    city: "Skopje",
    country: "Macedonia",
    code: "MK",
  },
  {
    city: "Slatina",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Slavonski Brod",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Slavyansk-na-Kubani",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Slidell",
    country: "United States",
    code: "US",
  },
  {
    city: "Sliven",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Slough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Sloviansk",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Słupsk",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Slutsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Smila",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Smithtown",
    country: "United States",
    code: "US",
  },
  {
    city: "Smolensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Smyrna",
    country: "United States",
    code: "US",
  },
  {
    city: "Smyrna",
    country: "United States",
    code: "US",
  },
  {
    city: "Snezhinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "So-Awa",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Soacha",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Soasio",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sobral",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sóc Trăng",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Sochi",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Socopó",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Sodegaura",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Södertälje",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Sodo",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Sofia",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Sogamoso",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Sohna",
    country: "India",
    code: "IN",
  },
  {
    city: "Soio",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Sōja",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sojat",
    country: "India",
    code: "IN",
  },
  {
    city: "Sōka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sokcho",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Söke",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sokhumi",
    country: "Georgia",
    code: "GE",
  },
  {
    city: "Sokodé",
    country: "Togo",
    code: "TG",
  },
  {
    city: "Sokoto",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Solana",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Solano",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Solāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Soledad",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Soledad de Graciano Sánchez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Solihull",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Solikamsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Solingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Solnechnogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Solok",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sololá",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Soloma",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Solwezi",
    country: "Zambia",
    code: "ZM",
  },
  {
    city: "Soma",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sombor",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Somerville",
    country: "United States",
    code: "US",
  },
  {
    city: "Somolu",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Sơn La",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Sơn Tây",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Sonabedha",
    country: "India",
    code: "IN",
  },
  {
    city: "Songadh",
    country: "India",
    code: "IN",
  },
  {
    city: "Songea",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Songkhla",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Songlindian",
    country: "China",
    code: "CN",
  },
  {
    city: "Sŏngnam",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Songnim",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Songyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Songzi",
    country: "China",
    code: "CN",
  },
  {
    city: "Sonīpat",
    country: "India",
    code: "IN",
  },
  {
    city: "Sonpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Sonsonate",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Sopron",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Sopur",
    country: "India",
    code: "IN",
  },
  {
    city: "Soreang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sorgun",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Soro",
    country: "India",
    code: "IN",
  },
  {
    city: "Sorocaba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sorong",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Soroti",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Sorriso",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sorsogon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sŏsan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sosnowiec",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Sotik",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Soubré",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Souk Ahras",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Sousa",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sousse",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "South Bend",
    country: "United States",
    code: "US",
  },
  {
    city: "South Fulton",
    country: "United States",
    code: "US",
  },
  {
    city: "South Gate",
    country: "United States",
    code: "US",
  },
  {
    city: "South Hill",
    country: "United States",
    code: "US",
  },
  {
    city: "South Jordan",
    country: "United States",
    code: "US",
  },
  {
    city: "South Lyon",
    country: "United States",
    code: "US",
  },
  {
    city: "South San Francisco",
    country: "United States",
    code: "US",
  },
  {
    city: "South Shields",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "South Whittier",
    country: "United States",
    code: "US",
  },
  {
    city: "Southall",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Southampton",
    country: "United States",
    code: "US",
  },
  {
    city: "Southampton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Southaven",
    country: "United States",
    code: "US",
  },
  {
    city: "Southend",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Southfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Southport",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Soweto",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Spanish Town",
    country: "Jamaica",
    code: "JM",
  },
  {
    city: "Sparks",
    country: "United States",
    code: "US",
  },
  {
    city: "Spartanburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Speyer",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Spijkenisse",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Split",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Spokane",
    country: "United States",
    code: "US",
  },
  {
    city: "Spokane Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Spring",
    country: "United States",
    code: "US",
  },
  {
    city: "Spring Hill",
    country: "United States",
    code: "US",
  },
  {
    city: "Spring Valley",
    country: "United States",
    code: "US",
  },
  {
    city: "Springdale",
    country: "United States",
    code: "US",
  },
  {
    city: "Springfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Springfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Springfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Springfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Springfield",
    country: "United States",
    code: "US",
  },
  {
    city: "Springs",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Sremska Mitrovica",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Sri Jayewardenepura Kotte",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Srīnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Srīpur",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Srīvilliputtūr",
    country: "India",
    code: "IN",
  },
  {
    city: "St. Albert",
    country: "Canada",
    code: "CA",
  },
  {
    city: "St. Augustine",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Catharines",
    country: "Canada",
    code: "CA",
  },
  {
    city: "St. Charles",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Clair Shores",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Cloud",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Cloud",
    country: "United States",
    code: "US",
  },
  {
    city: "St. George",
    country: "United States",
    code: "US",
  },
  {
    city: "St. John's",
    country: "Canada",
    code: "CA",
  },
  {
    city: "St. Joseph",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Louis",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Paul",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Peters",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Petersburg",
    country: "United States",
    code: "US",
  },
  {
    city: "St. Thomas",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Stafford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stakhanov",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Stalowa Wola",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Stamford",
    country: "United States",
    code: "US",
  },
  {
    city: "Standerton",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Stara Pazova",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Stara Zagora",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Stargard Szczeciński",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Staryy Oskol",
    country: "Russia",
    code: "RU",
  },
  {
    city: "State College",
    country: "United States",
    code: "US",
  },
  {
    city: "Staten Island",
    country: "United States",
    code: "US",
  },
  {
    city: "Stavanger",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Stavropol",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sterling Heights",
    country: "United States",
    code: "US",
  },
  {
    city: "Sterlitamak",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Stevenage",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stockholm",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Stockport",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stockton",
    country: "United States",
    code: "US",
  },
  {
    city: "Stockton-on-Tees",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stoke-on-Trent",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stolberg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Stonecrest",
    country: "United States",
    code: "US",
  },
  {
    city: "Stourbridge",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stralsund",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Strasbourg",
    country: "France",
    code: "FR",
  },
  {
    city: "Stratford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stratford",
    country: "United States",
    code: "US",
  },
  {
    city: "Streatham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Stryi",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Stupino",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Stuttgart",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Subang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Subic",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Subulussalam",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sucat",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Suceava",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Sucre",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Sucúa",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Sucun",
    country: "China",
    code: "CN",
  },
  {
    city: "Sudbury",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Suez",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Suffolk",
    country: "United States",
    code: "US",
  },
  {
    city: "Sugar Land",
    country: "United States",
    code: "US",
  },
  {
    city: "Suginami-ku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sūhāj",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Suharekë",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Suhum",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Suifenhe",
    country: "China",
    code: "CN",
  },
  {
    city: "Suihua",
    country: "China",
    code: "CN",
  },
  {
    city: "Suileng",
    country: "China",
    code: "CN",
  },
  {
    city: "Suining",
    country: "China",
    code: "CN",
  },
  {
    city: "Sūjāngarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Sujānpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Sukabumi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sukkur",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Sukrah",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Sullana",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Sultan Kudarat",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Sultanbeyli",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sultānganj",
    country: "India",
    code: "IN",
  },
  {
    city: "Sultānpur Mazra",
    country: "India",
    code: "IN",
  },
  {
    city: "Sumaré",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Sumbawa Besar",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sumbawanga",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Sumbe",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Sumber",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sumedang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sumenep",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sumida",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Summerville",
    country: "United States",
    code: "US",
  },
  {
    city: "Sumqayıt",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Sumter",
    country: "United States",
    code: "US",
  },
  {
    city: "Sumy",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Sunām",
    country: "India",
    code: "IN",
  },
  {
    city: "Sunch’ŏn",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Suncheon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Sundapālaiyam",
    country: "India",
    code: "IN",
  },
  {
    city: "Sundarnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Sunderland",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Sundsvall",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Sungai Penuh",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sungai Petani",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Sungailiat",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Sungo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Sunnyvale",
    country: "United States",
    code: "US",
  },
  {
    city: "Sunrise",
    country: "United States",
    code: "US",
  },
  {
    city: "Sunrise Manor",
    country: "United States",
    code: "US",
  },
  {
    city: "Sunshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Sunyani",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Suohe",
    country: "China",
    code: "CN",
  },
  {
    city: "Suoluntun",
    country: "China",
    code: "CN",
  },
  {
    city: "Supaul",
    country: "India",
    code: "IN",
  },
  {
    city: "Suqian",
    country: "China",
    code: "CN",
  },
  {
    city: "Surabaya",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Surakarta",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Surallah",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Suramāla",
    country: "India",
    code: "IN",
  },
  {
    city: "Sūrat",
    country: "India",
    code: "IN",
  },
  {
    city: "Surat Thani",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Sūratgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Surendranagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Surgut",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Suriāpet",
    country: "India",
    code: "IN",
  },
  {
    city: "Surigao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Surprise",
    country: "United States",
    code: "US",
  },
  {
    city: "Surrey",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Surt",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Surubim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Suruç",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Sūsangerd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Susono",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Sutton Coldfield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Suva",
    country: "Fiji",
    code: "FJ",
  },
  {
    city: "Suwałki",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Suwon",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Suzano",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Suzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Suzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Suzuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Svobodnyy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Svyetlahorsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Swabi",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Swedru",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Świdnica",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Swindon",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Sydney",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Syktyvkar",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Sylhet",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Syracuse",
    country: "United States",
    code: "US",
  },
  {
    city: "Syzran",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Szczecin",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Szeged",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Székesfehérvár",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Szolnok",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Szombathely",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Ta‘izz",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Taal",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tabaco",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tabarre",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Tabatinga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Taboão da Serra",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tabora",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Tabrīz",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Tabuk",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tabūk",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Täby",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Tacámbaro de Codallos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tacaná",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Tacheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Tachikawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tachilek",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Tacloban",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tacna",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Tacoma",
    country: "United States",
    code: "US",
  },
  {
    city: "Tacuarembó",
    country: "Uruguay",
    code: "UY",
  },
  {
    city: "Tacurong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tādepalle",
    country: "India",
    code: "IN",
  },
  {
    city: "Tādepallegūdem",
    country: "India",
    code: "IN",
  },
  {
    city: "Tadmur",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Tādpatri",
    country: "India",
    code: "IN",
  },
  {
    city: "Tafo",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Tagajō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Taganrog",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tagaytay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tagbilaran City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tagkawayan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tagoloan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Taguig City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tagum",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tahara",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tahoua",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Tai’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Taichung",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Taihe",
    country: "China",
    code: "CN",
  },
  {
    city: "Taihecun",
    country: "China",
    code: "CN",
  },
  {
    city: "Tailai",
    country: "China",
    code: "CN",
  },
  {
    city: "Tainan",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Taipei",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Taiping",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Taishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Taisheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Taitō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Taitung",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Taixing",
    country: "China",
    code: "CN",
  },
  {
    city: "Taiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Taizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Taizhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Tajimi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tajumulco",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Takaishi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takamatsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takaoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takarazuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takasagochō-takasemachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takasaki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takatsuki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Takeo",
    country: "Cambodia",
    code: "KH",
  },
  {
    city: "Tākestān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Takizawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tala",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Talagante",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Talakag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Talara",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Talas",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Talatona",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Talavera",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Talavera de la Reina",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Talca",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Talcahuano",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Taldyqorghan",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Talegaon Dābhāde",
    country: "India",
    code: "IN",
  },
  {
    city: "Talibon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Talipao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Talipparamba",
    country: "India",
    code: "IN",
  },
  {
    city: "Talisay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Talisay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Taliwang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Ţalkhā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Tall ‘Afar",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Tallaght",
    country: "Ireland",
    code: "IE",
  },
  {
    city: "Tallahassee",
    country: "United States",
    code: "US",
  },
  {
    city: "Tallinn",
    country: "Estonia",
    code: "EE",
  },
  {
    city: "Tāluqān",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Tam Điệp",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tam Kỳ",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tamale",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Taman Johor Jaya",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Tamana",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tamanrasset",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Tamarac",
    country: "United States",
    code: "US",
  },
  {
    city: "Tamazunchale",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tamba",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tambacounda",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Tambaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Tamboril",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Tambov",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tame",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Tamiami",
    country: "United States",
    code: "US",
  },
  {
    city: "Tamlūk",
    country: "India",
    code: "IN",
  },
  {
    city: "Tammampatti",
    country: "India",
    code: "IN",
  },
  {
    city: "Tampa",
    country: "United States",
    code: "US",
  },
  {
    city: "Tampere",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Tampico",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tamworth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Tân An",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tân An",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tân Châu",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tân Phú",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tân Uyên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tan-Tan",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tanabe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tanauan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tanauan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tanay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tanbei",
    country: "China",
    code: "CN",
  },
  {
    city: "Tanch’ŏn",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Tandag",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tandil",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Tando Allahyar",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Tāndūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tandwa",
    country: "India",
    code: "IN",
  },
  {
    city: "Tanga",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Tāngāil",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Tangalla",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Tangdong",
    country: "China",
    code: "CN",
  },
  {
    city: "Tangerang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tangier",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tangjia",
    country: "China",
    code: "CN",
  },
  {
    city: "Tangjin",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Tangshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Tangub",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tanguiéta",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Tangxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Tanjay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tanjombato",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Tanjore",
    country: "India",
    code: "IN",
  },
  {
    city: "Tanjungbalai",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tanjungpandan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tanjungpinang",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Ţanţā",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Tantoyuca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tanuku",
    country: "India",
    code: "IN",
  },
  {
    city: "Tanza",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Taourirt",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Taoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Taoyuan District",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Taozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Tapachula",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tapas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Taquara",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Taquaritinga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tarakan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Taranto",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Tarapoto",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Taraz",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Targovishte",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Târgovişte",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Târgu Jiu",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Târgu-Mureş",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Táriba",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Tarija",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Tarīm",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Tarīn Kōṯ",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Tarlac City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tarma",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Tarn Tāran",
    country: "India",
    code: "IN",
  },
  {
    city: "Tarnów",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Tarnowskie Góry",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Tarogong",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Taroudannt",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tarragona",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Tarrasa",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Tarsus",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tartagal",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Tartu",
    country: "Estonia",
    code: "EE",
  },
  {
    city: "Ţarţūs",
    country: "Syria",
    code: "SY",
  },
  {
    city: "Tashkent",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Tasikmalaya",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tatabánya",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Tatakan",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tatalon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tataouine",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Tatebayashi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tatsunochō-tominaga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tatuí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tatvan",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tauá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Taubaté",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Taungdwingyi",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Taunggyi",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Taungoo",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Taunton",
    country: "United States",
    code: "US",
  },
  {
    city: "Taunton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Tauranga",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Tavşanlı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tawau",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Taxco de Alarcón",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tây Ninh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tayabas",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tāybād",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Taylor",
    country: "United States",
    code: "US",
  },
  {
    city: "Taylorsville",
    country: "United States",
    code: "US",
  },
  {
    city: "Taytay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Taytay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Taza",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tbilisi",
    country: "Georgia",
    code: "GE",
  },
  {
    city: "Tchaourou",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Tchibota",
    country: "Congo (Brazzaville)",
    code: "CG",
  },
  {
    city: "Tchitato",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Tczew",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Tébessa",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Tebingtinggi",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tebourba",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Tecámac",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tecamachalco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tecate",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Techiman",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Tecomán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tecpán Guatemala",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Tefé",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tegal",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tegucigalpa",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Tehran",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Tehuacán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Teixeira de Freitas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tejen",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Tejupilco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tekirdağ",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tekkeköy",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tel Aviv-Yafo",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Tela",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Telde",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Telêmaco Borba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Telford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Tellicherry",
    country: "India",
    code: "IN",
  },
  {
    city: "Teluk Intan",
    country: "Malaysia",
    code: "MY",
  },
  {
    city: "Teluknaga",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tema",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Temara",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Temascalcingo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Temecula",
    country: "United States",
    code: "US",
  },
  {
    city: "Temirtaū",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Temixco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Temoaya",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tempe",
    country: "United States",
    code: "US",
  },
  {
    city: "Temperley",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Temple",
    country: "United States",
    code: "US",
  },
  {
    city: "Temuco",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Tenāli",
    country: "India",
    code: "IN",
  },
  {
    city: "Tenancingo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tendō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tengyue",
    country: "China",
    code: "CN",
  },
  {
    city: "Tenkāsi",
    country: "India",
    code: "IN",
  },
  {
    city: "Tenkodogo",
    country: "Burkina Faso",
    code: "BF",
  },
  {
    city: "Tenri",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Teófilo Otoni",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tepatitlán de Morelos",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tepic",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tepotzotlán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Teramo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Teresa",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Teresina",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Teresópolis",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Termiz",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Ternate",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Terneuzen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Terni",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Ternopil",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Terre Haute",
    country: "United States",
    code: "US",
  },
  {
    city: "Terrebonne",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Tete",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Tétouan",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tetovo",
    country: "Macedonia",
    code: "MK",
  },
  {
    city: "Texarkana",
    country: "United States",
    code: "US",
  },
  {
    city: "Texas City",
    country: "United States",
    code: "US",
  },
  {
    city: "Texcoco",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Teyateyaneng",
    country: "Lesotho",
    code: "LS",
  },
  {
    city: "Teziutlan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tezonapa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tezpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Thái Bình",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Thái Nguyên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Thakhèk",
    country: "Laos",
    code: "LA",
  },
  {
    city: "Thanatpin",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Thandwe",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Thāne",
    country: "India",
    code: "IN",
  },
  {
    city: "Thānesar",
    country: "India",
    code: "IN",
  },
  {
    city: "Thanh Hóa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Thành Phố Uông Bí",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Thanhlyin",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Tharād",
    country: "India",
    code: "IN",
  },
  {
    city: "Thaton",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "The Hague",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "The Hammocks",
    country: "United States",
    code: "US",
  },
  {
    city: "The Villages",
    country: "United States",
    code: "US",
  },
  {
    city: "The Woodlands",
    country: "United States",
    code: "US",
  },
  {
    city: "Thérmi",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Thessaloníki",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Thiès",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Thika",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Thimphu",
    country: "Bhutan",
    code: "BT",
  },
  {
    city: "Thingangyun",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Thiruvananthapuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Thohoyandou",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Thomazeau",
    country: "Haiti",
    code: "HT",
  },
  {
    city: "Thongwa",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Thornton",
    country: "United States",
    code: "US",
  },
  {
    city: "Thornton Heath",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Thousand Oaks",
    country: "United States",
    code: "US",
  },
  {
    city: "Thủ Dầu Một",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Thủ Đức",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Thunder Bay",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Tianchang",
    country: "China",
    code: "CN",
  },
  {
    city: "Tianguá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tianjin",
    country: "China",
    code: "CN",
  },
  {
    city: "Tianningcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Tiantoujiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Tiaong",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tiaret",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Tibati",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Tiddim",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Tieli",
    country: "China",
    code: "CN",
  },
  {
    city: "Tieling",
    country: "China",
    code: "CN",
  },
  {
    city: "Tierralta",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Tiflet",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tigaon",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tigaraksa",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tigard",
    country: "United States",
    code: "US",
  },
  {
    city: "Tigbauan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tighenif",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Tijuana",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tīkamgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Tikhoretsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tikhvin",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tikrīt",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Tila",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tilakpur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Tilburg",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Tilhar",
    country: "India",
    code: "IN",
  },
  {
    city: "Timargara",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Timashevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Timayy al Imdīd",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Timbaúba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Timbío",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Timişoara",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Timon",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Timóteo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tin Shui Wai",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Tinambac",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tinaquillo",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Tindivanam",
    country: "India",
    code: "IN",
  },
  {
    city: "Tinley Park",
    country: "United States",
    code: "US",
  },
  {
    city: "Tinnevelly",
    country: "India",
    code: "IN",
  },
  {
    city: "Tinsukia",
    country: "India",
    code: "IN",
  },
  {
    city: "Tipitapa",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Tiptūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiquisate",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Tirana",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Tiraspol",
    country: "Moldova",
    code: "MD",
  },
  {
    city: "Tire",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tiruchengodu",
    country: "India",
    code: "IN",
  },
  {
    city: "Tirumangalam",
    country: "India",
    code: "IN",
  },
  {
    city: "Tirupparangunram",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruppattūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruppūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tirur",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruttani",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruvalla",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruvallūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruvālūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruvannāmalai",
    country: "India",
    code: "IN",
  },
  {
    city: "Tiruvottiyūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tissamaharama",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Tissemsilt",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Titāgarh",
    country: "India",
    code: "IN",
  },
  {
    city: "Titay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tivoli",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Tiwi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tizayuca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tizi Ouzou",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Tiznit",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Tlacotepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tlajomulco de Zúñiga",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tlalnepantla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tlaquepaque",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tlemcen",
    country: "Algeria",
    code: "DZ",
  },
  {
    city: "Toamasina",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Toba Tek Singh",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Toboali",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tobolsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tobruk",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Tochigi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tocoa",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Tocumen",
    country: "Panama",
    code: "PA",
  },
  {
    city: "Tocuyito",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Toda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toffo",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Tōgane",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Togoch’alē",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Tohāna",
    country: "India",
    code: "IN",
  },
  {
    city: "Tōkai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tokār",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Tokat",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Toki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tokmok",
    country: "Kyrgyzstan",
    code: "KG",
  },
  {
    city: "Tokoname",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tokorozawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tokushima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tokyo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tôlan̈aro",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Toledo",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Toledo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Toledo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Toledo",
    country: "United States",
    code: "US",
  },
  {
    city: "Toliara",
    country: "Madagascar",
    code: "MG",
  },
  {
    city: "Toluca",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tolyatti",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tomakomai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tomaszów Mazowiecki",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Tomé-Açu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tomigusuku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tomiya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tomohon",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Toms River",
    country: "United States",
    code: "US",
  },
  {
    city: "Tomsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tonacatepeque",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Tonalá",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tondabayashichō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tondo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tonekābon",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Tongchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Tongchuanshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Tongjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Tongjin",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Tongliao",
    country: "China",
    code: "CN",
  },
  {
    city: "Tongren",
    country: "China",
    code: "CN",
  },
  {
    city: "Tongshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Tongye",
    country: "China",
    code: "CN",
  },
  {
    city: "Tønsberg",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Toowoomba",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Topeka",
    country: "United States",
    code: "US",
  },
  {
    city: "Torbalı",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Torbat-e Ḩeydarīyeh",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Torbat-e Jām",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Toride",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toronto",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Tororo",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Torquay",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Torrance",
    country: "United States",
    code: "US",
  },
  {
    city: "Torre del Greco",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Torrejón de Ardoz",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Torrelavega",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Torremolinos",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Torrente",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Torreón",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Torres Vedras",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Torrevieja",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Toruń",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Toshima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tosu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tota",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Totonicapán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Tottenham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Tottori",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Touba",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Toufen",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Toulon",
    country: "France",
    code: "FR",
  },
  {
    city: "Toulouse",
    country: "France",
    code: "FR",
  },
  {
    city: "Tourcoing",
    country: "France",
    code: "FR",
  },
  {
    city: "Tournai",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Tours",
    country: "France",
    code: "FR",
  },
  {
    city: "Toviklin",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Towada",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Town 'n' Country",
    country: "United States",
    code: "US",
  },
  {
    city: "Townsville",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Towson",
    country: "United States",
    code: "US",
  },
  {
    city: "Toyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyoake",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyohashi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyokawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyomamachi-teraike",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyonaka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyooka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Toyota",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Trà Vinh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Trabzon",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tracy",
    country: "United States",
    code: "US",
  },
  {
    city: "Trairi",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Trang",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Trảng Bàng",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Trani",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Trapani",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Trece Martires City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Trelew",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Trenčín",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Trento",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Trento",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Trenton",
    country: "United States",
    code: "US",
  },
  {
    city: "Três Corações",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Três Lagoas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Três Pontas",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Três Rios",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Treviso",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Trichinopoly",
    country: "India",
    code: "IN",
  },
  {
    city: "Trichūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Trier",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Trieste",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Tríkala",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Trincomalee",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Trindade",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Trinidad",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Trinidad",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Tripoli",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Tripoli",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Tripunittura",
    country: "India",
    code: "IN",
  },
  {
    city: "Trnava",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Trois-Rivières",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Troisdorf",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Troitsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Trollhättan",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Trondheim",
    country: "Norway",
    code: "NO",
  },
  {
    city: "Troy",
    country: "United States",
    code: "US",
  },
  {
    city: "Troy",
    country: "United States",
    code: "US",
  },
  {
    city: "Troyes",
    country: "France",
    code: "FR",
  },
  {
    city: "Trujillo",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Trujillo",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Tsévié",
    country: "Togo",
    code: "TG",
  },
  {
    city: "Tshikapa",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Tshilenge",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Tsing Yi Town",
    country: "Hong Kong",
    code: "HK",
  },
  {
    city: "Tsu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsubame",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsuchiura",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsukuba-kenkyūgakuen-toshi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsukubamirai",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsuruga",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsurugashima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsuruoka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsushima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tsuyama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Tual",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tuao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tuapse",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tuban",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Tubarão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tübingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Tubod",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tuburan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tucano",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tucson",
    country: "United States",
    code: "US",
  },
  {
    city: "Tucupita",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Tucurú",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Tucuruí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tuguegarao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ţūkh",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Tukuyu",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Tula",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tula de Allende",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tulancingo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tulare",
    country: "United States",
    code: "US",
  },
  {
    city: "Tulcán",
    country: "Ecuador",
    code: "EC",
  },
  {
    city: "Tulcea",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Ţūlkarm",
    country: "West Bank",
    code: "XW",
  },
  {
    city: "Tulsa",
    country: "United States",
    code: "US",
  },
  {
    city: "Tulsīpur",
    country: "Nepal",
    code: "NP",
  },
  {
    city: "Tultepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tultitlán de Mariano Escobedo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tuluá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Tulunan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tumaco",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Tumauini",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tumbes",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Tumen",
    country: "China",
    code: "CN",
  },
  {
    city: "Tumkūr",
    country: "India",
    code: "IN",
  },
  {
    city: "Tunasan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tuncheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Tūndla",
    country: "India",
    code: "IN",
  },
  {
    city: "Tungi",
    country: "Bangladesh",
    code: "BD",
  },
  {
    city: "Tuni",
    country: "India",
    code: "IN",
  },
  {
    city: "Tunis",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Tunja",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Tupã",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Tupi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Tura",
    country: "India",
    code: "IN",
  },
  {
    city: "Ţurayf",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Turbaco",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Turbaná",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Turbat",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Turbo",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Turgutlu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Turhal",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Turin",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Türkistan",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Türkmenabat",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Türkmenbaşy",
    country: "Turkmenistan",
    code: "TM",
  },
  {
    city: "Türkoğlu",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Turku",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Turlock",
    country: "United States",
    code: "US",
  },
  {
    city: "Turmero",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Turpan",
    country: "China",
    code: "CN",
  },
  {
    city: "Tursunzoda",
    country: "Tajikistan",
    code: "TJ",
  },
  {
    city: "Tŭrtkŭl",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Tuscaloosa",
    country: "United States",
    code: "US",
  },
  {
    city: "Tustin",
    country: "United States",
    code: "US",
  },
  {
    city: "Tuticorin",
    country: "India",
    code: "IN",
  },
  {
    city: "Tutóia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Túxpam de Rodríguez Cano",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tuxtepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tuxtla",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Tuy Hòa",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Tuymazy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tūyserkān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ţūz Khūrmātū",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Tuzla",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Tuzla",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Tver",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Twickenham",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Twin Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Twin Rivers",
    country: "United States",
    code: "US",
  },
  {
    city: "Tychy",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Tyler",
    country: "United States",
    code: "US",
  },
  {
    city: "Tynemouth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Tyre",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Tyumen",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Tzaneen",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Uacu Cungo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ubá",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ubatuba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ubay",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Ube",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Uberaba",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Uberlândia",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Ubon Ratchathani",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ucu Seles",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Ucuma",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Udaipur",
    country: "India",
    code: "IN",
  },
  {
    city: "Udamalpet",
    country: "India",
    code: "IN",
  },
  {
    city: "Udine",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Udipi",
    country: "India",
    code: "IN",
  },
  {
    city: "Udon Thani",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Ueda",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ufa",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ufeyn",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Ugep",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Uíge",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Uijeongbu",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Uitenhage",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Ŭiwang",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Uji",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ujjain",
    country: "India",
    code: "IN",
  },
  {
    city: "Ukhta",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Uki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ulaanbaatar",
    country: "Mongolia",
    code: "MN",
  },
  {
    city: "Ulan-Ude",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ulanhot",
    country: "China",
    code: "CN",
  },
  {
    city: "Ulhāsnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Ullāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Ulm",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Ulsan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Uluberiya",
    country: "India",
    code: "IN",
  },
  {
    city: "Ulyanovsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Uman",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Umán",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Umeå",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Umingan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Umm el Faḥm",
    country: "Israel",
    code: "IL",
  },
  {
    city: "Umm Qaşr",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Umm Qurūn",
    country: "Qatar",
    code: "QA",
  },
  {
    city: "Umm Ruwaba",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Umred",
    country: "India",
    code: "IN",
  },
  {
    city: "Umreth",
    country: "India",
    code: "IN",
  },
  {
    city: "Umtata",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Umuahia",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Umuarama",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Una",
    country: "India",
    code: "IN",
  },
  {
    city: "Unaí",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "União da Vitória",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "União dos Palmares",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Union",
    country: "United States",
    code: "US",
  },
  {
    city: "Union",
    country: "United States",
    code: "US",
  },
  {
    city: "Union City",
    country: "United States",
    code: "US",
  },
  {
    city: "Union City",
    country: "United States",
    code: "US",
  },
  {
    city: "Unjha",
    country: "India",
    code: "IN",
  },
  {
    city: "Unna",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Unnāo",
    country: "India",
    code: "IN",
  },
  {
    city: "Unwana",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Ünye",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Upata",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Upi",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Upington",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Upland",
    country: "United States",
    code: "US",
  },
  {
    city: "Upleta",
    country: "India",
    code: "IN",
  },
  {
    city: "Upper Darby",
    country: "United States",
    code: "US",
  },
  {
    city: "Uppsala",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Uppsala",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Urasoe",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Urayasu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Urbiztondo",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Urdaneta",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Urganch",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Urgut Shahri",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Uriangato",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Uribia",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Uripa",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Urla",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Uruapan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Uruguaiana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Uruma",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ürümqi",
    country: "China",
    code: "CN",
  },
  {
    city: "Urun-Islāmpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Urus-Martan",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Usa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Uşak",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Ushiku",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Ushuaia",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Usol’ye-Sibirskoye",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Uson",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Uspantán",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Ussuriysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ust’-Ilimsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Ústí nad Labem",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Usulután",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Utica",
    country: "United States",
    code: "US",
  },
  {
    city: "Utrecht",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Utrera",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Utsunomiya",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Uttarpāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Uvinza",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Uvira",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Uwajima",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Uxbridge",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Uyo",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Uzhhorod",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Užice",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Uzlovaya",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Uzunköprü",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Vaasa",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Vacaria",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vacaville",
    country: "United States",
    code: "US",
  },
  {
    city: "Vadodara",
    country: "India",
    code: "IN",
  },
  {
    city: "Vaijāpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Valdemoro",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Valdivia",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Valdosta",
    country: "United States",
    code: "US",
  },
  {
    city: "Valença",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Valença",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Valence",
    country: "France",
    code: "FR",
  },
  {
    city: "Valencia",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Valencia",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Valencia",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Valenzuela",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Valera",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Valinhos",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Valjevo",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Valladolid",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Valle de Bravo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Valle de La Pascua",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Valle de Santiago",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Valle del Guamuez",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Valle Hermoso",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Valledupar",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Vallejo",
    country: "United States",
    code: "US",
  },
  {
    city: "Valletta",
    country: "Malta",
    code: "MT",
  },
  {
    city: "Valongo",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Vālpārai",
    country: "India",
    code: "IN",
  },
  {
    city: "Valparaíso",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Valsād",
    country: "India",
    code: "IN",
  },
  {
    city: "Van",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Vanadzor",
    country: "Armenia",
    code: "AM",
  },
  {
    city: "Vancouver",
    country: "United States",
    code: "US",
  },
  {
    city: "Vancouver",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Vanderbijlpark",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Vāniyambādi",
    country: "India",
    code: "IN",
  },
  {
    city: "Vannes",
    country: "France",
    code: "FR",
  },
  {
    city: "Vantaa",
    country: "Finland",
    code: "FI",
  },
  {
    city: "Varāmīn",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Vārānasi",
    country: "India",
    code: "IN",
  },
  {
    city: "Varese",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Varginha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Varisshiyakuni",
    country: "India",
    code: "IN",
  },
  {
    city: "Varna",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Várzea Grande",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Várzea Paulista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vasai",
    country: "India",
    code: "IN",
  },
  {
    city: "Vāsco Da Gāma",
    country: "India",
    code: "IN",
  },
  {
    city: "Vaslui",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Västerås",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Vaughan",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Vaulx-en-Velin",
    country: "France",
    code: "FR",
  },
  {
    city: "Växjö",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Veenendaal",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Vejle",
    country: "Denmark",
    code: "DK",
  },
  {
    city: "Velampālaiyam",
    country: "India",
    code: "IN",
  },
  {
    city: "Velbert",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Vélez-Málaga",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Velika Gorica",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Velikiy Novgorod",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Velikiye Luki",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Veliko Tarnovo",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Velletri",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Vellore",
    country: "India",
    code: "IN",
  },
  {
    city: "Velsen-Zuid",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Venado Tuerto",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Venâncio Aires",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Venice",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Vénissieux",
    country: "France",
    code: "FR",
  },
  {
    city: "Venkatagiri",
    country: "India",
    code: "IN",
  },
  {
    city: "Venustiano Carranza",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Veracruz",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Verāval",
    country: "India",
    code: "IN",
  },
  {
    city: "Vereeniging",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Verkhnyaya Pyshma",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vero Beach South",
    country: "United States",
    code: "US",
  },
  {
    city: "Verona",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Versailles",
    country: "France",
    code: "FR",
  },
  {
    city: "Vertientes",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Verviers",
    country: "Belgium",
    code: "BE",
  },
  {
    city: "Vespasiano",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Veszprém",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Vị Thanh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Viamão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Viana",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Viana do Castelo",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Viareggio",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Vicenza",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Viçosa do Ceará",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Víctor Larco Herrera",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Victoria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Victoria",
    country: "United States",
    code: "US",
  },
  {
    city: "Victoria",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Victoria",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Victorias",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Victorville",
    country: "United States",
    code: "US",
  },
  {
    city: "Vidin",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Vidisha",
    country: "India",
    code: "IN",
  },
  {
    city: "Vidnoye",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Viedma",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Vienna",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Vientiane",
    country: "Laos",
    code: "LA",
  },
  {
    city: "Viersen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Việt Trì",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Vigan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Vigevano",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Vigo",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Vihari",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Vihiga",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Vijayavāda",
    country: "India",
    code: "IN",
  },
  {
    city: "Vikārābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Vila do Conde",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Vila Franca de Xira",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Vila Nova de Gaia",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Vila Real",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Vila Teixeira da Silva",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Vila Velha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vila Velha",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Viladecáns",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Vilhena",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Villa Alemana",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Villa Altagracia",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Villa Canales",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Villa Carlos Paz",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa Celina",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa Curuguaty",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Villa de Álvarez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Villa de Cura",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Villa del Rosario",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Villa Domínico",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa Elisa",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Villa Hayes",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Villa Krause",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa Luzuriaga",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa María",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa Mercedes",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Villa Nueva",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Villa Tunari",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Villa Victoria",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Villach",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Villahermosa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Villamaría",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Villanueva",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Villanueva y Geltrú",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Villareal",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Villarrica",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Villarrica",
    country: "Paraguay",
    code: "PY",
  },
  {
    city: "Villasis",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Villavicencio",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Villejuif",
    country: "France",
    code: "FR",
  },
  {
    city: "Villeurbanne",
    country: "France",
    code: "FR",
  },
  {
    city: "Villingen-Schwenningen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Villupuram",
    country: "India",
    code: "IN",
  },
  {
    city: "Vilnius",
    country: "Lithuania",
    code: "LT",
  },
  {
    city: "Vimmerby",
    country: "Sweden",
    code: "SE",
  },
  {
    city: "Viña del Mar",
    country: "Chile",
    code: "CL",
  },
  {
    city: "Vineland",
    country: "United States",
    code: "US",
  },
  {
    city: "Vinh",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Vĩnh Châu",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Vĩnh Long",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Vĩnh Yên",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Vinhedo",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vinnytsia",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Vinukonda",
    country: "India",
    code: "IN",
  },
  {
    city: "Virac",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Viramgām",
    country: "India",
    code: "IN",
  },
  {
    city: "Viranşehir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Vīrappanchathiram",
    country: "India",
    code: "IN",
  },
  {
    city: "Virginia",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Virginia Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "Virudunagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Visalia",
    country: "United States",
    code: "US",
  },
  {
    city: "Viseu",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Viseu",
    country: "Portugal",
    code: "PT",
  },
  {
    city: "Vishākhapatnam",
    country: "India",
    code: "IN",
  },
  {
    city: "Visnagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Vista",
    country: "United States",
    code: "US",
  },
  {
    city: "Viterbo",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Vitória",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vitória da Conquista",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vitória de Santo Antão",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vitoria-Gasteiz",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Vitry-sur-Seine",
    country: "France",
    code: "FR",
  },
  {
    city: "Vitsyebsk",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Vittoria",
    country: "Italy",
    code: "IT",
  },
  {
    city: "Vizianagaram",
    country: "India",
    code: "IN",
  },
  {
    city: "Vlaardingen",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Vladikavkaz",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vladimir",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vladivostok",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vlorë",
    country: "Albania",
    code: "AL",
  },
  {
    city: "Volgodonsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Volgograd",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vologda",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vólos",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Volsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Volta Redonda",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Volzhsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Volzhskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vorkuta",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Voronezh",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Voskresensk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Votkinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Votorantim",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Votuporanga",
    country: "Brazil",
    code: "BR",
  },
  {
    city: "Vranje",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Vratsa",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Vriddhāchalam",
    country: "India",
    code: "IN",
  },
  {
    city: "Vrindāvan",
    country: "India",
    code: "IN",
  },
  {
    city: "Vryheid",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Vsevolozhsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vũng Tàu",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Vushtrri",
    country: "Kosovo",
    code: "XK",
  },
  {
    city: "Vyāra",
    country: "India",
    code: "IN",
  },
  {
    city: "Vyazma",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vyborg",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Vyksa",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Výronas",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Wa",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Waco",
    country: "United States",
    code: "US",
  },
  {
    city: "Wad Medani",
    country: "Sudan",
    code: "SD",
  },
  {
    city: "Wafangdian",
    country: "China",
    code: "CN",
  },
  {
    city: "Wagga Wagga",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Waiblingen",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Waitakere",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Wajir",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Wakayama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Wakefield",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wakiso",
    country: "Uganda",
    code: "UG",
  },
  {
    city: "Wakō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Wałbrzych",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Waldorf",
    country: "United States",
    code: "US",
  },
  {
    city: "Wallasey",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Walnut Creek",
    country: "United States",
    code: "US",
  },
  {
    city: "Walsall",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Waltham",
    country: "United States",
    code: "US",
  },
  {
    city: "Walthamstow",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Walton upon Thames",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Walvisbaai",
    country: "Namibia",
    code: "NA",
  },
  {
    city: "Wamba",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Wamena",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Wancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Wandiwāsh",
    country: "India",
    code: "IN",
  },
  {
    city: "Wandsworth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wangguanzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Wangjia",
    country: "China",
    code: "CN",
  },
  {
    city: "Wangjiazhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Wangqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Wani",
    country: "India",
    code: "IN",
  },
  {
    city: "Wanparti",
    country: "India",
    code: "IN",
  },
  {
    city: "Wanyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Wanzhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Wao",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Wāpi",
    country: "India",
    code: "IN",
  },
  {
    city: "Warabi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Warangal",
    country: "India",
    code: "IN",
  },
  {
    city: "Wārāseonī",
    country: "India",
    code: "IN",
  },
  {
    city: "Warder",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Warner Robins",
    country: "United States",
    code: "US",
  },
  {
    city: "Warnes",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Warora",
    country: "India",
    code: "IN",
  },
  {
    city: "Warren",
    country: "United States",
    code: "US",
  },
  {
    city: "Warri",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Warrington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Warsaw",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Warwick",
    country: "United States",
    code: "US",
  },
  {
    city: "Warzat",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Wāshīm",
    country: "India",
    code: "IN",
  },
  {
    city: "Washington",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Washington",
    country: "United States",
    code: "US",
  },
  {
    city: "Watampone",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Waterbury",
    country: "United States",
    code: "US",
  },
  {
    city: "Waterloo",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Waterloo",
    country: "United States",
    code: "US",
  },
  {
    city: "Waterlooville",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Watford",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Watsonville",
    country: "United States",
    code: "US",
  },
  {
    city: "Wau",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Waukegan",
    country: "United States",
    code: "US",
  },
  {
    city: "Waukesha",
    country: "United States",
    code: "US",
  },
  {
    city: "Wausau",
    country: "United States",
    code: "US",
  },
  {
    city: "Wayaobu",
    country: "China",
    code: "CN",
  },
  {
    city: "Wayne",
    country: "United States",
    code: "US",
  },
  {
    city: "Weert",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Weichanglu",
    country: "China",
    code: "CN",
  },
  {
    city: "Weifang",
    country: "China",
    code: "CN",
  },
  {
    city: "Weifen",
    country: "China",
    code: "CN",
  },
  {
    city: "Weihai",
    country: "China",
    code: "CN",
  },
  {
    city: "Weimar",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Weinan",
    country: "China",
    code: "CN",
  },
  {
    city: "Weirton",
    country: "United States",
    code: "US",
  },
  {
    city: "Weiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Weiyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Weldiya",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Weligama",
    country: "Sri Lanka",
    code: "LK",
  },
  {
    city: "Welkom",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Welland",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Wellingborough",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wellington",
    country: "United States",
    code: "US",
  },
  {
    city: "Wellington",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Wels",
    country: "Austria",
    code: "AT",
  },
  {
    city: "Wembley",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wenatchee",
    country: "United States",
    code: "US",
  },
  {
    city: "Wenchang",
    country: "China",
    code: "CN",
  },
  {
    city: "Wencheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Wenlan",
    country: "China",
    code: "CN",
  },
  {
    city: "Wenling",
    country: "China",
    code: "CN",
  },
  {
    city: "Wenping",
    country: "China",
    code: "CN",
  },
  {
    city: "Wenxicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Wenzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Wesel",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wesley Chapel",
    country: "United States",
    code: "US",
  },
  {
    city: "West Allis",
    country: "United States",
    code: "US",
  },
  {
    city: "West Bend",
    country: "United States",
    code: "US",
  },
  {
    city: "West Bromwich",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "West Covina",
    country: "United States",
    code: "US",
  },
  {
    city: "West Des Moines",
    country: "United States",
    code: "US",
  },
  {
    city: "West Hartford",
    country: "United States",
    code: "US",
  },
  {
    city: "West Haven",
    country: "United States",
    code: "US",
  },
  {
    city: "West Jordan",
    country: "United States",
    code: "US",
  },
  {
    city: "West New York",
    country: "United States",
    code: "US",
  },
  {
    city: "West Palm Beach",
    country: "United States",
    code: "US",
  },
  {
    city: "West Sacramento",
    country: "United States",
    code: "US",
  },
  {
    city: "West Valley City",
    country: "United States",
    code: "US",
  },
  {
    city: "Westchester",
    country: "United States",
    code: "US",
  },
  {
    city: "Western Bicutan",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Westland",
    country: "United States",
    code: "US",
  },
  {
    city: "Westminster",
    country: "United States",
    code: "US",
  },
  {
    city: "Westminster",
    country: "United States",
    code: "US",
  },
  {
    city: "Westminster",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Weston",
    country: "United States",
    code: "US",
  },
  {
    city: "Weston-super-Mare",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wetzlar",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Weymouth",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Weymouth",
    country: "United States",
    code: "US",
  },
  {
    city: "Whangarei",
    country: "New Zealand",
    code: "NZ",
  },
  {
    city: "Wheaton",
    country: "United States",
    code: "US",
  },
  {
    city: "Wheaton",
    country: "United States",
    code: "US",
  },
  {
    city: "Wheeling",
    country: "United States",
    code: "US",
  },
  {
    city: "Whitby",
    country: "Canada",
    code: "CA",
  },
  {
    city: "White Plains",
    country: "United States",
    code: "US",
  },
  {
    city: "White Rock",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Whittier",
    country: "United States",
    code: "US",
  },
  {
    city: "Wichita",
    country: "United States",
    code: "US",
  },
  {
    city: "Wichita Falls",
    country: "United States",
    code: "US",
  },
  {
    city: "Widekum",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Widnes",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wiesbaden",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wigan",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wilde",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Wilhelmshaven",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Willemstad",
    country: "Curaçao",
    code: "CW",
  },
  {
    city: "Willenhall",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Williamsburg",
    country: "United States",
    code: "US",
  },
  {
    city: "Willich",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wilmington",
    country: "United States",
    code: "US",
  },
  {
    city: "Wilmington",
    country: "United States",
    code: "US",
  },
  {
    city: "Wimbledon",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Winchester",
    country: "United States",
    code: "US",
  },
  {
    city: "Windhoek",
    country: "Namibia",
    code: "NA",
  },
  {
    city: "Windsor",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Winneba",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Winnipeg",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Winston-Salem",
    country: "United States",
    code: "US",
  },
  {
    city: "Winter Haven",
    country: "United States",
    code: "US",
  },
  {
    city: "Winterthur",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Wisil",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Wislane",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Witbank",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Witten",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wiwilí",
    country: "Nicaragua",
    code: "NI",
  },
  {
    city: "Włocławek",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Woerden",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Woking",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wolfenbüttel",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wolfsburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wollongong",
    country: "Australia",
    code: "AU",
  },
  {
    city: "Wolverhampton",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wŏnju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Wŏnsan",
    country: "North Korea",
    code: "KP",
  },
  {
    city: "Wood Buffalo",
    country: "Canada",
    code: "CA",
  },
  {
    city: "Woodbridge",
    country: "United States",
    code: "US",
  },
  {
    city: "Woodbury",
    country: "United States",
    code: "US",
  },
  {
    city: "Woodland",
    country: "United States",
    code: "US",
  },
  {
    city: "Woolwich",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Worcester",
    country: "South Africa",
    code: "ZA",
  },
  {
    city: "Worcester",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Worcester",
    country: "United States",
    code: "US",
  },
  {
    city: "Worms",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Worthing",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wote",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Wrecsam",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Wrocław",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Wu’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Wudalianchi",
    country: "China",
    code: "CN",
  },
  {
    city: "Wugang",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuhan",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuhu",
    country: "China",
    code: "CN",
  },
  {
    city: "Wujiaqu",
    country: "China",
    code: "CN",
  },
  {
    city: "Wukari",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Wulan",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuling",
    country: "China",
    code: "CN",
  },
  {
    city: "Wum",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Wundanyi",
    country: "Kenya",
    code: "KE",
  },
  {
    city: "Wuppertal",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Würzburg",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Wusong",
    country: "China",
    code: "CN",
  },
  {
    city: "Wutiancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Wutong",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuwei",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuxue",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuyishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuzhong",
    country: "China",
    code: "CN",
  },
  {
    city: "Wuzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Wylie",
    country: "United States",
    code: "US",
  },
  {
    city: "Wyoming",
    country: "United States",
    code: "US",
  },
  {
    city: "Wythenshawe",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "Xai-Xai",
    country: "Mozambique",
    code: "MZ",
  },
  {
    city: "Xalapa",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Xangongo",
    country: "Angola",
    code: "AO",
  },
  {
    city: "Xánthi",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Xarardheere",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Xi’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiamen",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangjiaba",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangkhoang",
    country: "Laos",
    code: "LA",
  },
  {
    city: "Xiangshui",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangtan",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangxiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiangyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiantangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiantao",
    country: "China",
    code: "CN",
  },
  {
    city: "Xianyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiaoganzhan",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiaoli",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiaoxita",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiaoyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiaping",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiashi",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiazhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Xibang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xibeijie",
    country: "China",
    code: "CN",
  },
  {
    city: "Xichang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xicotepec de Juárez",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Xiedian",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiegang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xielu",
    country: "China",
    code: "CN",
  },
  {
    city: "Xieqiaocun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiezhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Xigazê",
    country: "China",
    code: "CN",
  },
  {
    city: "Xihu",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Xikeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiluodu",
    country: "China",
    code: "CN",
  },
  {
    city: "Ximeicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xin’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Xincheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xindi",
    country: "China",
    code: "CN",
  },
  {
    city: "Xindian",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinfeng",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Xingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinglong",
    country: "China",
    code: "CN",
  },
  {
    city: "Xingren",
    country: "China",
    code: "CN",
  },
  {
    city: "Xingtai",
    country: "China",
    code: "CN",
  },
  {
    city: "Xingyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinhua",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinhualu",
    country: "China",
    code: "CN",
  },
  {
    city: "Xining",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinji",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinmin",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinmin",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinpu",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Xintai",
    country: "China",
    code: "CN",
  },
  {
    city: "Xintang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinxing",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinyu",
    country: "China",
    code: "CN",
  },
  {
    city: "Xinzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiongzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiping",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiping",
    country: "China",
    code: "CN",
  },
  {
    city: "Xishan",
    country: "China",
    code: "CN",
  },
  {
    city: "Xishancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xishancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiulin",
    country: "China",
    code: "CN",
  },
  {
    city: "Xiva",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Xırdalan",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Xo‘jayli Shahri",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Xuanzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Xuddur",
    country: "Somalia",
    code: "SO",
  },
  {
    city: "Xunyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Xuqiaocun",
    country: "China",
    code: "CN",
  },
  {
    city: "Xushan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yabēlo",
    country: "Ethiopia",
    code: "ET",
  },
  {
    city: "Yachimata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yachiyo",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yacuiba",
    country: "Bolivia",
    code: "BO",
  },
  {
    city: "Yafran",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Yaguajay",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Yaizu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yakeshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Yakima",
    country: "United States",
    code: "US",
  },
  {
    city: "Yakou",
    country: "China",
    code: "CN",
  },
  {
    city: "Yakutsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yala",
    country: "Thailand",
    code: "TH",
  },
  {
    city: "Yalamakūru",
    country: "India",
    code: "IN",
  },
  {
    city: "Yalova",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Yalta",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Yamagata",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yamaguchi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yamasá",
    country: "Dominican Republic",
    code: "DO",
  },
  {
    city: "Yamatotakada",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yambol",
    country: "Bulgaria",
    code: "BG",
  },
  {
    city: "Yame",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yamoussoukro",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Yamunānagar",
    country: "India",
    code: "IN",
  },
  {
    city: "Yan’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Yan’an Beilu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanagawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yanam",
    country: "India",
    code: "IN",
  },
  {
    city: "Yanbu",
    country: "Saudi Arabia",
    code: "SA",
  },
  {
    city: "Yancheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangchun",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanggao",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanggok",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Yanghe",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangiyŭl",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Yangjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangmei",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangmei",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Yangqingcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangsan",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Yangshe",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanguancun",
    country: "China",
    code: "CN",
  },
  {
    city: "Yangzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanji",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Yanjiao",
    country: "China",
    code: "CN",
  },
  {
    city: "Yantai",
    country: "China",
    code: "CN",
  },
  {
    city: "Yao",
    country: "Chad",
    code: "TD",
  },
  {
    city: "Yao",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yaofeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yaoundé",
    country: "Cameroon",
    code: "CM",
  },
  {
    city: "Yaozhuangcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Yara",
    country: "Cuba",
    code: "CU",
  },
  {
    city: "Yaritagua",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Yaroslavl",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yashio",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yasu",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yāsūj",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Yato",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yatou",
    country: "China",
    code: "CN",
  },
  {
    city: "Yattir",
    country: "West Bank",
    code: "XW",
  },
  {
    city: "Yauco",
    country: "Puerto Rico",
    code: "PR",
  },
  {
    city: "Yavatmāl",
    country: "India",
    code: "IN",
  },
  {
    city: "Yawata-shimizui",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yazd",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Ye",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Yegoryevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yei",
    country: "South Sudan",
    code: "SS",
  },
  {
    city: "Yekaterinburg",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yelabuga",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yelahanka",
    country: "India",
    code: "IN",
  },
  {
    city: "Yelets",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yên Bái",
    country: "Vietnam",
    code: "VN",
  },
  {
    city: "Yenakiieve",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Yenangyaung",
    country: "Myanmar",
    code: "MM",
  },
  {
    city: "Yendi",
    country: "Ghana",
    code: "GH",
  },
  {
    city: "Yeosu",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Yeosu",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Yerba Buena",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Yerevan",
    country: "Armenia",
    code: "AM",
  },
  {
    city: "Yeşilyurt",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Yessentuki",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yevlax",
    country: "Azerbaijan",
    code: "AZ",
  },
  {
    city: "Yevpatoriia",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Yeysk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yi Xian",
    country: "China",
    code: "CN",
  },
  {
    city: "Yibin",
    country: "China",
    code: "CN",
  },
  {
    city: "Yicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yicheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yichun",
    country: "China",
    code: "CN",
  },
  {
    city: "Yichun",
    country: "China",
    code: "CN",
  },
  {
    city: "Yidu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yilan",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Yilong",
    country: "China",
    code: "CN",
  },
  {
    city: "Yima",
    country: "China",
    code: "CN",
  },
  {
    city: "Yinchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yingcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yingchuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yingkou",
    country: "China",
    code: "CN",
  },
  {
    city: "Yingmen",
    country: "China",
    code: "CN",
  },
  {
    city: "Yingtan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yingzhong",
    country: "China",
    code: "CN",
  },
  {
    city: "Yinying",
    country: "China",
    code: "CN",
  },
  {
    city: "Yishi",
    country: "China",
    code: "CN",
  },
  {
    city: "Yishui",
    country: "China",
    code: "CN",
  },
  {
    city: "Yitiaoshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yiwu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yiyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Yiyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Yıldırım",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Yogyakarta",
    country: "Indonesia",
    code: "ID",
  },
  {
    city: "Yŏju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Yokkaichi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yokohama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yokosuka",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yokotemachi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yola",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Yonago",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yonezawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yong’an",
    country: "China",
    code: "CN",
  },
  {
    city: "Yongbei",
    country: "China",
    code: "CN",
  },
  {
    city: "Yongcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yongji",
    country: "China",
    code: "CN",
  },
  {
    city: "Yŏngju",
    country: "South Korea",
    code: "KR",
  },
  {
    city: "Yongqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Yongqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Yongyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Yongzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Yonkers",
    country: "United States",
    code: "US",
  },
  {
    city: "Yopal",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Yopougon",
    country: "Côte d'Ivoire",
    code: "CI",
  },
  {
    city: "Yorba Linda",
    country: "United States",
    code: "US",
  },
  {
    city: "York",
    country: "United Kingdom",
    code: "GB",
  },
  {
    city: "York",
    country: "United States",
    code: "US",
  },
  {
    city: "Yoro",
    country: "Honduras",
    code: "HN",
  },
  {
    city: "Yoshikawa",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yoshkar-Ola",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yotsukaidō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Youngstown",
    country: "United States",
    code: "US",
  },
  {
    city: "Youssoufia",
    country: "Morocco",
    code: "MA",
  },
  {
    city: "Yozgat",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Yuanlin",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Yuanping",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuba City",
    country: "United States",
    code: "US",
  },
  {
    city: "Yucaipa",
    country: "United States",
    code: "US",
  },
  {
    city: "Yucheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yucheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuci",
    country: "China",
    code: "CN",
  },
  {
    city: "Yueqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Yūki",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yüksekova",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Yukuhashi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yulin",
    country: "China",
    code: "CN",
  },
  {
    city: "Yulinshi",
    country: "China",
    code: "CN",
  },
  {
    city: "Yulu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuma",
    country: "United States",
    code: "US",
  },
  {
    city: "Yumbo",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Yuncheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Yunfu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yunfu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yunnanyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Yunxian Chengguanzhen",
    country: "China",
    code: "CN",
  },
  {
    city: "Yunzhong",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuquan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yüreğir",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Yurga",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Yurihonjō",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Yurimaguas",
    country: "Peru",
    code: "PE",
  },
  {
    city: "Yushan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yushu",
    country: "China",
    code: "CN",
  },
  {
    city: "Yutan",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuyao",
    country: "China",
    code: "CN",
  },
  {
    city: "Yuzhno-Sakhalinsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zaandam",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Zaanstad",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Zabīd",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Zābol",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Zabrze",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Zacapa",
    country: "Guatemala",
    code: "GT",
  },
  {
    city: "Zacatecas",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zacatecoluca",
    country: "El Salvador",
    code: "SV",
  },
  {
    city: "Zadar",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Zafarwal",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Zagnanado",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Zagreb",
    country: "Croatia",
    code: "HR",
  },
  {
    city: "Zāhedān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Zahirābād",
    country: "India",
    code: "IN",
  },
  {
    city: "Zahlé",
    country: "Lebanon",
    code: "LB",
  },
  {
    city: "Zākhū",
    country: "Iraq",
    code: "IQ",
  },
  {
    city: "Zalaegerszeg",
    country: "Hungary",
    code: "HU",
  },
  {
    city: "Zalantun",
    country: "China",
    code: "CN",
  },
  {
    city: "Zalău",
    country: "Romania",
    code: "RO",
  },
  {
    city: "Zama",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Zamboanga City",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Zamora",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Zamora",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zamość",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Zangāreddigūdem",
    country: "India",
    code: "IN",
  },
  {
    city: "Zango",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Zanhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zanjān",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Zanzibar",
    country: "Tanzania",
    code: "TZ",
  },
  {
    city: "Zaoyang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zaozhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zapopan",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zaporizhzhia",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Zapotlanejo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zarafshon Shahri",
    country: "Uzbekistan",
    code: "UZ",
  },
  {
    city: "Zaragoza",
    country: "Philippines",
    code: "PH",
  },
  {
    city: "Zaragoza",
    country: "Spain",
    code: "ES",
  },
  {
    city: "Zarand",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Zaranj",
    country: "Afghanistan",
    code: "AF",
  },
  {
    city: "Zárate",
    country: "Argentina",
    code: "AR",
  },
  {
    city: "Zaraza",
    country: "Venezuela",
    code: "VE",
  },
  {
    city: "Zarechnyy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zaria",
    country: "Nigeria",
    code: "NG",
  },
  {
    city: "Zarrīn Shahr",
    country: "Iran",
    code: "IR",
  },
  {
    city: "Zarzal",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Zarzis",
    country: "Tunisia",
    code: "TN",
  },
  {
    city: "Zeist",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Zelënodol’sk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zelenogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zempoala",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zemun",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Zengcun",
    country: "China",
    code: "CN",
  },
  {
    city: "Zenica",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Zephyrhills",
    country: "United States",
    code: "US",
  },
  {
    city: "Zerakpur",
    country: "India",
    code: "IN",
  },
  {
    city: "Zêtang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zgierz",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Zhangaözen",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Zhanggu",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangjiajie",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangjiakou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangjiakou Shi Xuanhua Qu",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangjiazhuang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangmu Touwei",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangping",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangshu",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangye",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhangzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhanjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhanlicun",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhaodong",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhaoqing",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhaotong",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhaozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhaxi",
    country: "China",
    code: "CN",
  },
  {
    city: "Zheleznogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zheleznogorsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zhengding",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhengjiatun",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhengzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhenjiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhenzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhexiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhezqazghan",
    country: "Kazakhstan",
    code: "KZ",
  },
  {
    city: "Zhigulevsk",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zhijiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhijiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhlobin",
    country: "Belarus",
    code: "BY",
  },
  {
    city: "Zhob",
    country: "Pakistan",
    code: "PK",
  },
  {
    city: "Zhongba",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhongcheng",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhongli",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Zhongshan",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhongshu",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhongwei",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhongxiang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhoujiajing",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhoukou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhoushan",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhuanghe",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhuangyuan",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhubei",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Zhudong",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Zhufeng",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhugang",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhuhai",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhuji",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhukovskiy",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zhumadian",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhunan",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Zhuolu",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhuozhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhushan",
    country: "Taiwan",
    code: "TW",
  },
  {
    city: "Zhuzhou",
    country: "China",
    code: "CN",
  },
  {
    city: "Zhytomyr",
    country: "Ukraine",
    code: "UA",
  },
  {
    city: "Zibo",
    country: "China",
    code: "CN",
  },
  {
    city: "Zielona Góra",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Ziftá",
    country: "Egypt",
    code: "EG",
  },
  {
    city: "Zigong",
    country: "China",
    code: "CN",
  },
  {
    city: "Ziguinchor",
    country: "Senegal",
    code: "SN",
  },
  {
    city: "Zihuatanejo",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zijinglu",
    country: "China",
    code: "CN",
  },
  {
    city: "Zile",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Žilina",
    country: "Slovakia",
    code: "SK",
  },
  {
    city: "Zinacantepec",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zinder",
    country: "Niger",
    code: "NE",
  },
  {
    city: "Zinjibār",
    country: "Yemen",
    code: "YE",
  },
  {
    city: "Zipaquirá",
    country: "Colombia",
    code: "CO",
  },
  {
    city: "Živinice",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Zlatoust",
    country: "Russia",
    code: "RU",
  },
  {
    city: "Zlín",
    country: "Czechia",
    code: "CZ",
  },
  {
    city: "Zoetermeer",
    country: "Netherlands",
    code: "NL",
  },
  {
    city: "Zogbodomé",
    country: "Benin",
    code: "BJ",
  },
  {
    city: "Zográfos",
    country: "Greece",
    code: "GR",
  },
  {
    city: "Zomba",
    country: "Malawi",
    code: "MW",
  },
  {
    city: "Zongo",
    country: "Congo (Kinshasa)",
    code: "CD",
  },
  {
    city: "Zonguldak",
    country: "Turkey",
    code: "TR",
  },
  {
    city: "Żory",
    country: "Poland",
    code: "PL",
  },
  {
    city: "Zouerate",
    country: "Mauritania",
    code: "MR",
  },
  {
    city: "Zouping",
    country: "China",
    code: "CN",
  },
  {
    city: "Zrenjanin",
    country: "Serbia",
    code: "RS",
  },
  {
    city: "Zumpango",
    country: "Mexico",
    code: "MX",
  },
  {
    city: "Zunhua",
    country: "China",
    code: "CN",
  },
  {
    city: "Zunyi",
    country: "China",
    code: "CN",
  },
  {
    city: "Zürich",
    country: "Switzerland",
    code: "CH",
  },
  {
    city: "Zushi",
    country: "Japan",
    code: "JP",
  },
  {
    city: "Zuwārah",
    country: "Libya",
    code: "LY",
  },
  {
    city: "Zvornik",
    country: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    city: "Zwickau",
    country: "Germany",
    code: "DE",
  },
  {
    city: "Zwolle",
    country: "Netherlands",
    code: "NL",
  },
] as const;
