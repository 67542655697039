import AllBrightError from "./AllBrightError";
import AllBrightErrorType from "./AllBrightErrorType";

class UnknownError extends AllBrightError {
  constructor(message: string, name: string) {
    super(message, name, AllBrightErrorType.ERROR_MESSAGE);
    super.logError();
  }
}

export default UnknownError;
