import React from "react";
import axios from "axios";
import dayjs from "dayjs";
import * as Router from "next/router";
import * as Sentry from "@sentry/browser";

import * as c from "../constants";
import * as u from "../utils";
import * as formatDateAndTime from "../utils/formatDateAndTime";
import * as gtag from "../utils/gtag";
import firebase from "../firebase.client";
import { UserModel } from "@/lib/Domain/User/User";
import * as sc from "stream-chat";
import config from "@/config.client";

export interface UserContextModel extends UserModel {
  membership?: membership;
  updateUserInfo: (user: UserModel) => void;
  hasLoaded: boolean;
  refresh: () => Promise<void>;
}

const Context = React.createContext<UserContextModel | null>(null);

type membership = {
  id: string | null | undefined; // string = membership, null = free, undefined = waiting to be set
  isClub: boolean;
};

function Provider(props) {
  const router = Router.useRouter();
  const [userInfo, setUserInfo] = React.useState(getLocalUser());
  const [membership, setMembership] = React.useState<membership>({
    id: undefined,
    isClub: false,
  });
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const value = {
    ...props.value,
    updateUserInfo,
    hasLoaded,
    membership,
    refresh: onInit,
    onCheckAndSetUserState,
    ...userInfo,
  };

  React.useEffect(() => {
    onInit();
  }, [userInfo?.id]);
  React.useEffect(onObserveAuth, []);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;

  function getLocalUser() {
    try {
      return JSON.parse(u.localStorage.getItem(c.USER_INFO) || "");
    } catch (err) {
      return {};
    }
  }

  async function onInit() {
    setHasLoaded(false);

    const streamToken = u.localStorage.getItem(c.CHAT_TOKEN);

    if (streamToken && userInfo?.id) {
      const chatClient = sc.StreamChat.getInstance(config.STREAM_PUBLIC);
      chatClient.connectUser({ id: userInfo.id }, streamToken);
    }

    await onCheckAndSetUserState();
    setHasLoaded(true);
  }

  async function onCheckAndSetUserState() {
    if (!userInfo?.id) return;

    let userMembership = { id: undefined, isClub: false };

    try {
      userMembership = (await axios.get("/api/user.get-membership")).data;
    } catch (err) {
      Sentry.captureException(err);
    }

    setMembership(userMembership);

    const lastActiveAt = dayjs().unix();

    gtag.identifyUser(userInfo.id);

    gtag.event({
      name: gtag.GA_EVENT.USER_LAST_ACTIVE,
      category: "User",
      label: userMembership.isClub
        ? "Club"
        : userMembership.id
          ? "Digital"
          : "Free",
      value: dayjs().unix() - lastActiveAt,
    });

    const userRef = firebase.firestore().collection("users").doc(userInfo.id);
    const currUserInfo = (await userRef.get()).data();

    let timezone = currUserInfo?.timezone || null;
    if (timezone !== formatDateAndTime.userTz)
      timezone = formatDateAndTime.userTz;
    const updates = {
      isPaid: !!userMembership.id,
      isClub: userMembership.isClub,
      lastActiveAt,
      timezone,
    };

    await userRef.set(updates, { merge: true });
    updateUserInfo({ ...currUserInfo, ...updates });
  }

  function updateUserInfo(newUserInfo) {
    setUserInfo(newUserInfo);
    u.localStorage.setItem(c.USER_INFO, JSON.stringify(newUserInfo));
  }

  function onObserveAuth() {
    if (!userInfo?.id) return;
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) return;
      await axios("/api/user.logout");
      setUserInfo({});
      const locallyStoredItems = Object.values(c);
      locallyStoredItems.forEach((item) => u.localStorage.removeItem(item));
      u.sessionStorage.clear();
      await firebase.auth().signOut();
      router.push("/auth/login");
    });
  }
}

export { Context, Provider };
