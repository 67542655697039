import styled from "styled-components";
import Link from "next/link";

import * as T from "./Typography";

// Use isTextAnimation attr if text is first child of this Component.
// Otherwise use T.Animation at source as a wrapper for text.

export default function LinkWrap(props) {
  return (
    <Link href={props.href} passHref>
      {props.isTextAnimation ? (
        <T.A isCta={props.isCta} data-cy={`${props.name}-link`}>
          {props.children}
        </T.A>
      ) : (
        <S.A
          target={props.target}
          isDisable={props.isDisable}
          data-cy={`${props.name}-link`}
        >
          {props.children}
        </S.A>
      )}
    </Link>
  );
}

const S = {};

S.A = styled.a`
  border-radius: 20px;
  color: inherit;
  text-decoration: inherit;
  underline: none;
  ${(p) =>
    p.isDisable &&
    `
    pointer-events: none;
  `}
`;
