import React from "react";
import styled from "styled-components";
import * as Router from "next/router";
import Link from "next/link";

import IconAllBright from "@/components/IconAllBright";
import { colours } from "@/utils";
import { Device } from "@/utils/device";
import * as T from "@/components/Typography";
import * as I from "@/components/Icons";
import { UserContextModel } from "@/components/UserContext";
import NavDropdown from "./NavDropdown";
import NavDropdownContent from "./NavDropdownContent";
import { NavigationItemModel } from "@/types/NavigationItems";
import NavCircleButtons from "./NavCircleButtons";

type NavLinksProps = {
  links: NavigationItemModel[];
  userInfo: UserContextModel;
  isOpen: boolean;
  onClick: () => void;
  isPlatform?: boolean;
};

const NavLinks: React.FunctionComponent<NavLinksProps> = (props) => {
  const router = Router.useRouter();
  const navRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const signedIn = props.userInfo?.id && loggedIn;
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const [dropdownContent, setDropdownContent] =
    React.useState<NavigationItemModel | null>(null);
  const [dropdownHeight, setDropdownHeight] = React.useState(0);
  const [currentDropdown, setCurrentDropdown] = React.useState("");
  const [burgerMenuOpen, setBurgerMenuOpen] = React.useState(false);

  React.useEffect(() => {
    setLoggedIn(!!props.userInfo?.id);
  }, [props.userInfo?.id]);

  React.useEffect(() => {
    const measureHeight = () => {
      if (contentRef.current) {
        setDropdownHeight(contentRef.current.clientHeight);
      }
    };

    measureHeight();
    window.addEventListener("resize", measureHeight);
    return () => window.removeEventListener("resize", measureHeight);
  }, [dropdownContent]);

  React.useEffect(() => {
    router?.events?.on("routeChangeComplete", clearDropdowns);

    return () => {
      router?.events?.off("routeChangeComplete", clearDropdowns);
    };
  }, [router]);

  React.useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("click", checkForOutsideClick);
      return () => document.removeEventListener("click", checkForOutsideClick);
    }
  }, [dropdownOpen]);

  const clearDropdowns = () => {
    setDropdownOpen(false);
    setBurgerMenuOpen(false);
    setCurrentDropdown("");
    setTimeout(() => {
      setDropdownContent(null);
    }, 200);
  };

  const checkForOutsideClick = (evt: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(evt.target as Node)) {
      clearDropdowns();
    }
  };

  const handleDropdownButtonClick = (link) => {
    if (currentDropdown === link.title && dropdownOpen === true) {
      clearDropdowns();
    } else {
      setDropdownOpen(true);
      setCurrentDropdown(link.title);
      setDropdownContent(link);
    }
  };

  const handleBurgerMenuClick = () => {
    if (burgerMenuOpen === false) {
      setBurgerMenuOpen(true);
    }
    if (burgerMenuOpen === true) {
      clearDropdowns();
    }
  };

  const getDesktopLink = (link: NavigationItemModel) => {
    const isActive = currentDropdown === link.title;

    return (
      <S.Button
        data-cy={`nav-desktop-tab-${link.title}`}
        tabIndex={0}
        isActive={isActive}
        onClick={() => handleDropdownButtonClick(link)}
        aria-label={`Open ${link.title} Menu`}
        key={link.title}
      >
        {isActive && <S.ArrowIcon />}
        <S.LinkText color={colours.charcoal} isActive={isActive}>
          {link.title}
        </S.LinkText>
      </S.Button>
    );
  };

  const getMobileLink = (link: NavigationItemModel) => {
    const isActive = currentDropdown === link.title;

    return (
      <S.Button
        data-cy={`nav-mobile-tab-${link.title}`}
        tabIndex={0}
        isActive={isActive}
        onClick={() => handleDropdownButtonClick(link)}
        aria-label={`Open ${link.title} Menu`}
        key={link.title}
      >
        {isActive && <S.ArrowIcon />}
        <S.LinkText color={colours.charcoal} isActive={isActive}>
          {link.title}
        </S.LinkText>
      </S.Button>
    );
  };

  return (
    <S.Container ref={navRef} data-cy="nav-bar">
      <S.NavContent>
        <S.Flex signedIn={signedIn}>
          <Link href={signedIn ? "/home" : "/"}>
            <S.IconAllBrightB />
          </Link>
          <S.NavLinks>
            {props.links && props.links.map(getDesktopLink)}
            {!signedIn && (
              <S.Button
                data-cy={`nav-desktop-tab-login`}
                tabIndex={0}
                onClick={() => router.push("/auth/login")}
              >
                <S.LinkText color={colours.teal} colour={colours.teal}>
                  Log in
                </S.LinkText>
              </S.Button>
            )}
          </S.NavLinks>
        </S.Flex>
        <NavCircleButtons
          dropdownClick={handleBurgerMenuClick}
          isPlatform={props.isPlatform}
          isOpen={burgerMenuOpen}
        />
      </S.NavContent>
      <S.BurgerLinks menuOpen={burgerMenuOpen}>
        {!signedIn ? (
          <S.Button tabIndex={0} onClick={() => router.push("/auth/login")}>
            <S.LinkText color={colours.teal} colour={colours.teal}>
              Log in
            </S.LinkText>
          </S.Button>
        ) : (
          <S.Button
            tabIndex={0}
            onClick={() => router.push("/account")}
            aria-label="Go to your account"
          >
            <S.LinkText color={colours.teal} colour={colours.teal}>
              Account
            </S.LinkText>
          </S.Button>
        )}
        {props.links && props.links.map(getMobileLink)}
      </S.BurgerLinks>
      <NavDropdown dropdownOpen={dropdownOpen} contentHeight={dropdownHeight}>
        <NavDropdownContent
          {...dropdownContent}
          clearDropdowns={clearDropdowns}
          dropdownOpen={dropdownOpen}
          ref={contentRef}
        />
      </NavDropdown>
    </S.Container>
  );
};

type StyleProps = {
  isActive?: boolean;
  menuOpen?: boolean;
  signedIn?: boolean;
};

const S = () => {};

S.Container = styled.nav`
  position: fixed;
  z-index: 999;
  width: 100%;
`;

S.NavContent = styled.div<StyleProps>`
  height: 60px;
  padding: 0 20px;
  background: ${colours.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;

  @media (min-width: ${Device.tablet}) {
    height: 84px;
    padding: 0 40px;
  }
`;

S.Flex = styled.div<StyleProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.signedIn ? "flex-start" : "space-between")};
`;

S.NavLinks = styled.div`
  display: none;

  @media (min-width: ${Device.desktop}) {
    margin-left: 25px;
    height: 100%;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

S.BurgerLinks = styled.div<StyleProps>`
  position: absolute;
  right: 0;
  z-index: 999;
  box-shadow: 0 1em 1em -1em ${colours.charcoalMid};
  max-height: ${(p) => (p.menuOpen ? "25em" : "0")};
  width: 10em;
  background: ${colours.white};
  padding: 0 1em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;

  ${(p) =>
    p.menuOpen &&
    `
    display: flex;
    padding: 1em 0;
    text-align: left;
  `}

  @media (min-width: ${Device.desktop}) {
    display: none;
  }
`;

S.ArrowIcon = styled(I.Triangle)`
  position: absolute;
  left: 1em;
  margin: 0.25em 0 0 0;
  transform: rotate(180deg);

  @media (min-width: ${Device.desktop}) {
    display: none;
  }
`;

S.IconAllBrightB = styled(IconAllBright)`
  width: 100%;
  max-width: 100px;
  cursor: pointer;
  padding-right: 6px;
  transition: color 0.2s ease;

  @media (min-width: ${Device.tablet}) {
    padding-right: 16px;
    min-width: 200px;
  }
`;

S.LinkText = styled(T.Animation)<StyleProps>`
  height: fit-content;
  opacity: 1;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  white-space: nowrap;

  ${(p) =>
    p.isActive &&
    `
    &::after {
      background-color: ${(p) => p.color || colours.charcoal};
      width: 100%;
    }`}
`;

S.Button = styled.button<StyleProps>`
  position: relative;
  outline: inherit;
  border: none;
  height: 100%;
  cursor: pointer;
  background: ${(p) => (p.isActive ? colours.shell : "none")};
  padding: 0.8em 1.5em;
  display: flex;
  align-items: center;
  justify-content: end;
  color: inherit;
  font: inherit;
  transition: all 0.2s ease-out;

  &:hover {
    p:after {
      background-color: ${colours.charcoal};
      width: 100%;
    }
  }

  &:focus-visible {
    border: 2px solid blue;
  }
`;

export default NavLinks;
