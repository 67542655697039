import styled from "styled-components";
import * as u from "../utils";

// To find out more about how responsive fonts work, see
// https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/
const getResponsiveFont = (
  responsiveFont: ResponsiveFont
): string | undefined => {
  if (responsiveFont) {
    const { min, max } = responsiveFont;
    return `
      --min-font: ${min / 10}rem;
      --max-font: ${max / 10}rem;
      font-size: clamp(var(--min-font), calc(1rem + 8vw), var(--max-font));
    `;
  }
};

type ResponsiveFont = {
  min: number;
  max: number;
};

export type BaseProps = {
  background?: string;
  color?: string;
  /** @deprecated Use color instead */
  colour?: string;
  height?: string | number;
  width?: string | number;
  weight?: string | number;
  fontStyle?: string;
  fontSize?: string;
  textAlign?: string;
  letterSpacing?: string | number;
  opacity?: string;
  lineHeight?: string | number;
  allowWhiteSpace?: boolean;
  zIndex?: string;
  transform?: string;
  roxborough?: boolean;
  responsiveFonts?: boolean | ResponsiveFont;

  display?: "flex" | "block" | "inline" | "inline-block" | "none";
  justifyContent?: "center" | "start" | "end" | "between" | "around"; // Flexbox justify-content values
  alignItems?: "center" | "start" | "end" | "baseline" | "stretch"; // Flexbox align-items values

  // Number value is in px
  margin?: string | number; // For setting all sides at once, e.g., "10px 20px 10px 20px"
  marginTop?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;

  // Number value is in px
  padding?: string | number; // For setting all sides at once, e.g., "10px 20px 10px 20px"
  paddingTop?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
  paddingRight?: string | number;

  border?: string;
  borderWidth?: string | number;
  borderTop?: string;
  borderTopWidth?: string | number;
  borderBottom?: string;
  borderBottomWidth?: string | number;
  borderLeft?: string;
  borderLeftWidth?: string | number;
  borderRight?: string;
  borderRightWidth?: string | number;
  borderRadius?: string | number;
  borderColor?: string;
  borderStyle?: string;

  maxWidth?: string | number;
  minWidth?: string | number;
  maxHeight?: string | number;
  minHeight?: string | number;
};

const formatNumberToPx = (value?: string | number): string => {
  if (!value) return "";
  return typeof value === "number" ? `${value}px` : value;
};

export function base(p: BaseProps) {
  let formatting = ``;
  if (p.background) formatting += `background: ${p.background};`;
  if (p.color || p.colour) formatting += `color: ${p.color || p.colour};`;
  if (p.width) formatting += `width: ${formatNumberToPx(p.width)};`;
  if (p.height) formatting += `height: ${formatNumberToPx(p.height)};`;
  if (p.weight) formatting += `font-weight: ${p.weight};`;
  if (p.fontStyle) formatting += `font-style: ${p.fontStyle};`;
  if (p.fontSize) formatting += `font-size: ${formatNumberToPx(p.fontSize)};`;
  if (p.textAlign) formatting += `text-align: ${p.textAlign};`;
  if (p.letterSpacing)
    formatting += `letter-spacing: ${formatNumberToPx(p.letterSpacing)};`;
  if (p.opacity) formatting += `opacity: ${p.opacity};`;
  if (p.lineHeight) formatting += `line-height: ${p.lineHeight};`;
  if (p.allowWhiteSpace) formatting += `white-space: pre-wrap;`;
  if (p.zIndex) formatting += `z-index: ${p.zIndex};`;
  if (p.transform) formatting += `text-transform: ${p.transform};`;
  if (p.roxborough) formatting += `font-family: Roxborough CF, sans-serif;`;

  if (p.display) formatting += `display: ${p.display};`;
  if (p.justifyContent) formatting += `justify-content: ${p.justifyContent};`;
  if (p.alignItems) formatting += `align-items: ${p.alignItems};`;

  if (p.margin) formatting += `margin: ${formatNumberToPx(p.margin)};`;
  if (p.marginTop)
    formatting += `margin-top: ${formatNumberToPx(p.marginTop)};`;
  if (p.marginBottom)
    formatting += `margin-bottom: ${formatNumberToPx(p.marginBottom)};`;
  if (p.marginLeft)
    formatting += `margin-left: ${formatNumberToPx(p.marginLeft)};`;
  if (p.marginRight)
    formatting += `margin-right: ${formatNumberToPx(p.marginRight)};`;

  if (p.padding) formatting += `padding: ${formatNumberToPx(p.padding)};`;
  if (p.paddingTop)
    formatting += `padding-top: ${formatNumberToPx(p.paddingTop)};`;
  if (p.paddingBottom)
    formatting += `padding-bottom: ${formatNumberToPx(p.paddingBottom)};`;
  if (p.paddingLeft)
    formatting += `padding-left: ${formatNumberToPx(p.paddingLeft)};`;
  if (p.paddingRight)
    formatting += `padding-right: ${formatNumberToPx(p.paddingRight)};`;

  // all border combinations
  if (p.border) {
    formatting += `border: ${p.border};`;
  } else if (p.borderWidth) {
    formatting += `border: ${formatNumberToPx(p.borderWidth)} ${p.borderStyle || "solid"} ${p.borderColor || "#000"};`;
  }

  if (p.borderTop) {
    formatting += `border-top: ${p.borderTop};`;
  } else if (p.borderTopWidth) {
    formatting += `border-top: ${formatNumberToPx(p.borderTopWidth)} ${p.borderStyle || "solid"} ${p.borderColor || "#000"};`;
  }

  if (p.borderBottom) {
    formatting += `border-bottom: ${p.borderBottom};`;
  } else if (p.borderBottomWidth) {
    formatting += `border-bottom: ${formatNumberToPx(p.borderBottomWidth)} ${p.borderStyle || "solid"} ${p.borderColor || "#000"};`;
  }

  if (p.borderLeft) {
    formatting += `border-left: ${p.borderLeft};`;
  } else if (p.borderLeftWidth) {
    formatting += `border-left: ${formatNumberToPx(p.borderLeftWidth)} ${p.borderStyle || "solid"} ${p.borderColor || "#000"};`;
  }

  if (p.borderRight) {
    formatting += `border-right: ${p.borderRight};`;
  } else if (p.borderRightWidth) {
    formatting += `border-right: ${formatNumberToPx(p.borderRightWidth)} ${p.borderStyle || "solid"} ${p.borderColor || "#000"};`;
  }

  if (p.borderRadius) {
    formatting += `border-radius: ${formatNumberToPx(p.borderRadius)};`;
  }

  if (p.maxWidth) formatting += `max-width: ${formatNumberToPx(p.maxWidth)};`;
  if (p.minWidth) formatting += `min-width: ${formatNumberToPx(p.minWidth)};`;
  if (p.maxHeight)
    formatting += `max-height: ${formatNumberToPx(p.maxHeight)};`;
  if (p.minHeight)
    formatting += `min-height: ${formatNumberToPx(p.minHeight)};`;

  return formatting;
}

export const H1Rox = styled.h1<BaseProps>`
  ${base};
  font-family:
    Roxborough CF,
    sans-serif;
  font-size: 52px;

  @media ${u.device.mobile} {
    font-size: 58px;
  }

  @media ${u.device.tablet} {
    font-size: 64px;
  }
`;

export const H2Rox = styled.h2<BaseProps>`
  ${base};
  font-family:
    Roxborough CF,
    sans-serif;
  font-size: 26px;

  @media ${u.device.mobile} {
    font-size: 28px;
  }

  @media ${u.device.tablet} {
    font-size: 32px;
  }
`;

export const H3Rox = styled.h3<BaseProps>`
  ${base};
  font-family:
    Roxborough CF,
    sans-serif;
  font-size: 20px;

  @media ${u.device.mobile} {
    font-size: 22px;
  }

  @media ${u.device.tablet} {
    font-size: 24px;
  }
`;

export const H1 = styled.h1<BaseProps>`
  ${base};

  ${(props) => {
    if (props.responsiveFonts === true) {
      return getResponsiveFont({ min: 38, max: 48 });
    } else if (props.responsiveFonts) {
      return getResponsiveFont(props.responsiveFonts);
    }

    // legacy, TODO can be converted to xs, sm, md, ...
    return `
      font-size: 38px;
  
      @media ${u.device.tablet} {
        font-size: 40px;
      }
  
      @media ${u.device.mobile} {
        font-size: 44px;
      }
    `;
  }}
`;

export const H2 = styled.h2<BaseProps>`
  ${base};

  ${(props) => {
    if (props.responsiveFonts === true) {
      return getResponsiveFont({ min: 24, max: 32 });
    } else if (props.responsiveFonts) {
      return getResponsiveFont(props.responsiveFonts);
    }

    // legacy, TODO can be converted to xs, sm, md, ...
    return `
      font-size: 24px;
      
      @media ${u.device.tablet} {
        font-size: 28px;
      }
      
      @media ${u.device.mobile} {
        font-size: 32px;
      }
    `;
  }}
`;

export const H3 = styled.h3<BaseProps>`
  ${base};

  ${(props) => {
    if (props.responsiveFonts === true) {
      return getResponsiveFont({ min: 20, max: 24 });
    } else if (props.responsiveFonts) {
      return getResponsiveFont(props.responsiveFonts);
    }

    // legacy, TODO can be converted to xs, sm, md, ...
    return `
      font-size: 20px;
      
      @media ${u.device.tablet} {
        font-size: 22px;
      }
      
      @media ${u.device.mobile} {
        font-size: 24px;
      }
    `;
  }}
`;

export const H4 = styled.h4<BaseProps>`
  ${base};

  ${(props) => {
    if (props.responsiveFonts === true) {
      return getResponsiveFont({ min: 16, max: 20 });
    } else if (props.responsiveFonts) {
      return getResponsiveFont(props.responsiveFonts);
    }

    // legacy, TODO can be converted to xs, sm, md, ...
    return `
      font-size: 16px;
      
      @media ${u.device.tablet} {
        font-size: 18px;
      }
      
      @media ${u.device.mobile} {
        font-size: 20px;
      }
    `;
  }}
`;

export const H5 = styled.h5<BaseProps>`
  ${base};

  ${(props) => {
    if (props.responsiveFonts === true) {
      return getResponsiveFont({ min: 14, max: 16 });
    } else if (props.responsiveFonts) {
      return getResponsiveFont(props.responsiveFonts);
    }

    // legacy, TODO can be converted to xs, sm, md, ...
    return `
      font-size: 14px;
      
      @media ${u.device.tablet} {
        font-size: 16px;
      }
      
      @media ${u.device.mobile} {
        font-size: 16px;
      }
    `;
  }}
`;

export const H6 = styled.h6<BaseProps>`
  ${base};

  ${(props) => {
    if (props.responsiveFonts === true) {
      return getResponsiveFont({ min: 10, max: 12 });
    } else if (props.responsiveFonts) {
      return getResponsiveFont(props.responsiveFonts);
    }

    // legacy, TODO can be converted to xs, sm, md, ...
    return `
  font-size: 10px;
  
  @media ${u.device.tablet} {
    font-size: 10px;
  }
  
  @media ${u.device.mobile} {
    font-size: 12px;
  }
    `;
  }}
`;

export const CXXL = styled.p<BaseProps>`
  ${base};
  font-size: 20px;

  @media ${u.device.tablet} {
    font-size: 22px;
  }

  @media ${u.device.mobile} {
    font-size: 24px;
  }
`;

export const CXL = styled.p<BaseProps>`
  ${base};
  font-size: 14px;

  @media ${u.device.tablet} {
    font-size: 16px;
  }

  @media ${u.device.mobile} {
    font-size: 18px;
  }
`;

export const CL = styled.p<BaseProps>`
  ${base};
  font-size: 12px;

  @media ${u.device.mobile} {
    font-size: 14px;
  }

  @media ${u.device.tablet} {
    font-size: 16px;
  }
`;

export const CM = styled.p<BaseProps>`
  ${base};
  font-size: 12px;

  @media ${u.device.mobile} {
    font-size: 14px;
  }

  @media ${u.device.tablet} {
    font-size: 14px;
  }
`;

export const CS = styled.p<BaseProps>`
  ${base};
  font-size: 10px;

  @media ${u.device.tablet} {
    font-size: 10px;
  }

  @media ${u.device.mobile} {
    font-size: 12px;
  }
`;

export const CXS = styled.p<BaseProps>`
  ${base};
  font-size: 8px;

  @media ${u.device.tablet} {
    font-size: 10px;
  }

  @media ${u.device.mobile} {
    font-size: 10px;
  }
`;

export const SPAN = styled.span<BaseProps>`
  ${base};

  ${(props) =>
    typeof props.responsiveFonts === "object" &&
    getResponsiveFont(props.responsiveFonts)}
`;

export const DIV = styled.div<BaseProps>`
  ${base};

  ${(props) =>
    typeof props.responsiveFonts === "object" &&
    getResponsiveFont(props.responsiveFonts)}
`;

interface AnimationProps extends BaseProps {
  isCta?: boolean;
  selected?: boolean;
}

export const A = styled.a<AnimationProps>`
  ${base};
  color: ${(p) => p.colour || "inherit"};
  cursor: pointer;
  font-weight: 700;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  ${(p) =>
    p.isCta &&
    `
    text-transform: uppercase;
    letter-spacing: 1px;
  `}

  &::after {
    background: ${(p) => p.colour || u.colours.charcoal};
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    width: 0;
    ${(p) => p.selected && "width: 100%;"}
  }

  &:hover::after {
    width: 100%;
  }
`;

export const AXL = styled(A)<AnimationProps>`
  font-size: 20px;

  @media ${u.device.mobile} {
    font-size: 22px;
  }

  @media ${u.device.tablet} {
    font-size: 24px;
  }
`;

export const Animation = styled.p<AnimationProps>`
  ${base};
  cursor: pointer;
  font-weight: 700;
  margin: 0 auto;
  position: relative;
  white-space: nowrap;
  text-decoration: none;

  ${(p) =>
    p.isCta
      ? `
    text-transform: uppercase;
    letter-spacing: 1px;
  `
      : ""}

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 0;
    left: 0;
    bottom: -2px;
    height: 2px;
    background-color: ${(p) => p.color || p.colour || u.colours.charcoal};
    transition: all 0.3s ease-out;
  }

  &:hover::after {
    width: 100%;
  }
`;
