import { ethnicGroups } from "../../types/user/ethnicGroups";
import goals from "../../types/user/goals";
import { interests } from "../../types/user/interests";
import { jobLevels } from "../../types/user/jobLevels";
import { jobStatus } from "../../types/user/jobStatus";
import organisationSize from "../../types/user/organisationSize";
import salary, { getSalariesByCountry } from "../../types/user/salary";
import { cities } from "../../types/user/cities";
import countries from "../../utils/countries";
import { onboardingIndustries } from "../../utils/industries";

class OnboardingClient {
  public getCities(
    userCountry: (typeof countries)[number]["Name"]
  ): (typeof cities)[number]["city"][] {
    const country = countries.find((country) => country.Name === userCountry);
    const countryCode = country ? country.Code : "GB";
    const cityNames = countryCode
      ? // get list of cities matching the user's country code
        cities
          .filter((city) => city.code === countryCode)
          .map((data) => data.city)
      : // otherwise list all cities
        cities.map((data) => data.city);

    return [...new Set(cityNames)];
  }

  public getJobLevels() {
    return [...jobLevels] as unknown as (typeof jobLevels)[number][];
  }

  public getIndustries() {
    return [
      ...onboardingIndustries,
    ] as unknown as (typeof onboardingIndustries)[number][];
  }

  public getGoals() {
    return [...goals] as unknown as (typeof goals)[number][];
  }

  public getInterests() {
    return [...interests];
  }

  public getJobStatus() {
    return [...jobStatus] as unknown as (typeof jobStatus)[number][];
  }

  public getSalary(country?: string) {
    return [
      ...getSalariesByCountry(country),
    ] as unknown as (typeof salary)[number][];
  }

  public getOrganisationSize() {
    return [
      ...organisationSize,
    ] as unknown as (typeof organisationSize)[number][];
  }

  public getEthnicGroups() {
    return [...ethnicGroups] as unknown as (typeof ethnicGroups)[number][];
  }
}

export default OnboardingClient;
