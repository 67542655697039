export default {
  "/": {
    title: "Join Our Online Membership For Women | AllBright",
    description:
      "We are the career network for women. Inclusive community. Exclusive content. Endless opportunities. Sign up and become part of AllBright today.",
  },
  "/about": {
    title: "About Us | AllBright",
    description:
      "Upskill in professional courses. Learn the latest in brand marketing, capital raising and diveristy, equality + inclusion from leading industry experts.",
  },
  "/academy": {
    title: "Our Academy Courses | AllBright",
    description:
      "Upskill in professional courses. Learn the latest in brand marketing, capital raising and diveristy, equality + inclusion from leading industry experts.",
  },
  "/academy/collections": {
    title: "Collections | Academy | AllBright",
    description: "",
  },
  "/academy/my-learning": {
    title: "My Learning | Academy | AllBright",
    description: "",
  },
  "/account": { title: "Account & Settings | AllBright", description: "" },
  "/auth/login": { title: "Log In | AllBright", description: "" },
  "/auth/new-password": { title: "New Password | AllBright", description: "" },
  "/auth/reset-password": {
    title: "Reset Password | AllBright",
    description: "",
  },
  "/auth/session-expired": {
    title: "Session Expired | AllBright",
    description: "",
  },
  "/business": { title: "Alliance | AllBright", description: "" },
  "/connect": { title: "Connect | AllBright", description: "" },
  "/connect/mentorship": {
    title: "Find a Mentor | AllBright",
    description: "",
  },
  "/contact": { title: "Contact Us | AllBright", description: "" },
  "/DEI": {
    title: "DEI | AllBright",
    description:
      "Our digital membership is open to all women across the world. Expand your network + knowledge through virtual events, online courses + career content.",
  },
  "/digital/interests": {
    title: "Your Digital Membership Interests | AllBright",
    description: "",
  },
  "/digital/pay": {
    title: "Pay For Digital Membership | AllBright",
    description: "",
  },
  "/digital/profile": {
    title: "Your Digital Membership Profile | AllBright",
    description: "",
  },
  "/digital/register": {
    title: "Register for Digital Membership | AllBright",
    description: "",
  },
  "/digital/select-plan": {
    title: "Select A Digital Payment Plan | AllBright",
    description: "",
  },
  "/digital/welcome": {
    title: "Welcome To Your Digital Membership | AllBright",
    description: "",
  },
  "/edit": {
    title: "Post | Advice. Opinions. Conversation. | AllBright",
    description:
      "AllBright Post features interviews with inspiring professional women, practical business how-tos and exclusive articles to enhance your work life.",
  },
  "/edit/articles": {
    title: "Post | Advice. Opinions. Conversation. | AllBright",
    description: "",
  },
  "/edit/share-story": {
    title: "Post | Advice. Opinions. Conversation. | AllBright",
    description: "",
  },
  "/events": {
    title: "Our Events | AllBright",
    description: `AllBright's programme of live and on-demand events include world-class keynote speakers, interactive workshops and inclusive networking.`,
  },
  "/events/my-events": { title: "My Events | AllBright", description: "" },
  "/events/on-demand": {
    title: "Events On-Demand | AllBright",
    description: "",
  },
  "/conversation": { title: "Feed | Connect | AllBright", description: "" },
  "/groups": { title: "Groups | Connect | AllBright", description: "" },
  "/individuals": {
    title: "Inspire | Connect | Learn | AllBright",
    description: "",
  },
  "/learning/courses": {
    title: "Courses | Learning | AllBright",
    description: "",
  },
  "/learning/programmes": {
    title: "Programmes | Learning | AllBright",
    description: "",
  },
  "/memberships": {
    title: "Our Memberships | AllBright",
    description:
      "Join our global community for access to exclusive events, unlimited courses and exceptional networking opportunities with professional women.",
  },
  "/mentorship": {
    title: "Mentor Matching | AllBright",
    description:
      "We’ve developed a tool that uses a skillset-based algorithm, designed to match mentees and mentors to ensure every woman succeeds in their career, faster. ",
  },
  "/mentorship/welcome": {
    title: "Welcome to Mentor Matching | AllBright",
    description: "",
  },
  "/mentorship/feedback": {
    title: "Mentor Matching Feedback | AllBright",
    description: "",
  },
  "/messages": { title: "Messages | AllBright", description: "" },
  "/newsletter": {
    title: "Sign Up to our Newsletter | AllBright",
    description: "",
  },
  "/notifications": { title: "Notifications | AllBright", description: "" },
  "/onboarding/complete": {
    title: "Complete | Onboarding | AllBright",
    description: "",
  },
  "/onboarding/pledge": {
    title: "Pledge | Onboarding | AllBright",
    description: "",
  },
  "/onboarding/profile-goals": {
    title: "Career Goals | Onboarding | AllBright",
    description: "",
  },
  "/onboarding/profile-private": {
    title: "Private Profile | Onboarding | AllBright",
    description: "",
  },
  "/onboarding/profile-public": {
    title: "Public Public | Onboarding | AllBright",
    description: "",
  },
  "/onboarding/resources": {
    title: "Get Started on our Platform | AllBright",
    description: "",
  },
  "/onboarding/welcome": {
    title: "Welcome | Onboarding | AllBright",
    description: "",
  },
  "/policies/cookies": { title: "Cookies Policy | AllBright", description: "" },
  "/policies/covid-19": {
    title: "Covid-19 Policy | AllBright",
    description: "",
  },
  "/policies/privacy": { title: "Privacy Policy | AllBright", description: "" },
  "/policies/terms-and-conditions": {
    title: "Terms & Conditions | AllBright",
    description: "",
  },
  "/private-hire": { title: "Private Hire | AllBright", description: "" },
  "/spaces/london": { title: "London Townhouse | AllBright", description: "" },
  "/spaces/events": { title: "Events | AllBright", description: "" },
  "/spaces/global": {
    title: "Global Memberships & Events | AllBright",
    description: "",
  },
  "/update-payment": {
    title: "Update Your Payment Method | AllBright",
    description: "",
  },
};
