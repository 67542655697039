import * as u from "../utils";

export default function getTextContrast(bgColour) {
  const colour =
    bgColour.charAt(0) === "#" ? bgColour.substring(1, 7) : bgColour;
  const r = parseInt(colour.substring(0, 2), 16); // hexToR
  const g = parseInt(colour.substring(2, 4), 16); // hexToG
  const b = parseInt(colour.substring(4, 6), 16); // hexToB

  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? u.colours.charcoal
    : u.colours.white;
}
