import React from "react";
import * as I from "@/components/Icons";
import * as T from "@/components/Typography";
import * as u from "@/utils";
import * as U from "@/components/Utils";
import TickInput from "@/components/TickInput";
import axios from "axios";
import * as gtag from "@/utils/gtag";
import styled from "styled-components";
import { Button } from "@/components/Button";

const SubscribeForm: React.FunctionComponent = () => {
  const [email, setEmail] = React.useState("");
  const [inputState, setInputState] = React.useState("");
  const [thirdPartyAgreed, setThirdPartyAgreed] = React.useState(false);
  const isSuccess = inputState.includes("Welcome");
  const MessageIcon = isSuccess ? (
    <I.Tick color="white" />
  ) : (
    <I.Close color="white" />
  );

  const handleInput = (i) => {
    setEmail(i.target.value);
    if (inputState) setInputState("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length === 0) return;
    const url = "/api/user.subscribe-to-newsletter";
    const body = { email, thirdPartyAgreed };
    try {
      await axios.post(url, body);
      setEmail("");
      setInputState("Welcome to AllBright!");

      gtag.event({
        name: gtag.GA_EVENT.PLATFORM_ENGAGEMENT,
        category: "Engagement",
        label: "Footer click",
        value: "Subscribe to newsletter via banner",
      });
    } catch (error) {
      setInputState("Please check your email and try again.");
    }
  };

  return (
    <S.SubscribeForm
      onSubmit={handleSubmit}
      noValidate
      data-cy={"newsletter-banner"}
    >
      <S.Column align={"left"}>
        <T.CL colour={u.colours.shell} weight={"bold"}>
          Ready to liberate your ambition?
        </T.CL>
        <U.Space height={20} />
        <S.SubscribeWrapper>
          <S.EmailInput
            type="email"
            data-cy={`newsletter-email-input`}
            placeholder="Enter your email to begin"
            value={email}
            onChange={handleInput}
            aria-labelledby="newsletterTitle"
            invalid={inputState && !isSuccess}
          />
          <Button
            isWide
            colour={u.colours.pillTeal}
            data-cy={`subscribe-button`}
          >
            Subscribe
          </Button>
        </S.SubscribeWrapper>
        <S.Ticks>
          <TickInput
            name="thirdParty"
            isTrue={thirdPartyAgreed}
            onChange={() => setThirdPartyAgreed(!thirdPartyAgreed)}
          >
            <T.CS colour={"white"}>
              I would like to receive mail and offers from carefully selected
              partners of AllBright
            </T.CS>
          </TickInput>
        </S.Ticks>
        <S.InputState data-cy="subscribe-message">
          {inputState && MessageIcon}
          {inputState}
        </S.InputState>
      </S.Column>
    </S.SubscribeForm>
  );
};

type StyleProps = {
  align?: string;
  invalid?: boolean;
};

const S = () => {};

S.Column = styled.div<StyleProps>`
  width: 100%;
  @media ${u.device.tablet} {
    text-align: ${(p) => p.align};
  }
`;

S.SubscribeForm = styled.form`
  min-width: 100%;
  max-width: 500px;
  text-alight: left;
  margin: 30px 0;
  @media ${u.device.mobile} {
    min-width: 100%;
  }
  @media ${u.device.tablet} {
    min-width: 10%;
  }
`;

S.SubscribeWrapper = styled.div`
  border-radius: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  margin: 0 0px 0;
  @media ${u.device.mobile} {
    flex-direction: row;
    background-color: ${u.colours.pillTeal};
  }
`;

S.EmailInput = styled.input<StyleProps>`
  min-width: 220px;
  text-align: center;
  background-color: ${(p) =>
    p.invalid ? "hsla(0, 0%, 100%, .3)" : u.colours.shell};
  border: none;
  font-size: 1.5rem;
  outline: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  &::placeholder {
    opacity: 1;
  }
  @media ${u.device.mobile} {
    text-align: left;
    margin-bottom: 0px;
    width: 100%;
  }
`;

S.InputState = styled.p`
  align-items: center;
  color: white;
  display: flex;
  font-style: normal;
  font-size: 1.5rem;
  height: 10px;
  justify-content: center;
  line-height: 2rem;
  margin: 15px 0 -25px;
  opacity: 1;
  > * {
    margin: 0 5px;
  }
`;

S.Ticks = styled.div`
  width: 100%;
  text-align: left;
`;

export default SubscribeForm;
