import config from "../config.client";

export default {
  make: function (_, apiKey) {
    const rudderanalytics = require("rudder-sdk-js");
    rudderanalytics.load(apiKey, config.RUDDERSTACK_DATA_PLANE_URL);
  },
  logEvent: function (eventName, eventProperties) {
    const rudderanalytics = require("rudder-sdk-js");
    rudderanalytics.track(eventName, eventProperties);
  },
  // [...] other Rudderstack API's like .identify(), .alias(), .group()
};
