import React from "react";
import styled from "styled-components";

import { colours } from "@/utils";
import { Device } from "@/utils/device";

type NavDropdownProps = {
  children: React.ReactNode;
  contentHeight: number;
  dropdownOpen: boolean;
};

const NavDropdown: React.FunctionComponent<NavDropdownProps> = (props) => {
  return (
    <S.Container
      data-cy="nav-dropdown"
      dropdownOpen={props.dropdownOpen}
      contentHeight={props.contentHeight}
    >
      {props.children}
    </S.Container>
  );
};

type StyleProps = {
  dropdownOpen?: boolean;
  contentHeight?: number;
};

const S = () => {};

S.Container = styled.div<StyleProps>`
  position: absolute;
  right: 10em;
  box-shadow: 1em 1em 1em -1em ${colours.charcoalMid};
  border-radius: 0 0 5px 5px;
  max-height: ${(p) => (p.dropdownOpen ? `calc(100vh - 70px)` : "0")};
  background: ${colours.white};
  padding: 0;
  overflow: hidden;
  transition: all 0.2s linear;
  ${(p) =>
    p.dropdownOpen &&
    `
  padding: 1em 0 2em 0;
  overflow: scroll;
  `}

  @media(min-width: ${Device.tablet}) {
    max-height: ${(p) => (p.dropdownOpen ? `calc(100vh - 94px)` : "0")};
  }

  @media (min-width: ${Device.desktop}) {
    right: 0;
    box-shadow:
      0 1em 1em -1em ${colours.charcoalMid},
      -1em 0 1em -1em ${colours.charcoalMid};
    border-radius: 0;
    max-height: ${(p) =>
      p.dropdownOpen ? `calc(${p.contentHeight}px + 2em)` : "0"};
    width: 100%;
  }
`;

export default NavDropdown;
