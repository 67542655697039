import { combineReducers } from "@reduxjs/toolkit";
import mentorshipSlice from "./mentorshipSlice";
import onboardingSlice from "./onboardingSlice";
import careerGoalSlice from "./careerGoalSlice";

const memberReducers = () =>
  combineReducers({
    mentorship: mentorshipSlice,
    onboarding: onboardingSlice,
    careerGoals: careerGoalSlice,
  });

export default memberReducers;
