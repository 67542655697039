import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MentorshipModel } from "../../lib/Domain/Mentorship/Mentorship";
import { UserModel } from "../../lib/Domain/User/User";

export interface MentorshipState {
  mentorships: MentorshipModel[];
  mentors: UserModel[];
}

const initialState: MentorshipState = {
  mentorships: [],
  mentors: [],
};

export const mentorshipSlice = createSlice({
  name: "mentorship",
  initialState,
  reducers: {
    addMentors: (state, action: PayloadAction<UserModel[]>) => {
      const newUsers = action.payload.filter((user) => {
        return (
          state.mentors.findIndex((mentor) => mentor.id === user.id) === -1
        );
      });

      state.mentors = state.mentors.concat(newUsers);
    },
    removeMentors: (state, action: PayloadAction<UserModel>) => {
      state.mentors = state.mentors.filter((mentor) => {
        return mentor.id === action.payload.id;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMentors, removeMentors } = mentorshipSlice.actions;

export default mentorshipSlice.reducer;
