import React from "react";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";
import * as I from "./Icons";
import * as u from "../utils";
import getTextContrast from "../utils/textContrast";

/**
 *  When the Button component is used
 *  as a child of Link component from nextjs,
 *  the latter will pass a "ref" reference.
 *  React will complain about this with a
 *  warning. To avoid it we wrap Button in
 *  React.forwardRef(Button).
 *
 *  Rresources:
 *  - https://github.com/vercel/next.js/issues/7915#issuecomment-519786376
 */
export default Button;

export function Button(props) {
  const arrowIcon = props.showIcon;
  const style = getStyle();
  const colour = props.isSecondary ? u.colours.shellOverlay : props.colour;
  const buttonColour = colour ?? u.colours.teal;
  const fontColour = getTextContrast(buttonColour);

  const subProps = {
    buttonColour,
    disabled: props.isDisable ?? props.isLoading,
    fontColour: fontColour,
    isRight: props.isRight,
    isNotRound: props.isNotRound,
    style,
    ...props,
  };

  return (
    <div>
      <S.Button {...subProps}>
        {getChildren()}
        {arrowIcon && !props.isLoading && (
          <S.ArrowIconWrap {...props}>
            <I.ArrowTall fill={fontColour} />
          </S.ArrowIconWrap>
        )}
      </S.Button>
    </div>
  );

  function getChildren() {
    if (props.isLoading) return <LoadingSpinner size={"small"} />;
    return props.text || props.name || props.children;
  }

  function getStyle() {
    let sizeProps;
    switch (props.size) {
      case "xsml":
        sizeProps = { height: 25, fontSize: 12, fontWeight: 400 };
        break;
      case "sml":
        sizeProps = { height: 30, fontSize: 12, fontWeight: 400 };
        break;
      case "big":
        sizeProps = { height: 50, fontSize: 18, fontWeight: 500 };
        break;
      case "mid":
      default:
        sizeProps = { height: 40, fontSize: 16, fontWeight: 400 };
        break;
    }

    const height = sizeProps.height ?? props.height ?? 40;
    const width = props.isCircle ? height : "";
    return {
      height,
      width,
      fontSize: sizeProps.fontSize ?? props.fontSize,
      fontWeight: sizeProps.fontWeight,
    };
  }
}

const S = {};

S.Button = styled.button`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  font-family: "HK Grotesk", sans-serif;
  gap: 10px;
  justify-content: center;
  min-width: ${(p) => (p.isCircle ? "" : "100px")};
  padding: 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: filter 0.3s;
  background-color: ${(p) => p.buttonColour};
  border-radius: ${(p) =>
    p.isNotRound ? "0 30px 30px 0" : "30px 30px 30px 30px"};
  color: ${(p) => p.fontColour};
  ${(p) =>
    p.isRight &&
    `
    border-radius: 30px 0 0 30px;
  `}
  :focus {
    outline: none;
    box-shadow: none;
  }
  :hover {
    filter: brightness(0.9);
  }
  ${(p) =>
    p.isDisable &&
    `
    pointer-events: none;
    opacity: 70%;
  `}
  ${(p) =>
    p.isWide &&
    `
    width: 100%;
  `}
  ${(p) =>
    p.isCircle &&
    `
    height: 40px;
    width: 40px;
  `}
  @media ${u.device.tablet} {
    font-weight: 700;
    font-size: 20px;
  }
`;

S.ArrowIconWrap = styled.div`
  ${(p) => (p.isCircle ? "margin: 0 0 0 4px" : "margin: 0")};
  transform: translate(0, 2px);
`;
