import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import combineGlobalReducer from "./global/combineGlobalReducer";
import combineMemberReducer from "./member/combineMemberReducer";

const appReducer = combineReducers({
  member: combineMemberReducer(),
  global: combineGlobalReducer(),
});

export const rootReducer = (state: RootState, action: Action) => {
  return appReducer(state, action);
};

export const store = configureStore({ reducer: rootReducer });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
