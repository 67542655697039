import React from "react";

export default function Modal(props) {
  React.useEffect(onBlockScroll, []);

  return props.children;

  function onBlockScroll() {
    document.body.setAttribute("style", "overflow: hidden;");
    return () => document.body.setAttribute("style", "overflow: visible;");
  }
}
