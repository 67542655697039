export default [
  "Accounting and Finance",
  "Advertising and PR",
  "Business Consultancy and Management",
  "Career/Business Coach",
  "Charity and Volunteer Work",
  "Consumer packaged goods",
  "Creative Arts and Design",
  "Digital",
  "Education",
  "Energy and Utilities",
  "Engineering and Manufacturing",
  "Environmental and Agricultural",
  "Fashion",
  "Food and Beverage",
  "GLAM",
  "Government",
  "Healthcare",
  "Hospitality and Events Management",
  "Information Technology",
  "Law",
  "Law Enforcement and Security",
  "Leisure",
  "Life & Wellness",
  "Marketing",
  "Media",
  "Property and Construction",
  "Publishing",
  "Recruitment and HR",
  "Sport and Tourism",
  "Technology",
];

export const onboardingIndustries = [
  "Accommodation",
  "Administrative & Support Services",
  "Charity / Not for Profit",
  "Construction",
  "Consumer Service",
  "Education",
  "Entertainment Providers",
  "Farming",
  "Financial Services",
  "Government Administration",
  "Holding Companies",
  "Health Care",
  "Manufacturing",
  "Oil, Gas, Mining",
  "Professional services",
  "Real Estate",
  "Retail",
  "Technology, Information & Media",
  "Transportation, Logistics & Supply Chain",
  "Utilities",
] as const;
