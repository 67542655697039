import config from "../config.client";
import * as u from "../utils";
import paths from "../utils/paths";
import { sendToGTM } from "@elgorditosalsero/react-gtm-hook";

export const identifyUser = (userId) => {
  sendToGTM({
    dataLayerName: "dataLayer",
    data: {
      user_id: userId,
    },
  });
};
export const pageview = (url) => {
  const base = url.split("?")[0];

  sendToGTM({
    dataLayerName: "dataLayer",
    data: {
      event: "page_view",
      page_location: `${config.ALLBRIGHT_URL}${url}`,
      page_title: paths[base]?.title ?? base,
      page_path: base,
    },
  });
};

export const event = (event: GaEventType) => {
  if (!u.localStorage.getItem("@CAN_TRACK") || !event.name) return;

  const gaEvent = {
    event: event.name,
    value: event.value,
    page: location.pathname,
  };

  if (event.category) gaEvent["category"] = event.category;
  if (event.label) gaEvent["label"] = event.label;

  sendToGTM({
    dataLayerName: "dataLayer",
    data: gaEvent,
  });
};

type GaEventType = {
  name: string;
  category?: string;
  label?: string;
  value: string | number;
};

export enum GA_EVENT {
  ACADEMY_SHOW_MORE = "academy_show_more",
  ACADEMY_CHAPTER = "academy_chapter",
  ACADEMY_ENGAGEMENT = "academy_engagement",
  ACCOUNT_ENGAGEMENT = "account_engagement",
  RECOMMENDATION_TRIGGER = "recommendation_engagement",
  AB_TEST = "ab_test",
  CONNECT_ENGAGEMENT = "connect_engagement",
  CUSTOMER_CONTACT = "customer_contact",
  DIGITAL_REGISTRATION = "digital_registration",
  DIGITAL_ONBOARDING = "digital_onboarding",
  DIGITAL_PAYMENT = "digital_payment",
  DIGITAL_ENGAGEMENT = "digital_engagement",
  EDIT_ENGAGEMENT = "edit_engagement",
  EVENT_ENGAGEMENT = "event_engagement",
  EXCEPTION = "exception",
  FEED_SHOW_MORE = "feed_show_more",
  GROUP_ENGAGEMENT = "group_engagement",
  MARKETING_ENGAGEMENT = "marketing_engagement",
  MAYFAIR_AMENITIES = "mayfair_amenities",
  MEMBERSHIP_ENGAGEMENT = "membership_engagement",
  MENTORSHIP_ENGAGEMENT = "mentorship_engagement",
  MESSAGE_ENGAGEMENT = "message_engagement",
  PLATFORM_ENGAGEMENT = "platform_engagement",
  SIGN_UP = "sign_up",
  LOGIN = "login",
  SOCIAL_ENGAGEMENT = "social_engagement",
  USER_UPDATE = "user_update",
  USER_LOGOUT = "user_logout",
  USER_RESET_PASSWORD = "user_reset_password",
  USER_LAST_ACTIVE = "user_last_active",
}
