import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import goals from "../../types/user/goals";
import { InterestTitles, interests } from "../../types/user/interests";
import salary from "../../types/user/salary";
import organisationSize from "../../types/user/organisationSize";
import UserClient from "../../client/user/UserClient";
import { UserModel } from "../../lib/Domain/User/User";
import { AppDispatch } from "../index";
import { CareerGoalType } from "../../types/user/careerGoals";
import { ErrorStateActions } from "../global/errorSlice";
import UnknownError from "../../types/errors/UnknownError";
import { LocalImageType } from "../../types/files/localImage";
import { ethnicGroups } from "../../types/user/ethnicGroups";
import { onboardingIndustries } from "../../utils/industries";
import { cities } from "../../types/user/cities";
import { jobLevels } from "../../types/user/jobLevels";
import { jobStatus } from "../../types/user/jobStatus";
import OnboardingClient from "../../client/user/OnboardingClient";
import { WritableDraft } from "immer/src/types/types-external";

interface OnboardingState {
  profile: {
    id: string | null;
    city: (typeof cities)[number]["city"] | null;
    jobTitle: string | null;
    jobCompany: string | null;
    jobLevel: (typeof jobLevels)[number] | null;
    jobStatus: (typeof jobStatus)[number] | null;
    jobIndustry: (typeof onboardingIndustries)[number] | null;
    goals: (typeof goals)[number][];
    imageSrc: string | null;
    bio: string | null;
    interests: InterestTitles;
    salary: (typeof salary)[number] | null;
    dateOfBirth: Date | null;
    organisationSize: (typeof organisationSize)[number] | null;
    ethnicGroups: (typeof ethnicGroups)[number][];
    careerGoals: CareerGoalType["id"][];
    agreedToPledge: boolean | null;
  };
  localImage: {
    state: LocalImageType;
    file: string | null;
  };
  data: {
    cities: (typeof cities)[number]["city"][];
    jobLevels: (typeof jobLevels)[number][];
    industries: (typeof onboardingIndustries)[number][];
    goals: (typeof goals)[number][];
    interests: typeof interests;
    jobStatus: (typeof jobStatus)[number][];
    salary: (typeof salary)[number][];
    organisationSize: (typeof organisationSize)[number][];
    ethnicGroups: (typeof ethnicGroups)[number][];
  };
}

const initialState: OnboardingState = {
  profile: {
    id: null,
    city: null,
    jobTitle: null,
    jobCompany: null,
    jobLevel: null,
    jobStatus: null,
    jobIndustry: null,
    goals: [],
    imageSrc: null,
    bio: null,
    interests: [],
    salary: null,
    dateOfBirth: null,
    organisationSize: null,
    ethnicGroups: [],
    careerGoals: [],
    agreedToPledge: null,
  },
  localImage: {
    state: LocalImageType.FILE_NOT_SET,
    file: null,
  },
  data: {
    cities: [],
    jobLevels: [],
    industries: [],
    goals: [],
    interests: [],
    jobStatus: [],
    salary: [],
    organisationSize: [],
    ethnicGroups: [],
  },
};

export type onboardingKey = keyof OnboardingState["profile"];
export type onboardingValue = OnboardingState["profile"][onboardingKey];

type onboardingDataKey = keyof OnboardingState["data"];
type onboardingDataValue = OnboardingState["data"][onboardingDataKey];

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    initiate: (state, action: PayloadAction<{ user: UserModel }>) => {
      if (!action.payload.user.id) return;

      state.profile = {
        id: action.payload.user.id,
        city: action.payload.user.city || null,
        jobTitle: action.payload.user.jobTitle || null,
        jobCompany: action.payload.user.jobCompany || null,
        jobLevel: action.payload.user.jobLevel || null,
        jobStatus: action.payload.user.jobStatus || null,
        jobIndustry: action.payload.user.jobIndustry || null,
        goals: action.payload.user.goals || [],
        imageSrc: action.payload.user.imageSrc || null,
        bio: action.payload.user.bio || null,
        interests: action.payload.user.interests || [],
        salary: action.payload.user.salary || null,
        dateOfBirth: action.payload.user.dateOfBirth || null,
        organisationSize: action.payload.user.organisationSize || null,
        ethnicGroups: action.payload.user.ethnicGroups || [],
        careerGoals: action.payload.user.careerGoals || [],
        agreedToPledge: action.payload.user.agreedToPledge || false,
      };
    },
    setupData: (
      state,
      action: PayloadAction<WritableDraft<OnboardingState["data"]>>
    ) => {
      state.data = action.payload;
    },
    refreshData: (
      state,
      action: PayloadAction<{
        key: onboardingDataKey;
        value: onboardingDataValue;
      }>
    ) => {
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.value,
      };
    },
    setField: (
      state,
      action: PayloadAction<{ key: onboardingKey; value: onboardingValue }>
    ) => {
      state.profile = {
        ...state.profile,
        [action.payload.key]: action.payload.value,
      };
    },
    setProfileImage: (state, action: PayloadAction<{ file: string }>) => {
      state.localImage.file = action.payload.file;
      state.localImage.state = LocalImageType.FILE_SET;
    },
    profileImageWanted: (state, action: PayloadAction<{ set: boolean }>) => {
      state.localImage.state = action.payload.set
        ? LocalImageType.FILE_SET
        : LocalImageType.FILE_UNSET;
    },
  },
});

export const OnboardingStateActions = {
  setField: onboardingSlice.actions.setField,
  setProfileImage: onboardingSlice.actions.setProfileImage,
  profileImageWanted: onboardingSlice.actions.profileImageWanted,

  refreshCities: (country: string) => async (dispatch: AppDispatch) => {
    dispatch(
      onboardingSlice.actions.refreshData({
        key: "cities",
        value: new OnboardingClient().getCities(country),
      })
    );
  },
  fetchOnboardingData: (userId: string) => async (dispatch: AppDispatch) => {
    const onboardingClient = new OnboardingClient();
    const user = await new UserClient().findUserById(userId);

    const onboardingData = {
      cities: onboardingClient.getCities(user?.country || "United Kingdom"),
      jobLevels: onboardingClient.getJobLevels() as WritableDraft<
        (typeof jobLevels)[number][]
      >,
      jobStatus: onboardingClient.getJobStatus() as WritableDraft<
        (typeof jobStatus)[number][]
      >,
      industries: onboardingClient.getIndustries() as WritableDraft<
        (typeof onboardingIndustries)[number][]
      >,
      salary: onboardingClient.getSalary(user.country) as WritableDraft<
        (typeof salary)[number][]
      >,
      organisationSize: onboardingClient.getOrganisationSize() as WritableDraft<
        (typeof organisationSize)[number][]
      >,
      goals: onboardingClient.getGoals() as WritableDraft<
        (typeof goals)[number][]
      >,
      interests: onboardingClient.getInterests(),
      ethnicGroups: onboardingClient.getEthnicGroups() as WritableDraft<
        (typeof ethnicGroups)[number][]
      >,
    };

    const ethnicGroupIds = onboardingData.ethnicGroups.map(
      (ethnicGroup) => ethnicGroup.id
    );
    const userFiltered: UserModel = {
      ...user,
      city: onboardingData.cities.includes(user.city) ? user.city : null,
      jobLevel: onboardingData.jobLevels.includes(user.jobLevel)
        ? user.jobLevel
        : null,
      jobStatus: onboardingData.jobStatus.includes(user.jobStatus)
        ? user.jobStatus
        : null,
      jobIndustry: onboardingData.industries.includes(user.jobIndustry)
        ? user.jobIndustry
        : null,
      salary: onboardingData.salary.includes(user.salary) ? user.salary : null,
      organisationSize: onboardingData.organisationSize.includes(
        user.organisationSize
      )
        ? user.organisationSize
        : null,
      goals: (user.goals || []).filter((goal) =>
        onboardingData.goals.includes(goal)
      ),
      interests: (user.interests || []).filter((interest) =>
        onboardingData.interests.includes(interest)
      ),
      ethnicGroups: (user.ethnicGroups || []).filter((ethnicity) =>
        ethnicGroupIds.includes(ethnicity.id)
      ),
    };

    dispatch(onboardingSlice.actions.initiate({ user: userFiltered }));
    dispatch(onboardingSlice.actions.setupData(onboardingData));
  },

  saveOnboardingData:
    (
      userToSave: Partial<OnboardingState["profile"]>,
      localImage: OnboardingState["localImage"] = initialState.localImage
    ) =>
    async (dispatch): Promise<boolean> => {
      let user = { ...userToSave };

      try {
        const client = new UserClient();

        if (
          localImage.state === LocalImageType.FILE_SET &&
          localImage.file !== null
        ) {
          user = {
            ...user,
            imageSrc: await client.updateUserImage(localImage.file),
          };
        }

        if (localImage.state === LocalImageType.FILE_UNSET) {
          user = { ...user, imageSrc: null };
        }

        await client.updateUser(user);

        dispatch(
          onboardingSlice.actions.setField({
            key: "imageSrc",
            value: user.imageSrc,
          })
        );
      } catch (e) {
        dispatch(
          ErrorStateActions.addError(
            new UnknownError(
              "Something went wrong, we could not save your information",
              "onboarding.api.error"
            )
          )
        );
        return false;
      }

      return true;
    },
};

export default onboardingSlice.reducer;
