export const BACK_TO = "@BACK_TO";
export const CHAT_TOKEN = "@CHAT_TOKEN";
export const EDIT_PUBS = "@EDIT_PUBS";
export const EDIT_TIME = "@EDIT_TIME";
export const FEED_TOKEN = "@FEED_TOKEN";
export const IS_BOARDING = "@IS_BOARDING";
export const RETURN_TO = "@RETURN_TO";
export const SIGNUP_TYPE = "@SIGNUP_TYPE";
export const USER_INFO = "@USER_INFO";
export const CACHE_CONN_IDS = "@CACHE_CONN_IDS";
export const SALESFORCE_ACK =
  '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:out="http://soap.sforce.com/2005/09/outbound"><soapenv:Header/><soapenv:Body><out:notificationsResponse><out:Ack>true</out:Ack></out:notificationsResponse></soapenv:Body></soapenv:Envelope>';
